import { useContext } from "react";
import { OrderDetailsContext } from "..";
import { Button, Icon, Modal, Text } from "../../../../components";
import { PickupStatus } from "../../../../enums";
import { useAxios } from "../../../../hooks";
import { togglePropsType } from "../../../../types";
import { LineItemContext } from "./LineItemRow";

export default function PickupConfirm({ isOpen, toggle }: togglePropsType) {
  const { axios, loading } = useAxios();
  const { order } = useContext(OrderDetailsContext);
  const { lineItem, updateLineItem, lineItemLoading } =
    useContext(LineItemContext);
  const articleNumber = lineItem.product?.articleNumber;
  const hasArticleNumber = !!articleNumber;
  // const hasDeliveryDate = !!lineItem.desiredDeliveryDate;
  const hasLoading = lineItemLoading || loading.post;
  const submit = () => {
    const url = "/salesservice/api/orderlineitems/change-pickup-status";
    const body = {
      orderId: order.id,
      orderLineItemIds: [lineItem.id],
      pickupRequestType: PickupStatus.PickupRequested,
    };
    axios.post(url, body).then(() => {
      updateLineItem().then(() => {
        toggle();
      });
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6 className="text-base text-dark font-medium">
          <Text>pickup.pickupConfirm.title</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <p className="text-sm text-secondary font-medium">
          <Text>pickup.pickupConfirm.text</Text>
        </p>
        <div className="flex-1 flex items-center text-start gap-4">
          <div className="flex-center size-11">
            <Icon.Wrapper variant="primary">
              <Icon name="Box1" variant="Bold" />
            </Icon.Wrapper>
          </div>
          <div className="flex-1 space-y-px">
            <h6 className="w-full text-base font-normal text-balance max-w-72 whitespace-normal line-clamp-2">
              {lineItem.title}
            </h6>
            {hasArticleNumber && (
              <p className="block text-secondary">#{articleNumber}</p>
            )}
          </div>
        </div>
        {/* {hasDeliveryDate && (
          <Alert
            variant="warning"
            light
            className="border-none bg-opacity-10 p-2"
            IconProps={{ variant: "Bulk", size: 20 }}
          >
            <Alert.Text className="text-warning">
              <Text>pickup.pickupConfirm.deliveryDateAlert</Text>
            </Alert.Text>
          </Alert>
        )} */}
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button variant="danger" onClick={toggle} disabled={hasLoading}>
          <Text>pickup.pickupConfirm.cancelButton</Text>
        </Button>
        <Button variant="primary" onClick={submit} loading={hasLoading}>
          <Text>pickup.pickupConfirm.submitButton</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
