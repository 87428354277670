import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Icon,
  Layout,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
  Wrapper,
} from "../../../components";
import { config } from "../../../constants";
import { CustomerType } from "../../../enums";
import { withPermission } from "../../../hoc";
import { useAxios, useConverters, useToggle } from "../../../hooks";
import { Order, WithPaging } from "../../../types";
import FilterDrawer from "./FilterDrawer";
import SaleChannelFilter from "./SaleChannelFilter";

function OrdersList() {
  const initParams: Order.Params = {
    pageNumber: 1,
    pageSize: config.pageSize,
    channelCode: null,
  };
  const { axios, loading } = useAxios();
  const { convertAmount, convertDate } = useConverters();
  const [data, setData] = useState<WithPaging<Order.Item>>({});
  const [params, setParams] = useState(initParams);
  const [showFilter, toggleFilter, hideFilter] = useToggle(false);
  const handleSetParams = (key: keyof Order.Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: any) => {
      return setParams((p) => ({
        ...p,
        [key]: value,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
    };
  };
  const getData = () => {
    const url = "/salesservice/api/orders";
    const config = { params };
    setData({});
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <SearchBox
            onSubmit={handleSetParams("keyword")}
            className="w-auto flex-1"
          />
          <Button light onClick={toggleFilter}>
            <Icon name="Filter" variant="Bold" size={20} />
          </Button>
        </Layout.Header>
        <Layout.Body className="space-y-4">
          <SaleChannelFilter
            value={params.channelCode ?? null}
            setValue={handleSetParams("channelCode")}
            disabled={loading.get}
          />
          <Wrapper>
            <Wrapper.Body>
              <Table.Wrapper>
                <Table>
                  <Table.Body loading={loading.get}>
                    {data.items?.map((e) => {
                      const isCompany =
                        e.customer?.customerType === CustomerType.Company;
                      const isPublished = !!e.lastPublishedDate;
                      const channel = e.saleChannel;
                      const hasChannel = !!channel;
                      const to = {
                        pathname: `/orders/${e.id}`,
                        search: `channelCode=${e.channelCode}`,
                      };
                      return (
                        <tr key={e.id}>
                          <td>
                            <Link to={to} className="flex-center w-fit gap-4">
                              <div className="flex-center w-10 h-10 rounded-full text-primary bg-primary/5">
                                <Icon
                                  name="ReceiptText"
                                  variant="Bold"
                                  size={28}
                                />
                              </div>
                              <div>
                                <h6 className="text-base text-dark font-medium">
                                  {e.title}
                                </h6>
                                <p className="text-sm text-secondary font-medium">
                                  #{e.number}
                                </p>
                                {hasChannel && (
                                  <p className="flex items-center gap-1 text-sm text-secondary font-medium">
                                    <Icon
                                      name="DirectboxDefault"
                                      variant="Bulk"
                                      className="text-primary size-3.5"
                                    />
                                    {channel.title}
                                  </p>
                                )}
                              </div>
                            </Link>
                          </td>
                          {/* <td>
                        <Link to={`/offers/${e.offerId}`} className="space-y-1">
                        <span className="block">
                            <Icon
                              name="Link1"
                              size={18}
                              className="text-primary"
                            />{" "}
                            {e.offerTitle}
                            </span>
                          <span className="block text-secondary">
                            #{e.orderNumber}
                          </span>
                        </Link>
                      </td> */}
                          <td>
                            <Link
                              to={`/${isCompany ? "companies" : "customers"}/${
                                e.customer?.customerId
                              }`}
                              className="space-y-1"
                            >
                              <h6 className="block">
                                <Icon
                                  name="Link1"
                                  size={18}
                                  className="text-primary"
                                />{" "}
                                {isCompany
                                  ? e.customer?.companyName
                                  : [
                                      e.customer?.firstName,
                                      e.customer?.lastName,
                                    ].join(" ")}
                              </h6>
                              <span className="block text-secondary">
                                #{e.customer?.cimNumber}
                              </span>
                            </Link>
                          </td>
                          <td className="text-secondary">
                            {convertAmount(e.totalAmount)}
                          </td>
                          <td className="space-y-1">
                            <p className="text-sm text-dark">
                              <Text>global.orderVersion</Text> {e.version}
                            </p>
                            <p className="text-sm text-secondary">
                              <Text>global.createdAt</Text> (
                              {convertDate(e.createdAt)})
                            </p>
                          </td>
                          <td className="text-center space-y-1">
                            <Status.Order isPublished={isPublished} />
                            {isPublished && (
                              <Fragment>
                                <p className="text-sm text-secondary">
                                  <Text>global.orderLastPublishAt</Text>{" "}
                                  {convertDate(e.lastPublishedDate)}
                                </p>
                                <p className="text-sm text-secondary">
                                  <Text>global.orderLastPublishedVersion</Text>{" "}
                                  {e.lastPublishedVersion}
                                </p>
                              </Fragment>
                            )}
                          </td>
                          <td data-sticky>
                            <Button as={Link} to={to} light>
                              <Text>button.viewDetails</Text>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Table.Wrapper>
            </Wrapper.Body>
          </Wrapper>
          <Pagination
            pageNumber={params.pageNumber}
            setActivePage={handleSetParams("pageNumber")}
            totalItems={data.totalItems}
            totalPages={data.totalPages}
          />
        </Layout.Body>
      </Layout>
      <FilterDrawer
        isOpen={showFilter}
        toggle={hideFilter}
        initParams={initParams}
        params={params}
        onSubmit={setParams}
      />
    </Fragment>
  );
}
export default withPermission(OrdersList, ["SC_GetSaleServiceOrder"]);
