import { Fragment, useCallback, useContext } from "react";
import { CustomerContext } from ".";
import { Button, Icon, Modal, Status, Text } from "../../../components";
import { dateFormats } from "../../../constants";
import { CustomerStatus } from "../../../enums";
import { useConverters } from "../../../hooks";
import { showCallNumber } from "../../../methods";
import { togglePropsType } from "../../../types";
export default function AccountInfo({ isOpen, toggle }: togglePropsType) {
  const { convertDate } = useConverters();
  const { customerData, customerHasAccount, customerAccountInfo } =
    useContext(CustomerContext);

  const hasAccount = customerHasAccount && !!customerAccountInfo;
  const RegisterInfo = useCallback(() => {
    const isOnboarding =
      customerAccountInfo?.status === CustomerStatus.Onboarding;
    if (!hasAccount)
      return (
        <Fragment>
          <span className="text-danger">
            <Icon name="CloseCircle" size={20} />{" "}
            <Text>alertDescriptions.haveNotMobileAccount</Text>
          </span>
        </Fragment>
      );
    if (isOnboarding)
      return (
        <span className="text-warning">
          <Text>alertDescriptions.customerRegistrationNotCompleted</Text>
        </span>
      );
    return (
      <span className="text-secondary">
        {convertDate(customerAccountInfo.registeredAt, dateFormats.fullDate)}
      </span>
    );
  }, [hasAccount, customerAccountInfo]);
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <Text>modalTitles.mobileAccountInfo</Text>
      </Modal.Header>
      <Modal.Body className="space-y-6">
        <div className="flex-center flex-col space-y-2">
          <Icon name="User" size={25} className="text-primary" />
          <h6>
            {hasAccount ? (
              <Fragment>
                {customerAccountInfo?.firstName} {customerAccountInfo?.lastName}
              </Fragment>
            ) : (
              <Fragment>
                {customerData?.firstName} {customerData?.lastName}
              </Fragment>
            )}
          </h6>
          <span>CIM: {customerData?.cimNumber}</span>
          <span className="text-secondary">
            {hasAccount
              ? showCallNumber(customerAccountInfo.mobileNumber)
              : "---"}
          </span>
          <Status.Customer id={customerAccountInfo?.status} />
        </div>
        <hr className="w-full bg-gray" />
        <div className="flex-center flex-col space-y-2">
          {hasAccount && (
            <Fragment>
              <Icon name="Mobile" size={25} className="text-primary" />
              <h6>
                <Text>global.customerRegistrationAt</Text>:
              </h6>
            </Fragment>
          )}
          <RegisterInfo />
        </div>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button light onClick={toggle}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
