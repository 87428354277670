import Base64 from "crypto-js/enc-base64";
import hmacSHA512 from "crypto-js/hmac-sha512";
import sha256 from "crypto-js/sha256";

export default function hash(str: string) {
  const privateKey = "sales tools";
  const hashDigest = sha256(str);
  const hmacDigest = Base64.stringify(hmacSHA512(hashDigest, privateKey));
  return hmacDigest;
}
