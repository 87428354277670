import { createSlice } from "@reduxjs/toolkit";

const selectSaleChannelActions = createSlice({
  name: "selectSaleChannelActions",
  initialState: {
    showSelectSaleChannelModal: false,
    showSelectSaleChannelMenu: false,
  },
  reducers: {
    toggleSelectSaleChannelModal: (state) => {
      state.showSelectSaleChannelModal = !state.showSelectSaleChannelModal;
      return state;
    },
    toggleSelectSaleChannelMenu: (state) => {
      state.showSelectSaleChannelMenu = !state.showSelectSaleChannelMenu;
      return state;
    },
  },
});

export const { toggleSelectSaleChannelMenu, toggleSelectSaleChannelModal } =
  selectSaleChannelActions.actions;
export default selectSaleChannelActions.reducer;
