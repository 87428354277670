import { Address } from "../types";

export default function useHasAddress(address: Address | null) {
  if (!address) return false;
  const keys: (keyof Address)[] = [
    "street",
    "number",
    "postalCode",
    "city",
    "country",
    "state",
  ];
  const values = keys.map((key) => address[key]);
  return values.some(Boolean);
}
