import { Fragment } from "react";
import { Button, Icon, Text } from "../components";
import { SelectedLineItem } from "./_ChooseIncidentLineItem";

type IncidentLineItemCardProps = {
  lineItem: SelectedLineItem | null;
  onSelectLineItem: () => void;
  onClear?: (value: null) => void;
};
export default function IncidentLineItemCard({
  lineItem,
  onSelectLineItem,
  onClear,
}: IncidentLineItemCardProps) {
  const hasLineItem = !!lineItem && Object.values(lineItem ?? {}).some(Boolean);
  const canClear = !!onClear && hasLineItem;
  const hasArticleNumber = !!lineItem?.product?.articleNumber;
  const hasProgramCode = !!lineItem?.supplierProgram?.code;
  const hasSupplierIln = !!lineItem?.supplier?.iln;
  return (
    <section className="border rounded p-2 space-y-4">
      <h6 className="text-base text-dark">
        <Text>incident.form.attachLineItem</Text>
      </h6>
      <div className="flex items-start gap-2">
        {hasLineItem && <Icon name="Box1" size={25} className="text-primary" />}
        <div className="flex-1 space-y-1">
          {hasLineItem ? (
            <Fragment>
              <h6 className="text-base text-dark">{lineItem.title}</h6>
              {hasArticleNumber && (
                <p className="text-sm text-secondary">
                  #{lineItem.product?.articleNumber}
                </p>
              )}
              {hasProgramCode && (
                <p className="text-sm text-secondary">
                  <Text>tableTitles.lineItemProgramCode</Text>:{" "}
                  {lineItem.supplierProgram?.code}
                </p>
              )}
              {hasSupplierIln && (
                <p className="text-sm text-secondary">
                  <Text>tableTitles.lineItemSupplierILN</Text>:{" "}
                  {lineItem.supplier?.iln}
                </p>
              )}
            </Fragment>
          ) : (
            <Button type="button" light onClick={onSelectLineItem}>
              <Icon name="Add" /> <Text>incident.form.attachLineItemBtn</Text>
            </Button>
          )}
        </div>
        {canClear && (
          <Button
            type="button"
            variant="danger"
            light
            onClick={() => onClear(null)}
          >
            <Icon name="Trash" variant="Bold" />
          </Button>
        )}
      </div>
    </section>
  );
}
