import { Fragment, useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import CurrentPhase from "./CurrentPhase";
import Price from "./Price";
import SalesStatus from "./SalesStatus";
import SalesTimeLine from "./SalesTimeLine";
import SelectTemplate from "./SelectTemplate";

export default function SalesProcess() {
  const { isPreparation } = useContext(SellOffProductDetailsContext);
  return (
    <Fragment>
      {isPreparation ? <SelectTemplate /> : <CurrentPhase />}
      <SalesTimeLine />
      {!isPreparation && <Price />}
      {!isPreparation && <SalesStatus />}
    </Fragment>
  );
}
