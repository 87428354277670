import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import { ChangeButton, Drawer, Icon, Text } from "../../../../../components";
import { ProgramCard, SupplierCard } from "../../../../../containers";
import { Program, Supplier, togglePropsType } from "../../../../../types";
import ManualForm from "./ManualForm";
import Programs from "./Programs";
import Suppliers from "./Suppliers";

type CreateFromCatalogueProps = togglePropsType & {
  parentId: string | null;
};
type CreateFromCatalogueContextType = {
  parentId: string | null;
  supplier: Supplier.Item | null;
  setSupplier: Dispatch<SetStateAction<Supplier.Item | null>>;
  program: Program.Item | null;
  setProgram: Dispatch<SetStateAction<Program.Item | null>>;
  toggle: () => void;
};

export const CreateFromCatalogueContext = createContext(
  {} as CreateFromCatalogueContextType
);

export default function CreateFromCatalogue({
  isOpen,
  toggle,
  parentId,
}: CreateFromCatalogueProps) {
  const [supplier, setSupplier] = useState<Supplier.Item | null>(null);
  const [program, setProgram] = useState<Program.Item | null>(null);
  const hasSupplier = !!supplier;
  const hasProgram = !!program;
  const hasProductGroup = !!program?.pimProductGroupId;
  const ActiveStep = useMemo(() => {
    if (!supplier) return Suppliers;
    if (!program) return Programs;
    return ManualForm;
  }, [supplier, program]);
  const activeTitle = useMemo(() => {
    if (!supplier) return "drawerTitles.chooseSupplier";
    if (!program) return "drawerTitles.chooseProgram";
    return "drawerTitles.createProductFromCatalogue";
  }, [supplier, program]);
  const clearSupplier = () => {
    setSupplier(null);
  };
  const clearProgram = () => {
    setProgram(null);
  };
  const handleClearData = () => {
    if (!!program) return clearProgram();
    return clearSupplier();
  };
  const handleToggle = () => {
    clearSupplier();
    clearProgram();
    toggle();
  };
  return (
    <Drawer isOpen={isOpen} toggle={handleToggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base text-dark text-center">
            <Text>{activeTitle}</Text>
          </h6>
        </Drawer.Header>
        {hasSupplier && (
          <Drawer.Header className="flex flex-center text-start border-b-0">
            <SupplierCard supplier={supplier} className="flex-1" />
            <ProgramCard program={program} className="flex-1" />
            <ChangeButton onClick={handleClearData} />
          </Drawer.Header>
        )}
        {hasProductGroup && (
          <Drawer.Header className="flex flex-center text-start border-dashed">
            <div className="flex-1 flex flex-center w-full gap-4">
              <Icon.Wrapper rounded>
                <Icon name="TableDocument" variant="Bold" />
              </Icon.Wrapper>
              <div className="flex-1">
                <h6 className="truncate">{program?.pimProductGroupName}</h6>
                <span className="block truncate text-secondary">
                  #{program?.pimProductGroupCode}
                </span>
              </div>
            </div>
          </Drawer.Header>
        )}
        <CreateFromCatalogueContext.Provider
          value={{
            parentId,
            supplier,
            setSupplier,
            program,
            setProgram,
            toggle: handleToggle,
          }}
        >
          {isOpen && <ActiveStep />}
        </CreateFromCatalogueContext.Provider>
      </Drawer.Menu>
    </Drawer>
  );
}
