import { cloneDeep } from "lodash";
import {
  createContext,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import checkIcon from "../../../assets/icons/check.svg";
import {
  Button,
  CheckBox,
  Form,
  Icon,
  Layout,
  Modal,
  Text,
  Wrapper,
} from "../../../components";
import { types } from "../../../constants";
import { CountryCode } from "../../../enums";
import { withPermission } from "../../../hoc";
import {
  useAxios,
  useHasAddress,
  useToggle,
  useValidateAddress,
} from "../../../hooks";
import { Customer } from "../../../types";
import BasicInfo from "./BasicInfo";
import Completed from "./Completed";
import ContactInfo from "./ContactInfo";
import Location from "./Location";
type ContextType = {
  data: Customer.Create;
  setData: Dispatch<SetStateAction<Customer.Create>>;
  hasAddress: boolean;
  hasPhoneNumber: boolean;
};
export const AddCustomerDataContext = createContext({} as ContextType);
function AddNewCustomer() {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const [validateAddress, validateLoading] = useValidateAddress();
  const [showTermModal, toggleTermModal] = useToggle(false);
  const steps = [
    {
      title: "tabs.basicInfo",
      text: "tabsDescription.basicInfo",
      component: BasicInfo,
    },
    {
      title: "tabs.contactInfo",
      text: "tabsDescription.contactInfo",
      component: ContactInfo,
    },
    {
      title: "tabs.location",
      text: "tabsDescription.location",
      component: Location,
    },
    {
      title: "tabs.completed",
      text: "tabsDescription.completed",
      component: Completed,
    },
  ].map((e, id) => ({ ...e, id }));
  const initData: Customer.Create = {
    firstName: "",
    lastName: "",
    gender: types.gender[0].id,
    salutation: types.salutation[0].id,
    birthdate: null,
    emailAddress: "",
    mobileNumber: {
      countryCode: CountryCode.De,
      number: NaN,
    },
    phoneNumber: {
      countryCode: CountryCode.De,
      number: NaN,
    },
    address: {
      city: null,
      location: null,
      name: null,
      number: null,
      postalCode: null,
      state: null,
      street: null,
      country: null,
      countryCode: null,
      countryCodeISO3: null,
      countrySubdivision: null,
      countrySubdivisionName: null,
    },
    gdprAccepted: false,
  };
  const [data, setData] = useState(initData);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const ActiveStep = steps[activeStepIndex].component;
  const isFirstStep = activeStepIndex === 0;
  const isLocationStep = activeStepIndex === 2;
  const isLastStep = activeStepIndex === steps.length - 1;
  const hasPhoneNumber = !!data.phoneNumber?.number;
  const hasAddress = useHasAddress(data.address);
  const toggleGDPR = () => {
    setData((p) => {
      const data = cloneDeep(p);
      data.gdprAccepted = !data.gdprAccepted;
      return data;
    });
  };
  const goToNextStep = () => {
    setActiveStepIndex((p) => p + 1);
  };
  const goToPrevStep = () => {
    setActiveStepIndex((p) => p - 1);
  };
  const submitNextStep = () => {
    if (!isLocationStep || !hasAddress) return goToNextStep();
    validateAddress(data.address).then(({ isValid, suggested }) => {
      if (isValid) return goToNextStep();
      if (!suggested) return toast.error("errorCodes.inValidAddress");
      toast("toast.warning.suggestedAddress");
      setData((p) => ({ ...p, address: suggested }));
    });
  };
  const submit = () => {
    const url = "/accountservice/api/customers";
    const body = {
      ...data,
      ...(!hasAddress && { address: null }),
      ...(!hasPhoneNumber && { phoneNumber: null }),
    };
    if (!hasPhoneNumber) {
      body.phoneNumber = null;
    }
    axios.post(url, body).then(({ data: id }) => {
      const message = "toast.success.addCustomer";
      toast.success(message);
      navigate(`/customers/${id}`, { replace: true });
    });
  };
  return (
    <Fragment>
      <Layout
        as={Form}
        onSubmit={isLastStep ? toggleTermModal : submitNextStep}
      >
        <Layout.Header>
          <h6 className="mr-auto">
            <Text>page.addNewCustomer.title</Text>
          </h6>
          {isFirstStep ? (
            <Button as={Link} to=".." variant="danger">
              <Text>button.cancel</Text>
            </Button>
          ) : (
            <Button light variant="primary" onClick={goToPrevStep}>
              <Text>button.back</Text>
            </Button>
          )}
          {isLastStep ? (
            <Button type="submit" variant="success">
              <Text>button.submitCustomer</Text>
            </Button>
          ) : (
            <Button type="submit" loading={validateLoading}>
              <Text>button.next</Text>
            </Button>
          )}
        </Layout.Header>
        <Layout.Body className="space-y-4">
          <Wrapper>
            <Wrapper.Body className="flex-center gap-4 py-10">
              {steps.map((e) => (
                <Fragment key={e.id}>
                  <div
                    data-active={e.id === activeStepIndex}
                    data-prev={e.id < activeStepIndex || undefined}
                    className="group flex-center gap-4"
                    onClick={() => {
                      e.id < activeStepIndex && setActiveStepIndex(e.id);
                      // setActiveStepIndex(e.id);
                    }}
                  >
                    <div className="flex-center text-xl bg-primary/10 text-primary w-10 h-10 rounded transition-colors group-data-active:bg-primary group-data-active:text-white">
                      <img
                        src={checkIcon}
                        alt={e.title}
                        className="hidden group-data-[prev]:inline-block w-4 h-4"
                      />
                      <span className="inline-flex group-data-[prev]:hidden">
                        {e.id + 1}
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="truncate">
                        <Text>{e.title}</Text>
                      </h6>
                      <span className="truncate block text-secondary text-sm">
                        <Text>{e.text}</Text>
                      </span>
                    </div>
                  </div>
                  <div
                    data-active={e.id <= activeStepIndex}
                    className="group w-7 h-7 [&>*]:w-full [&>*]:h-full [&>*]:object-contain last:hidden"
                  >
                    <Icon
                      name="ArrowRight"
                      variant="TwoTone"
                      className="text-placeholder group-data-active:text-primary"
                      size={28}
                    />
                  </div>
                </Fragment>
              ))}
            </Wrapper.Body>
          </Wrapper>
          <AddCustomerDataContext.Provider
            value={{ data, setData, hasAddress, hasPhoneNumber }}
          >
            <ActiveStep />
          </AddCustomerDataContext.Provider>
        </Layout.Body>
      </Layout>
      <Modal isOpen={showTermModal} toggle={toggleTermModal}>
        <Modal.Header>
          <Text>page.addNewCustomer.conditions.title</Text>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <div className="text-secondary">
            <Text>page.addNewCustomer.conditions.text</Text>
          </div>
          <CheckBox
            label="page.addNewCustomer.conditions.gdprAccepted"
            value={!!data.gdprAccepted}
            setValue={toggleGDPR}
          />
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggleTermModal}>
            <Text>button.cancel</Text>
          </Button>
          <Button
            disabled={!data.gdprAccepted}
            variant="success"
            onClick={submit}
            loading={loading.post}
          >
            <Text>button.submit</Text>
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
export default withPermission(AddNewCustomer, ["AS_CreateCustomer"]);
