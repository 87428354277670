import { cloneDeep } from "lodash";
import { Dispatch, useContext, useState } from "react";
import toast from "react-hot-toast";
import { IncidentDetailsContext } from "../..";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
} from "../../../../../components";
import {
  dateFormats,
  now,
  rules,
  taskDurationItems,
  types,
} from "../../../../../constants";
import { IncidentAssignedEmployee } from "../../../../../containers";
import { SelectedEmployee } from "../../../../../containers/_IncidentAssignedEmployee";
import {
  CRMActivityType,
  CRMTargetType,
  CRMTaskPriority,
} from "../../../../../enums";
import { useAxios, useConverters } from "../../../../../hooks";
import { Task, togglePropsType } from "../../../../../types";

type ActivityFormProps = togglePropsType & {
  activityType: CRMActivityType | null;
  onSubmitted: Dispatch<string>;
};
type Data = Task.Create;

export default function ActivityForm({
  isOpen,
  toggle,
  activityType,
  onSubmitted,
}: ActivityFormProps) {
  const { incident } = useContext(IncidentDetailsContext);
  const initData: Data = {
    targetId: incident.crmIncidentId,
    targetType: CRMTargetType.Incident,
    priority: CRMTaskPriority.Normal,
    duration: 30,
  };
  const { axios, loading } = useAxios();
  const { convertDate } = useConverters();
  const [data, setData] = useState(initData);
  const [employee, setEmployee] = useState<SelectedEmployee | null>(null);
  const activeType = types.incidentActivity.find((e) => e.id === activityType);
  const hasActiveType = !!activeType;
  const hasLoading = [loading.post, loading.get].some(Boolean);

  const handleToggle = () => {
    setData(initData);
    setEmployee(null);
    toggle();
  };

  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => {
      setData((p) => ({ ...p, [key]: value }));
    };
  };
  const submit = () => {
    const url = "/channelmanagerservice/api/activities/task";
    const body = cloneDeep(data);
    body.assignedId = employee?.id;
    body.assignedType = employee?.type;
    body.duration &&= Number(body.duration);
    body.duration ||= 0;
    axios.post(url, body).then(({ data }) => {
      const message = "incident.activities.createNew.successMessage";
      toast.success(message);
      handleToggle();
      onSubmitted(data);
    });
  };
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={handleToggle}>
      <Drawer.Menu>
        <Drawer.Header className="space-y-1 border-b-0">
          <h6 className="text-base text-dark">
            <Text>incident.activities.createNew.drawerTitle</Text>
          </h6>
          <p className="text-sm text-secondary">
            {convertDate(now, dateFormats.fullDate)}
          </p>
          {hasActiveType && (
            <p className="text-sm text-secondary">
              <Icon name={activeType.icon} /> <Text>{activeType.name}</Text>
            </p>
          )}
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <InputGroup
            label="incident.activities.createNew.subject"
            placeholder="incident.activities.createNew.subjectPlaceholder"
            value={data.subject}
            setValue={handleSetValue("subject")}
            rules={rules.required}
          />
          <InputGroup
            as="textarea"
            label="incident.activities.createNew.description"
            placeholder="incident.activities.createNew.descriptionPlaceholder"
            value={data.description}
            setValue={handleSetValue("description")}
            rules={rules.required}
          />
          <Select
            label="incident.activities.createNew.priority"
            value={data.priority}
            setValue={handleSetValue("priority")}
            // rules={rules.required}
            items={types.taskPriority}
          />
          <Select
            label="incident.activities.createNew.duration"
            value={data.duration}
            setValue={handleSetValue("duration")}
            //  rules={rules.required}
            items={taskDurationItems}
          />
          <InputGroup
            label="incident.activities.createNew.dueDate"
            value={data.dueDate}
            setValue={handleSetValue("dueDate")}
            // rules={rules.required}
            type="date"
          />
          <IncidentAssignedEmployee
            label="incident.activities.createNew.assignEmployee"
            value={employee}
            setValue={setEmployee}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4 bottom-0">
          <Button
            type="button"
            variant="danger"
            onClick={handleToggle}
            disabled={hasLoading}
          >
            <Text>incident.activities.createNew.cancelButton</Text>
          </Button>
          <Button type="submit" variant="primary" loading={hasLoading}>
            <Text>incident.activities.createNew.submitButton</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
