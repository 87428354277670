import { useContext, useState } from "react";
import { SellOffProductDetailsContext } from "..";
import { Button, Image, Modal, Text } from "../../../../components";
import { Image as ImageType, togglePropsType } from "../../../../types";

type Props = togglePropsType;
export default function MediaModal({ isOpen, toggle }: Props) {
  const { basicInfo } = useContext(SellOffProductDetailsContext);
  const media = basicInfo?.assets ?? [];
  const [activeIndex, setActiveIndex] = useState(0);
  const activeMedia = (media[activeIndex] ?? null) as ImageType | null;
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <Modal.Header>
        <h6 className="text-sm font-medium text-dark">
          {basicInfo?.pimProduct?.originalName}
        </h6>
        <p className="text-xs font-medium text-secondary">
          {basicInfo?.pimProduct?.articleNumber}
        </p>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <Image
          src={activeMedia?.url}
          alt={activeMedia?.type}
          className="w-full aspect-image object-contain bg-gray-50"
          isDynamic
        />
        <section className="w-full overflow-auto">
          <div className="flex items-center gap-4 w-fit">
            {media.map((e, i) => (
              <button
                key={e.id}
                type="button"
                className="w-40 aspect-image rounded overflow-auto"
                onClick={() => setActiveIndex(i)}
              >
                <Image
                  src={e.thumbnailUrl}
                  alt={e.type}
                  className="size-full object-contain bg-gray-50"
                  isDynamic
                />
              </button>
            ))}
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button type="button" onClick={toggle} light>
          <Text>button.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
