import { createContext, Fragment } from "react";
import { Button, Icon, Text } from "../../../../components";
import { useConverters, useToggle } from "../../../../hooks";
import { SellOffProduct } from "../../../../types";
import LineItemDetailsDrawer from "./LineItemDetailsDrawer";

type Props = {
  lineItem: SellOffProduct.PimBundleItem;
};
type ContextType = {
  lineItem: SellOffProduct.PimBundleItem;
};
export const LineItemContext = createContext({} as ContextType);
export default function LineItemRow({ lineItem }: Props) {
  const { convertAmount, convertNumber } = useConverters();
  const [showDetails, toggleDetails, hideDetails] = useToggle(false);
  return (
    <Fragment>
      <tr className="!border-t-[#E4E6EF]">
        <td>
          <div className="flex items-center gap-4">
            <div className="flex-1 flex items-center text-start gap-4">
              <div className="flex-center size-11">
                <Icon.Wrapper variant="primary">
                  <Icon name="Box1" variant="Linear" className="size-6" />
                </Icon.Wrapper>
              </div>
              <div className="flex-1 space-y-1">
                <h6 className="w-full text-base font-medium text-dark text-balance max-w-72 whitespace-normal line-clamp-2">
                  {lineItem.originalName}
                </h6>
                <p className="text-xs text-secondary font-medium">
                  #{lineItem.articleNumber}
                </p>
              </div>
            </div>
          </div>
        </td>
        <td>
          <p className="text-sm text-dark font-medium">
            x{convertNumber(lineItem.quantity)}
          </p>
        </td>
        <td className="text-xs font-medium space-y-0.5">
          <p className="text-dark">
            {convertAmount(lineItem.vk1Price?.amount)}
          </p>
          <p className="text-secondary line-through">
            {convertAmount(lineItem.vk2Price?.amount)}
          </p>
        </td>
        <td>
          <Button
            type="button"
            light
            className="btn-sm"
            onClick={toggleDetails}
          >
            <Text>sellOffProducts.details.subProductDetailsButton</Text>
          </Button>
        </td>
      </tr>
      <LineItemContext.Provider value={{ lineItem }}>
        <LineItemDetailsDrawer isOpen={showDetails} toggle={hideDetails} />
      </LineItemContext.Provider>
    </Fragment>
  );
}
