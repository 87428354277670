import { cloneDeep, now } from "lodash";
import {
  createContext,
  createElement,
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  BackButton,
  Button,
  Layout,
  Loading,
  NoItems,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { PimProductType, SellOffProductStatus } from "../../../enums";
import { withPermission } from "../../../hoc";
import { useAxios, useToggle } from "../../../hooks";
import { Optional, SellOffProduct } from "../../../types";
import Attribute from "./Attributes";
import BasicInfo from "./BasicInfo";
import ChangePhaseDrawer from "./ChangePhaseDrawer";
import ClosePhaseModal from "./ClosePhaseModal";
import LineItems from "./LineItems";
import Media from "./Media";
import PriceInfo from "./PriceInfo";
import ProductInfo from "./ProductInfo";
import SalesProcess from "./SalesProcess";

type ContextType = {
  basicInfo: SellOffProduct.BasicInfo;
  statusInfo: SellOffProduct.StatusInfo;
  timelines: SellOffProduct.Timelines;
  isBundle: boolean;
  isPreparation: boolean;
  isSold: boolean;
  setBasicInfo: Dispatch<SetStateAction<SellOffProduct.BasicInfo | null>>;
  setStatusInfo: Dispatch<SetStateAction<SellOffProduct.StatusInfo | null>>;
  setTimelines: Dispatch<SetStateAction<SellOffProduct.Timelines | null>>;
  getData: () => void;
  toggleChangePhase: () => void;
  toggleClosePhase: () => void;
  canChangePhase: boolean;
  canClosePhase: boolean;
  // setStatusInfo: Dispatch<SetStateAction<SellOffProduct.StatusInfo | null>>;
};

export const SellOffProductDetailsContext = createContext<
  Optional<ContextType>
>({});

function SellOffProductDetails() {
  const { productId } = useParams();
  const { axios, loading } = useAxios();
  const [basicInfo, setBasicInfo] = useState<SellOffProduct.BasicInfo | null>(
    null
  );
  const [statusInfo, setStatusInfo] =
    useState<SellOffProduct.StatusInfo | null>(null);
  const [timelines, setTimelines] = useState<SellOffProduct.Timelines | null>(
    null
  );
  const [showChangePhase, toggleChangePhase, hideChangePhase] =
    useToggle(false);
  const [showClosePhase, toggleClosePhase, hideClosePhase] = useToggle(false);
  const checkDate = (date: Date | string | null | undefined) => {
    if (!date) return false;
    return now() >= new Date(date).getTime();
  };
  const hasData = !!basicInfo && !!statusInfo && !!timelines;
  const hasLoading = loading.get;
  const isPreparation = basicInfo?.status === SellOffProductStatus.Preparation;
  const isClosed = basicInfo?.status === SellOffProductStatus.Closed;
  const isSold = basicInfo?.status === SellOffProductStatus.Sold;
  const isBundle =
    basicInfo?.pimProduct?.pimProductType === PimProductType.Bundle;
  const hasTemplate = hasData && !!basicInfo?.sellPhaseTemplate;
  const canStartProcess = hasData && !statusInfo?.currentPhaseDay;
  const canClosePhase = [
    hasData,
    !isClosed,
    statusInfo?.closePhaseRequired,
    checkDate(statusInfo?.needClosePhaseDate),
  ].every(Boolean);
  const canChangePhase = [
    hasData,
    !isClosed,
    !canClosePhase,
    statusInfo?.changePhaseRequired,
    checkDate(statusInfo?.needChangePhaseDate),
  ].every(Boolean);
  // const needPhaseChange = useMemo(() => {
  //   const needChangePhaseDate = statusInfo?.needChangePhaseDate;
  //   if (!needChangePhaseDate) return false;
  //   return now() >= new Date(needChangePhaseDate).getTime();
  // }, [statusInfo]);
  // const needClose = useMemo(() => {
  //   const needClosePhaseDate = statusInfo?.needClosePhaseDate;
  //   if (!needClosePhaseDate) return false;
  //   return now() >= new Date(needClosePhaseDate).getTime();
  // }, [statusInfo]);

  const initTabs = [
    {
      label: "sellOffProducts.details.basicInfoTab",
      id: "basic-info",
      component: BasicInfo,
      active: true,
    },
    {
      label: "sellOffProducts.details.lineItemsTab",
      id: "items",
      component: LineItems,
      active: isBundle,
    },
    {
      label: "sellOffProducts.details.mediaTab",
      id: "media",
      component: Media,
      active: true,
    },
    {
      label: "sellOffProducts.details.salesProcessTab",
      id: "sales-process",
      component: SalesProcess,
      active: true,
    },
    {
      label: "sellOffProducts.details.attributesTab",
      id: "attributes",
      component: Attribute,
      active: true,
    },
  ];
  const tabs = initTabs.filter((e) => e.active);
  const getBasicInfo = useCallback(() => {
    const url = `/salesservice/api/selloffproducts/${productId}/basic-info`;
    axios.get(url).then(({ data }) => {
      setBasicInfo(data);
    });
  }, [productId]);
  const getPriceInfo = useCallback(() => {
    const url = `/salesservice/api/selloffproducts/${productId}/status-info`;
    axios.get(url).then(({ data }) => {
      setStatusInfo(data);
    });
  }, [productId]);
  const getTimelines = useCallback(() => {
    const url = `/salesservice/api/selloffproducttimelines/${productId}`;
    axios.get(url).then(({ data }) => {
      const result: SellOffProduct.Timelines = cloneDeep(data);
      const timelines = result.sellOffProductTimelineItems;
      if (timelines) {
        const sortedTimeline = timelines.sort((a, b) =>
          !a.actionType ? 1 : a.actionType - b.actionType
        );
        result.sellOffProductTimelineItems = sortedTimeline;
      }
      setTimelines(result);
    });
  }, [productId]);
  const getData = () => {
    getBasicInfo();
    getPriceInfo();
    getTimelines();
  };
  useEffect(getData, []);
  return (
    <Fragment>
      <Tabs activeKey={tabs[0]?.id}>
        <Layout>
          <Layout.Header>
            <BackButton to="/sell-off-products" className="me-auto" />
            {canStartProcess && (
              <Button type="submit" onClick={toggleChangePhase}>
                <Text>sellOffProducts.details.startsProcessButton</Text>
              </Button>
            )}
            {canChangePhase && (
              <Button
                type="button"
                variant="warning"
                onClick={toggleChangePhase}
              >
                <Text>sellOffProducts.details.changePhaseButton</Text>
              </Button>
            )}
            {canClosePhase && (
              <Button type="button" variant="danger" onClick={toggleClosePhase}>
                <Text>sellOffProducts.details.needCloseButton</Text>
              </Button>
            )}
          </Layout.Header>
          <Layout.Body className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            {hasLoading ? (
              <Loading.Header />
            ) : !hasData ? (
              <NoItems className="col-span-full" />
            ) : (
              <SellOffProductDetailsContext.Provider
                value={{
                  basicInfo,
                  statusInfo,
                  timelines,
                  isBundle,
                  isPreparation,
                  isSold,
                  canChangePhase,
                  canClosePhase,
                  getData,
                  setBasicInfo,
                  setStatusInfo,
                  setTimelines,
                  toggleChangePhase,
                  toggleClosePhase,
                  // setProductBasicInfo: setBasicInfo,
                  // setStatusInfo: setStatusInfo,
                }}
              >
                <ProductInfo />
                <PriceInfo />
                <Wrapper className="col-span-full">
                  <Wrapper.Body className="py-0">
                    <Tabs.ButtonGroup>
                      {tabs.map((e) => (
                        <Tabs.Button key={e.id} eventKey={e.id}>
                          <Text>{e.label}</Text>
                        </Tabs.Button>
                      ))}
                    </Tabs.ButtonGroup>
                  </Wrapper.Body>
                </Wrapper>
                {tabs.map((e) => (
                  <Tabs.Item key={e.id} eventKey={e.id}>
                    {createElement(e.component)}
                  </Tabs.Item>
                ))}
                <ChangePhaseDrawer
                  isOpen={showChangePhase}
                  toggle={hideChangePhase}
                  sellOffProductId={productId}
                  hasTemplate={hasTemplate}
                  start={canStartProcess}
                />
                <ClosePhaseModal
                  isOpen={showClosePhase}
                  toggle={hideClosePhase}
                  sellOffProductId={productId}
                />
              </SellOffProductDetailsContext.Provider>
            )}
          </Layout.Body>
        </Layout>
      </Tabs>
    </Fragment>
  );
}
export default withPermission(SellOffProductDetails, [
  "SC_SellOffProductFullAccess",
  "SC_GetSellOffProduct",
]);
