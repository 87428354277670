import { useContext, useState } from "react";
import { ProductDetailsContext } from "..";
import { NoItems } from "../../../../components";
import { useToggle } from "../../../../hooks";
import { isImage } from "../../../../methods";
import MediaGallery from "./MediaGallery";
import MediaItem from "./MediaItem";

export default function Media() {
  const { product } = useContext(ProductDetailsContext);
  const [showGallery, toggleGallery] = useToggle(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const hasMedia = !!product.mediaList?.length;
  const images = product.mediaList?.filter((e) => isImage(e.type)) ?? [];
  const otherFiles = product.mediaList?.filter((e) => !isImage(e.type)) ?? [];
  const handleClickMedia = (index: number) => {
    return () => {
      setActiveIndex(index);
      toggleGallery();
    };
  };
  if (!hasMedia) return <NoItems />;
  return (
    <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
      {[...images, ...otherFiles]?.map((e, i) => (
        <MediaItem
          key={e.thumbnailUrl}
          media={e}
          onClick={handleClickMedia(i)}
        />
      ))}
      <MediaGallery
        isOpen={showGallery}
        toggle={toggleGallery}
        activeIndex={activeIndex ?? 0}
        setActiveIndex={setActiveIndex}
      />
    </section>
  );
}
