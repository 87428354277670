import { Fragment, useState } from "react";
import {
  Button,
  CheckBox,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
} from "../components";
import { config, status } from "../constants";
import { AssignedType } from "../enums";
import { useToggle } from "../hooks";
import { isEmptyValue } from "../methods";
import { Incident, togglePropsType } from "../types";
import ChooseCustomer from "./_ChooseCustomer";
import ChooseOrder from "./_ChooseOrder";
import IncidentAssignedEmployee, {
  SelectedEmployee,
} from "./_IncidentAssignedEmployee";

type IncidentFilterProps = togglePropsType & {
  initParams: Incident.Params;
  onSubmit: (params: Incident.Params) => void;
};

export default function IncidentFilter({
  isOpen,
  toggle,
  initParams,
  onSubmit,
}: IncidentFilterProps) {
  const [params, setParams] = useState(initParams);
  const [showCustomerList, toggleCustomerList] = useToggle(false);
  const [showOrderList, toggleOrderList] = useToggle(false);

  const [customerName, setCustomerName] = useState<string | null>(null);
  const [assignedType, setAssignedType] = useState<AssignedType | null>(null);
  const [employeeName, setEmployeeName] = useState<string | null>(null);
  const [orderName, setOrderName] = useState<string | null>(null);

  const hasEmployee = ![params.assignedId, employeeName, assignedType].some(
    isEmptyValue
  );

  const employee: SelectedEmployee | null = hasEmployee
    ? {
        name: employeeName!,
        id: params.assignedId!,
        type: assignedType!,
      }
    : null;
  const handleSetEmployee = (employee: SelectedEmployee | null) => {
    setParams((p) => ({ ...p, assignedId: employee?.id }));
    setEmployeeName(employee?.name ?? null);
    setAssignedType(employee?.type ?? null);
  };

  const resetFilter = () => {
    setCustomerName(null);
    setEmployeeName(null);
    setOrderName(null);
    setAssignedType(null);
    setParams({ pageNumber: 1, pageSize: config.pageSize });
  };

  const submit = () => {
    onSubmit({ ...params, pageNumber: 1 });
    toggle();
  };

  return (
    <Fragment>
      <Drawer
        as={Form}
        onSubmit={submit}
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
      >
        <Drawer.Menu>
          <Drawer.Header className="flex items-center gap-4">
            <h6 className="text-dark text-base flex-1 text-start">
              <Text>incident.filter.title</Text>
            </h6>
            <button
              type="button"
              className="text-warning"
              onClick={resetFilter}
            >
              <Icon
                name="ArrowForwardSquare"
                variant="Bold"
                className="text-warning"
              />{" "}
              <Text>incident.filter.resetFilterBtn</Text>
            </button>
          </Drawer.Header>
          <Drawer.Body className="space-y-5">
            <section className="border rounded p-2">
              <InputGroup
                label="incident.filter.customer"
                value={customerName}
                placeholder="incident.filter.customerPlaceholder"
                onClick={toggleCustomerList}
                readOnly
                append={
                  <span className="input-group-text text-primary">
                    <Icon name="User" variant="Bold" size={22} />
                  </span>
                }
              />
            </section>
            <section className="border rounded p-2">
              <InputGroup
                label="incident.filter.assignedOrder"
                value={orderName}
                placeholder="incident.filter.assignedOrderPlaceholder"
                // rules={rules.required}
                onClick={toggleOrderList}
                readOnly
                append={
                  <span className="input-group-text text-primary">
                    <Icon name="ReceiptText" variant="Bold" size={22} />
                  </span>
                }
              />
            </section>
            <IncidentAssignedEmployee
              value={employee}
              setValue={handleSetEmployee}
            />
            <section className="border rounded p-2 space-y-4">
              <h6 className="text-base text-dark">
                <Text>incident.filter.incidentStatus</Text>
              </h6>
              <div className="flex items-center flex-wrap gap-2">
                {status.incident.map((e) => (
                  <CheckBox
                    key={e.name}
                    label={e.name}
                    value={e.id === params.status}
                    setValue={() => setParams((p) => ({ ...p, status: e.id }))}
                    className="w-auto flex-1"
                  />
                ))}
              </div>
            </section>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-end gap-4">
            <Button type="button" variant="danger" onClick={toggle}>
              <Text>incident.filter.cancelBtn</Text>
            </Button>
            <Button type="submit" variant="primary">
              <Text>incident.filter.submitBtn</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      {isOpen && (
        <Fragment>
          <ChooseCustomer
            isOpen={showCustomerList}
            toggle={toggleCustomerList}
            onSelect={(e) => {
              setParams((p) => ({
                ...p,
                customerId: e.id,
                customerType: e.type,
              }));
              setCustomerName(e.name);
            }}
          />
          <ChooseOrder
            isOpen={showOrderList}
            toggle={toggleOrderList}
            onSelect={(order) => {
              setParams((p) => ({ ...p, orderId: order?.id ?? null }));
              setOrderName(order?.name ?? "");
            }}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
