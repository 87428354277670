import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { LineItemsContext } from "..";
import { OfferDetailsContext } from "../..";
import {
  Button,
  ChangeButton,
  Drawer,
  Icon,
  Loading,
  Text,
} from "../../../../../components";
import { ProgramCard, SupplierCard } from "../../../../../containers";
import { useAxios } from "../../../../../hooks";
import {
  Basket,
  Program,
  Supplier,
  togglePropsType,
} from "../../../../../types";
import { OrderDetailsContext } from "../../../../Orders/Details";
import BasketDetails from "./BasketDetails";
import BasketList from "./BasketList";
import ManualForm from "./ManualForm";
import Programs from "./Programs";
import Suppliers from "./Suppliers";

type ActiveBasket = Basket.Item | null;
type ImportBasketProps = togglePropsType & {
  parentId: string | null;
};
type ContextType = {
  activeBasket: ActiveBasket;
  setActiveBasket: Dispatch<SetStateAction<ActiveBasket>>;
  removeAfterImport: boolean;
  toggleRemoveAfterImport: () => void;
  supplier: Supplier.Item | null;
  setSupplier: Dispatch<SetStateAction<Supplier.Item | null>>;
  program: Program.Item | null;
  setProgram: Dispatch<SetStateAction<Program.Item | null>>;
  isBundle: boolean | null;
  setIsBundle: Dispatch<SetStateAction<boolean | null>>;
  setBundleId: (id: string) => void;
  importBasket: () => void;
  back: () => void;
  hasInitParentId: boolean;
};
export const ImportBasketContext = createContext({} as ContextType);
export default function ImportBasket({
  isOpen,
  toggle,
  parentId: initParentId,
}: ImportBasketProps) {
  const bundleIdRef = useRef<string | null>(null);
  const { axios, loading, progress } = useAxios();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const { updateLineItems, updateLineItemsLoading } =
    useContext(LineItemsContext);
  const [activeBasket, setActiveBasket] = useState<ActiveBasket>(null);
  const [supplier, setSupplier] = useState<Supplier.Item | null>(null);
  const [program, setProgram] = useState<Program.Item | null>(null);
  const [isBundle, setIsBundle] = useState<boolean | null>(null);
  const [removeAfterImport, setRemoveAfterImport] = useState(false);
  const hasBasket = !!activeBasket;
  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const isOffer = !!offer;
  const isOrder = !!order;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const updateLoading =
    updateOfferLoading || updateOrderLoading || updateLineItemsLoading;
  const importLoading = [
    loading.post,
    updateLoading,
    updateLineItemsLoading,
  ].some(Boolean);
  const handleProgress = updateLoading ? 100 : progress;
  const hasInitParentId = !!initParentId;
  const setBundleId = (id: string | null) => {
    bundleIdRef.current = id;
  };
  const toggleRemoveAfterImport = () => {
    setRemoveAfterImport((p) => !p);
  };
  const handleToggle = () => {
    setActiveBasket(null);
    setSupplier(null);
    setProgram(null);
    setIsBundle(null);
    setRemoveAfterImport(false);
    setBundleId(null);
    toggle();
  };
  const ActiveTab = useMemo(() => {
    if (program) return ManualForm;
    if (supplier) return Programs;
    if (isBundle) return Suppliers;
    if (hasBasket) return BasketDetails;
    return BasketList;
  }, [hasBasket, isBundle, supplier, program]);
  const back = () => {
    if (program) return setProgram(null);
    if (supplier) return setSupplier(null);
    if (isBundle) return setIsBundle(null);
    if (hasBasket) return setActiveBasket(null);
    return handleToggle();
  };
  const importBasket = () => {
    const parentId = initParentId || bundleIdRef.current;
    const hasParentId = !!parentId;
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      !hasParentId && "import-from-iwofurn",
      hasParentId && isOffer && "add-iwofurn-basket-to-offer-manual-bundle",
      hasParentId && isOrder && "add-iwofurn-basket-to-order-manual-bundle",
    ]
      .filter(Boolean)
      .join("/");
    const body = {
      parentId: parentId,
      offerId: offer?.id,
      orderId: order?.id,
      shippingMethodCode: null,
      internalTag: activeBasket?.tag,
      deleteAfterImport: removeAfterImport,
    };
    axios.post(url, body).then(() => {
      (updateOffer ?? updateOrder)?.().then(() => {
        updateLineItems().then(() => {
          toast.success("toast.success.importBasket");
          handleToggle();
        });
      });
    });
  };

  return (
    <Drawer isOpen={isOpen} toggle={handleToggle} size="lg">
      <Drawer.Menu className="relative overflow-hidden">
        {importLoading && <Loading.ImportProduct value={handleProgress} />}
        <Drawer.Header className="flex items-center text-end">
          <div className="flex-1 flex">
            {hasBasket && (
              <Button variant="primary" light className="btn-sm" onClick={back}>
                <Icon name="ArrowLeft" variant="Linear" size={18} />{" "}
                <Text>button.back</Text>
              </Button>
            )}
          </div>
          <h6 className="flex-1 text-center">
            <Text>drawerTitles.importBasket</Text>
          </h6>
          <div className="flex-1 flex justify-end"></div>
        </Drawer.Header>
        {!!supplier && (
          <Drawer.Header className="flex flex-center text-start border-b-0">
            <SupplierCard supplier={supplier} className="flex-1" />
            <ProgramCard program={program} className="flex-1" />
            <ChangeButton onClick={back} />
          </Drawer.Header>
        )}
        <ImportBasketContext.Provider
          value={{
            activeBasket,
            setActiveBasket,
            removeAfterImport,
            toggleRemoveAfterImport,
            supplier,
            setSupplier,
            program,
            setProgram,
            isBundle,
            setIsBundle,
            setBundleId,
            importBasket,
            back,
            hasInitParentId,
          }}
        >
          {isOpen && <ActiveTab />}
        </ImportBasketContext.Provider>
      </Drawer.Menu>
    </Drawer>
  );
}
