import {
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  BackButton,
  Button,
  Layout,
  Loading,
  NoItems,
  Text,
} from "../../../components";
import { CustomerCard } from "../../../containers";
import { CustomerType } from "../../../enums";
import { withPermission } from "../../../hoc";
import { useAxios, useToggle } from "../../../hooks";
import { Company, Customer, LineItem, Order } from "../../../types";
import LineItems from "./LineItems";
import OrderInfo from "./OrderInfo";
import SubmitRequestToPickupDrawer from "./SubmitRequestToPickupDrawer";

type OrderData = Order.Details;
type OrderDetailsContextType = {
  order: OrderData;
  lineItems: LineItem.Item[];
  setLineItems: Dispatch<SetStateAction<LineItem.Item[]>>;
  selectMode: boolean;
  deactivateSelectMode: () => void;
  selectedLineItemIds: string[];
  setSelectedLineItemIds: Dispatch<SetStateAction<string[]>>;
};
export const OrderDetailsContext = createContext({} as OrderDetailsContextType);

function OrderDetails() {
  const { orderId } = useParams();
  const { axios, loading } = useAxios();
  const [order, setOrder] = useState<OrderData | null>(null);
  const [customer, setCustomer] = useState<
    (Customer.Details & Company.Details) | null
  >(null);
  const [lineItems, setLineItems] = useState<LineItem.Item[]>([]);
  const [selectMode, toggleSelectMode, deactivateSelectMode] = useToggle(false);
  const [
    showSubmitRequestDrawer,
    toggleSubmitRequestDrawer,
    hideSubmitRequestDrawer,
  ] = useToggle(false);

  const [selectedLineItemIds, setSelectedLineItemIds] = useState<string[]>([]);
  const hasOrder = !!order;
  const disabledRequestButton = !selectedLineItemIds.length;
  const handleDeactivateSelectMode = () => {
    deactivateSelectMode();
    setSelectedLineItemIds([]);
  };
  const getOrder = () => {
    const url = `/salesservice/api/orders/${orderId}`;
    axios.get(url).then(({ data }) => {
      const isCompany = data.customer?.customerType === CustomerType.Company;
      setOrder(data);
      getCustomer(data?.customer?.customerId, isCompany);
    });
  };
  const getCustomer = (id: string, isCompany: boolean) => {
    if (!id) return;
    const url = isCompany
      ? `/accountservice/api/customers/${id}/as-company`
      : `/accountservice/api/customers/${id}`;
    axios.get(url).then(({ data }) => {
      setCustomer(data);
    });
  };
  const getLineItems = () => {
    const url = "/salesservice/api/orderlineitems";
    const config = { params: { orderId: orderId, isCanceled: false } };
    axios.get(url, config).then(({ data }) => {
      setLineItems(data);
    });
  };
  useEffect(getOrder, [orderId]);
  useEffect(getLineItems, [orderId]);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <BackButton to="/pickup" className="mr-auto" />
          {!loading.get && (
            <Fragment>
              {selectMode ? (
                <Fragment>
                  <Button
                    type="button"
                    variant="danger"
                    light
                    className="ms-auto"
                    onClick={handleDeactivateSelectMode}
                  >
                    <Text>pickup.details.cancelRequestPickupButton</Text>
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={disabledRequestButton}
                    onClick={toggleSubmitRequestDrawer}
                  >
                    <Text>pickup.details.submitRequestPickupButton</Text>
                  </Button>
                </Fragment>
              ) : (
                <Button
                  type="button"
                  variant="primary"
                  light
                  className="ms-auto"
                  onClick={toggleSelectMode}
                >
                  <Text>pickup.details.selectItemsToPickupButton</Text>
                </Button>
              )}
            </Fragment>
          )}
        </Layout.Header>
        <Layout.Body className="grid grid-cols-1 lg:grid-cols-5 gap-3 [&>*]:col-span-full">
          {loading.get ? (
            <Loading.Header />
          ) : hasOrder ? (
            <OrderDetailsContext.Provider
              value={{
                order,
                lineItems,
                setLineItems,
                selectMode,
                deactivateSelectMode,
                selectedLineItemIds,
                setSelectedLineItemIds,
              }}
            >
              <OrderInfo />
              <CustomerCard customer={customer} className="lg:!col-span-2" />
              <LineItems />
              <SubmitRequestToPickupDrawer
                isOpen={showSubmitRequestDrawer}
                toggle={hideSubmitRequestDrawer}
              />
            </OrderDetailsContext.Provider>
          ) : (
            <NoItems />
          )}
        </Layout.Body>
      </Layout>
    </Fragment>
  );
}
export default withPermission(OrderDetails, ["SC_GetSaleServiceOrder"]);
