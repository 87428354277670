import { twMerge } from "tailwind-merge";
import noItemsIcon from "../assets/icons/no items.svg";
import { classNameProps } from "../types";
import Image from "./_Image";
import Text from "./_Text";
export default function NoItems({ className }: classNameProps) {
  return (
    <div
      className={twMerge(
        "w-full space-y-4 text-center p-4 text-2xl text-placeholder",
        className
      )}
    >
      <Image
        className="block w-64 mx-auto aspect-square object-contain max-w-full"
        src={noItemsIcon}
        alt="No item found"
      />
      <p className="text-3xl max-w-full">
        <Text>global.noItems</Text>
      </p>
      <p className="text-base max-w-full">
        <Text>global.noItems</Text>
      </p>
    </div>
  );
}
