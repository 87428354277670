import { LineItem } from "../types";

export default function convertOptionalDiscounts(
  discounts: LineItem.Item["discounts"]
): LineItem.OptionalDiscountRequest[] | null {
  return (
    discounts
      // ?.filter((e) =>
      //   [
      //     SalePriceTemplateItemStep.OptionalDiscounts,
      //     SalePriceTemplateItemStep.IndividualDiscounts,
      //   ].includes(e.step)
      // )
      ?.map((e) => ({
        calculationUnitIdentifier: e.discountIdentifier,
        manualValue: e.itemValue,
        description: e.description,
      })) ?? null
  );
}
