import { cloneDeep } from "lodash";
import { ComponentProps, Fragment, useMemo, useRef, useState } from "react";
import { useToggle, useTranslate } from "../hooks";
import { Optional } from "../types";
import Button from "./_Button";
import Icon from "./_Icon";
import InputGroup from "./_InputGroup";
import Loading from "./_Loading";
import Modal from "./_Modal";
import RadioButton from "./_RadioButton";
import SearchBox from "./_SearchBox";
import Text from "./_Text";

type SetValue<T> =
  | {
      setValue?: (id: T) => void;
      all?: false;
    }
  | {
      setValue?: (id: T | null) => void;
      all: true;
    };

type SelectProps<T> = {
  items?: { name: string; id: T }[];
  loading?: boolean;
  radioButtonProps?: Optional<ComponentProps<typeof RadioButton>>;
  searchable?: boolean;
} & SetValue<T> &
  Omit<
    ComponentProps<typeof InputGroup>,
    "onClick" | "append" | "readOnly" | "type" | "setValue"
  >;
export default function Select<T>({
  label,
  value,
  items = [],
  rules,
  prepend,
  placeholder,
  setValue = () => {},
  className,
  loading,
  disabled,
  radioButtonProps = {},
  searchable = false,
  all = false,
}: SelectProps<T>) {
  const searchRef = useRef<HTMLDivElement | null>(null);
  const translate = useTranslate();
  const [isOpen, toggle] = useToggle(false);
  const [search, setSearch] = useState("");
  const canSearch = [
    searchable,
    // items.length >= 10
  ].every(Boolean);
  const filteredItems = useMemo(() => {
    const result = cloneDeep(items);
    if (all) result.unshift({ name: "global.allFilterItem", id: null as T });
    if (!search || !canSearch) return result;
    return result.filter((e) => {
      const keyword = search.toLowerCase();
      const keys = [e.id, translate(e.name)].map((e) =>
        String(e).toLowerCase()
      );
      const available = keys.some((e) => e.includes(keyword));
      return available;
    });
  }, [canSearch, search, items, all, translate]);
  const activeItemName = useMemo(() => {
    if (!!all && value === null) return "global.allFilterItem";
    return items.find((e) => `${e.id}` === `${value}`)?.name;
  }, [items, value, all]);
  const noItems = !filteredItems.length;
  const handleToggle = () => {
    const input = searchRef.current?.querySelector("input");
    if (input) input.value = "";
    setSearch("");
    toggle();
  };
  const handleClick = (id: T | null) => {
    return () => {
      const isEqual = id === value;
      !isEqual && setValue(id as T);
      handleToggle();
    };
  };
  return (
    <Fragment>
      <InputGroup
        label={label}
        value={translate(activeItemName ?? "")}
        rules={rules}
        prepend={prepend}
        placeholder={placeholder}
        readOnly
        disabled={disabled}
        onClick={handleToggle}
        className={className}
        append={
          <span className="input-group-text text-primary">
            <Icon name="ArrowDown2" size={18} />
          </span>
        }
      />
      <Modal isOpen={isOpen} toggle={handleToggle} modalClassName="z-40">
        {!!label && (
          <Modal.Header>
            <Text>{label}</Text>
          </Modal.Header>
        )}
        {canSearch && (
          <Modal.Header>
            <SearchBox
              ref={searchRef}
              value={search}
              variant="gray"
              searchType="immediately"
              className="w-full"
              onSubmit={setSearch}
            />
          </Modal.Header>
        )}
        <Modal.Body className="h-72 divide-y divide-gray-100 overflow-auto flex-auto">
          {loading ? (
            <Loading.Radios />
          ) : noItems ? (
            <p className="text-base text-center max-w-full">
              <Text>global.noItems</Text>
            </p>
          ) : (
            <Fragment>
              {filteredItems.map((e) => (
                <RadioButton
                  key={String(e.id)}
                  label={e.name}
                  isActive={e.id === value}
                  onClick={handleClick(e.id)}
                  {...radioButtonProps}
                />
              ))}
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button onClick={handleToggle} light>
            <Text>Back</Text>
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
