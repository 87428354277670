import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import {
  Button,
  ChangeButton,
  Drawer,
  Icon,
  Text,
} from "../../../../../components";
import { ProgramCard, SupplierCard } from "../../../../../containers";
import { Program, Supplier, togglePropsType } from "../../../../../types";
import Iwofurn from "./Iwofurn";
import Programs from "./Programs";
import Suppliers from "./Suppliers";

type ConfigProductProps = togglePropsType & {
  parentId: string | null;
};
type ConfigProductContextType = {
  parentId: string | null;
  supplier: Supplier.Item | null;
  setSupplier: Dispatch<SetStateAction<Supplier.Item | null>>;
  program: Program.Item | null;
  setProgram: Dispatch<SetStateAction<Program.Item | null>>;
  toggle: () => void;
};

export const ConfigProductContext = createContext(
  {} as ConfigProductContextType
);

export default function ConfigProduct({
  isOpen,
  toggle,
  parentId,
}: ConfigProductProps) {
  const [supplier, setSupplier] = useState<Supplier.Item | null>(null);
  const [program, setProgram] = useState<Program.Item | null>(null);
  const hasSupplier = !!supplier;
  const hasProgram = !!program;
  const menuSize = hasSupplier && hasProgram ? "xl" : undefined;
  const ActiveStep = useMemo(() => {
    if (!supplier) return Suppliers;
    if (!program) return Programs;
    return Iwofurn;
  }, [supplier, program]);
  const activeTitle = useMemo(() => {
    if (!supplier) return "drawerTitles.chooseSupplier";
    if (!program) return "drawerTitles.chooseProgram";
    return "drawerTitles.addProduct";
  }, [supplier, program]);
  const clearSupplier = () => {
    setSupplier(null);
  };
  const clearProgram = () => {
    setProgram(null);
  };
  const handleClearData = () => {
    if (!!program) return clearProgram();
    return clearSupplier();
  };
  const handleToggle = () => {
    clearSupplier();
    clearProgram();
    toggle();
  };
  return (
    <Drawer
      isOpen={isOpen}
      toggle={!hasProgram || !hasProgram ? handleToggle : () => {}}
      size={menuSize}
    >
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="flex-1 text-start">
            <Text>{activeTitle}</Text>
          </h6>
          <Button type="button" variant="danger" light onClick={handleToggle}>
            <Icon name="CloseCircle" size="1em" /> <Text>button.close</Text>
          </Button>
        </Drawer.Header>
        {hasSupplier && (
          <Drawer.Header className="flex flex-center text-start border-b-0">
            <SupplierCard supplier={supplier} className="flex-1" />
            <ProgramCard program={program} className="flex-1" />
            <ChangeButton onClick={handleClearData} />
          </Drawer.Header>
        )}
        <ConfigProductContext.Provider
          value={{
            parentId,
            supplier,
            setSupplier,
            program,
            setProgram,
            toggle: handleToggle,
          }}
        >
          {isOpen && <ActiveStep />}
        </ConfigProductContext.Provider>
      </Drawer.Menu>
    </Drawer>
  );
}
