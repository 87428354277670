import {
  createContext,
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
type tabContextType = {
  active: string;
  setActive: (val: string) => void;
};
type tabsProps = {
  children: ReactNode;
  activeKey?: string;
  searchParams?: string;
};
type tabWrapperProps = {
  children: ReactNode;
};
type tabButtonProps = {
  children: ReactNode;
  eventKey: string;
};
type tabItemProps = {
  children: ReactNode;
  eventKey: string;
};
const TabContext = createContext({} as tabContextType);
function Tabs({ searchParams = "tab", activeKey = "", children }: tabsProps) {
  const [params, setParams] = useSearchParams({ [searchParams]: activeKey });
  const active = params.get(searchParams) ?? activeKey;
  const setActive = (tab: string) => {
    setParams((p) => {
      p.set(searchParams, tab);
      return p;
    });
  };
  return (
    <TabContext.Provider value={{ active, setActive }}>
      {children}
    </TabContext.Provider>
  );
}
function TabButtonGroup({ children }: tabWrapperProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const indicatorRef = useRef<HTMLSpanElement>(null);
  const { i18n } = useTranslation();
  const { active } = useContext(TabContext);
  const handleIndicator = () => {
    setTimeout(() => {
      const parent = divRef.current;
      const selector = "button[data-active='true']";
      const activeButton = parent?.querySelector<HTMLButtonElement>(selector);
      const buttonOffsetLeft = activeButton?.offsetLeft ?? 0;
      const buttonWidth = activeButton?.getBoundingClientRect().width ?? 0;
      const left = buttonOffsetLeft;
      const width = buttonWidth;
      indicatorRef.current?.style.setProperty("left", `${left}px`);
      indicatorRef.current?.style.setProperty("width", `${width}px`);
    }, 0);
  };
  useEffect(handleIndicator, [active, i18n.language]);
  return (
    <div
      ref={divRef}
      className="relative w-full flex items-center gap-4 overflow-auto"
    >
      <span
        className="absolute bottom-0 left-0 w-auto h-[1.5px] rounded-full bg-primary transition-[width,left]"
        ref={indicatorRef}
      />
      {children}
    </div>
  );
}
function TabButton({ eventKey, children }: tabButtonProps) {
  const { active, setActive } = useContext(TabContext);
  const isActive = active === eventKey;
  const handleClick = () => {
    setActive(eventKey);
  };
  return (
    <button
      data-active={isActive}
      type="button"
      onClick={handleClick}
      className="text-placeholder text-sm border-b border-transparent px-1 py-4 data-active:text-primary transition-colors whitespace-nowrap min-w-fit font-medium"
    >
      {children}
    </button>
  );
}
function TabItem({ eventKey, children }: tabItemProps) {
  const { active } = useContext(TabContext);
  const isActive = active === eventKey;
  return <Fragment>{isActive && children}</Fragment>;
}
Tabs.ButtonGroup = TabButtonGroup;
Tabs.Button = TabButton;
Tabs.Item = TabItem;
export default Tabs;
