import { Button, Drawer, List, RadioButton, Text } from "../components";
import { languages } from "../constants";
import { useLanguage } from "../hooks";
import { languageType, togglePropsType } from "../types";

type settingsMenuProps = togglePropsType;

export default function SettingsMenu({ isOpen, toggle }: settingsMenuProps) {
  const [language, setLanguage] = useLanguage();
  const isActiveLanguage = (id: languageType) => {
    return language === id;
  };
  const handleSetLanguage = (id: languageType) => {
    return () => {
      setLanguage(id);
    };
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Text>drawerTitles.settings</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body>
          <h6 className="text-secondary mb-8">
            <Text>drawerTitles.languages</Text>:
          </h6>
          <List>
            {languages.map((e) => (
              <List.Item
                key={e.id}
                data-active={isActiveLanguage(e.id)}
                className="group"
                onClick={handleSetLanguage(e.id)}
              >
                <RadioButton
                  className="w-auto flex-1 py-0"
                  label={e.name}
                  isActive={isActiveLanguage(e.id)}
                />
                <img src={e.icon} alt={e.name} className="w-6" />
              </List.Item>
            ))}
          </List>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" onClick={toggle}>
            <Text>button.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
