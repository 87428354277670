import React, { useContext } from "react";
import { AddCustomerDataContext } from ".";
import { Icon, Wrapper } from "../../../components";
import { arrayMatrix, showCallNumber } from "../../../methods";
import { Address } from "../../../types";
export default function Completed() {
  const { data } = useContext(AddCustomerDataContext);
  const address = [
    "street",
    "number",
    "postalCode",
    "city",
    "state",
    "country",
  ].map((key) => data.address[key as keyof Address]);
  const showAddress = address.filter(Boolean).length > 1;
  const hasPhoneNumber =
    !!data.phoneNumber && Object.values(data.phoneNumber).every(Boolean);
  return (
    <Wrapper>
      <Wrapper.Body className="space-y-6 max-w-screen-md mx-auto">
        <section className="w-full p-2 flex-center flex-col space-y-2 bg-gray-50 rounded">
          <Icon
            name="User"
            variant="Bold"
            size={70}
            className="text-[#44A789]"
          />
          <h5>{`${data.firstName} ${data.lastName}`}</h5>
          <p className="text-secondary">{data.emailAddress}</p>
        </section>
        <section className="flex-center flex-col space-y-3">
          <Icon name="Call" variant="Bulk" className="text-primary" size={28} />
          <span>{showCallNumber(data.mobileNumber)}</span>
          {hasPhoneNumber && <span>{showCallNumber(data.phoneNumber)}</span>}
        </section>
        <section
          hidden={!showAddress}
          className="flex-center flex-col space-y-3"
        >
          <Icon
            name="Location"
            variant="Bulk"
            className="text-primary"
            size={28}
          />
          <p className="text-center leading-6">
            {arrayMatrix(address, 2).map((items, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <br />}
                {items.filter(Boolean).join(", ")}
              </React.Fragment>
            ))}
          </p>
        </section>
      </Wrapper.Body>
    </Wrapper>
  );
}
