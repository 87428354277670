import { Fragment, useContext, useEffect, useState } from "react";
import { ActivityContext } from ".";
import {
  Accordion,
  Drawer,
  Icon,
  Loading,
  Status,
  Text,
  Types,
  Wrapper,
} from "../../../../../components";
import {
  dateFormats,
  taskDurationItems,
  types,
} from "../../../../../constants";
import { CRMActivityType } from "../../../../../enums";
import { useAxios, useConverters } from "../../../../../hooks";
import { iconNameType, Task, togglePropsType } from "../../../../../types";

type ActivityInfoProps = togglePropsType;

export default function ActivityInfo({ isOpen, toggle }: ActivityInfoProps) {
  const { axios, loading } = useAxios();
  const { convertDate } = useConverters();
  const { activity } = useContext(ActivityContext);
  const [data, setData] = useState<Task.Details | null>(null);
  const isTask = activity.type === CRMActivityType.Task;
  const activityType = types.incidentActivity.find(
    (e) => e.id === activity.type
  );
  const hasType = !!activityType;
  const duration = taskDurationItems.find((e) => e.id === data?.duration)?.name;
  const hasDuration = !!duration;
  const taskItem = [
    {
      label: "incident.activities.info.createdAt",
      value: convertDate(data?.createdAt) || "--",
      icon: "Calendar" as iconNameType,
    },
    {
      label: "incident.activities.info.duration",
      value: hasDuration ? <Text>{duration}</Text> : "--",
      icon: "Clock" as iconNameType,
    },
    {
      label: "incident.activities.info.dueDate",
      value: convertDate(data?.dueDate) || "--",
      icon: "CalendarCircle" as iconNameType,
    },
    {
      label: "incident.activities.info.priority",
      value: <Types.TaskPriority id={data?.priority} />,
      icon: "InfoCircle" as iconNameType,
    },
    {
      label: "incident.activities.info.status",
      value: (
        <Status.Task status={data?.status} statusReason={data?.statusReason} />
      ),
      icon: "InfoCircle" as iconNameType,
    },
    {
      label: "incident.activities.info.assignedName",
      value: data?.assignedName ?? "--",
      icon: "InfoCircle" as iconNameType,
    },
  ];

  const getData = () => {
    if (!isOpen) return;
    if (!isTask) return;
    const url = `/channelmanagerservice/api/activities/task/${activity.id}`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [isOpen, isTask, activity.id]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className={isTask ? "bg-[#F5F7F9]" : ""}>
        <Drawer.Header className="space-y-px">
          <h6 className="text-dark text-base">{activity.subject || "--"}</h6>
          <p className="text-placeholder text-sm">
            {convertDate(activity.createdOn, dateFormats.fullDate)}
          </p>
          {hasType && (
            <p className="text-placeholder text-sm space-x-1">
              <Icon name={activityType.icon} />
              <Text>{activityType.name}</Text>
            </p>
          )}
          {/* <p className="text-danger text-sm">
            {convertDate(activity.createdOn, dateFormats.fullDate)}
          </p> */}
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          {loading.get ? (
            <Loading.ActivityInfo />
          ) : isTask ? (
            <Accordion defaultActiveKey={["info", "description"]}>
              <Wrapper>
                <Accordion.Item eventKey="info">
                  <Accordion.Toggle className="flex-row-reverse">
                    <h6 className="text-sm text-dark flex-1 text-start">
                      <Text>incident.activities.info.infoTitle</Text>
                    </h6>
                  </Accordion.Toggle>
                  <Accordion.Body>
                    <Wrapper.Body className="py-0">
                      <table className="w-full text-sm font-medium [&_td]:py-3">
                        <tbody className="divide-y divide-dashed">
                          {taskItem.map((e) => (
                            <tr key={e.label}>
                              <td className="text-secondary text-start whitespace-nowrap">
                                <Icon name={e.icon} variant="Bold" />{" "}
                                <Text>{e.label}</Text>
                              </td>
                              <td className="text-gray-900 text-end">
                                {e.value}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Wrapper.Body>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              <Wrapper>
                <Accordion.Item eventKey="description">
                  <Accordion.Toggle className="flex-row-reverse">
                    <h6 className="text-sm text-dark flex-1 text-start">
                      <Text>incident.activities.info.descriptionTitle</Text>
                    </h6>
                  </Accordion.Toggle>
                  <Accordion.Body>
                    <Wrapper.Body
                      dangerouslySetInnerHTML={{
                        __html: data?.description || "--",
                      }}
                      className="text-secondary text-justify break-words py-0 min-h-60"
                    ></Wrapper.Body>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
            </Accordion>
          ) : (
            <Fragment>
              <h6 className="text-base text-dark font-medium">
                {activity.subject}
              </h6>
              <div
                className="text-base text-secondary font-normal text-justify break-words py-0 min-h-60"
                dangerouslySetInnerHTML={{
                  __html: activity.content || "--",
                }}
              ></div>
            </Fragment>
          )}
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
