import { Dispatch } from "react";
import { Button, Text } from "../../../components";
import { types } from "../../../constants";
import { PimProductType } from "../../../enums";

type Props = {
  value: PimProductType | null;
  setValue: Dispatch<PimProductType | null>;
  disabled?: boolean;
};

export default function PimProductTypeFilter({
  value,
  setValue,
  disabled,
}: Props) {
  // const location = useLocation();
  const items = [
    { name: "products.list.allButton", id: null },
    ...types.pimProduct,
  ];
  // const productTypes = [
  //   { name: "Pim EN", id: "/products" },
  //   { name: "Sell off EN", id: "/sell-off-products" },
  // ];
  return (
    <section className="col-span-full flex flex-wrap items-center gap-2 py-3">
      {items.map((e) => {
        const active = e.id === value;
        return (
          <Button
            key={e.name}
            type="button"
            variant={active ? "primary" : "white"}
            onClick={() => setValue(e.id)}
            disabled={disabled}
          >
            <Text>{e.name}</Text>
          </Button>
        );
      })}
      <span className="flex-1" />
      {/* {productTypes.map((e) => {
        const active = e.id === location.pathname;
        return (
          <Button
            key={e.name}
            as={Link}
            to={e.id}
            type="button"
            variant="primary"
            outline
            replace
            className={cn(
              "bg-white rounded-full px-5",
              active
                ? "text-primary border-primary"
                : "text-placeholder border-placeholder"
            )}
          >
            <Text>{e.name}</Text>
          </Button>
        );
      })} */}
    </section>
  );
}
