import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Drawer,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../components";
import { config } from "../constants";
import { useAxios } from "../hooks";
import { getRandomColor } from "../methods";
import { Department, WithPaging, togglePropsType } from "../types";

type SelectedDepartment = { name: string; id: string };

type ChooseDepartmentProps = togglePropsType & {
  onSelect: (department: SelectedDepartment) => void;
};

export default function ChooseDepartment({
  isOpen,
  toggle,
  onSelect,
}: ChooseDepartmentProps) {
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Department.Item>>();
  const [params, setParams] = useState<Department.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const handleSelect = (e: Department.Item) => {
    return () => {
      onSelect({ id: e.id, name: e.title });
      toggle();
    };
  };
  const handleSetParams = (key: string) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p) => ({ ...p, [key]: val }));
    };
  };
  const getData = () => {
    if (!isOpen) return;
    const url = "/accountservice/api/departments";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [isOpen, params, axios]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
      <Drawer.Menu>
        <Drawer.Header className="border-b-0">
          <h6 className="text-base text-dark">
            <Text>incident.chooseDepartment.title</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox
            placeholder="incident.chooseDepartment.search"
            variant="gray"
            onSubmit={handleSetParams("keyword")}
          />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {data?.items?.map((e, i) => (
              <List.Item key={e.id} onClick={handleSelect(e)}>
                <div className="flex items-center gap-3 w-fit">
                  <Avatar icon="Building" color={getRandomColor(i)} />
                  <div>
                    <h6 className="text-base text-dark mb-1">{e.title}</h6>
                    {/* <p className="block text-secondary">{e.cimNumber || "-"}</p> */}
                  </div>
                </div>
                <p className="flex-1 truncate">{e.emailAddress}</p>
                <Button as="span" light>
                  <Text>incident.chooseDepartment.chooseBtn</Text>
                </Button>
              </List.Item>
            ))}
          </List>
        </Drawer.Body>
        <Drawer.Footer>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            totalItems={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
