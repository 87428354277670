export const noteSuggestion = [
  {
    code: "100",
    id: "Kundeninformation",
    display:
      "Die Bearbeitung beträgt ca. 4 - 6 Wochen. Nach Wareneingang in unserem Haus werden wir Sie umgehend informieren, um mit Ihnen einen Termin zur Rücklieferung zu vereinbaren.",
  },
  {
    code: "101",
    id: "Stoff/Leder senden",
    display:
      "Den Stoff/das Leder senden Sie bitte schnellstmöglich unter Angabe von Kom-Name und Kom-Nummer an folgende Firma:",
  },
  {
    code: "102",
    id: "Stoff/Leder beziehen",
    display:
      "Den Stoff/das Leder zum Beziehen erhalten Sie unter Angabe von Kom-Name und Kom-Nr von folgender Firma.",
  },
  {
    code: "103",
    id: "Arbeitsplatten und Langteile",
    display:
      "Arbeitsplatten, Sockelblenden und sonstige Langteile werden aus technischen Gründen übermäßig geliefert und müssen bauseits an die Räumlichkeiten angepasst werden. Ausschnitte für Kochfelder, Spülen oder sonstige Ausklinkungen müssen ebenfalls bauseits vorgenommen werden.",
  },
  {
    code: "104",
    id: "Nachbestellung",
    display:
      "NACHBESTELLUNG!!! Farb-, Struktur- und sonstige Abweichungen sind möglich und daher kein Reklamationsgrund!",
  },
  {
    code: "105",
    id: "Widerrufsfrist bei Finanzierung",
    display:
      "Aufgrund der bestehenden Widerrufsfrist bei Finanzierungen steht Ihre Ware erst 14 Tage nach der Genehmigung des Finanzierungsbetrages zur Abholung/Lieferung bereit.",
  },
  {
    code: "106",
    id: "Stoffmuster für Kunden",
    display:
      "Stoffmuster vorab für Kunden zum Verbleib! Als Farbmuster für weitere Einkäufe wie Vorhänge, Teppichboden, etc. Bitte senden Sie dies zu Händen:",
  },
  {
    code: "107",
    id: "Beanstandung erledigt",
    display:
      "Die Beanstandung ist somit erledigt. Ein Austausch erfolgt nicht. Weitere Beanstandungen können nicht geltend gemacht werden.",
  },
  {
    code: "108",
    id: "Finanzierung ohne Gebühren",
    display:
      "0,00%-Finanzierung ohne Gebühren. Aufgrund der Widerrufsfrist bei Finanzierungen steht Ihre Ware erst 14 Tage nach der Genehmigung des Finanzierungsbetrages zur Abholung/Lieferung für Sie bereit.",
  },
  {
    code: "109",
    id: "Teilnahmebedingungen Gewinnspiel",
    display:
      'Ich habe die Teilnahmebedingungen zum Gewinnspiel "Jeder 10. Einkauf geschenkt" erhalten.',
  },
  {
    code: "110",
    id: "Sonderbestellung",
    display:
      "Bei den Artikeln handelt es sich um eine Sonderbestellung. Diese sind vom Umtausch ausgeschlossen.",
  },
  {
    code: "111",
    id: "Farbunterschiede",
    display:
      "In dieser Bestellung wurden Artikel von verschiedenen Herstellern zusammengestellt. Daraus resultierende Farbunterschiede stellen keinen Reklamationsgrund dar.",
  },
  {
    code: "112",
    id: "Kundeninformation Gartenmöbel",
    display: '"Kundeninformation Gartenmöbel aus Holz" erhalten.',
  },
  {
    code: "113",
    id: "Ausstellung",
    display:
      "Bitte für unsere Ausstellung 1x aufgebaut liefern. Falls nicht möglich, werden wir 30,- Euro als Montagehilfe vom Rechnungsbetrag kürzen.",
  },
  {
    code: "114",
    id: "Berechnung Stundensatz",
    display:
      "Berechnung erfolgt nach Stundensatz 50,- Euro inkl. MwSt je Monteur (gilt für Fahrt- und Montagezeit). Für Schäden, die trotz einer fachgerechten Ausführung eintreten, wird keine Haftung übernommen. Insgesamt    Std. x 50,- EUR=    EUR",
  },
  {
    code: "115",
    id: "Ledermuster",
    display:
      "Bitte Ledermuster von zu verarbeitendem Leder für Kunden vorab - eilt!",
  },
  {
    code: "116",
    id: "Möbelgarantie",
    display:
      "5-Jahre-Möbelgarantie inklusive. Diese Garantie gilt für Artikel: !BITTE ARTIKELBEZEICHNUNG EINGEBEN! Die Garantiebedingungen wurden übergeben.",
  },
  {
    code: "117",
    id: "Zusammenstellung",
    display:
      "Zusammenstellung laut bereits erhaltener Skizze und Einzelaufstellung/Änderungs-Bestätigung. Bitte beachten: Bei verspäteter Anzahlung kann der vereinbarte Liefertermin nicht eingehalten werden! Einbauküche aus Systembauteilen.",
  },
  {
    code: "118",
    id: "Ergänzungsauftrag",
    display:
      "Ergänzungsauftrag nach dem Ausmessen der Küche lt. Skizzen und Änderungsbestätigung!",
  },
  {
    code: "119",
    id: "Montageluft",
    display:
      '"Montageluft": Für die problemlose Montage werden zur Seite und nach oben min. 5 cm Platz benötigt.',
  },
  {
    code: "120",
    id: "Geschirr kontrollieren",
    display:
      "Bitte kontrollieren Sie Ihr Geschirr umgehend nach Erhalt auf Bruchschäden! Spätere Beanstandungen können nicht mehr anerkannt werden.",
  },
  {
    code: "121",
    id: "Umtausch ausgeschlossen",
    display: "Vom Umtausch ausgeschlossen.",
  },
  {
    code: "122",
    id: "Möbelbedarf",
    display:
      "Sollte sich bei Ihnen innerhalb eines Jahres - also bis zum TT.MM.JJ - ein erneuter Möbelbedarf ergeben, rechnen wir Ihnen die geleistete Abstandssumme bei Erteilung eines Neuauftrages ab einer Höhe von 99999 EUR (Höhe des stornierten Kaufvertrages) an.",
  },
  {
    code: "123",
    id: "Inkl. Wasseranschluss",
    display: "Inkl. Wasseranschluss (ab funktionsfähigem Eckventil)",
  },
  {
    code: "124",
    id: "Ohne Wasseranschluss",
    display: "Ohne Wasseranschluss",
  },
  {
    code: "125",
    id: "Inkl. Herdanschluss",
    display: "Inkl. Herdanschluss (ab funktionsfähiger Herdanschlussdose)",
  },
  {
    code: "126",
    id: "Ohne Herdanschluss",
    display: "Ohne Herdanschluss",
  },
  {
    code: "127",
    id: "Zahlung bei Lieferung",
    display:
      "!!!!!!!! ACHTUNG MONTEURE !!!!!!!! Der Betrag von _______ Euro ist sofort zur Zahlung fällig - bitte bei Lieferung kassieren!",
  },
  {
    code: "128",
    id: "Einbau Elektrogeräte",
    display:
      "Der Einbau der von Kunden zur Verfügung gestellten Elektrogeräte erfolgt in Kulanz ohne Gewährleistung und ist kein Bestandteil des Küchenauftrages.",
  },
  {
    code: "129",
    id: "Ausstellungsstück",
    display:
      "Ausstellungsstück gekauft wie besichtigt. Ware vom Umtausch ausgeschlossen! Preisreduziert wegen leichter Gebrauchsspuren. Es handelt sich um gebrauchte Ware, weshalb die gesetzliche Gewährleistung auf 1 Jahr reduziert ist.",
  },
  {
    code: "130",
    id: "Ausstellungsstück Besonderheiten",
    display:
      "Ausstellungsstück gekauft wie besichtigt. Ware vom Umtausch ausgeschlossen! Es handelt sich um gebrauchte Ware, weshalb die gesetzliche Gewährleistung auf 1 Jahr reduziert ist. Folgende besonderen Merkmale wurden festgestellt und stellen keinen Reklamationsgrund dar:",
  },
  {
    code: "131",
    id: "Tiefpreisgarantie",
    display:
      "Möbelhof Tiefpreisgarantie. Falls Sie das Produkt, das Sie bei uns erworben haben, bei gleichen Leistungen woanders günstiger bekommen und dies innerhalb von 10 Tagen nachweisen können, erstatten wir Ihnen den Differenzbetrag.",
  },
  {
    code: "132",
    id: "Zusammenstellung inkl. E-Geräte",
    display:
      "Zusammenstellung lt. bereits erhaltener Skizze und Einzelaufstellung/Änderungsbestätigung inkl. E-Geräte",
  },
  {
    code: "133",
    id: "Kaufvertragswert Gewinn",
    display:
      "Der ursprüngliche Kaufvertragswert betrug: ?????,- Euro. Durch Ihren Gewinn in unserer Aktion erhalten Sie diesen Einkauf geschenkt.",
  },
  {
    code: "134",
    id: "Austausch",
    display:
      "Um die Ware wieder einwandfrei präsentieren zu können und keine Umsatzeinbußen hinnehmen zu müssen, bitten wir auch im Rahmen unserer bisherigen guten Zusammenarbeit um kostenlosen Austausch.",
  },
  {
    code: "135",
    id: "Kulanz",
    display: "Kulanz, ohne Anerkennung einer Rechtspflicht!!",
  },
  {
    code: "136",
    id: "Selbstmontage",
    display: "SELBSTMONTAGE",
  },
  {
    code: "137",
    id: "Lieferung und Retoure",
    display: "Bitte liefern und Retoure mit zurücknehmen:",
  },
  {
    code: "138",
    id: "Retoure im Haus",
    display: "Retoure bereits im Haus",
  },
  {
    code: "139",
    id: "Vor Ort prüfen",
    display: "Bitte vor Ort prüfen und wenn möglich vor Ort erledigen:",
  },
  {
    code: "140",
    id: "Direktversand an Endkunde",
    display:
      "Achtung - bitte um Beachtung: Ware bitte direkt an Endkundenadresse versenden!!!",
  },
  {
    code: "141",
    id: "Begutachtung ohne Nachbesserung",
    display:
      "Achtung! Nur Begutachtung, keine Nachbesserung! Rein aus Kulanz, ohne Anerkennung einer Rechtspflicht!!",
  },
  {
    code: "142",
    id: "Instandsetzung im Werk",
    display:
      "Wir bestätigen Ihre Angabe vom ??.??.?? bezüglich der Instandsetzung der reklamierten Ware im Werk und erwarten die Rücklieferung binnen 20 Werktagen nach Abholung!",
  },
  {
    code: "143",
    id: "Polsterservice vor Ort",
    display:
      "Wir bestätigen Ihre Angabe vom ??.??.?? bezüglich des Einsatzes eines Polsterservices vor Ort und erwarten eine Erledigung binnen 20 Werktagen!",
  },
  {
    code: "144",
    id: "Beanstandung erledigt",
    display:
      "Die Beanstandung ist somit erledigt. Ein Austausch erfolgt nicht. Weitere Beanstandungen diesbezüglich können nicht mehr berücksichtigt werden.",
  },
];
