import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Icon,
  Layout,
  Pagination,
  SearchBox,
  Table,
  Text,
  Wrapper,
} from "../../../components";
import { config } from "../../../constants";
import { IncidentFilter } from "../../../containers";
import { withPermission } from "../../../hoc";
import {
  useAxios,
  useDefaultSaleChannel,
  usePermission,
  useToggle,
} from "../../../hooks";
import { Incident, WithPaging } from "../../../types";
import IncidentRow from "./IncidentRow";

function Incidents() {
  const saleChannel = useDefaultSaleChannel();
  const canCreate = usePermission(
    "CMS_IncidentFullAccess",
    "CMS_CreateIncident"
  );
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Incident.Item>>({});
  const [params, setParams] = useState<Incident.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    storeId: saleChannel?.saleChannelId,
  });
  const [showFilter, toggleFilter] = useToggle(false);

  const handleSetParams = (key: keyof Incident.Params) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p: any) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p: any) => ({ ...p, [key]: val }));
    };
  };
  const getData = () => {
    const url = "/channelmanagerservice/api/incidents";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <SearchBox
            placeholder="incident.list.searchPlaceholder"
            onSubmit={handleSetParams("keyword")}
            className="w-auto flex-1"
          />

          <Button type="button" variant="primary" light onClick={toggleFilter}>
            <Icon name="Filter" variant="Bold" size="1.25rem" />
          </Button>
          {/* <Button type="button" variant="primary" light>
          <Icon name="Scan" variant="Bold" size="1.25rem" />
          </Button>
          <Button type="button" variant="primary" light>
          <Icon name="Sort" size="1.25rem" />
        </Button>*/}
          {canCreate && (
            <Button as={Link} to="new" variant="primary" className="ms-auto">
              <Icon name="Add" /> <Text>incident.list.createNewBtn</Text>
            </Button>
          )}
        </Layout.Header>
        <Layout.Body>
          <Wrapper>
            <Wrapper.Body>
              <Table.Wrapper>
                <Table>
                  <Table.Body loading={loading.get}>
                    {data.items?.map((e) => (
                      <IncidentRow
                        key={e.crmIncidentId}
                        incident={e}
                        onActionEnd={getData}
                      />
                    ))}
                  </Table.Body>
                </Table>
              </Table.Wrapper>
            </Wrapper.Body>
          </Wrapper>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            totalItems={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Layout.Body>
      </Layout>
      <IncidentFilter
        isOpen={showFilter}
        toggle={toggleFilter}
        initParams={params}
        onSubmit={setParams}
      />
    </Fragment>
  );
}
export default withPermission(Incidents, [
  "CMS_IncidentFullAccess",
  "CMS_GetIncident",
]);
