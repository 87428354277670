import { useState } from "react";
import {
  Accordion,
  Button,
  Drawer,
  Form,
  Icon,
  Text,
} from "../../../components";
import { addedSinceItems, types } from "../../../constants";
import { togglePropsType } from "../../../types";
// type FilterType = Customer.Params;
type FilterType = unknown | any;
type filterMenuProps = togglePropsType & {
  onSubmit: (data: FilterType) => void;
};
export default function FilterMenu({
  isOpen,
  toggle,
  onSubmit,
}: filterMenuProps) {
  const initData: FilterType = {
    // city: null,
    // title: null,
    // gender: null,
    // registeredFromDate: null,
    // registeredToDate: null,
    // createdFromDate: null,
    // createdToDate: null,
    // pageNumber: 1,
    // pageSize: config.pageSize,
  };
  const [data, setData] = useState(initData);
  const resetFilter = () => {
    setData(initData);
    onSubmit(initData);
    toggle();
  };
  const submit = () => {
    onSubmit({ ...data, pageNumber: 1 });
    toggle();
  };
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center">
          <div className="flex-1"></div>
          <h6>
            <Text>global.filter</Text>
          </h6>
          <div className="flex-1 flex justify-end">
            <button
              type="button"
              className="text-warning"
              onClick={resetFilter}
            >
              <Icon name="ArrowForward" variant="Bold" />{" "}
              <Text>button.resetFilter</Text>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Accordion>
            <Accordion.Item>
              <Accordion.Toggle>
                <Text>formControls.addedSince</Text>
              </Accordion.Toggle>
              <Accordion.Body>
                {addedSinceItems.map((e) => (
                  <div
                    key={e.id.toString()}
                    className="py-3 border-b border-gray-100 last:border-b-0"
                  >
                    <button
                      type="button"
                      onClick={() =>
                        setData((p: any) => ({ ...p, createdFromDate: e.id }))
                      }
                      className="flex items-center gap-2"
                    >
                      <span
                        data-active={`${e.id}` === `${data.createdFromDate}`}
                        className="relative w-5 h-5 rounded-full bg-gray-100 before:content('') before:absolute before:inset-1 before:bg-primary before:rounded-full before:opacity-0 data-active:before:opacity-100 before:transition-opacity"
                      />
                      <Text>{e.name}</Text>
                    </button>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Toggle>
                <Text>formControls.salutation</Text>
              </Accordion.Toggle>
              <Accordion.Body>
                {types.salutation.map((e) => (
                  <div
                    key={e.id}
                    className="py-3 border-b border-gray-100 last:border-b-0"
                  >
                    <button
                      type="button"
                      onClick={() =>
                        setData((p: any) => ({ ...p, title: e.id }))
                      }
                      className="flex items-center gap-2"
                    >
                      <span
                        data-active={`${e.id}` === `${data.title}`}
                        className="relative w-5 h-5 rounded-full bg-gray-100 before:content('') before:absolute before:inset-1 before:bg-primary before:rounded-full before:opacity-0 data-active:before:opacity-100 before:transition-opacity"
                      />
                      <Text>{e.name}</Text>
                    </button>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item>
              <Accordion.Toggle>
                <Text>formControls.gender</Text>
              </Accordion.Toggle>
              <Accordion.Body>
                {types.gender.map((e) => (
                  <div
                    key={e.id}
                    className="py-3 border-b border-gray-100 last:border-b-0"
                  >
                    <button
                      type="button"
                      onClick={() =>
                        setData((p: any) => ({ ...p, gender: e.id }))
                      }
                      className="flex items-center gap-2"
                    >
                      <span
                        data-active={`${e.id}` === `${data.gender}`}
                        className="relative w-5 h-5 rounded-full bg-gray-100 before:content('') before:absolute before:inset-1 before:bg-primary before:rounded-full before:opacity-0 data-active:before:opacity-100 before:transition-opacity"
                      />
                      <Text>{e.name}</Text>
                    </button>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit">
            <Text>button.search</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
