import { Fragment, ReactNode, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "../components";
import { useAxios, useGetProfile, useSelector } from "../hooks";
import { isEmptyValue } from "../methods";
import { setPermissions } from "../redux/permissions";
type props = { children: ReactNode };
export default function ProfileProvider({ children }: props) {
  const [getProfile, profileLoading] = useGetProfile();
  const dispatch = useDispatch();
  const { axios, loading } = useAxios();

  const profile = useSelector((s) => s.profile);
  const permissions = useSelector((s) => s.permissions);

  const hasProfile = !isEmptyValue(profile) && !!permissions.length;
  const hasLoading = loading.get || profileLoading;

  const getPermissions = useCallback(() => {
    const url = "/accountservice/api/users/permissions";
    axios.get(url).then(({ data }) => {
      dispatch(setPermissions(data.permissions ?? []));
    });
  }, []);

  useEffect(() => {
    getPermissions();
    getProfile();
  }, [getProfile, getPermissions]);

  if (hasLoading)
    return (
      <section className="container max-w-sm py-20 text-center space-y-4">
        <Icon
          name="Profile"
          variant="Broken"
          size={150}
          className="animate-pulse"
        />
        <div className="w-full h-2 bg-gray-300 rounded-full overflow-hidden">
          <div className="h-full bg-dark transition-all rounded-full min-w-[5%] max-w-[90%] animate-progress" />
        </div>
      </section>
    );
  if (!hasProfile) return null;
  return <Fragment>{children}</Fragment>;
}
