import { ComponentProps, useEffect, useMemo, useRef } from "react";
import { twMerge } from "tailwind-merge";
import imageLoadingGif from "../assets/icons/image Loading.gif";
import thumbnail from "../assets/icons/image.jpeg";
import { useGetFile } from "../hooks";
type imageProps = {
  src?: string | null;
  alt?: string | null;
  // thumbnail?: string;
  isDynamic?: boolean;
};
type imgProps = Omit<ComponentProps<"img">, keyof imageProps>;
export default function Image({
  src,
  alt,
  // thumbnail = imageIcon,
  isDynamic = false,
  className,
  ...props
}: imageProps & imgProps) {
  const [getFile] = useGetFile();
  const imageRef = useRef<HTMLImageElement>(null);
  const hasSrc = !!src;
  const initSrc = useMemo(
    () => (hasSrc ? imageLoadingGif : thumbnail),
    [hasSrc]
  );
  const imageSrcHandler = () => {
    const element = imageRef.current;
    if (!hasSrc || !element) return;
    const isLazy = isDynamic && src.includes("/get-file");
    element.src = initSrc;
    if (!isLazy) {
      const image = new window.Image();
      image.src = src;
      image.onload = () => (element.src = src);
      image.onerror = () => (element.src = thumbnail);
      return;
    }
    getFile(src).then((src) => (element.src = src ?? thumbnail));
  };
  useEffect(imageSrcHandler, [isDynamic, src, hasSrc, initSrc]);
  return (
    <img
      ref={imageRef}
      src={initSrc}
      alt={alt ?? undefined}
      loading={props.loading ?? "lazy"}
      data-src={src}
      className={twMerge(className, !hasSrc && "bg-[#eaedf2]")}
      {...props}
    />
  );
}
