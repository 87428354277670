import { useEffect, useMemo, useState } from "react";
import { Button, Drawer, Icon, List, SearchBox, Text } from "../components";
import { useAxios } from "../hooks";
import { LineItem, Optional, togglePropsType } from "../types";

export type SelectedLineItem = Optional<LineItem.Item>;

type ChooseLineItemProps = togglePropsType & {
  orderId?: string | null;
  onSelect: (lineItem: SelectedLineItem | null) => void;
};

export default function ChooseIncidentLineItem({
  isOpen,
  toggle,
  onSelect,
  orderId,
}: ChooseLineItemProps) {
  const { axios, loading } = useAxios();
  const [data, setData] = useState<LineItem.Item[]>([]);
  const [keyword, setKeyword] = useState<string | null>(null);
  const lineItems = useMemo(() => {
    if (!keyword) return data;
    return data.filter((e) =>
      e.title.toLowerCase().includes(keyword.toLocaleLowerCase())
    );
  }, [data, keyword]);
  const handleToggle = () => {
    toggle();
  };
  const handleSelect = (e: LineItem.Item) => {
    return () => {
      onSelect(e);
      handleToggle();
    };
  };
  const getData = () => {
    if (!isOpen) return;
    const url = "/salesservice/api/orderlineitems";
    const config = { params: { orderId: orderId, isCanceled: false } };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [isOpen]);
  return (
    <Drawer isOpen={isOpen} toggle={handleToggle} className="z-[31]">
      <Drawer.Menu>
        <Drawer.Header className="flex items-center border-b-0">
          <h6 className="text-start text-base text-dark flex-1">
            <Text>incident.chooseLineItem.title</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox
            placeholder="incident.chooseLineItem.search"
            variant="gray"
            onSubmit={setKeyword}
          />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {lineItems.map((e) => (
              <List.Item key={e.id} onClick={handleSelect(e)}>
                <div className="flex-center w-10 h-10 rounded-full text-primary bg-primary/5">
                  <Icon name="Box" variant="Bold" size={28} />
                </div>
                <div className="flex-1">
                  <h6 className="text-base text-dark">{e.title}</h6>
                  <p className="text-sm text-secondary">
                    #{e.product?.articleNumber}
                  </p>
                  <p className="text-sm text-secondary">
                    <Text>tableTitles.lineItemProgramCode</Text>:{" "}
                    {e.supplierProgram?.code}
                  </p>
                  <p className="text-sm text-secondary">
                    <Text>tableTitles.lineItemSupplierILN</Text>:{" "}
                    {e.supplier?.iln}
                  </p>
                </div>
                <Button as="span" light>
                  <Text>incident.chooseLineItem.chooseBtn</Text>
                </Button>
              </List.Item>
            ))}
          </List>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
