import { useState } from "react";

type Callback = () => void;

export default function useToggle(
  initValue: boolean = false
): [boolean, Callback, Callback] {
  const [state, setState] = useState(initValue);
  const toggle = () => {
    setState((p) => !p);
  };
  const hide = () => {
    setState(false);
  };
  return [state, toggle, hide];
}
