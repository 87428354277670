import {
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  BackButton,
  Icon,
  Layout,
  Loading,
  Status,
  Tabs,
  Text,
  Types,
  Wrapper,
} from "../../../components";
import {
  CustomerCard,
  IncidentChangeStatus,
  IncidentStatusInfo,
} from "../../../containers";
import { CustomerType, IncidentStatus } from "../../../enums";
import { withPermission } from "../../../hoc";
import {
  useAxios,
  useConverters,
  usePermission,
  useToggle,
} from "../../../hooks";
import { Company, Customer, Incident, iconNameType } from "../../../types";
import Activities from "./Activities";
import Documents from "./Documents";
import General from "./General";

type MoreInfoItem = {
  icon: iconNameType;
  label: string;
  value: ReactNode;
};
type IncidentData = Incident.Details;
type IncidentDetailsContextType = {
  incident: IncidentData;
  setIncident: Dispatch<SetStateAction<IncidentData | null>>;
  isActive: boolean;
  branch: null;
};
export const IncidentDetailsContext = createContext(
  {} as IncidentDetailsContextType
);

function IncidentDetails() {
  const canEdit = usePermission("CMS_IncidentFullAccess", "CMS_UpdateIncident");
  const { incidentId } = useParams();
  const { axios, loading } = useAxios();
  const { convertDate } = useConverters();
  const [incident, setIncident] = useState<IncidentData | null>(null);
  const [customer, setCustomer] = useState<
    (Customer.Details & Company.Details) | null
  >(null);
  const [showStatusInfo, toggleStatusInfo] = useToggle(false);
  const [showChangeStatus, toggleChangeStatus] = useToggle(false);
  const hasData = !!incident;
  const branch = null;
  const isActive = incident?.status === IncidentStatus.Active;
  const moreInfo: MoreInfoItem[] = [
    {
      icon: "Document",
      label: "incident.details.subjectName",
      value: incident?.subjectName ?? "--",
    },
    {
      icon: "Calendar",
      label: "incident.details.createdAt",
      value: convertDate(incident?.createdAt),
    },
    {
      icon: "LocationTick",
      label: "incident.details.store",
      value: incident?.branchName ?? "--",
    },
  ];
  const tabs = [
    {
      label: "incident.details.activitiesTab",
      id: "0",
      component: Activities,
    },
    {
      label: "incident.details.generalTab",
      id: "1",
      component: General,
    },
    {
      label: "incident.details.documentsTab",
      id: "2",
      component: Documents,
    },
  ];
  const getIncident = () => {
    const url = `/channelmanagerservice/api/incidents/${incidentId}`;
    axios.get(url).then(({ data }) => {
      const isCompany = data.customer?.customerType === CustomerType.Company;
      setIncident(data);
      getCustomer(data?.customer?.customerId, isCompany);
    });
  };
  const getCustomer = (id: string, isCompany: boolean) => {
    if (!id) return;
    const url = isCompany
      ? `/accountservice/api/customers/${id}/as-company`
      : `/accountservice/api/customers/${id}`;
    axios.get(url).then(({ data }) => {
      setCustomer(data);
    });
  };
  useEffect(getIncident, [incidentId]);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <BackButton to="/incidents" className="mr-auto" />
        </Layout.Header>
        <Layout.Body className="space-y-4">
          {loading.get ? (
            <Loading.Header />
          ) : (
            <Fragment>
              <section className="grid grid-cols-1 lg:grid-cols-5 gap-3">
                <Wrapper className="lg:col-span-3">
                  <Wrapper.Body className="flex flex-col gap-3 h-full justify-between">
                    <section className="flex items-center gap-2">
                      <Icon.Wrapper rounded>
                        <Icon name="ReceiptText" variant="Bold" />
                      </Icon.Wrapper>
                      <div className="text-start">
                        <h1 className="text-base text-dark">
                          {incident?.title}
                        </h1>
                        <p className="text-sm text-secondary">
                          #{incident?.ticketNumber}
                        </p>
                      </div>
                    </section>
                    <section className="flex flex-wrap items-center gap-5">
                      {moreInfo.map((e) => (
                        <p key={e.label} className="text-sm">
                          <Icon
                            name={e.icon}
                            variant="Bulk"
                            className="text-primary"
                          />{" "}
                          <span className="text-placeholder">
                            <Text>{e.label}</Text>:
                          </span>{" "}
                          <span className="text-secondary">{e.value}</span>
                        </p>
                      ))}
                    </section>
                    <section className="flex items-center flex-wrap gap-3">
                      <Status.Incident
                        status={incident?.status}
                        statusReason={incident?.statusReason}
                        className="py-1"
                        onClick={canEdit ? toggleStatusInfo : undefined}
                      />
                      <Types.IncidentPriority id={incident?.priority} />
                      <Types.IncidentOrigin id={incident?.origin} />
                    </section>
                  </Wrapper.Body>
                </Wrapper>
                <CustomerCard customer={customer} className="lg:col-span-2" />
              </section>
              <Tabs activeKey={tabs[0].id}>
                <Wrapper className="col-span-full">
                  <Wrapper.Body className="py-0">
                    <Tabs.ButtonGroup>
                      {tabs.map((e) => (
                        <Tabs.Button key={e.id} eventKey={e.id}>
                          <Text>{e.label}</Text>
                        </Tabs.Button>
                      ))}
                    </Tabs.ButtonGroup>
                  </Wrapper.Body>
                </Wrapper>
                {hasData && (
                  <Fragment>
                    <IncidentDetailsContext.Provider
                      value={{
                        incident,
                        setIncident,
                        isActive: isActive,
                        branch,
                      }}
                    >
                      {tabs.map((e) => (
                        <Tabs.Item key={e.id} eventKey={e.id}>
                          {createElement(e.component)}
                        </Tabs.Item>
                      ))}
                    </IncidentDetailsContext.Provider>
                  </Fragment>
                )}
              </Tabs>
            </Fragment>
          )}
        </Layout.Body>
      </Layout>
      {hasData && (
        <Fragment>
          <IncidentStatusInfo
            isOpen={showStatusInfo}
            toggle={toggleStatusInfo}
            incident={incident}
            onChangeStatus={toggleChangeStatus}
          />
          {canEdit && (
            <IncidentChangeStatus
              isOpen={showChangeStatus}
              toggle={toggleChangeStatus}
              incident={incident}
              onSubmitted={getIncident}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

export default withPermission(IncidentDetails, [
  "CMS_IncidentFullAccess",
  "CMS_GetIncident",
]);
