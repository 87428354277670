import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import {
  Button,
  CheckBox,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Loading,
  Text,
} from "../components";
import { rules, salePriceTemplateItemSteps, types } from "../constants";
import { LineItemType } from "../enums";
import { useAxios } from "../hooks";
import useOptionalDiscounts from "../hooks/useOptionalDiscounts";
import { convertOptionalDiscounts } from "../methods";
import { OfferDetailsContext } from "../pages/Offers/Details";
import { LineItemsContext } from "../pages/Offers/Details/LineItems";
import { OrderDetailsContext } from "../pages/Orders/Details";
import { LineItem, togglePropsType } from "../types";

type AddOptionalDiscountsProps = togglePropsType & {
  lineItem: LineItem.Item;
  isSubLineItem: boolean;
};

export default function AddOptionalDiscounts({
  isOpen,
  toggle,
  lineItem,
  isSubLineItem,
}: AddOptionalDiscountsProps) {
  const [optionalDiscounts, discountsLoading] = useOptionalDiscounts({
    lineItem: lineItem,
    force: isOpen,
  });
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const { axios, loading } = useAxios();
  const { setLineItems } = useContext(LineItemsContext);

  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const isOffer = !!offerContext;
  const isOrder = !!orderContext;

  const initDiscounts = convertOptionalDiscounts(lineItem.discounts) ?? [];
  const isIwofurnProduct =
    lineItem.lineItemType === LineItemType.IwofurnProduct && !isSubLineItem;
  const isIwofurnBundleItem =
    lineItem.lineItemType === LineItemType.IwofurnProduct && isSubLineItem;
  const isCatalogue = lineItem.lineItemType === LineItemType.ManualProduct;
  const isPimBundleItem = lineItem.lineItemType === LineItemType.PimBundleItem;

  const hasLoading = [
    loading.update,
    loading.get,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);

  const [discounts, setDiscounts] = useState(initDiscounts);

  const list = useMemo(() => {
    return salePriceTemplateItemSteps
      .map((e) => {
        const step = e.id;
        return {
          title: e.name,
          items: optionalDiscounts.filter((e) => e.step === step),
        };
      })
      .filter((e) => !!e.items.length);
  }, [optionalDiscounts]);
  const handleSetInitData = () => {
    if (!isOpen) return;
    setDiscounts(initDiscounts);
  };
  const handleValue = (id: string) => {
    return discounts.map((e) => e.calculationUnitIdentifier).includes(id);
  };
  const handleInputValue = (
    id: string,
    key: keyof LineItem.OptionalDiscountRequest
  ) => {
    const discount = discounts.find((e) => e.calculationUnitIdentifier === id);
    if (!discount) return "";
    return discount[key];
  };
  const handleSetValueId = (id: string) => {
    return () => {
      setDiscounts((p) => {
        const data = cloneDeep(p);
        const index = data.findIndex((e) => e.calculationUnitIdentifier === id);
        const has = index !== -1;
        if (has) data.splice(index, 1);
        if (!has)
          data.push({
            calculationUnitIdentifier: id,
            description: null,
            manualValue: null,
          });
        return data;
      });
    };
  };
  const handleSetInputValue = (
    id: string,
    key: keyof LineItem.OptionalDiscountRequest
  ) => {
    return (value: any) => {
      setDiscounts((p) => {
        const data = cloneDeep(p);
        const index = data.findIndex((e) => e.calculationUnitIdentifier === id);
        const has = index !== -1;
        const isManualValue = key === "manualValue";
        const isDescription = key === "description";
        if (!has) {
          data[index] ||= {
            calculationUnitIdentifier: id,
            description: null,
            manualValue: null,
          };
        }
        if (isManualValue) {
          data[index].manualValue = !!value ? Number(value) : null;
        } else if (isDescription) {
          data[index].description = !!value ? String(value) : null;
        }
        return data;
      });
    };
  };
  const updateLineItem = async (id: string | null) => {
    if (!id) return Promise.resolve(null);
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      id,
    ]
      .filter(Boolean)
      .join("/");
    return await axios
      .get<LineItem.Item>(url)
      .then(({ data }) => {
        setLineItems?.((p) => {
          const lineItems = cloneDeep(p);
          const index = lineItems.findIndex((e) => e.id === id);
          if (data) lineItems[index] = data;
          return lineItems;
        });
        return null;
      })
      .catch(() => null);
  };
  const generateUrl = () => {
    if (isOffer) {
      const baseUrl = `/salesservice/api/offerlineitem/${lineItem.id}`;
      if (isIwofurnProduct) return `${baseUrl}/apply-iwofurn-product-discount`;
      if (isIwofurnBundleItem)
        return `${baseUrl}/apply-iwofurn-bundle-item-discount`;
      if (isSubLineItem && isCatalogue)
        return `${baseUrl}/apply-manual-bundle-item-discount`;
      if (!isSubLineItem && isCatalogue)
        return `${baseUrl}/apply-manual-product-discount`;
      if (isPimBundleItem) return `${baseUrl}/apply-pim-bundle-item-discount`;
      return `${baseUrl}/apply-discount`;
    }
    if (isOrder) {
      const baseUrl = `/salesservice/api/orderlineitems/${lineItem.id}`;
      if (isIwofurnProduct) return `${baseUrl}/apply-iwofurn-product-discount`;
      if (isIwofurnBundleItem)
        return `${baseUrl}/apply-iwofurn-bundle-item-discount`;
      if (isSubLineItem && isCatalogue)
        return `${baseUrl}/apply-sub-line-item-discount`;
      if (!isSubLineItem && isCatalogue)
        return `${baseUrl}/apply-manual-product-discount`;
      if (isPimBundleItem) return `${baseUrl}/apply-pim-bundle-item-discount`;
      return `${baseUrl}/apply-discount`;
    }
    return "";
  };
  const generateBody = () => {
    if (isOffer) {
      const lineItemBody: LineItem.ApplyOfferLineItemDiscount = {
        offerLineItemId: lineItem?.id,
        offerId: offer?.id ?? "",
        quantity: lineItem.quantity,
        optionalDiscounts: discounts,
      };
      const catalogueBody: LineItem.ApplyOfferLineItemManualProductDiscount = {
        offerLineItemId: lineItem.id,
        offerId: offer?.id ?? "",
        quantity: lineItem.quantity,
        salesPrice: lineItem.originalPrice,
        optionalDiscounts: discounts,
      };
      const catalogueItemBody: LineItem.ApplyOfferLineItemManualBundleItemDiscount =
        {
          parentId: lineItem.parentId,
          offerLineItemId: lineItem.id,
          offerId: offer?.id ?? "",
          quantity: lineItem.quantity,
          salesPrice: lineItem.originalPrice,
          optionalDiscounts: discounts,
        };
      const pimBundleItemBody: LineItem.ApplyOfferLineItemPimBundleItemDiscount =
        {
          offerLineItemId: lineItem?.id,
          offerId: offer?.id ?? "",
          optionalDiscounts: discounts,
        };
      const iwofurnProductBody: LineItem.ApplyIwofurnProductDiscount = {
        offerLineItemId: lineItem.id,
        offerId: offer?.id ?? "",
        quantity: lineItem.quantity,
        optionalDiscounts: discounts,
        basePrice: lineItem.originalPrice,
      };
      const iwofurnBundleItemBody: LineItem.ApplyOfferLineItemIwofurnBundleItemDiscount =
        {
          parentId: lineItem.parentId,
          offerLineItemId: lineItem.id,
          offerId: offer?.id ?? "",
          quantity: lineItem.quantity,
          optionalDiscounts: discounts,
          basePrice: lineItem.originalPrice,
        };
      if (isIwofurnProduct) return iwofurnProductBody;
      if (isIwofurnBundleItem) return iwofurnBundleItemBody;
      if (isSubLineItem && isCatalogue) return catalogueItemBody;
      if (!isSubLineItem && isCatalogue) return catalogueBody;
      if (isPimBundleItem) return pimBundleItemBody;
      return lineItemBody;
    }
    if (isOrder) {
      const lineItemBody: LineItem.ApplyOrderLineItemDiscount = {
        orderLineItemId: lineItem?.id,
        orderId: order?.id ?? "",
        quantity: lineItem.quantity,
        optionalDiscounts: discounts,
      };
      const catalogueBody: LineItem.ApplyOrderLineItemManualProductDiscount = {
        orderLineItemId: lineItem?.id,
        orderId: order?.id ?? "",
        quantity: lineItem.quantity,
        salesPrice: lineItem.originalPrice,
        optionalDiscounts: discounts,
      };
      const pimBundleItemBody: LineItem.ApplyOrderLineItemPimBundleItemDiscount =
        {
          orderLineItemId: lineItem?.id,
          orderId: order?.id ?? "",
          optionalDiscounts: discounts,
        };
      const iwofurnBody: LineItem.ApplyOrderLineItemIwofurnProductDiscountRequest =
        {
          orderLineItemId: lineItem?.id,
          orderId: order?.id ?? "",
          quantity: lineItem.quantity,
          optionalDiscounts: discounts,
          basePrice: lineItem.originalPrice,
        };
      const iwofurnBundleItemBody: LineItem.ApplyOrderLineItemIwofurnBundleItemDiscount =
        {
          orderLineItemId: lineItem?.id,
          orderId: order?.id ?? "",
          parentId: lineItem.parentId,
          quantity: lineItem.quantity,
          optionalDiscounts: discounts,
          basePrice: lineItem.originalPrice,
        };
      const subLineItemBody: LineItem.ApplyOrderSubLineItemDiscount = {
        orderLineItemId: lineItem?.id,
        orderId: order?.id ?? "",
        parentId: lineItem.parentId,
        quantity: lineItem.quantity,
        salesPrice: lineItem.originalPrice,
        optionalDiscounts: discounts,
      };
      if (isIwofurnProduct) return iwofurnBody;
      if (isIwofurnBundleItem) return iwofurnBundleItemBody;
      if (isSubLineItem && isCatalogue) return subLineItemBody;
      if (!isSubLineItem && isCatalogue) return catalogueBody;
      if (isPimBundleItem) return pimBundleItemBody;
      return lineItemBody;
    }
    return {};
  };
  const submitOfferLineItem = () => {
    const url = generateUrl();
    const body = generateBody();
    axios.put(url, body).then(() => {
      updateOffer?.().then(() => {
        const message = isSubLineItem
          ? "toast.success.editSubLineItem"
          : "toast.success.editLineItem";
        const updatedLineItems = Promise.all([
          updateLineItem(lineItem.id),
          updateLineItem(lineItem.parentId),
        ]);
        updatedLineItems.then(() => {
          toast.success(message);
          toggle();
        });
      });
    });
  };
  const submitOrderLineItem = () => {
    const url = generateUrl();
    const body = generateBody();
    axios.put(url, body).then(() => {
      updateOrder?.().then(() => {
        const message = isSubLineItem
          ? "toast.success.editSubLineItem"
          : "toast.success.editLineItem";
        const updatedLineItems = Promise.all([
          updateLineItem(lineItem.id),
          updateLineItem(lineItem.parentId),
        ]);
        updatedLineItems.then(() => {
          toast.success(message);
          toggle();
        });
      });
    });
  };
  const submit = () => {
    isOffer && submitOfferLineItem();
    isOrder && submitOrderLineItem();
  };
  useEffect(handleSetInitData, [isOpen]);
  return (
    <Drawer
      as={Form}
      onSubmit={submit}
      isOpen={isOpen}
      toggle={toggle}
      className="z-[31]"
    >
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base text-dark">
            <Text>drawerTitles.lineItemOptionalDiscount</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="divide-y">
          {discountsLoading ? (
            <Loading.Inline />
          ) : (
            list.map((e) => (
              <section key={e.title} className="py-4">
                <h6 className="text-base text-dark mb-4">
                  <Text>{e.title}</Text>
                </h6>
                <ul className="">
                  {e.items.map((item) => {
                    const calculationSymbol = types.calculationValue.find(
                      (e) => e.id === item.valueType
                    )?.symbol;
                    const active =
                      handleValue(item.calculationUnitId) || item.isPermanent;
                    const hasManualValue = item.hasManualValue;
                    const min = item.minValue ?? 0;
                    const max = item.maxValue ?? Infinity;
                    const showFormControls = active && hasManualValue;
                    return (
                      <li
                        key={item.calculationUnitId}
                        className="py-1.5 space-y-2"
                      >
                        <div className="flex items-center gap-4">
                          <CheckBox
                            value={active}
                            setValue={handleSetValueId(item.calculationUnitId)}
                            label={item.title}
                            className="flex-1"
                            disabled={item.isPermanent}
                          />{" "}
                          <span className="text-base text-dark">
                            {hasManualValue ? (
                              <Icon
                                name="User"
                                variant="Bold"
                                className="size-4"
                              />
                            ) : (
                              <Fragment>
                                {item.itemValue} {calculationSymbol}
                              </Fragment>
                            )}
                          </span>
                        </div>
                        {showFormControls && (
                          <div className="border rounded p-4 space-y-4">
                            <InputGroup
                              label="global.lineItemOptionalDiscountManualValue"
                              value={handleInputValue(
                                item.calculationUnitId,
                                "manualValue"
                              )}
                              setValue={handleSetInputValue(
                                item.calculationUnitId,
                                "manualValue"
                              )}
                              rules={rules.minmax(min, max)}
                              type="number"
                              helperTextProps={{
                                min: `${min} ${calculationSymbol}`,
                                max: `${max} ${calculationSymbol}`,
                              }}
                              append={
                                <span className="input-group-text">
                                  {calculationSymbol}
                                </span>
                              }
                            />
                            <InputGroup
                              as="textarea"
                              label="global.lineItemOptionalDiscountDescription"
                              value={handleInputValue(
                                item.calculationUnitId,
                                "description"
                              )}
                              setValue={handleSetInputValue(
                                item.calculationUnitId,
                                "description"
                              )}
                              rules={rules.required}
                            />
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </section>
            ))
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button
            type="button"
            variant="danger"
            onClick={toggle}
            disabled={hasLoading}
          >
            <Text>button.cancel</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            // onClick={submit}
            loading={hasLoading}
          >
            <Text>button.applyLineItemDiscount</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
