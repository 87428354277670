import { useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import { Text, Wrapper } from "../../../../components";
import { useConverters } from "../../../../hooks";
import { cn } from "../../../../methods";

export default function Price() {
  const { convertAmount } = useConverters();
  const { basicInfo, statusInfo } = useContext(SellOffProductDetailsContext);
  const discount = statusInfo?.currentDiscount;
  const hasDiscount = !!discount;
  const items = [
    {
      label: "sellOffProducts.details.regularPrice",
      value: convertAmount(basicInfo?.pimProduct?.vk1Price?.amount),
      classes: "text-gray-900",
    },
    {
      label: "sellOffProducts.details.discount",
      value: hasDiscount ? `${discount}%` : null,
      classes: "text-danger",
    },
    {
      label: "sellOffProducts.details.phasePrice",
      value: convertAmount(basicInfo?.currentPhasePrice),
      classes: "text-gray-900",
    },
  ];
  return (
    <Wrapper className="lg:col-span-2">
      <Wrapper.Header>
        <h6 className="flex-1 text-sm text-dark font-medium">
          <Text>sellOffProducts.details.priceInfo</Text>
        </h6>
      </Wrapper.Header>
      <Wrapper.Body>
        <table className="w-full text-sm font-medium">
          <tbody className="divide-y divide-dashed">
            {items.map((e) => (
              <tr
                key={e.label}
                className="*:py-3 [&:first-child>*]:pt-0 [&:last-child>*]:pb-0"
              >
                <td className="text-secondary text-start whitespace-nowrap">
                  <Text>{e.label}</Text>
                </td>
                <td className={cn("text-end", e.classes)}>{e.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Wrapper.Body>
    </Wrapper>
  );
}
