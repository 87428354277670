import { Dispatch, useContext } from "react";
import toast from "react-hot-toast";
import { ProgramDetailsContext } from "..";
import { Button, Modal, Text } from "../../../../../components";
import { useAxios } from "../../../../../hooks";
import { togglePropsType } from "../../../../../types";

type Props = {
  id: string | null;
  onRemoved: Dispatch<string>;
} & togglePropsType;

export default function RemoveConfirm({
  id,
  isOpen,
  toggle,
  onRemoved,
}: Props) {
  const { axios, loading } = useAxios();
  const { program } = useContext(ProgramDetailsContext);
  const remove = () => {
    if (!id) return;
    if (!program?.id) return;
    const url = `/salesservice/api/supplierprograms/${program.id}/availability-status/${id}`;
    axios.delete(url).then(() => {
      const message =
        "suppliers.programAvailabilityStatus.removeSuccessMessage";
      toast.success(message);
      toggle();
      onRemoved(id);
    });
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={loading.delete ? () => {} : toggle}
      className="z-40"
    >
      <Modal.Header className="flex flex-row">
        <h6 className="text-heading-2 text-gray-800">
          <Text>suppliers.programAvailabilityStatus.removeStatusTitle</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="max-h-100 space-y-10 overflow-auto">
        <p className="text-body-base font-normal text-gray-900">
          <Text>suppliers.programAvailabilityStatus.removeStatusText</Text>
        </p>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button
          type="button"
          variant={"light"}
          onClick={loading.delete ? undefined : toggle}
          disabled={loading.delete}
        >
          <Text>suppliers.programAvailabilityStatus.cancelRemoveButton</Text>
        </Button>
        <Button
          type="button"
          variant={"danger"}
          loading={loading.delete}
          onClick={remove}
        >
          <Text>suppliers.programAvailabilityStatus.removeStatusButton</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
