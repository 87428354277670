import { Dispatch, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { Icon, Text } from "../../../../components";
import { SalesStatus } from "../../../../enums";
import { useConverters, useErpStatus, useTranslate } from "../../../../hooks";
import { Order } from "../../../../types";

type Props = {
  order: Order.Item;
  isSelected: boolean;
  deliveryDateISO: string;
  deliveryDate: string;
  onSelect: Dispatch<string>;
};

export default function ChooseOrderItem({
  order,
  isSelected,
  deliveryDateISO,
  deliveryDate,
  onSelect,
}: Props) {
  const salesStatus = useErpStatus({ statuses: order.salesStatuses });
  const { convertDate, convertAmount } = useConverters();
  const translate = useTranslate();
  const hasDeliveryDate = !!order.desiredDeliveryDate;
  const isDisabled = useMemo(() => {
    const disableDate =
      order?.desiredDeliveryDate &&
      deliveryDateISO <= order?.desiredDeliveryDate;
    return [
      salesStatus !== SalesStatus.None,
      order?.commissionGroup,
      disableDate,
      !order.lastPublishedVersion,
    ].some(Boolean);
  }, [order, deliveryDateISO, salesStatus]);
  const warningMassage = useMemo(() => {
    let massage = "";
    if (order?.commissionGroup)
      massage = `${translate("commissions.addedToCommissionGroupWarning")} (#${
        order.commissionGroup.code
      })`;
    else if (!order.lastPublishedVersion)
      massage = `${translate("commissions.notPublishWarning")}`;
    else if (salesStatus !== SalesStatus.None) {
      switch (salesStatus) {
        case SalesStatus.Backorder:
          massage = translate("commissions.orderStatusInProgress");
          break;
        case SalesStatus.Delivered:
          massage = translate("commissions.orderStatusSentOut");
          break;
        case SalesStatus.Invoiced:
          massage = translate("commissions.orderStatusInvoiced");
          break;
        case SalesStatus.Canceled:
          massage = translate("commissions.orderStatusOrdered");
          break;
      }
    } else
      massage = `${translate(
        "commissions.commissionGroupDeliveryWarning"
      )} (${convertDate(deliveryDate)})`;
    return (
      <p className="text-warning gap-1 text-xs flex align-middle">
        <Icon name="InfoCircle" variant="Bulk" className="text-warning" />
        {massage}
      </p>
    );
  }, [order, deliveryDate, salesStatus]);

  return (
    <button
      type="button"
      disabled={isDisabled}
      className={twMerge(
        "block w-full text-start rounded p-2 space-y-1 transition-colors",
        isSelected && "bg-light",
        isDisabled
          ? "opacity-70 cursor-not-allowed"
          : "cursor-pointer hover:bg-light"
      )}
      onClick={() => onSelect(order.id)}
    >
      <div className="flex space-x-2 ">
        <div className="flex space-x-2 ">
          <Icon name="ReceiptText" className="text-primary" variant="Bold" />
          <div className="flex flex-col space-y-2">
            <p className="text-sm font-medium">{order?.title}</p>
            <p className="text-xs text-secondary">#{order.number}</p>
            {/* <span className="flex items-center gap-1">
              <p className="text-xs text-gray-600 space-x-1">
                <Icon name="LocationTick" className="text-danger text-sm" />
                <span>{order?.store?.storeName}</span>
              </p>
            </span> */}
            {hasDeliveryDate && (
              <p className="text-xs flex items-center">
                <Icon
                  name="Calendar"
                  className="mr-2 text-primary"
                  variant="Bulk"
                />
                <span className="text-gray-400 mr-2">
                  <Text>commissions.delivaryDate</Text>
                </span>
                <span className="text-gray-500">
                  {convertDate(order?.desiredDeliveryDate)}
                </span>
              </p>
            )}
          </div>
        </div>
        <p className="text-sm text-gray-500">
          {convertAmount(order.totalAmount)}
        </p>
      </div>
      {isDisabled && warningMassage}
    </button>
  );
}
