import { ComponentProps, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { iconNameType, variantType } from "../types";
import Icon from "./_Icon";

type VariantLight = { light?: boolean; outline?: never };
type VariantOutline = { light?: never; outline?: boolean };
type VariantMiddle = VariantLight | VariantOutline;
type AlertProps = ComponentProps<"div"> & {
  icon?: null | iconNameType;
  variant?: variantType;
  IconProps?: Omit<ComponentProps<typeof Icon>, "name">;
} & VariantMiddle;
type Variants = {
  [key in Exclude<variantType, null>]: {
    DEFAULT: string;
    outline: string;
    light: string;
  };
};

function Alert({
  icon = "InfoCircle",
  variant,
  light,
  outline,
  children,
  className,
  IconProps,
  ...props
}: AlertProps) {
  const activeVariant = useMemo(() => {
    if (!variant) return "";
    const variants: Variants = {
      primary: {
        DEFAULT: "bg-primary text-white border-primary",
        outline: "bg-transparent text-primary border-primary",
        light: "bg-primary text-primary border-primary",
      },
      secondary: {
        DEFAULT: "bg-secondary text-white border-secondary",
        outline: "bg-transparent text-secondary border-secondary",
        light: "bg-secondary bg-opacity-5 text-secondary border-secondary",
      },
      info: {
        DEFAULT: "bg-info text-white border-info",
        outline: "bg-transparent text-info border-info",
        light: "bg-info bg-opacity-5 text-info border-info",
      },
      success: {
        DEFAULT: "bg-success text-white border-success",
        outline: "bg-transparent text-success border-success",
        light: "bg-success bg-opacity-5 text-success border-success",
      },
      danger: {
        DEFAULT: "bg-danger text-white border-danger",
        outline: "bg-transparent text-danger border-danger",
        light: "bg-danger bg-opacity-5 text-danger border-danger",
      },
      warning: {
        DEFAULT: "bg-warning text-white border-warning",
        outline: "bg-transparent text-warning border-warning",
        light: "bg-warning bg-opacity-5 text-warning border-warning",
      },
      dark: {
        DEFAULT: "bg-dark text-white border-dark",
        outline: "bg-transparent text-dark border-dark",
        light: "bg-dark bg-opacity-5 text-dark border-dark",
      },
      light: {
        DEFAULT: "bg-light text-white border-light",
        outline: "bg-transparent text-light border-light",
        light: "bg-light bg-opacity-5 text-light border-light",
      },
      white: {
        DEFAULT: "bg-white text-white border-white",
        outline: "bg-transparent text-white border-white",
        light: "bg-white bg-opacity-5 text-white border-white",
      },
    };
    const active = variants[variant];
    if (light) return active.light;
    if (outline) return active.outline;
    return active.DEFAULT;
  }, [variant, light, outline]);
  const hasIcon = !!icon;
  return (
    <div
      className={twMerge(
        "flex items-center gap-4 border border-dashed rounded p-3",
        activeVariant,
        className
      )}
      {...props}
    >
      {hasIcon && (
        <Icon
          {...IconProps}
          name={icon}
          className={twMerge("text-2xl", IconProps?.className)}
        />
      )}
      <div className="flex-1 space-y-1">{children}</div>
    </div>
  );
}
function Title({ className, ...props }: ComponentProps<"h6">) {
  return (
    <h6 className={twMerge("text-base text-dark", className)} {...props}>
      {props.children}
    </h6>
  );
}
function Text({ className, ...props }: ComponentProps<"p">) {
  return (
    <p
      className={twMerge("text-secondary leading-5", className)}
      {...props}
    ></p>
  );
}
Alert.Title = Title;
Alert.Text = Text;
export default Alert;
