import { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CompanyContext } from "..";
import {
  Button,
  Icon,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
  Wrapper,
} from "../../../../components";
import { config } from "../../../../constants";
import { useAxios, useConverters } from "../../../../hooks";
import { Order, WithPaging } from "../../../../types";

export default function Orders() {
  const { companyData } = useContext(CompanyContext);
  const { convertAmount, convertDate } = useConverters();
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Order.Item>>({});
  const [params, setParams] = useState<Order.Params>({
    customerId: companyData.id,
    pageNumber: 1,
    pageSize: config.pageSize,
    // salesStatus: SalesStatus.None,
  });
  const handleSetParams = (key: keyof Order.Params) => {
    return (value: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: value, pageNumber: 1 }));
      }
      return setParams((p) => ({ ...p, [key]: value }));
    };
  };
  const getData = () => {
    const url = "/salesservice/api/orders";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <div className="basis-1/3">
          <SearchBox onSubmit={handleSetParams("keyword")} />
        </div>
      </section>
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              <Table.Body loading={loading.get}>
                {data.items?.map((e) => {
                  const isPublished = !!e.lastPublishedDate;
                  return (
                    <tr key={e.id}>
                      <td>
                        <Link
                          to={`/orders/${e.id}`}
                          className="flex-center w-fit gap-4"
                        >
                          <Icon.Wrapper>
                            <Icon name="ReceiptText" variant="Bold" />
                          </Icon.Wrapper>
                          <div className="space-y-1">
                            <span>{e.title}</span>
                            <span className="block text-secondary">
                              #{e.number}
                            </span>
                          </div>
                        </Link>
                      </td>
                      <td className="text-secondary">
                        {convertAmount(e.totalAmount)}
                      </td>
                      <td className="space-y-1">
                        <p className="text-sm text-dark">
                          <Text>global.orderVersion</Text> {e.version}
                        </p>
                        <p className="text-sm text-secondary">
                          <Text>global.createdAt</Text> (
                          {convertDate(e.createdAt)})
                        </p>
                      </td>
                      <td className="text-center space-y-1">
                        <Status.Order isPublished={isPublished} />
                        {isPublished && (
                          <Fragment>
                            <p className="text-sm text-secondary">
                              <Text>global.orderLastPublishAt</Text>{" "}
                              {convertDate(e.lastPublishedDate)}
                            </p>
                            <p className="text-sm text-secondary">
                              <Text>global.orderLastPublishedVersion</Text>{" "}
                              {e.lastPublishedVersion}
                            </p>
                          </Fragment>
                        )}
                      </td>
                      <td data-sticky>
                        <Button as={Link} to={`/orders/${e.id}`} light>
                          <Text>button.viewDetails</Text>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </Table.Body>
            </Table>
          </Table.Wrapper>
        </Wrapper.Body>
      </Wrapper>
      <Pagination
        pageNumber={params.pageNumber}
        setActivePage={handleSetParams("pageNumber")}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
      />
    </Fragment>
  );
}
