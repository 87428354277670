import { cloneDeep } from "lodash";
import { useContext } from "react";
import { AddCompanyContext } from ".";
import { InputGroup, Select, Text, Wrapper } from "../../../components";
import { countryCodes, rules, types } from "../../../constants";
import { Company } from "../../../types";
export default function ContactInfo() {
  const { data, setData, hasPhoneNumber } = useContext(AddCompanyContext);
  const handleSetValue = (key: keyof Company.Details) => {
    const isMobileNumber = key === "mobileNumber";
    const isPhoneNumber = key === "phoneNumber";
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        if (isMobileNumber || isPhoneNumber) {
          const countryCode = data[key]?.countryCode ?? NaN;
          data[key] = { countryCode, number: value };
          return data;
        }
        // @ts-ignore: Unreachable code error
        data[key] = value;
        return data;
      });
  };
  const handleSetCountryCodeValue = (key: "mobileNumber" | "phoneNumber") => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        const number = data[key]?.number ?? NaN;
        data[key] = { countryCode: value, number };
        return data;
      });
  };
  return (
    <Wrapper>
      <Wrapper.Body className="space-y-6 *:w-full *:flex *:gap-2">
        <section>
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>company.create.contactInfo.firstName</Text>
          </h6>
          <InputGroup
            className="w-auto flex-[5]"
            value={data.firstName}
            setValue={handleSetValue("firstName")}
            placeholder="company.create.contactInfo.firstNamePlaceholder"
            rules={rules.required}
          />
        </section>
        <section>
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>company.create.contactInfo.lastName</Text>
          </h6>
          <InputGroup
            className="w-auto flex-[5]"
            value={data.lastName}
            setValue={handleSetValue("lastName")}
            placeholder="company.create.contactInfo.lastNamePlaceholder"
            rules={rules.required}
          />
        </section>
        <section>
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>company.create.contactInfo.gender</Text>
          </h6>
          <Select
            className="w-auto flex-[5]"
            value={data.gender}
            setValue={handleSetValue("gender")}
            placeholder="company.create.contactInfo.genderPlaceholder"
            rules={rules.required}
            items={types.gender}
          />
        </section>
        <section>
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>company.create.contactInfo.salutation</Text>
          </h6>
          <Select
            className="w-auto flex-[5]"
            value={data.salutation}
            setValue={handleSetValue("salutation")}
            placeholder="company.create.contactInfo.salutationPlaceholder"
            rules={rules.required}
            items={types.salutation}
          />
        </section>
        <section>
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>company.create.contactInfo.salutationTitle</Text>
          </h6>
          <Select
            className="w-auto flex-[5]"
            value={data.salutationTitle}
            setValue={handleSetValue("salutationTitle")}
            placeholder="company.create.contactInfo.salutationTitlePlaceholder"
            items={types.salutationTitle}
          />
        </section>
        <section>
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>company.create.contactInfo.emailAddress</Text>
          </h6>
          <InputGroup
            className="w-auto flex-[5]"
            value={data.emailAddress}
            setValue={handleSetValue("emailAddress")}
            placeholder="company.create.contactInfo.emailAddressPlaceholder"
            rules={rules.email}
            type="email"
          />
        </section>
        <section>
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>company.create.contactInfo.position</Text>
          </h6>
          <InputGroup
            className="w-auto flex-[5]"
            value={data.position}
            setValue={handleSetValue("position")}
            placeholder="company.create.contactInfo.positionPlaceholder"
          />
        </section>
        <section className="flex w-full gap-2">
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>company.create.contactInfo.mobileNumber</Text>
          </h6>
          <Select
            className="w-auto flex-[1]"
            value={data.mobileNumber?.countryCode}
            setValue={handleSetCountryCodeValue("mobileNumber")}
            placeholder="+49"
            items={countryCodes}
          />
          <InputGroup
            className="w-auto flex-[4]"
            value={data.mobileNumber?.number}
            setValue={handleSetValue("mobileNumber")}
            placeholder="company.create.contactInfo.mobileNumberPlaceholder"
            type="integer"
            rules={rules.required}
          />
        </section>
        <section className="flex w-full gap-2">
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>company.create.contactInfo.phoneNumber</Text>
          </h6>
          <Select
            className="w-auto flex-[1]"
            value={data.phoneNumber?.countryCode}
            setValue={handleSetCountryCodeValue("phoneNumber")}
            placeholder="+49"
            items={countryCodes}
          />
          <InputGroup
            className="w-auto flex-[4]"
            value={data.phoneNumber?.number}
            setValue={handleSetValue("phoneNumber")}
            placeholder="company.create.contactInfo.phoneNumberPlaceholder"
            type="integer"
            rules={hasPhoneNumber ? rules.phoneNumber : undefined}
          />
        </section>
      </Wrapper.Body>
    </Wrapper>
  );
}
