import { twMerge } from "tailwind-merge";
import { Icon } from "../components";
import { classNameProps } from "../types";

type Props = {
  supplier: { name: string; iln: string | null } | null;
} & classNameProps;

export default function SupplierCard({ supplier, className }: Props) {
  const hasIln = !!supplier?.iln;
  if (!supplier) return null;
  return (
    <div className={twMerge("flex flex-center w-full gap-4", className)}>
      <Icon.Wrapper rounded>
        <Icon name="Building" variant="Bold" />
      </Icon.Wrapper>
      <div className="flex-1">
        <h6 className="truncate">{supplier.name}</h6>
        {hasIln && (
          <span className="block truncate text-secondary">#{supplier.iln}</span>
        )}
      </div>
    </div>
  );
}
