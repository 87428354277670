import CreateIncident from "../pages/Incidents/AddNew";
import IncidentDetails from "../pages/Incidents/Details";
import Incidents from "../pages/Incidents/List";

const incident = [
  {
    path: "incidents",
    element: <Incidents />,
  },
  {
    path: "incidents/:incidentId",
    element: <IncidentDetails />,
  },
  {
    path: "incidents/new",
    element: <CreateIncident />,
  },
];
export default incident;
