import { Fragment, ReactNode, useContext } from "react";
import { OrderDetailsContext } from ".";
import { Button, Icon, Status, Text, Wrapper } from "../../../components";
import { useConverters, useToggle } from "../../../hooks";
import { cn } from "../../../methods";
import { iconNameType } from "../../../types";
import OrderNotesDrawer from "./OrderNotesDrawer";

type MoreInfoItem = {
  icon: iconNameType;
  label: string;
  value: ReactNode;
  fullWidth?: boolean;
};

export default function OrderInfo() {
  const { order } = useContext(OrderDetailsContext);
  const { convertDate, convertAmount } = useConverters();
  const [showOrderNote, toggleOrderNote] = useToggle(false);
  const isPublished = !!order?.lastPublishedDate;
  const moreInfo: MoreInfoItem[] = [
    {
      icon: "Calendar",
      label: "pickup.details.lastUpdate",
      value: convertDate(order?.createdAt),
    },
    {
      icon: "Hierarchy2",
      label: "pickup.details.version",
      value: order?.version,
    },
    // {
    //   icon: "Calendar",
    //   label: "pickup.details.deadline",
    //   fullWidth: true,
    //   value: (
    //     <>
    //       {convertDate(order?.customerConfirmationDeadline) ?? "--"}{" "}
    //       {order?.pendingCustomerConfirmation ? (
    //         <b className="font-semibold text-warning">
    //           <Text>global.orderPendingCustomerConfirmation</Text>{" "}
    //           <Icon name="InfoCircle" variant="Bulk" />
    //         </b>
    //       ) : (
    //         <b className="font-semibold text-success">
    //           <Text>global.orderConfirmedCustomerConfirmation</Text>{" "}
    //           <Icon name="TickCircle" variant="Bulk" />
    //         </b>
    //       )}
    //     </>
    //   ),
    // },
  ];
  return (
    <Fragment>
      <Wrapper className="lg:!col-span-3">
        <Wrapper.Body className="flex flex-wrap items-start gap-4">
          <div className="flex-1 flex flex-col gap-3">
            <section className="flex items-center flex-wrap gap-2">
              <Icon.Wrapper rounded>
                <Icon name="ReceiptText" variant="Bold" />
              </Icon.Wrapper>
              <div className="lg:flex-1">
                <h1 className="text-base text-dark">{order?.title}</h1>
                <p className="text-sm text-secondary">#{order?.number}</p>
              </div>
              <Status.Order
                isPublished={isPublished}
                className={cn(
                  "text-sm font-medium",
                  isPublished && "bg-[#E8FFF3] border-none [&>svg]:hidden"
                )}
              />
            </section>
            <section className="flex flex-wrap items-center gap-x-3 gap-y-2">
              {moreInfo.map((e) => (
                <p
                  key={e.label}
                  className={cn("text-sm", !!e.fullWidth && "basis-full")}
                >
                  <Icon name={e.icon} variant="Bulk" className="text-primary" />{" "}
                  <span className="text-placeholder">
                    <Text>{e.label}</Text>:
                  </span>{" "}
                  <span className="text-secondary">{e.value}</span>
                </p>
              ))}
            </section>
            <section className="flex flex-wrap items-center justify-between gap-3 mt-auto">
              <span className="bg-gray-50 px-2 py-1 rounded-md text-base md:me-auto">
                <Icon
                  name="DollarSquare"
                  variant="Bold"
                  size={22}
                  className="text-dark"
                />{" "}
                <Text>pickup.details.totalPrice</Text>:{" "}
                {convertAmount(order?.totalAmount)}
              </span>
              <Button type="button" light onClick={toggleOrderNote}>
                <Icon name="DocumentText" variant="Bold" />{" "}
                <Text>pickup.details.noteButton</Text>
              </Button>
            </section>
          </div>
        </Wrapper.Body>
      </Wrapper>
      <OrderNotesDrawer isOpen={showOrderNote} toggle={toggleOrderNote} />
    </Fragment>
  );
}
