import { useEffect, useState } from "react";
import { useGetFile } from "../hooks";
import { Document } from "../types";

type EmbedProps = {
  fileInfo: Exclude<Document.Details["documentFileInfo"], null>;
};

export default function Embed({ fileInfo }: EmbedProps) {
  const [getFile, fileLoading] = useGetFile();
  const [src, setSrc] = useState<string | null>(null);
  const hasSrc = !!src;

  const getSrc = () => {
    getFile(fileInfo.fileUrl).then((src) => {
      setSrc(src);
    });
  };
  useEffect(getSrc, []);
  if (fileLoading) return <div className="block size-full loading" />;
  if (!hasSrc) return null;
  return (
    <embed
      type={fileInfo.mimeType}
      src={src}
      className="block size-full rounded"
    />
  );
}
