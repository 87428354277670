import { ComponentProps } from "react";
import InputMask from "react-input-mask";
// import { InputMask } from "@react-input/mask";

type BaseProps = {
  stringDate?: string;
  value?: any;
  openCalendar?: () => void;
  handleValueChange?: () => void;
};
type Props = BaseProps &
  Omit<ComponentProps<typeof InputMask>, keyof BaseProps>;

export default function InputDateMask({
  value,
  openCalendar,
  handleValueChange,
  stringDate,
  mask,
  ...props
}: Props) {
  return (
    <InputMask
      onFocus={openCalendar}
      onChange={handleValueChange}
      value={value ?? ""}
      mask={mask}
      maskChar="_"
      {...props}
    />
  );
}
