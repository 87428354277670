import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { OfferDetailsContext } from "..";
import uploadIcon from "../../../../assets/icons/document upload.svg";
import { DocumentCard, Loading, SearchBox, Text } from "../../../../components";
import { SignatureModal } from "../../../../containers";
import { SalesDocumentType } from "../../../../enums";
import { useAxios, useToggle } from "../../../../hooks";
import { Document } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";
import AddDocumentDrawer from "../AddDocumentDrawer";
import ChooseDocumentMenu from "./ChooseDocumentMenu";
import DocumentTypeModal from "./DocumentTypeModal";
import NoDocument from "./NoDocument";
export default function Documents() {
  const { axios, loading } = useAxios();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const [showTypeModal, toggleTypeModal] = useToggle(false);
  const [activeSignatureId, setActiveSignatureId] = useState<string>("");
  const [showChooseMenu, toggleChooseMenu] = useToggle(false);
  const [showPersonalMenu, togglePersonalMenu] = useToggle(false);
  const [keyword, setKeyword] = useState<string | null>(null);
  const [documents, setDocuments] = useState<Document.Item[]>([]);
  const filteredDocument = documents.filter((e) =>
    (e.title ?? "").toLowerCase().includes((keyword ?? "").toLowerCase())
  );
  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;
  const isOffer = !!offer;
  const isOrder = !!order;
  const setUnsignedDocuments =
    offerContext?.setUnsignedDocuments || orderContext?.setUnsignedDocuments;
  const showSignatureModal = (id: string) => {
    return activeSignatureId === id;
  };
  const toggleSignatureModal = () => {
    setActiveSignatureId("");
  };
  const getDocuments = () => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerdocuments",
      isOrder && "orderdocuments",
      offer?.id || order?.id,
    ]
      .filter(Boolean)
      .join("/");
    const params = {
      offerLineItemId: null,
      salesDocumentType: SalesDocumentType.Main,
    };
    axios.get(url, { params }).then(({ data }) => {
      const documents: Document.Item[] = cloneDeep(data);
      const unsignedDocuments = !!documents.filter(
        (e) => e.signMandatory && !e.isSigned
      ).length;
      setUnsignedDocuments?.(unsignedDocuments);
      setDocuments(documents);
    });
  };

  const submitSignature = (document: Document.Item, signUrl: string) => {
    const url = [
      "/salesservice",
      "api",
      isOrder ? "orderdocuments" : "offerdocuments",
      document.id,
    ].join("/");
    const body = cloneDeep({
      ...document,
      offerId: offer?.id,
      orderId: order?.id,
      createdFromTemplate: !!document.templateCode,
    });
    body.signUrl = signUrl;
    body.isSigned = true;
    axios.put(url, body).then(() => {
      toast.success("toast.success.editDocument");
      toggleSignatureModal();
      getDocuments();
    });
  };
  useEffect(getDocuments, [offer?.id, order?.id]);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <div className="basis-1/3">
          <SearchBox onSubmit={setKeyword} />
        </div>
      </section>
      <section className="grid grid-cols-5 gap-4">
        {isEnabled && (
          <button
            type="button"
            className="bg-primary/10 border border-dashed border-primary p-4 rounded text-center"
            onClick={toggleTypeModal}
          >
            <img
              className="block w-16 mx-auto mb-6"
              src={uploadIcon}
              alt="upload"
            />
            <h6 className="truncate block text-sm">
              <Text>button.addDocument</Text>
            </h6>
            <span className="truncate block text-secondary text-sm">
              <Text>button.createDocument</Text>
            </span>
          </button>
        )}
        {loading.get ? (
          <Loading.Document length={9} />
        ) : (
          filteredDocument.map((e) => (
            <Fragment key={e.id}>
              <DocumentCard
                document={e}
                offerId={offer?.id}
                orderId={order?.id}
                onRequestSign={setActiveSignatureId}
                isEnabled={isEnabled}
              />
              {isEnabled && (
                <SignatureModal
                  isOpen={showSignatureModal(e.id)}
                  toggle={toggleSignatureModal}
                  onSubmit={(signUrl) => submitSignature(e, signUrl)}
                  loading={loading.update}
                />
              )}
            </Fragment>
          ))
        )}
        {!isEnabled && !documents?.length && !loading.get && <NoDocument />}
      </section>
      {isEnabled && (
        <Fragment>
          <DocumentTypeModal
            isOpen={showTypeModal}
            toggle={toggleTypeModal}
            onSelectPersonal={togglePersonalMenu}
            onSelectTemplate={toggleChooseMenu}
          />
          <AddDocumentDrawer
            isOpen={showPersonalMenu}
            toggle={togglePersonalMenu}
            onSubmitted={getDocuments}
          />
          <ChooseDocumentMenu
            isOpen={showChooseMenu}
            toggle={toggleChooseMenu}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
