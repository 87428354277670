import MainLayout from "../layouts/MainLayout";
import Error404 from "../pages/Error404";
import company from "./company";
import customer from "./customer";
import document from "./document";
import incident from "./incident";
import offer from "./offer";
import order from "./order";
import pickup from "./pickup";
import product from "./product";
import sellOffProducts from "./sell-off-products";
import supplier from "./supplier";
import tasks from "./task";

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      ...customer,
      ...company,
      ...offer,
      ...document,
      ...order,
      ...product,
      ...incident,
      ...supplier,
      ...tasks,
      ...sellOffProducts,
      ...pickup,
      {
        path: "notfound",
        element: <Error404 />,
      },
    ],
  },
  {
    path: "*",
    element: <Error404 />,
  },
];
export default routes;
