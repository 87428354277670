import { Link } from "react-router-dom";
import { Icon, Text, Wrapper } from "../components";
import { CustomerType } from "../enums";
import { showCallNumber } from "../methods";
import { Company, Customer, classNameProps } from "../types";

type CustomerPropsType = {
  customer: (Customer.Details & Company.Details) | null;
};

type CustomerCardProps = CustomerPropsType & classNameProps;

export default function CustomerCard({
  customer,
  className,
}: CustomerCardProps) {
  const isCompany = customer?.customerType === CustomerType.Company;
  const customerData = {
    name: isCompany
      ? customer?.companyName
      : [customer?.firstName, customer?.lastName].join(" "),
    cim: customer?.cimNumber,
    address: [customer?.address?.postalCode, customer?.address?.city]
      .filter(Boolean)
      .join(", "),
    emailAddress: customer?.emailAddress,
    phoneNumber: customer?.phoneNumber,
    registrationNumber: isCompany ? customer?.companyRegistrationNumber : "--",
    contactPerson: isCompany
      ? [customer?.firstName, customer?.lastName].join(" ")
      : null,
    href: isCompany
      ? `/companies/${customer?.id}`
      : `/customers/${customer?.id}`,
  };
  return (
    <Wrapper className={className}>
      <Wrapper.Body className="space-y-3">
        <section className="flex items-center gap-2">
          <Icon.Wrapper rounded>
            <Icon name={isCompany ? "Home" : "User"} variant="Bold" />
          </Icon.Wrapper>
          <Link to={customerData.href} className="text-start">
            <h1 className="text-base text-dark">{customerData.name}</h1>
            <p className="text-sm text-secondary">CIM: #{customerData.cim}</p>
          </Link>
        </section>
        <ul className="space-y-2 text-secondary text-sm">
          {!isCompany && (
            <li>
              <Icon name="Sms" variant="Bulk" className="text-primary" />{" "}
              {customerData.emailAddress}
            </li>
          )}
          {isCompany && (
            <li>
              <Icon
                name="DocumentText"
                variant="Bulk"
                className="text-primary"
              />{" "}
              <Text>company.details.companyRegistrationNumber</Text>: #
              {customerData.registrationNumber}
            </li>
          )}
          {!isCompany && (
            <li>
              <Icon name="Call" variant="Bulk" className="text-primary" />{" "}
              {showCallNumber(customerData.phoneNumber) ?? "--"}
            </li>
          )}
          <li>
            <Icon name="LocationTick" variant="Bulk" className="text-primary" />{" "}
            {customerData.address || "--"}
          </li>
          {isCompany && (
            <li>
              <Icon name="User" variant="Bulk" className="text-primary" />{" "}
              <Text>company.details.contactPersonTitle</Text>:{" "}
              {customerData.contactPerson ?? "--"}
            </li>
          )}
        </ul>
      </Wrapper.Body>
    </Wrapper>
  );
}
