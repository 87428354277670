import { Button, Icon, Image, Modal, Text } from "../components";
import { useLogout, useSelector } from "../hooks";
import { togglePropsType } from "../types";
export default function ProfileModal({ isOpen, toggle }: togglePropsType) {
  const logout = useLogout();
  const profile = useSelector((s) => s.profile);
  const profileImage = profile.avatar?.thumbnailUrl;
  const hasProfileImage = !!profileImage;
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Body className="flex-center flex-col space-y-4">
        <div className="w-full flex-col text-center flex-center space-y-2">
          {!!hasProfileImage ? (
            <Image
              className="size-20 shadow object-contain rounded-full"
              src={profileImage}
              alt={profile.emailAddress}
              isDynamic
            />
          ) : (
            <Icon.Wrapper variant="secondary">
              <Icon name="User" variant="Bold" className="text-primary" />
            </Icon.Wrapper>
          )}
          <h5>
            {profile.firstName} {profile.lastName}
          </h5>
          <h6 className="text-secondary">{profile.emailAddress}</h6>
        </div>
        <hr className="w-full bg-gray-50" />
        <button
          type="button"
          className="w-full flex-center gap-4"
          onClick={logout}
        >
          <Icon.Wrapper variant="secondary">
            <Icon name="Logout" variant="Bold" className="text-danger" />
          </Icon.Wrapper>
          <span className="flex-1 text-start">
            <Text>global.logout</Text>
          </span>
        </button>
        <hr className="w-full bg-gray-50" />
        <Button light onClick={toggle}>
          <Text>button.close</Text>
        </Button>
      </Modal.Body>
    </Modal>
  );
}
