import { Fragment, useContext, useState } from "react";
import { TaskDetailsContext } from "..";
import {
  Button,
  Icon,
  NoItems,
  SearchBox,
  Text,
  Wrapper,
} from "../../../../components";
import { useToggle } from "../../../../hooks";
import CreateNote from "./CreateNote";
import NoteItem from "./NoteItem";

export default function Activities() {
  const { task } = useContext(TaskDetailsContext);
  const [showCreateNote, toggleCreateNote] = useToggle(false);
  const [keyword, setKeyword] = useState<string | null>(null);
  const notes = task.noteList ?? [];
  const filteredNotes = notes.filter((e) =>
    e.body.toLowerCase().includes(keyword?.toLowerCase() ?? "")
  );
  const hasNotes = !!filteredNotes.length;
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <SearchBox
          onSubmit={setKeyword}
          className="w-auto flex-1 lg:flex-none lg:basis-1/3"
        />
        <Button type="button" className="ml-auto" onClick={toggleCreateNote}>
          <Icon name="Add" size={20} className="mr-1" />
          <Text>task.details.activities.addNoteButton</Text>
        </Button>
      </section>
      <Wrapper>
        <Wrapper.Body className="relative space-y-4">
          {hasNotes ? (
            <Fragment>
              {filteredNotes.map((e, i) => (
                <NoteItem key={e.createdOn} note={e} isFirst={i === 0} />
              ))}
              <div className="absolute top-4 left-[0.5625rem] w-0 h-[calc(100%-2rem)] border-s border-dashed border-[#DEEBF4] !mt-0" />
            </Fragment>
          ) : (
            <NoItems />
          )}
        </Wrapper.Body>
      </Wrapper>
      <CreateNote isOpen={showCreateNote} toggle={toggleCreateNote} />
    </Fragment>
  );
}
