import { useContext } from "react";
import { IncidentDetailsContext } from "..";
import { NoItems } from "../../../../components";
import DocumentItem from "./DocumentItem";

export default function Documents() {
  const { incident } = useContext(IncidentDetailsContext);
  const hasDocuments = !!incident.documents?.length;
  if (!hasDocuments) return <NoItems className="mx-auto" />;
  return (
    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
      {incident.documents?.map((e, i) => (
        <DocumentItem key={e.fileName + i} document={e} />
      ))}
    </section>
  );
}
