import { Dispatch, useMemo, useRef, useState } from "react";
import {
  Button,
  Drawer,
  Dropdown,
  Form,
  Icon,
  InputGroup,
  Loading,
  Text,
} from "../../../components";
import {
  DropdownHandler,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../components/_Dropdown";
import { rules } from "../../../constants";
import { useAxios, useDebounce } from "../../../hooks";
import { Employee, Order, rulesType, togglePropsType } from "../../../types";

type Data = Omit<Order.SecondSalesPerson, "sharedProvision">;
type AddSecondSalesPersonProps = togglePropsType & {
  onSubmit: Dispatch<Order.SecondSalesPerson>;
};

export default function AddSecondSalesPerson({
  isOpen,
  toggle,
  onSubmit,
}: AddSecondSalesPersonProps) {
  const debounce = useDebounce();
  const dropdownRef = useRef<DropdownHandler>(null);
  const { axios, loading } = useAxios();
  const [share, setShare] = useState("");
  const [secondSalesPerson, setSecondSalesPersons] = useState<Data | null>(
    null
  );
  const [employeeList, setEmployeeList] = useState<Data[] | []>([]);
  const [search, setSearch] = useState<string | null>(null);
  const employee = useMemo(() => {
    if (!!secondSalesPerson) return secondSalesPerson;
    const isOneEmployee = employeeList.length === 1;
    if (isOneEmployee) return employeeList[0];
    return null;
  }, [secondSalesPerson, employeeList]);
  // const hasSecondSalesPerson = !!secondSalesPerson;
  const searchRules: rulesType = [
    ...rules.required,
    () => !!employee || "errorCodes.noSecondSalesPersonAcceptOffer",
  ];

  const handleSearch = (keyword: string) => {
    setSearch(keyword);
    setSecondSalesPersons(null);
    !keyword && dropdownRef.current?.hide();
    debounce(() => {
      searchEmployee(keyword);
    });
  };
  const handleSetSecondSalesPerson = (employee: Data) => {
    return () => {
      setSearch([employee.firstName, employee.lastName].join(" "));
      setSecondSalesPersons(employee);
    };
  };
  const searchEmployee = (keyword: string | null) => {
    if (!keyword) return setEmployeeList([]);
    const url = "/accountservice/api/employees/by-sales-person-number";
    const config = { params: { keyword } };
    dropdownRef.current?.show();
    axios.get<Employee.Item[]>(url, config).then(({ data }) => {
      const result: Data[] = data.map((item) => ({
        employeeId: item.employeeId,
        firstName: item.firstName,
        lastName: item.lastName,
        salesNumber: item.salesPersonNumber,
      }));
      setEmployeeList(result);
      dropdownRef.current?.show();
    });
  };
  const resetData = () => {
    setShare("");
    setSecondSalesPersons(null);
    setEmployeeList([]);
    setSearch("");
  };
  const handleToggle = () => {
    resetData();
    toggle();
  };
  const handleSubmit = () => {
    if (!employee) return;
    onSubmit({ ...employee, sharedProvision: Number(share) });
    handleToggle();
  };
  return (
    <Drawer
      as={Form}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      toggle={handleToggle}
    >
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-dark text-base font-semibold">
            <Text>drawerTitles.addOrderSecondSalesPerson</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <section className="space-y-2">
            <Dropdown ref={dropdownRef} className="w-full">
              <DropdownToggle
                // @ts-expect-error
                as={InputGroup}
                value={search}
                setValue={handleSearch}
                placeholder="placeholders.searchOrderSecondSalesPerson"
                rules={searchRules}
                append={
                  <span className="input-group-text text-primary">
                    <Icon name="ArrowDown2" size={18} />
                  </span>
                }
              />
              <DropdownMenu>
                {loading.get ? (
                  <Loading.Inline />
                ) : (
                  employeeList.map((e) => (
                    <DropdownItem
                      key={e.employeeId}
                      eventKey={e.employeeId}
                      onClick={handleSetSecondSalesPerson(e)}
                    >
                      {e.firstName} {e.lastName}
                      <span className="block text-xs text-secondary font-medium my-0 mt-1">
                        {e.salesNumber}
                      </span>
                    </DropdownItem>
                  ))
                )}
              </DropdownMenu>
            </Dropdown>
          </section>
          <InputGroup
            label="formControls.orderSecondSalesPersonShare"
            value={share}
            setValue={setShare}
            rules={rules.percent}
            type="number"
            append={<span className="input-group-text text-dark">%</span>}
            className="max-w-28"
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button type="button" variant="danger" onClick={handleToggle}>
            <Text>button.cancelOrderSecondSalesPerson</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            // disabled={!hasSecondSalesPerson}
          >
            <Text>button.submitOrderSecondSalesPerson</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
