import { authInstance } from "../constants";
import { getToken } from "../methods";

export default function useLogout() {
  const logout = () => {
    getToken().then((token) => {
      sessionStorage.removeItem("default-store-id");
      authInstance.logoutRedirect({ idTokenHint: token || undefined });
    });
  };
  return logout;
}
