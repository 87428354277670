import { Fragment, useContext, useEffect, useState } from "react";
import { CustomerContext } from "..";
import { Loading, NoItems, Pagination, Wrapper } from "../../../../components";
import { config } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import { FavoriteProduct, WithPaging } from "../../../../types";
import ProductCard from "./ProductCard";

type Params = FavoriteProduct.Params;
type Data = FavoriteProduct.Item;

export default function Favorites() {
  const { customerData } = useContext(CustomerContext);
  const { axios, loading } = useAxios();

  const [params, setParams] = useState<Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    customerId: customerData.id,
  });
  const [data, setData] = useState<WithPaging<Data>>({});
  const hasData = !!data.items?.length;
  const handleSetParams = (key: keyof Params) => {
    return (val: any) => {
      const isPageNumber = key === "pageNumber";
      setParams((p) => ({
        ...p,
        [key]: val,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
    };
  };
  const getData = () => {
    const url = "/salesservice/api/favorites/by-customer";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        {/* <div className="flex-1 lg:flex-none lg:basis-1/3">
          <SearchBox onSubmit={handleSetParams('keyword')} />
        </div> */}
        {/* <Button light>
          <Icon name="Filter" variant="Bold" />
        </Button> */}
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
        {loading.get ? (
          <Loading.Cards />
        ) : (
          <Fragment>
            {hasData ? (
              data.items?.map((e) => (
                <ProductCard key={e.favoriteId} product={e} />
              ))
            ) : (
              <Wrapper className="col-span-full">
                <Wrapper.Body className="flex-center">
                  <NoItems />
                </Wrapper.Body>
              </Wrapper>
            )}
          </Fragment>
        )}
      </section>
      <Pagination
        pageNumber={params?.pageNumber ?? 0}
        totalItems={data?.totalItems ?? 0}
        totalPages={data?.totalPages ?? 0}
        setActivePage={handleSetParams("pageNumber")}
      />
    </Fragment>
  );
}
