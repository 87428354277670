import React, {
  ComponentProps,
  FormEvent,
  Fragment,
  HTMLInputTypeAttribute,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";
import InputDatePicker from "react-multi-date-picker";
import { twMerge } from "tailwind-merge";
import { mask } from "../constants";
import { useLanguage, useTranslate } from "../hooks";
import {
  convertInputNumber,
  convertPrice,
  onlyInteger,
  randomUUID,
  replaceNonDigits,
} from "../methods";
import { rulesType } from "../types";
import { FormContext } from "./_Form";
import Icon from "./_Icon";
import InputDateMask from "./_InputDateMask";
import Text from "./_Text";

type SuggestionData = {
  id: string;
  display: string;
  code?: string;
};

type CustomSuggestionData = {
  code?: string;
};

type inputGroupProps = {
  as?: "input" | "textarea";
  value: any;
  setValue?: (val: any) => void;
  onClick?: () => void;
  label?: string;
  rules?: rulesType;
  append?: ReactNode;
  prepend?: ReactNode;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
  inputBoxClassName?: string;
  formControlClassName?: string;
  autoCapitalize?: boolean;
  type?: HTMLInputTypeAttribute | "price" | "integer" | "decimal";
  minDate?: string | number | Date | null;
  maxDate?: string | number | Date | null;
  snippet?: boolean;
  suggestionData?: SuggestionData[];
  trigger?: string;
  helperTextProps?: Omit<ComponentProps<typeof Text>, "children">;
};
export default function InputGroup({
  as,
  value = "",
  label = "",
  rules = [],
  setValue = () => {},
  onClick,
  append = null,
  prepend = null,
  type = "text",
  placeholder,
  readOnly,
  disabled,
  className,
  inputBoxClassName,
  formControlClassName,
  autoCapitalize = false,
  minDate = null,
  maxDate = null,
  snippet = false,
  suggestionData,
  trigger = "#",
  helperTextProps = {},
}: inputGroupProps) {
  const [language] = useLanguage();
  const { setFormControl, removeFormControl } = useContext(FormContext);
  const ID = useMemo(() => randomUUID(), []);
  const inputGroupRef = useRef<HTMLDivElement>(null);
  const isDate = type === "date";
  const isPrice = type === "price";
  const isDecimal = type === "decimal";
  // const isText = type === "text";
  const isInteger = type === "integer";
  const hasRules = !!rules.length;
  const clickable = !!onClick;
  const translate = useTranslate();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const hasError = !!rules.length && !!errorMessage;
  const Component = as || "input";
  const handleMask = mask.date?.[language];
  const handleFormat = mask.format?.[language];
  const handleValue = useMemo(() => {
    if (isPrice) return convertPrice(String(value ?? ""));
    if (isDecimal) return convertInputNumber(String(value ?? ""));
    if (isInteger) return onlyInteger(String(value ?? ""));
    if (isDate) return !!value ? new Date(value) : null;
    return value;
  }, [isDate, isPrice, isInteger, isDecimal, value]);
  const handleChange = (e: any) => {
    if (isPrice || isDecimal) return setValue(replaceNonDigits(e.target.value));
    if (isInteger) return setValue(onlyInteger(e.target.value));
    setValue(e.target.value);
  };
  const handleClick = () => {
    if (disabled) return;
    onClick?.();
  };
  const handleFocus = (
    e: FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    isInteger && e.currentTarget.select();
  };
  const handlePlaceholder = useMemo(() => {
    if (!!placeholder) return translate(placeholder);
    if (isDate) return handleFormat;
    return undefined;
  }, [placeholder, isDate, handleFormat]);
  const handleType: HTMLInputTypeAttribute = useMemo(() => {
    if (isPrice || isDecimal) return "tel";
    if (isInteger) return "tel";
    return type;
  }, [type, isPrice, isDecimal, isInteger]);
  const checkRules = useCallback(() => {
    return rules.every((rule) => {
      const result = rule(value ?? "");
      const isValid = result === true;
      setErrorMessage(isValid ? "" : result);
      return isValid;
    });
  }, [rules, value]);
  useEffect(() => {
    const element = inputGroupRef.current!;
    const callback = () => checkRules();
    setFormControl?.(ID, { element, callback });
    return () => {
      removeFormControl?.(ID);
    };
  }, [value, rules, ID, checkRules, setFormControl, removeFormControl]);
  return (
    <React.Fragment>
      <div
        ref={inputGroupRef}
        className={twMerge(
          "input-group group w-full",
          hasRules && "required",
          hasError && "error",
          className
        )}
      >
        {!!label && (
          <label
            htmlFor={ID}
            className="block w-full mb-2 truncate group-[.input-group.required]:after:content-['*'] after:text-danger"
          >
            <Text>{label}</Text>
          </label>
        )}
        <div
          onClick={handleClick}
          className={twMerge(
            "input-box relative row items-stretch w-full bg-gray-50 border border-primary/10 rounded-md [&>textarea]:min-h-[10rem] [&>textarea]:py-3 [&>textarea]:leading-5 [&>*]:min-w-0 focus-within:border-primary group-[.input-group.error]:border-danger [&>*:first-child]:rounded-l-md [&>*:last-child]:rounded-r-md transition-colors",
            inputBoxClassName,
            clickable && "cursor-text",
            disabled && "bg-[#D6D6E0]",
            snippet && "min-h-[10rem]"
          )}
        >
          {prepend}
          {isDate ? (
            <InputDatePicker
              id={ID}
              format={handleFormat}
              value={handleValue ?? ""}
              onOpenPickNewDate={false}
              className="date-picker"
              inputClass="size-full"
              containerClassName="form-control flex-1 !h-11 transition-colors [&>div]:size-full"
              weekStartDayIndex={1}
              showOtherDays
              portal
              onChange={(e: any) => setValue(e?.toDate() || null)}
              // readOnly={readOnly}
              // disabled={disabled}
              minDate={!!minDate ? new Date(minDate) : undefined}
              maxDate={!!maxDate ? new Date(maxDate) : undefined}
              render={
                <InputDateMask
                  className="size-full"
                  mask={handleMask}
                  placeholder={handlePlaceholder}
                  readOnly={readOnly}
                  disabled={disabled}
                />
              }
            />
          ) : snippet ? (
            <MentionsInput
              id={ID}
              readOnly={readOnly}
              value={handleValue || ""}
              onChange={(_event, _newValue, newPlainTextValue, _mention) => {
                handleChange({ target: { value: newPlainTextValue } });
              }}
              className={`form-control  ${
                readOnly ? (prepend ? "w-[22px]" : "") : "flex-1"
              } `}
              placeholder={handlePlaceholder}
            >
              <Mention
                trigger={trigger}
                data={(search) => {
                  const filteredData = suggestionData?.filter(
                    (item) =>
                      item.id.toLowerCase().includes(search.toLowerCase()) ||
                      item.code?.toLowerCase().includes(search.toLowerCase())
                  );

                  return filteredData;
                }}
                appendSpaceOnAdd
                renderSuggestion={(
                  entry: SuggestionDataItem & CustomSuggestionData
                ) => {
                  return (
                    <Fragment key={[entry.id, entry.code].join("")}>
                      {entry?.code && (
                        <span className="mr-2 text-gray-500">
                          #{entry?.code}
                        </span>
                      )}
                      <span>{entry?.id}</span>
                    </Fragment>
                  );
                }}
              />
            </MentionsInput>
          ) : (
            <Component
              id={ID}
              className={twMerge(
                "form-control flex-1 h-11 transition-colors",
                formControlClassName
              )}
              placeholder={handlePlaceholder}
              readOnly={readOnly}
              disabled={disabled}
              value={handleValue ?? ""}
              onChange={handleChange}
              onFocus={handleFocus}
              type={handleType}
              autoCapitalize={autoCapitalize ? "on" : undefined}
            />
          )}
          {append}
          {hasError && (
            <span className="input-group-text h-11 text-danger overflow-hidden">
              <Icon name="InfoCircle" className="animate-shake" />
            </span>
          )}
          {isPrice && <span className="input-group-text">&euro;</span>}
          {isDate && (
            <label htmlFor={ID} className="input-group-text">
              <Icon name="Calendar" variant="Bold" className="text-gray-900" />
            </label>
          )}
          {!!rules.length && !label && !errorMessage && (
            <span className="input-group-text text-secondary">&lowast;</span>
          )}
        </div>
        {hasError && (
          <p className="text-sm text-danger mt-1">
            <Text {...helperTextProps}>{errorMessage}</Text>
          </p>
        )}
      </div>
    </React.Fragment>
  );
}
