import {
  Button,
  Drawer,
  Icon,
  Loading,
  Text,
  Wrapper,
} from "../../../../components";
import WithPermission from "../../../../components/_WithPermission";
import { useAxios, useConverters, useToggle } from "../../../../hooks";
import EditCommissionGroup from "./Edit";
interface ChooseOrderProps {
  isOpen?: boolean;
  toggle: () => void;
  getData: () => void;
  commissionGroup: any;
}

export default function CommissionGroupDetails({
  isOpen = false,
  toggle,
  commissionGroup,
  getData,
}: ChooseOrderProps) {
  const { loading } = useAxios();
  const [isOpenEdit, toggleEdit] = useToggle();
  const { convertAmount, convertDate } = useConverters();

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
        <Drawer.Menu className="bg-gray-50">
          <Drawer.Header className="flex justify-between items-center">
            <Text>commissions.details</Text>
            <WithPermission
              permissions={[
                "SC_UpdateSaleServiceCommissionGroup",
                "SC_SaleServiceCommissionGroupFullAccess",
              ]}
            >
              <Button type="button" light onClick={toggleEdit}>
                <Icon name="Edit2" /> <Text>button.edit</Text>
              </Button>
            </WithPermission>
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            {loading.get ? (
              <Loading.Inline />
            ) : (
              <>
                <Wrapper className="shadow-none">
                  <Wrapper.Body>
                    <div className="space-y-2 flex gap-2">
                      <p className="flex items-center">
                        <Icon
                          name="CalendarTick"
                          className="mr-2 text-primary"
                          variant="Bulk"
                        />
                      </p>
                      <div>
                        <p className="text-base font-medium">
                          {commissionGroup?.title}
                        </p>
                        <p className="text-xs font-medium text-gray-500">
                          #{commissionGroup?.code}
                        </p>
                      </div>
                    </div>
                  </Wrapper.Body>
                </Wrapper>
                <Wrapper className="shadow-none">
                  <Wrapper.Body className="flex justify-between">
                    <span className="text-gray-400 mr-2">
                      <Text>commissions.delivaryDate</Text>
                    </span>
                    <span className="text-gray-500">
                      {convertDate(commissionGroup?.deliveryDate)}
                    </span>
                  </Wrapper.Body>
                </Wrapper>
                <Wrapper className="shadow-none">
                  <Wrapper.Header>
                    <Text>commissions.assignedOrders</Text>
                  </Wrapper.Header>
                  <Wrapper.Body>
                    <div className="grid grid-cols-1 gap-2">
                      {commissionGroup?.orders?.map(
                        (order: any, index: number) => {
                          return (
                            <div
                              className="flex space-x-2 border rounded-md p-2"
                              key={`orderList_${index}`}
                            >
                              <Icon
                                name="ReceiptText"
                                className="text-primary"
                              />
                              <div className="flex flex-col space-y-2">
                                <p className="text-sm font-medium">
                                  {order?.title}
                                </p>
                                <p className="text-xs text-gray-400">
                                  #{order?.number}
                                </p>
                                <p className="text-xs text-gray-400">
                                  {convertAmount(order?.totalAmount)}
                                </p>
                                <span className="flex items-center gap-1">
                                  <p className="text-xs text-gray-600 space-x-1">
                                    <Icon
                                      name="LocationTick"
                                      className="text-danger text-sm"
                                    />
                                    <span>{order?.storeName}</span>
                                  </p>
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </Wrapper.Body>
                </Wrapper>
              </>
            )}
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-end gap-4">
            <Button type="button" light variant={"secondary"} onClick={toggle}>
              <Text>button.close</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <EditCommissionGroup
        isOpen={isOpenEdit}
        toggle={toggleEdit}
        toggleEdit={toggle}
        commissionGroup={commissionGroup}
        getData={getData}
      />
    </>
  );
}
