import { cloneDeep } from "lodash";
import { Fragment, useContext } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router";
import { CustomerContext } from "../";
import {
  Button,
  Form,
  InputGroup,
  Select,
  Text,
  WithPermission,
  Wrapper,
} from "../../../../components";
import { countryCodes, rules, types } from "../../../../constants";
import { SearchAddress } from "../../../../containers";
import {
  useAxios,
  useCountries,
  useDataState,
  useHasAddress,
  useValidateAddress,
} from "../../../../hooks";
import { Address, Customer } from "../../../../types";

export default function Overview() {
  // const navigate = useNavigate();
  const [countries, countriesLoading] = useCountries();
  const { axios, loading } = useAxios();
  const [validateAddress, validateLoading] = useValidateAddress();
  const { customerId } = useParams();
  const { customerData, setCustomerData } = useContext(CustomerContext);
  // const [activeConfirm, toggleConfirm] = useToggle(false);
  const [data, setData, setBaseData, isChanged] = useDataState(customerData);
  const hasAddress = useHasAddress(data.address);
  const hasPhoneNumber = !!data.phoneNumber?.number;

  const handleSetValue = (
    key: keyof Customer.Details | "mobileNumberCode" | "phoneNumberCode"
  ) => {
    const isMobileNumber = key === "mobileNumber";
    const isPhoneNumber = key === "phoneNumber";
    const isMobileNumberCode = key === "mobileNumberCode";
    const isPhoneNumberCode = key === "phoneNumberCode";
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        if (isMobileNumber) {
          const number = value;
          const countryCode = p.mobileNumber?.countryCode ?? NaN;
          data.mobileNumber = { countryCode, number };
          return data;
        }
        if (isPhoneNumber) {
          const number = value;
          const countryCode = p.phoneNumber?.countryCode ?? NaN;
          data.phoneNumber = { countryCode, number };
          return data;
        }
        if (isMobileNumberCode) {
          const number = p.mobileNumber?.number ?? NaN;
          const countryCode = value;
          data.mobileNumber = { countryCode, number };
          return data;
        }
        if (isPhoneNumberCode) {
          const number = p.phoneNumber?.number ?? NaN;
          const countryCode = value;
          data.phoneNumber = { countryCode, number };
          return data;
        }
        // @ts-ignore: Unreachable code error
        data[key] = value;
        return data;
      });
  };
  const handleSetAddress = (key: keyof Address) => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        const isCountryCode = key === "countryCode";
        data.address ??= {} as Address;
        data.address[key] = value;
        if (isCountryCode) {
          const country = countries.find(
            (e) => e.id === data?.address?.countryCode
          );
          data.address.country = country?.name ?? null;
          data.address.countryCodeISO3 = country?.countryIso3 ?? null;
        }
        return data;
      });
  };
  // const removeCustomer = () => {
  //   const url = `/accountservice/api/customers/${customerId}`;
  //   axios.delete(url).then(() => {
  //     toast.success("toast.success.removeCustomer");
  //     navigate("/customers");
  //   });
  // };

  const submitChange = async () => {
    if (!hasAddress) return submitRequest();
    validateAddress(data.address).then(({ isValid, suggested }) => {
      if (isValid) return submitRequest();
      if (!suggested) return toast.error("errorCodes.inValidAddress");
      toast("toast.warning.suggestedAddress");
      setData((p) => ({ ...p, address: suggested }));
    });
  };

  const submitRequest = () => {
    const url = `/accountservice/api/customers/${customerId}`;
    const body = {
      ...data,
      ...(!hasAddress && { address: null }),
      ...(!hasPhoneNumber && { phoneNumber: null }),
    };
    axios.put(url, body).then(() => {
      const message = "toast.success.editCustomer";
      toast.success(message);
      setCustomerData(body);
      setBaseData(body);
    });
  };

  return (
    <Fragment>
      <Form onSubmit={submitChange} className="space-y-4">
        <WithPermission permissions={["AS_UpdateCustomer"]}>
          <div className="flex items-center justify-between">
            <h6>
              <Text>global.submit</Text>
            </h6>
            <Button
              type="submit"
              disabled={!isChanged}
              loading={loading.update || validateLoading}
            >
              <Text>button.submitChanges</Text>
            </Button>
          </div>
        </WithPermission>
        <section className="grid grid-cols-2 gap-4 items-start">
          <div className="space-y-4">
            <Wrapper>
              <Wrapper.Body className="space-y-4">
                <InputGroup
                  label="formControls.firstName"
                  value={data.firstName}
                  setValue={handleSetValue("firstName")}
                  rules={rules.required}
                  autoCapitalize
                />
                <InputGroup
                  label="formControls.lastName"
                  value={data.lastName}
                  setValue={handleSetValue("lastName")}
                  rules={rules.required}
                  autoCapitalize
                />
              </Wrapper.Body>
            </Wrapper>
            <Wrapper>
              <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <SearchAddress
                  className="col-span-full"
                  label="formControls.searchAddress"
                  setValue={(address) =>
                    setData((p) => {
                      const data = cloneDeep(p);
                      data.address = address;
                      data.address.name = p.address?.name ?? null;
                      return data;
                    })
                  }
                  countries={countries}
                  loading={countriesLoading}
                />
                <span className="block col-span-full text-secondary text-center">
                  <Text>global.orEnterManual</Text>
                </span>
                <InputGroup
                  label="formControls.name"
                  value={data.address?.name}
                  setValue={handleSetAddress("name")}
                  className="col-span-full"
                />
                <InputGroup
                  label="formControls.street"
                  value={data.address?.street}
                  setValue={handleSetAddress("street")}
                />
                <InputGroup
                  label="formControls.number"
                  value={data.address?.number}
                  setValue={handleSetAddress("number")}
                />
                <InputGroup
                  label="formControls.postalCode"
                  value={data.address?.postalCode}
                  setValue={handleSetAddress("postalCode")}
                />
                <InputGroup
                  label="formControls.city"
                  value={data.address?.city}
                  setValue={handleSetAddress("city")}
                />
                <Select
                  label="formControls.country"
                  value={data.address?.countryCode}
                  setValue={handleSetAddress("countryCode")}
                  items={countries}
                />
                <InputGroup
                  label="formControls.state"
                  value={data.address?.state}
                  setValue={handleSetAddress("state")}
                />
              </Wrapper.Body>
            </Wrapper>
          </div>
          <div className="space-y-4">
            <Wrapper>
              <Wrapper.Body>
                <InputGroup
                  label="formControls.birthDate"
                  value={data.birthdate}
                  setValue={handleSetValue("birthdate")}
                  type="date"
                />
              </Wrapper.Body>
            </Wrapper>
            <Wrapper>
              <Wrapper.Body className="space-y-4">
                <Select
                  label="formControls.gender"
                  value={data.gender}
                  setValue={handleSetValue("gender")}
                  items={types.gender}
                />
                <Select
                  label="formControls.salutation"
                  value={data.salutation}
                  setValue={handleSetValue("salutation")}
                  items={types.salutation}
                />
                <Select
                  label="formControls.salutationTitle"
                  value={data.salutationTitle}
                  setValue={handleSetValue("salutationTitle")}
                  items={types.salutationTitle}
                />
              </Wrapper.Body>
            </Wrapper>
            <Wrapper>
              <Wrapper.Body className="grid grid-cols-3 gap-4">
                <InputGroup
                  className="col-span-full"
                  label="formControls.email"
                  value={data.emailAddress}
                  setValue={handleSetValue("emailAddress")}
                  rules={rules.email}
                  type="email"
                />
                <Select
                  className="col-span-1"
                  label="formControls.countryCode"
                  value={data.mobileNumber?.countryCode}
                  setValue={handleSetValue("mobileNumberCode")}
                  rules={rules.required}
                  items={countryCodes}
                />
                <InputGroup
                  className="col-span-2"
                  label="formControls.mobileNumber"
                  value={data.mobileNumber?.number}
                  setValue={handleSetValue("mobileNumber")}
                  rules={rules.required}
                  type="integer"
                />
                <Select
                  className="col-span-1"
                  label="formControls.countryCode"
                  value={data.phoneNumber?.countryCode}
                  setValue={handleSetValue("phoneNumberCode")}
                  items={countryCodes}
                  rules={hasPhoneNumber ? rules.required : undefined}
                />
                <InputGroup
                  className="col-span-2"
                  label="formControls.phoneNumber"
                  value={data.phoneNumber?.number}
                  setValue={handleSetValue("phoneNumber")}
                  type="integer"
                  rules={hasPhoneNumber ? rules.phoneNumber : undefined}
                />
              </Wrapper.Body>
            </Wrapper>
          </div>
          {/* <h6 className="col-span-full">
            <Text>page.customerDetails.overview.removeCustomer.title</Text>
          </h6>
          <Wrapper className="col-span-full">
            <Wrapper.Body className="space-y-4">
              <div className="flex gap-4 border border-dashed border-warning rounded p-4 bg-warning/5">
                <Icon
                  name="InfoCircle"
                  // variant="Bulk"
                  size={28}
                  className="text-warning"
                />
                <div className="flex-1 space-y-2">
                  <h6>
                    <Text>alertTitles.deactivateAccount</Text>
                  </h6>
                  <p>
                    <Text>alertDescriptions.deactivateAccount</Text>
                  </p>
                </div>
              </div>
              <CheckBox
                value={activeConfirm}
                setValue={toggleConfirm}
                label="formControls.deActiveCustomer"
              />
            </Wrapper.Body>
            <Wrapper.Footer>
              <Button
                className="block w-fit ml-auto"
                variant="danger"
                onClick={removeCustomer}
                disabled={!activeConfirm}
                loading={loading.delete}
              >
                <Text>button.deactivateAccount</Text>
              </Button>
            </Wrapper.Footer>
          </Wrapper> */}
        </section>
      </Form>
    </Fragment>
  );
}
