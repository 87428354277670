import { Address } from "../types";
import { convertAddress } from "../methods";
import {
  Button,
  Icon,
  Text,
  Badge
} from "../components";
import { AddressType } from "../enums";

type CustomerAddressCardProps = {
  title: string;
  address: Address;
  type?: number;
  onClick: (address: Address) => void;
};

export default function CustomerAddressCard({
  title,
  address,
  type,
  onClick,
}: CustomerAddressCardProps) {
  return (
    <div className="border border-gray rounded p-3 space-y-2">
      <Badge variant={type === AddressType.Shipping || type === AddressType.Billing ? "warning" : "primary"} className=" text-[10px]">
        {type === AddressType.Shipping ?
          <Text>customers.addresses.shippingAddress</Text> :
          type === AddressType.Billing ?
            <Text>customers.addresses.billingAddress</Text> :
            <Text>customers.addresses.mainAddress</Text>
        }
      </Badge>
      <div className="flex items-center gap-2">
        <Icon name="ProfileCircle" size={19} className="mb-auto text-primary" />
        <div className="flex-1 space-y-2">
          <h6 className="text-sm">
            {title}
          </h6>
          <p className="text-gray-500 text-sm">
            {convertAddress(address)}
          </p>
        </div>
        <Button light className="btn-sm" onClick={() => onClick(address || {} as Address)}>
          <Text>button.useCustomerAddress</Text>
        </Button>
      </div>
    </div >
  );
}
