import CreateTask from "../pages/Tasks/Create";
import TaskDetails from "../pages/Tasks/Details";
import TaskList from "../pages/Tasks/List";

const tasks = [
  {
    path: "tasks",
    element: <TaskList />,
  },
  {
    path: "tasks/create",
    element: <CreateTask />,
  },
  {
    path: "tasks/:taskId",
    element: <TaskDetails />,
  },
];
export default tasks;
