import { ChangeEvent, ForwardedRef, forwardRef, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { useDebounce, useTranslate } from "../hooks";
import Icon from "./_Icon";
type SearchBoxProps = {
  placeholder?: string;
  variant?: "white" | "gray";
  searchType?: "immediately" | "debounce";
  className?: string;
  value?: string | null;
  onSubmit?: (val: string) => void;
};
function SearchBox(
  {
    placeholder = "global.searchBox",
    variant = "white",
    value,
    className,
    onSubmit,
    searchType = "debounce",
  }: SearchBoxProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const debounce = useDebounce();
  const translate = useTranslate();
  const activeVariant = useMemo(() => {
    const variants = {
      white: "bg-white",
      gray: "bg-gray-50",
    };
    return variants[variant];
  }, [variant]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || "";
    const isImmediately = searchType === "immediately";
    if (isImmediately) return onSubmit?.(value);
    debounce(() => onSubmit?.(value));
  };
  return (
    <div
      ref={ref}
      className={twMerge(
        "w-full flex-center gap-2 border border-transparent rounded px-2 py-1 focus-within:border-primary transition-colors",
        activeVariant,
        className
      )}
    >
      <button type="button">
        <Icon
          name="SearchNormal1"
          variant="Outline"
          size={24}
          className="text-[#A1A4AC]"
        />
      </button>
      <input
        defaultValue={value ?? ""}
        onChange={handleChange}
        placeholder={translate(placeholder) ?? ""}
        className="flex-1 h-8 px-2"
      />
    </div>
  );
}

export default forwardRef<HTMLDivElement, SearchBoxProps>(SearchBox);
