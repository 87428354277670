import { getRandomColor } from "../methods";
import { iconNameType } from "../types";
import Icon from "./_Icon";
type avatarProps = { color?: string; icon?: iconNameType };
export default function Avatar({
  color = getRandomColor(0),
  icon = "User",
}: avatarProps) {
  return (
    <i
      style={{ color }}
      className="size-10 rounded-full flex-center bg-light overflow-hidden"
    >
      <Icon name={icon} variant="Bold" size="1.75rem" />
    </i>
  );
}
