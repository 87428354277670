const orderBlockReasons = [
  { name: "Anzahlung fehlt", id: "Anzahlung fehlt" },
  { name: "Abruf Küchen", id: "Abruf Küchen" },
  { name: "Unklare Position", id: "Unklare Position" },
  { name: "Ersatzteil aus Lager", id: "Ersatzteil aus Lager" },
  { name: "Finanzierung", id: "Finanzierung" },
  { name: "Bestellung fehlt !nur für KSC! (aktuell: Auftrag wird nicht bestellt, man kann aber beispielsweise Lieferscheine und Rechnungen drucken)", 
    id: "Bestellung fehlt !nur für KSC! (aktuell: Auftrag wird nicht bestellt, man kann aber beispielsweise Lieferscheine und Rechnungen drucken)" },
  { name: "Aufmaß fehlt", id: "Aufmaß fehlt" },
  { name: "Rücktrittsrecht", id: "Rücktrittsrecht" },
  { name: "Streitfall/Rechtsanwalt", id: "Streitfall/Rechtsanwalt" },
  { name: "Unterschrift fehlt", id: "Unterschrift fehlt" },
  { name: "Auswahlauftrag", id: "Auswahlauftrag" }
];
export default orderBlockReasons;
