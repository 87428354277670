const errorCodes = {
  default: "An error occurred",
  file: "Please choose file",
  form: "Data is not correct",
  server: "Server error",
  incidentCustomerMood: "Customer mood is required",
  inValidAddress: "Address is not valid.",
  lineItemInventory: "This inventory service is not available at this time",
  noSecondSalesPersonAcceptOffer: "No person with this number, Try again",
  A1002: "User not fount",
  A1003: "Employee not found",
  A1004: "User group not found",
  A1005: "Legal entity not found",
  A1007: "Mobile number is already registered",
  A1008: "Customer account not found",
  A1009: "Mobile number verification code is not valid",
  A1010: "Mobile number is not verified yet",
  A1011: "Email address is not verified",
  A1012: "Pass code should be set",
  A1013: "Email address is already registered",
  A1014: "Pass code should be valid",
  A1015: "Email address is not match",
  A1016: "Legal entity with same code already exist",
  A1017: "New pass code is not match",
  A1018: "Invalid pass code",
  A1019: "User principal name not the same as email address",
  A1020: "User already signed up",
  A1021: "User already exist",
  A1022: "Device token is not valid",
  A1023: "Email confirmation code is expired",
  A1024: "Email confirmation code is not valid",
  A1025: "Mobile number is not confirmed",
  A1026: "Remove customer is not possible",
  A1027: "Customer must accept gdpr",
  A1028: "Customer must be person",
  A1029: "Mobile number must registered",
  A1030: "Company tax id must be filled",
  A1031: "Company registration number must be unique",
  A1032: "Customer must be company",
  A1033: "Company contact person not found",
  A1034: "Country not found",
  A1035: "CustomervAddress not found",
  C9001: "Erp configuration not found",
  C9002: "Send message to erp failed",
  C9003: "Channel message not found",
  C9004: "Favorite not found",
  C9005: "Favorite item should be unique",
  C9006: "Favorite item not found",
  C9007: "Can not create incident",
  C9008: "Can not update incident",
  C9009: "Can not create order in crm",
  C9101: "Crm store not found",
  C9102: "Crm order not found",
  C9103: "Crm order detail not found",
  C9104: "Crm customer not found",
  C9105: "Crm employee not found",
  AS1001: "Elo config not found",
  AS1002: "Elo config already exist",
  AS1003: "Elo service not available",
  AS2001: "Personal azure blob config not found",
  AS3001: "Tenant storage config not found",
  AS3002: "Storage type not set for puls file type",
  PE0000: "Calculation unit not found",
  PE0100: "Calculation unit customization not found",
  PE0101: "Custom calculation unit not found",
  PE0200: "Price template code already exists",
  PE0201: "Purchase price template not found",
  PE0202: "Purchase price template is not active",
  PE0203: "Purchase price template period not started",
  PE0204: "Purchase price template period finished",
  PE0205: "No purchase price template is available",
  PE0206: "Purchase price template dose not have item",
  PE0300: "Sale price template not found",
  PE0301: "Sale price template is not active",
  PE0302: "Sale price template period not started",
  PE0303: "Sale price template period finished",
  PE0304: "No sale price template is available",
  PE0305: "Sale price template dose not have item",
  PE0306: "Optional discounts not found in sale price template",
  PE0400: "Purchase price template item not found",
  PE0500: "Sale price template item not found",
  PE0600: "Manual discount value is required",
  PE0601: "Manual discount value is not in valid range",
  12000: "Duplicate iwofurn configuration",
  12001: "Iwofurn configuration not found",
  12002: "Store iln not exist",
  12003: "Iwofurn configuration url is not set",
  12004: "Iwofurn configuration user name is not set",
  12005: "Iwofurn configuration password is not set",
  12006: "Iwofurn internal tag is not found",
  12007: "Iwofurn order has no line item",
  12008: "Iwofurn api not responding",
  SS0001: "Object not found",
  SS0002: "Sale channel not found",
  SS0003: "Sale channel code should be unique",
  SS0100: "Channel message not found",
  SS0200: "Pim product not found",
  SS4000: "Supplier not found",
  SS5006: "Offer down payment reason can not be null or empty",
  SS6002: "Program not found",
  SS7024: "Pim product supplier is not set",
  SS7025: "Pim product supplier program is not set",
  SS7032: "Pim product availability status not found",
  SS7033: "Pim product availability status is default",
  SS7034: "Pim product availability status date range should not conflict",
  SS8025: "Offer not found",
  SS8026: "Document not found",
  SS8027: "Order not found",
  SS8028: "Make order not possible when line item supplier id null",
  SS8029: "Make order not possible when sub line item supplier id null",
  SS8030: "Offer line item not found",
  SS8031: "Offer document not found",
  SS8032: "Offer is expired",
  SS8033: "Customer not found",
  SS8034: "Customer company contact person not found",
  SS8035: "Customer has not cim number",
  SS8036: "Customer not approved",
  SS8037: "Order line not found",
  SS8038: "Offer line item has reserved in inventory",
  SS8039: "Offer line item has not reserved in inventory",
  SS8040: "All offer line item must have shipping method",
  SS8041: "Customer confirmation deadline can not be in the past",
  SS8042: "Order line item not found",
  SS8043: "Order line item has reserved in inventory",
  SS8045: "Order line item has not reserved in inventory",
  SS8046: "Order document pdf not created",
  SS8047: "Order document not found",
  SS8048:
    "Offer line item is already reserved in inventory with specified quantity",
  SS8049: "Offer desired delivery date is invalid",
  SS8050: "Order desired delivery date is invalid",
  SS8051: "Offer line item desired delivery date is invalid",
  SS8052: "Order line item desired delivery date is invalid",
  SS8053: "Bundle line item not found",
  SS8054: "Sub line item is only added to bundle products",
  SS8055: "Bundle sub line item can not canceled",
  SS8056: "Published order line item can not deleted",
  SS8062: "Invalid offer line item type",
  SS8063: "Invalid order line item type",
  SS8060: "Offer line item supplier program availability status is not valid",
  SS8061: "Order line item supplier program availability status is not valid",
  SS8064: "Offer line item pim product availability status is not valid",
  SS8065: "Order line item pim product availability status is not valid",
  SS6100: "Store not found",
  SS14208: "Price engine service is not available",
  SS15003: "Excel file has no data",
  SS14100: "Document template not found",
  SS14101: "Offer document pdf not created",
  SS14103: "Offer has accepted",
  SS14104: "Offer has not active",
  SS14105: "Default document template not found",
  SS14106: "Document template already exists",
  SS14107: "Document template mime type must be equal",
  SS14300: "Erp inventory config not found",
  SS14301: "Erp inventory service login microsoft failed",
  SS14302: "Erp inventory service login dynamics failed",
  SS14303: "Erp inventory service is not available",
  SS14304:
    "Only store have legal entity code can used in erp inventory service",
  SS14305: "Erp inventory service reserve product is not available",
  SS14306: "Erp inventory service reserve product in inventory has failed",
  SS14307: "Erp inventory service unreserve product is not available",
  SS14308: "Erp inventory service unreserve product from inventory has failed",
  SS14309: "Erp inventory service product quantity is not enough",
  SS14310: "Only product have article number can used in erp inventory service",
  SS14311: "Erp inventory service on hand result has no response",
  SS14312: "Erp inventory service on hand result location not exist",
  SS14313: "Erp inventory service login microsoft has bad request result",
  SS14314: "Erp inventory service login microsoft has unauthorized result",
  SS14315: "Erp inventory service login dynamics has bad request result",
  SS14316: "Erp inventory service login dynamics has unauthorized result",
  SS14400: "Sales person information not found",
  SS15000: "File not found",
  SS16000: "Commission group orders can not be empty",
  SS16001: "Can not add orders from different customers to commission group",
  SS16002: "Can not add orders from different stores to commission group",
  SS16003: "Can not add orders with processed status to commission group",
  SS16004: "Can not add orders with bigger delivery date to commission group",
  SS16005: "Commission group delivery date can not be in the past",
  SS16006: "Commission group not found",
  SS16007: "Order already has commission group",
  SS16008: "Order not published yet",
  SS17200: "Print request not found",
  SS17201: "Print request has on process status",
  SS18019: "Only last phase can be closed",
  SS18027:
    "Start phase is only possible when all preparation phase check list items have been checked",
  SS18010: "Change phase is available after current phase duration",
  SS0500: "Supplier program not found",
  SS0501: "Supplier program availability status not found",
  SS0502: "Supplier program availability status is default",
  SS0503: "Supplier program availability status date range should not conflict",
  SS0504: "Supplier holiday not found",
  SS0505: "Supplier contact person not found",
};
export default errorCodes;
