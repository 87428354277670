import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import findOrderIcon from "../../../assets/icons/find order.svg";
import {
  Button,
  Icon,
  Image,
  Layout,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
  Wrapper,
} from "../../../components";
import { config } from "../../../constants";
import { CustomerType } from "../../../enums";
import { withPermission } from "../../../hoc";
import {
  useAxios,
  useConverters,
  useStartViewTransition,
} from "../../../hooks";
import { cn } from "../../../methods";
import { Order, WithPaging } from "../../../types";

function OrdersList() {
  const startViewTransition = useStartViewTransition();
  const { axios, loading } = useAxios();
  const { convertDate } = useConverters();
  const [data, setData] = useState<WithPaging<Order.Item>>({});
  const [params, setParams] = useState<Order.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    // salesStatus: SalesStatus.None,
  });
  const hasKeyword = !!params.keyword;
  const handleSetParams = (key: keyof Order.Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: any) => {
      startViewTransition(() => {
        setParams((p) => ({
          ...p,
          [key]: value,
          ...(!isPageNumber && { pageNumber: 1 }),
        }));
      });
    };
  };
  const getData = () => {
    if (!hasKeyword) return setData({});
    const url = "/salesservice/api/orders";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params, hasKeyword]);
  return (
    <Layout className={cn(!hasKeyword && "flex flex-col")}>
      <Layout.Header>
        <h1 className="text-lg text-dark font-medium">
          <Text>pickup.list.title</Text>
        </h1>
      </Layout.Header>
      <Layout.Body
        className={cn("space-y-4", !hasKeyword && "flex-1 flex flex-col")}
      >
        <Wrapper
          // @ts-ignore // view-transition-name
          style={{ viewTransitionName: "search-box" }}
          className={cn("mx-auto", !hasKeyword && "px-10 max-w-3xl my-auto")}
        >
          <Wrapper.Body className={hasKeyword ? undefined : "py-10"}>
            {!hasKeyword && (
              <h6 className="text-base font-medium text-dark">
                <Text>pickup.list.findOrderTitle</Text>
              </h6>
            )}
            <div
              className={cn(
                "w-full flex items-center gap-4",
                !hasKeyword && "mt-5"
              )}
            >
              <SearchBox
                variant="gray"
                onSubmit={handleSetParams("keyword")}
                placeholder="pickup.list.findOrderSearch"
                className="flex-1"
              />
              {!hasKeyword && (
                <Button type="button" variant="primary" light onClick={getData}>
                  <Icon name="SearchNormal1" variant="Outline" />{" "}
                  <Text>pickup.list.searchButton</Text>
                </Button>
              )}
            </div>
            {!hasKeyword && (
              <Image
                src={findOrderIcon}
                alt="find order"
                className="block w-full max-w-56 aspect-square mx-auto mt-12 object-contain"
              />
            )}
          </Wrapper.Body>
        </Wrapper>
        {hasKeyword && (
          <Fragment>
            <Wrapper>
              <Wrapper.Body>
                <Table.Wrapper>
                  <Table>
                    <Table.Body loading={loading.get}>
                      {data.items?.map((e) => {
                        const isCompany =
                          e.customer?.customerType === CustomerType.Company;
                        const isPublished = !!e.lastPublishedDate;
                        return (
                          <tr key={e.id}>
                            <td>
                              <Link
                                to={e.id}
                                className="flex-center w-fit gap-4"
                              >
                                <div className="flex-center w-10 h-10 rounded-full text-primary bg-primary/5">
                                  <Icon
                                    name="ReceiptText"
                                    variant="Bold"
                                    size={28}
                                  />
                                </div>
                                <div className="space-y-1">
                                  <span>{e.title}</span>
                                  <span className="block text-secondary">
                                    #{e.number}
                                  </span>
                                </div>
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/${
                                  isCompany ? "companies" : "customers"
                                }/${e.customer?.customerId}`}
                                className="space-y-1"
                              >
                                <h6 className="block">
                                  <Icon
                                    name="Link1"
                                    size={18}
                                    className="text-primary"
                                  />{" "}
                                  {isCompany
                                    ? e.customer?.companyName
                                    : [
                                        e.customer?.firstName,
                                        e.customer?.lastName,
                                      ].join(" ")}
                                </h6>
                                <span className="block text-secondary">
                                  #{e.customer?.cimNumber}
                                </span>
                              </Link>
                            </td>
                            <td className="text-center space-y-1">
                              <Status.Order isPublished={isPublished} />
                              {isPublished && (
                                <Fragment>
                                  <p className="text-sm text-secondary">
                                    <Text>pickup.list.publishedAt</Text>{" "}
                                    {convertDate(e.lastPublishedDate)}
                                  </p>
                                  <p className="text-sm text-secondary">
                                    <Text>
                                      pickup.list.lastPublishedVersion
                                    </Text>{" "}
                                    {e.lastPublishedVersion}
                                  </p>
                                </Fragment>
                              )}
                            </td>
                            <td data-sticky>
                              <Button as={Link} to={e.id} light>
                                <Text>pickup.list.detailsButton</Text>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Table.Wrapper>
              </Wrapper.Body>
            </Wrapper>
            <Pagination
              pageNumber={params.pageNumber}
              setActivePage={handleSetParams("pageNumber")}
              totalItems={data.totalItems}
              totalPages={data.totalPages}
            />
          </Fragment>
        )}
      </Layout.Body>
    </Layout>
  );
}
export default withPermission(OrdersList, ["SC_GetSaleServiceOrder"]);
