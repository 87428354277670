import { Button, Icon, Modal, Text } from "../../../../../components";
import { types } from "../../../../../constants";
import { CRMActivityType } from "../../../../../enums";
import { togglePropsType } from "../../../../../types";

type SelectActivityTypeProps = togglePropsType & {
  onSelect: (type: CRMActivityType) => void;
};

export default function SelectActivityType({
  isOpen,
  toggle,
  onSelect,
}: SelectActivityTypeProps) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6 className="text-base text-dark">
          <Text>incident.activities.createNewActivityTitle</Text>
        </h6>
        <p className="text-sm text-secondary">
          <Text>incident.activities.chooseActivityType</Text>
        </p>
      </Modal.Header>
      <Modal.Body className="grid grid-cols-1 gap-4">
        {types.incidentActivity
          .filter((e) => e.selectable)
          .map((e) => (
            <button
              key={e.id}
              type="button"
              className="w-full flex flex-center gap-2 p-5 border border-[#DEE8F1] bg-[#FBFCFD] rounded"
              onClick={() => {
                onSelect(e.id);
                toggle();
              }}
            >
              <Icon
                name={e.icon}
                variant="Bulk"
                className="text-primary size-5"
              />
              <h6 className="text-start flex-1">
                <Text>{e.name}</Text>
              </h6>
            </button>
          ))}
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button type="button" onClick={toggle} light>
          <Text>incident.activities.chooseActivityTypeBackBtn</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
