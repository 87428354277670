import { useCallback, useEffect, useRef } from "react";

export default function useDebounce() {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearTimeoutRef = () => {
    if (!!timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const debounce = useCallback((callback: () => void, ms: number = 1000) => {
    clearTimeoutRef();
    timeoutRef.current = setTimeout(callback, ms);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeoutRef();
    };
  }, []);
  return debounce;
}
