import { useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal, Text } from "../components";
import { useAxios } from "../hooks";
import { isEmptyValue } from "../methods";
import { Incident, togglePropsType } from "../types";
import IncidentAssignedEmployee, {
  SelectedEmployee,
} from "./_IncidentAssignedEmployee";

type IncidentAssignAssignedProps = togglePropsType & {
  incident: Incident.Item;
  onSubmitted: (id: string) => void;
};

export default function IncidentAssignAssigned({
  isOpen,
  toggle,
  incident,
  onSubmitted,
}: IncidentAssignAssignedProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const { axios, loading } = useAxios();

  const initEmployee: SelectedEmployee | null = useMemo(() => {
    const hasEmployee = ![
      incident.assignedName,
      incident.assignedId,
      incident.assignedType,
    ].some(isEmptyValue);
    if (!hasEmployee) return null;
    return {
      name: incident.assignedName!,
      id: incident.assignedId!,
      type: incident.assignedType!,
    };
  }, [incident]);

  const [employee, setEmployee] = useState(initEmployee);

  const handleSubmit = () => {
    formRef.current?.requestSubmit();
  };
  const submit = () => {
    const url = `/channelmanagerservice/api/incidents/${incident.crmIncidentId}/employee`;
    const body = { assignedId: employee?.id, assignedType: employee?.type };
    axios.put(url, body).then(() => {
      toast.success("incident.form.assignEmployeeMessage");
      onSubmitted(incident.crmIncidentId);
      toggle();
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <Modal.Header>
        <h6 className="text-dark text-base">
          <Text>incident.form.assignedEmployee</Text>
        </h6>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef} onSubmit={submit}>
          <IncidentAssignedEmployee
            label={null}
            value={employee}
            setValue={setEmployee}
            className="p-0 border-none"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button
          type="button"
          variant="danger"
          disabled={loading.update}
          onClick={toggle}
        >
          <Text>button.cancel</Text>
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={handleSubmit}
          loading={loading.update}
        >
          <Text>button.submitChanges</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
