const taskDurationItems = [
  { name: "task.durationItems.1m", id: 1 },
  { name: "task.durationItems.15m", id: 15 },
  { name: "task.durationItems.30m", id: 30 },
  { name: "task.durationItems.45m", id: 45 },
  { name: "task.durationItems.1h", id: 60 },
  { name: "task.durationItems.1.5h", id: 90 },
  { name: "task.durationItems.2h", id: 120 },
  { name: "task.durationItems.2.5h", id: 150 },
  { name: "task.durationItems.3h", id: 180 },
  { name: "task.durationItems.3.5h", id: 210 },
  { name: "task.durationItems.4h", id: 240 },
  { name: "task.durationItems.4.5h", id: 270 },
  { name: "task.durationItems.5h", id: 300 },
  { name: "task.durationItems.5.5h", id: 330 },
  { name: "task.durationItems.6h", id: 360 },
  { name: "task.durationItems.6.5h", id: 390 },
  { name: "task.durationItems.7h", id: 420 },
  { name: "task.durationItems.7.5h", id: 450 },
  { name: "task.durationItems.8h", id: 480 },
  { name: "task.durationItems.1d", id: 24 * 60 },
  { name: "task.durationItems.2d", id: 2 * 24 * 60 },
  { name: "task.durationItems.3d", id: 3 * 24 * 60 },
];
export default taskDurationItems;
