import { useContext } from "react";
import { CreateIncidentContext } from ".";
import { Icon, Text, Types, Wrapper } from "../../../components";
import { types } from "../../../constants";
import { CustomerType } from "../../../enums";
import { useConverters } from "../../../hooks";
import { showCallNumber } from "../../../methods";

export default function Completed() {
  const { convertAmount } = useConverters();
  const { data, customer, order, employee } = useContext(CreateIncidentContext);
  const isCompany = customer?.type === CustomerType.Company;

  return (
    <Wrapper>
      <Wrapper.Body className="max-w-screen-md mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
        <section className="col-span-full flex flex-wrap items-start bg-[#F8FAFC] rounded gap-4 p-2">
          <Icon.Wrapper variant="primary" className="text-xl">
            <Icon name="DocumentText" variant="Bold" />
          </Icon.Wrapper>
          <div className="flex-1">
            <h6 className="text-lg text-dark font-medium">{data.title}</h6>
            {/* <p className="text-base text-dark">
              <Icon
                name="LocationTick"
                variant="Bold"
                className="text-danger"
              />{" "}
              {branch?.title}
            </p> */}
          </div>
          <div className="flex flex-col items-end justify-center gap-1">
            <Types.IncidentPriority id={data.priority!} />
            <Types.IncidentOrigin id={data.origin!} />
          </div>
        </section>
        <section className="border rounded p-2 space-y-4">
          <h6 className="text-base text-dark">
            <Text>incident.form.customer</Text>{" "}
            <span
              dangerouslySetInnerHTML={{
                __html:
                  types.customerModes.find((e) => e.id === data.customerMood)
                    ?.name ?? "",
              }}
            />
          </h6>
          <div className="flex items-start gap-2">
            <Icon
              name={isCompany ? "Home2" : "ProfileCircle"}
              size={25}
              className="text-primary"
            />
            <div className="flex-1">
              <h6 className="text-base text-dark">{customer?.name}</h6>
              {customer?.cimNumber && (
                <p className="text-sm text-secondary">#{customer.cimNumber}</p>
              )}
              {
                <p className="text-sm text-secondary">
                  {[
                    customer?.emailAddress,
                    showCallNumber(customer?.phoneNumber),
                  ]
                    .filter(Boolean)
                    .join(" | ")}
                </p>
              }
            </div>
          </div>
        </section>
        <section className="border rounded p-2 space-y-4">
          <h6 className="text-base text-dark">
            <Text>incident.form.attachmentOrder</Text>
          </h6>
          <div className="flex items-start gap-2">
            <Icon name="ReceiptText" size={25} className="text-primary" />
            <div className="flex-1">
              <h6 className="text-base text-dark">{order?.name}</h6>
              <p className="text-sm text-secondary">#{order?.number}</p>
              <p className="text-sm text-secondary">
                {convertAmount(order?.totalAmount)}
              </p>
            </div>
          </div>
        </section>
        <section className="border rounded p-2 space-y-4">
          <h6 className="text-base text-dark">
            <Text>incident.form.assignedEmployee</Text>
          </h6>
          <div className="flex items-start gap-2">
            <Icon name="UserTag" size={25} className="text-primary" />
            <div className="flex-1">
              <h6 className="text-base text-dark">{employee?.name ?? "--"}</h6>
            </div>
          </div>
        </section>
        {/* <section className="border rounded p-2 space-y-4 md:col-span-3">
          <h6 className="text-base text-dark">
            <Text>incident.form.topicDescription</Text>
          </h6>
          <p className="text-base text-secondary">{data.topicDescription}</p>
        </section> */}
        <section className="border rounded p-2 space-y-4 col-span-full">
          <h6 className="text-base text-dark">
            <Text>incident.form.description</Text>
          </h6>
          <p className="text-base text-secondary">{data.description}</p>
        </section>
      </Wrapper.Body>
    </Wrapper>
  );
}
