import { Dispatch } from "react";
import toast from "react-hot-toast";
import { Button, Modal, Text } from "../../../components";
import { useAxios } from "../../../hooks";
import { togglePropsType } from "../../../types";
import useUpdateSellOffProduct from "./useUpdateSellOffProduct";

type Props = togglePropsType & {
  sellOffProductId?: string;
  onSubmitted?: Dispatch<string>;
};

export default function ClosePhaseModal({
  isOpen,
  toggle,
  sellOffProductId,
  onSubmitted,
}: Props) {
  const [updateParent, parentLoading] = useUpdateSellOffProduct();
  const { axios, loading } = useAxios();
  const hasLoading = loading.post || parentLoading;
  const submit = () => {
    if (!sellOffProductId) return;
    const url = `salesservice/api/selloffproducts/${sellOffProductId}/close-phase`;
    axios.post(url).then(() => {
      updateParent().then(() => {
        const message = "sellOffProducts.closePhaseModal.successMessage";
        toast.success(message);
        toggle();
        onSubmitted?.(sellOffProductId);
      });
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6 className="text-base text-dark font-medium">
          <Text>sellOffProducts.closePhaseModal.title</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <p className="text-sm text-secondary font-normal">
          <Text>sellOffProducts.closePhaseModal.text1</Text>
        </p>
        <p className="text-sm text-secondary font-normal">
          <Text>sellOffProducts.closePhaseModal.text2</Text>
        </p>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button type="button" light onClick={toggle} disabled={hasLoading}>
          <Text>sellOffProducts.closePhaseModal.cancelButton</Text>
        </Button>
        <Button
          type="button"
          variant="danger"
          onClick={submit}
          loading={hasLoading}
        >
          <Text>sellOffProducts.closePhaseModal.submitButton</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
