import { Fragment, useContext } from "react";
import { OfferDetailsContext } from "..";
import {
  AddressViewer,
  Button,
  Icon,
  Text,
  Wrapper,
} from "../../../../components";
import { useToggle } from "../../../../hooks";
import { OrderDetailsContext } from "../../../Orders/Details";
import AddressDrawer from "../AddressDrawer";
import LineItems from "./LineItems";
import Offer from "./Offer";

export default function Logistic() {
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;

  const [showAddressDrawer, toggleAddressDrawer] = useToggle(false);
  const address = offer?.shippingAddress || order?.shippingAddress;
  const hasAddress = !!address;

  return (
    <Fragment>
      <Wrapper className="lg:!col-span-2">
        <Wrapper.Header className="flex items-center">
          <h6 className="text-base text-dark">
            <Text>drawerTitles.shipTo</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="flex items-start gap-4">
          {hasAddress && (
            <AddressViewer
              className="text-sm text-secondary flex-1"
              address={address}
            />
          )}
          {isEnabled && (
            <Button
              type="button"
              variant="primary"
              light
              className={`p-[0.375rem] ${
                hasAddress ? "text-lg" : "text-base"
              } leading-none`}
              onClick={toggleAddressDrawer}
            >
              <Icon name="Edit2" />
              {!hasAddress && (
                <Fragment>
                  {" "}
                  <Text>button.addShippingAddress</Text>
                </Fragment>
              )}
            </Button>
          )}
        </Wrapper.Body>
      </Wrapper>
      <Offer />
      <LineItems />
      {isEnabled && (
        <AddressDrawer
          isOpen={showAddressDrawer}
          toggle={toggleAddressDrawer}
          type="shipping"
        />
      )}
    </Fragment>
  );
}
