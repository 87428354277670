import { Fragment } from "react";
import { usePermission } from "../hooks";
import { childrenProps, Permission } from "../types";

type WithPermissionProps = { permissions: Permission[] } & childrenProps;
export default function WithPermission({
  permissions,
  children,
}: WithPermissionProps) {
  const hasPermission = usePermission(...permissions);
  if (!hasPermission) return null;
  return <Fragment>{children}</Fragment>;
}
