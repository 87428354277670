import { cloneDeep } from "lodash";
import { ChangeEvent, Fragment, useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import { Button, Icon, Text } from "../../../../components";
import { accept } from "../../../../constants";
import { PulsFileType } from "../../../../enums";
import { useAxios, useToggle, useUploadFile } from "../../../../hooks";
import { Image } from "../../../../types";
import MediaItem from "./MediaItem";
import MediaModal from "./MediaModal";

export default function Media() {
  const { axios, loading } = useAxios();
  const [uploadFile, fileLoading] = useUploadFile(PulsFileType.SellOffProduct);
  const { basicInfo, setBasicInfo } = useContext(SellOffProductDetailsContext);
  const [shoeMediaModal, toggleMediaModal] = useToggle(false);
  const media = basicInfo?.assets ?? [];
  const hasLoading = loading.update || fileLoading;
  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    uploadFile(file).then((file) => {
      const url = `/salesservice/api/selloffproducts/${basicInfo?.sellOffProductId}/assets`;
      const body = { assets: [file, ...media] };
      axios.patch(url, body).then(() => {
        setBasicInfo?.((p) => {
          const data = cloneDeep(p!);
          data.assets = body.assets as Image[];
          return data;
        });
      });
    });
  };
  return (
    <Fragment>
      <section className="col-span-full flex items-center gap-4">
        <h5 className="flex-1 text-sm text-dark font-medium">
          <Text>sellOffProducts.details.media</Text>
        </h5>
        {/* <Button type="button" outline>
          <Text>Edit sort EN</Text>
        </Button> */}
      </section>
      <section className="col-span-full grid grid-cols-2 lg:grid-cols-4 gap-4">
        <Button
          as="label"
          // type="button"
          className="flex flex-center flex-col gap-4 text-center border border-dashed border-primary bg-primary/10 text-primary rounded p-4"
          loading={hasLoading}
        >
          <Icon name="DocumentUpload" variant="Bulk" className="size-14" />
          <h6 className="text-sm font-medium">
            <Text>sellOffProducts.details.addMediaButton</Text>
          </h6>
          <input
            hidden
            type="file"
            onChange={handleUploadFile}
            accept={accept.image}
            disabled={hasLoading}
          />
        </Button>
        {media.map((e) => (
          <MediaItem key={e.id} media={e} onClick={toggleMediaModal} />
        ))}
      </section>
      <MediaModal isOpen={shoeMediaModal} toggle={toggleMediaModal} />
    </Fragment>
  );
}
