import { Dispatch, useContext } from "react";
import { ProductDetailsContext } from "..";
import { Button, Image, Modal, Text, Translate } from "../../../../components";
import { useFindAttribute } from "../../../../hooks";
import { isImage } from "../../../../methods";
import { togglePropsType } from "../../../../types";

type MediaGalleryProps = togglePropsType & {
  activeIndex: number;
  setActiveIndex: Dispatch<number>;
};

export default function MediaGallery({
  isOpen,
  toggle,
  activeIndex,
  setActiveIndex,
}: MediaGalleryProps) {
  const { product } = useContext(ProductDetailsContext);
  const findAttribute = useFindAttribute();
  const images = product.mediaList?.filter((e) => isImage(e.type));
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <Modal.Header className="text-start border-b-0">
        <h6 className="w-full text-dark text-base font-medium break-words">
          <Translate
            value={findAttribute(product.attributeGroups, "title")}
            defaultValue={product.originalName}
          />
        </h6>
        <p className="text-sm text-secondary font-medium w-full">
          #{product.articleNumber}
        </p>
      </Modal.Header>
      <Modal.Body className="space-y-2 overflow-hidden">
        <div
          style={{ transform: `translateX(-${activeIndex}00%)` }}
          className="flex w-full transition-transform overflow-visible"
        >
          {images?.map((e) => (
            <div
              key={e.thumbnailUrl}
              className="min-w-full aspect-image rounded overflow-hidden"
            >
              {isOpen && (
                <Image
                  src={e.url}
                  alt={e.type}
                  className="size-full object-contain bg-gray-50"
                  isDynamic
                />
              )}
            </div>
          ))}
        </div>
        <div className="flex w-full overflow-hidden gap-3">
          {images?.map((e, i) => (
            <button
              key={e.thumbnailUrl}
              type="button"
              data-active={activeIndex === i}
              className="w-40 h-28 min-w-40 border border-transparent transition-colors rounded overflow-hidden data-active:border-primary"
              onClick={() => setActiveIndex(i)}
            >
              {isOpen && (
                <Image
                  src={e.thumbnailUrl}
                  alt={e.type}
                  className="size-full object-cover"
                  isDynamic
                />
              )}
            </button>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button type="button" variant="primary" light onClick={toggle}>
          <Text>button.close</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
