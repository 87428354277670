import { ComponentProps } from "react";
import Button from "./_Button";
import Icon from "./_Icon";
import InputGroup from "./_InputGroup";

type CounterProps = Omit<
  ComponentProps<typeof InputGroup>,
  "type" | "inputBoxClassName" | "formControlClassName" | "append" | "prepend"
> & {
  min?: number;
  max?: number;
  type?: "integer" | "decimal";
};
export default function Counter({
  value,
  setValue,
  min = 1,
  max = Infinity,
  type = "integer",
  ...props
}: CounterProps) {
  const canDecrement = value > min;
  const canIncrement = value < max;

  const handleSetValue = (e: string) => {
    if (e.endsWith(".")) return setValue?.(e);
    const value = Number(e);
    if (isNaN(value)) return setValue?.(null);
    setValue?.(Math.max(Math.min(value, max), min));
  };

  const decrement = () => {
    if (!canDecrement) return;
    setValue?.(value - 1);
  };
  const increment = () => {
    if (!canIncrement) return;
    setValue?.(value + 1);
  };
  return (
    <InputGroup
      value={value}
      setValue={handleSetValue}
      type={type}
      inputBoxClassName="gap-2 bg-transparent border-transparent focus-within:border-transparent *:h-10"
      formControlClassName="border border-solid border-gray-50 focus:border-primary bg-gray-50 rounded text-center"
      append={
        <Button
          type="button"
          variant="primary"
          light
          className="p-0 w-10 rounded-md"
          onClick={increment}
          disabled={!canIncrement}
        >
          <Icon name="Add" />
        </Button>
      }
      prepend={
        <Button
          type="button"
          variant="primary"
          light
          className="p-0 w-10 rounded-md"
          onClick={decrement}
          disabled={!canDecrement}
        >
          <Icon name="Minus" />
        </Button>
      }
      {...props}
    />
  );
}
