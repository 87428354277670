import { Fragment, useContext, useState } from "react";
import { OfferDetailsContext } from "..";
import { Alert, Button, Icon, Text, Wrapper } from "../../../../components";
import { useAxios, useConverters, useToggle } from "../../../../hooks";
import { OrderDetailsContext } from "../../../Orders/Details";
import DownPaymentModal from "./DownPaymentModal";
import QrCodeModal from "./QrCodeModal";

export default function DownPayment() {
  const { axios, loading } = useAxios();
  const { convertAmount, convertDate } = useConverters();

  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const [qrCode, setQrCode] = useState("");
  const [showDownPaymentModal, toggleDownPaymentModal] = useToggle(false);
  const [showQrCodeModal, toggleQrCodeModal] = useToggle(false);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;
  const isOffer = !!offerContext;
  const isOrder = !!orderContext;

  const hasTotalAmount =
    offerContext?.hasTotalAmount ?? orderContext?.hasTotalAmount ?? false;
  const downPaymentAmount =
    offer?.downPaymentAmount ?? order?.downPaymentAmount;
  const downPaymentDueDate =
    offer?.downPaymentDueDate ?? order?.downPaymentDueDate;
  const hasDownPayment =
    offerContext?.hasDownPayment ?? orderContext?.hasDownPayment ?? false;

  const getQrCodeImage = () => {
    const url = `/salesservice/api/orders/${order?.id}/down-payment/qrcode`;
    const config = { cache: true };
    axios.get(url, config).then(({ data }) => {
      setQrCode(data);
      toggleQrCodeModal();
    });
  };

  return (
    <Fragment>
      <Wrapper className="lg:!col-span-3">
        <Wrapper.Body className="space-y-3">
          <div className="flex items-center">
            <span className="mr-auto">
              <Text>offerPaymentDetails.downPayment</Text>{" "}
              {!hasDownPayment && (
                <span className="text-warning">
                  <Icon name="InfoCircle" size={18} />{" "}
                  <Text>global.offerDownPaymentIsNotSet</Text>
                </span>
              )}
            </span>
            {isEnabled && isOffer && (
              <Fragment>
                {hasDownPayment ? (
                  <button
                    type="button"
                    className="text-primary text-xl"
                    onClick={toggleDownPaymentModal}
                    disabled={!hasTotalAmount}
                  >
                    <Icon name="Edit2" />
                  </button>
                ) : (
                  <Button
                    type="button"
                    variant="primary"
                    light
                    onClick={toggleDownPaymentModal}
                    disabled={!hasTotalAmount}
                  >
                    <Text>button.setOfferDownPayment</Text>
                  </Button>
                )}
              </Fragment>
            )}
          </div>
          {hasDownPayment && (
            <Fragment>
              <div className="flex items-center text-base">
                <span className="mr-auto text-secondary">
                  <Icon name="DollarSquare" variant="Bold" />{" "}
                  <Text>global.downPaymentAmount</Text>
                </span>
                <div>{convertAmount(downPaymentAmount)}</div>
              </div>
              <hr className="w-full bg-[#E1E1E7]" />
              <div className="flex items-center text-base">
                <span className="mr-auto text-secondary">
                  <Icon name="Calendar" variant="Bold" />{" "}
                  <Text>global.downPaymentDueDate</Text>
                </span>
                <div>{convertDate(downPaymentDueDate) ?? "--"}</div>
              </div>
            </Fragment>
          )}
        </Wrapper.Body>
        {!hasTotalAmount && isOffer && (
          <Wrapper.Footer>
            <Alert
              variant="warning"
              light
              className="border-none bg-opacity-10 p-2"
              IconProps={{ variant: "Bulk", size: 20 }}
            >
              <Alert.Text className="text-warning">
                <Text>alertTitles.offerDownPaymentEmpty</Text>
              </Alert.Text>
            </Alert>
          </Wrapper.Footer>
        )}
        {isOrder && (
          <Wrapper.Footer className="flex flex-center">
            <Button light onClick={getQrCodeImage} loading={loading.get}>
              <Icon name="Scanner" variant="Bold" size={20} />{" "}
              <Text>button.orderDownPaymentQrCode</Text>
            </Button>
          </Wrapper.Footer>
        )}
      </Wrapper>
      {isEnabled && isOffer && (
        <DownPaymentModal
          isOpen={showDownPaymentModal}
          toggle={toggleDownPaymentModal}
        />
      )}
      {isOrder && (
        <QrCodeModal
          isOpen={showQrCodeModal}
          toggle={toggleQrCodeModal}
          qrCode={qrCode}
        />
      )}
    </Fragment>
  );
}
