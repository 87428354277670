import { useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import { Badge, Text, Wrapper } from "../../../../components";
import { useConverters } from "../../../../hooks";
import { cn } from "../../../../methods";

export default function SalesStatus() {
  const { basicInfo, statusInfo, isSold } = useContext(
    SellOffProductDetailsContext
  );
  const { convertAmount, convertDate } = useConverters();
  const items = [
    {
      label: "sellOffProducts.details.soldPrice",
      value: convertAmount(basicInfo?.realSoldPrice),
      classes: "text-success",
    },
    {
      label: "sellOffProducts.details.soldDate",
      value: convertDate(basicInfo?.soldDateTime),
    },
    { label: "sellOffProducts.details.seller", value: statusInfo?.soldByName },
    {
      label: "sellOffProducts.details.sellerCommission",
      value: statusInfo?.currentSellerCommission,
    },
    {
      label: "sellOffProducts.details.fastSaleCommission",
      value: convertAmount(basicInfo?.fastSalesProvisionAmount),
    },
  ];
  return (
    <Wrapper className="lg:col-span-2">
      <Wrapper.Header className="flex items-center gap-4">
        <h6 className="flex-1 text-sm text-dark font-medium">
          <Text>sellOffProducts.details.salesStatus</Text>
        </h6>
        {isSold ? (
          <Badge className="text-xs bg-[#E7EAC5] text-[#5B8777] bg-opacity-100">
            <Text>sellOffProducts.details.sold</Text>
          </Badge>
        ) : (
          <Badge className="text-xs bg-gray-50 text-[#A1A4AC] bg-opacity-100">
            <Text>sellOffProducts.details.notSold</Text>
          </Badge>
        )}
      </Wrapper.Header>
      <Wrapper.Body>
        <table className="w-full text-sm font-medium">
          <tbody className="divide-y divide-dashed">
            {items.map((e) => (
              <tr
                key={e.label}
                className="*:py-3 [&:first-child>*]:pt-0 [&:last-child>*]:pb-0"
              >
                <td className="text-secondary text-start whitespace-nowrap">
                  <Text>{e.label}</Text>
                </td>
                <td className={cn("text-gray-900 text-end", e.classes)}>
                  {e.value || "--"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Wrapper.Body>
    </Wrapper>
  );
}
