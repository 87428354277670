import { useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import { Text, Wrapper } from "../../../../components";
import { cn } from "../../../../methods";
import TimelineItem from "./TimelineItem";

export default function SalesTimeLine() {
  const { timelines, isPreparation } = useContext(SellOffProductDetailsContext);
  return (
    <Wrapper
      className={cn(
        "flex flex-col lg:max-h-[45rem]",
        !isPreparation && "lg:row-span-3"
      )}
    >
      <Wrapper.Header>
        <h6 className="flex-1 text-sm text-dark font-medium">
          <Text>sellOffProducts.details.salesTimeline</Text>
        </h6>
      </Wrapper.Header>
      <Wrapper.Body className="flex-1 overflow-auto">
        <ul>
          {timelines?.sellOffProductTimelineItems?.map((e) => (
            <TimelineItem key={e.id} timeline={e} />
          ))}
        </ul>
      </Wrapper.Body>
    </Wrapper>
  );
}
