const fileType = {
  pdf: "application/pdf",
  png: "image/png",
  word: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  image: "image",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  gif: "image/gif",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  doc: "application/msword",
  svg:"image/svg+xml",
  webp:"image/webp"
};
export default fileType;
