import { useMemo } from "react";
import useSelector from "./useSelector";

export default function useDefaultSaleChannel() {
  const profile = useSelector((s) => s.profile);
  const channel = useMemo(() => {
    const channelId = profile.defaultSaleChannel ?? "";
    const channels = profile.availableSaleChannels ?? [];
    return channels.find((e) => e.saleChannelId === channelId);
  }, [profile.defaultSaleChannel, profile.availableSaleChannels]);
  if (!channel) return null;
  return channel;
}
