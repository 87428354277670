import { useContext, useMemo } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { TaskDetailsContext } from "..";
import {
  Button,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
  Types,
  Wrapper,
} from "../../../../components";
import { taskDurationItems, types } from "../../../../constants";
import IncidentAssignedEmployee, {
  SelectedEmployee,
} from "../../../../containers/_IncidentAssignedEmployee";
import { CRMActivityStatus, CRMTargetType } from "../../../../enums";
import { useAxios, useDataState } from "../../../../hooks";
import { Task } from "../../../../types";

export default function General() {
  const { axios, loading } = useAxios();
  const { task, setTask } = useContext(TaskDetailsContext);
  const isActive = task.status === CRMActivityStatus.Open;
  const disabled = !isActive;
  const targetIcon = types.crmTarget.find(
    (e) => e.id === task.target?.targetType
  )?.icon;
  const hasTargetIcon = !!targetIcon;
  const hasTarget = !!task.target;
  const targetLink = useMemo(() => {
    const target = task.target;
    const targetType = target?.targetType;
    if (targetType === CRMTargetType.Incident)
      return `/incidents/${target?.targetId}`;
    if (targetType === CRMTargetType.Lead) return null;
    if (targetType === CRMTargetType.Account)
      return `/companies/${target?.targetId}`;
    if (targetType === CRMTargetType.Contact)
      return `/customers/${target?.targetId}`;
    if (targetType === CRMTargetType.Order)
      return `/orders/${target?.targetId}`;
    return null;
  }, [task.target]);
  const hasTargetLink = !!targetLink;
  const initEmployee: SelectedEmployee | null = useMemo(() => {
    const { assignedName, assignedId, assignedType } = task;
    const hasEmployee = !!assignedName && !!assignedId && assignedType !== null;
    if (!hasEmployee) return null;
    return {
      name: assignedName,
      id: assignedId,
      type: assignedType,
    };
  }, [task]);
  const [data, setData, setBaseData, isDataChanged] = useDataState(task);
  const [employee, setEmployee, setBaseEmployee, isEmployeeChanged] =
    useDataState(initEmployee);

  const isChanged = isDataChanged || isEmployeeChanged;
  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => {
      setData((p) => ({ ...p!, [key]: value }));
    };
  };

  const submit = () => {
    const url = `/channelmanagerservice/api/activities/task/${data?.taskId}`;
    const body: Task.Create = {
      subject: data?.subject,
      description: data?.description,
      dueDate: data?.dueDate,
      priority: data?.priority,
      targetId: data?.target?.targetId,
      targetType: data?.target?.targetType,
      assignedId: employee?.id,
      assignedType: employee?.type,
      duration: data?.duration,
    };
    axios.put(url, body).then(() => {
      const message = "task.details.general.successMessage";
      toast.success(message);
      setTask(data);
      setBaseData(data);
      setBaseEmployee(employee);
    });
  };
  return (
    <Form onSubmit={submit} className="space-y-5">
      <section className="flex items-center gap-4">
        <p className="flex-1 text-dark text-sm font-medium">
          <Text>task.details.general.submitText</Text>
        </p>
        <Button
          type="submit"
          variant="primary"
          disabled={!isChanged || disabled}
          loading={loading.update}
        >
          <Text>task.details.general.submitButton</Text>
        </Button>
      </section>
      <section className="grid grid-cols-1 lg:grid-cols-5 gap-4">
        <Wrapper className="lg:col-span-3">
          <Wrapper.Body>
            {/* <InputGroup
              label="task.details.general.subject"
              disabled={disabled}
              placeholder="task.create.subjectPlaceholder"
              value={data.subject}
              setValue={handleSetValue("subject")}
              rules={rules.required}
            /> */}
            {/* <InputGroup
              as="textarea"
              label="task.details.general.description"
              disabled={disabled}
              placeholder="task.create.descriptionPlaceholder"
              value={data.description}
              setValue={handleSetValue("description")}
              rules={rules.required}
            /> */}
            <label className="block w-full mb-2 truncate">
              <Text>task.details.general.description</Text>
            </label>
            <p className="text-sm text-gray-900 font-normal text-justify whitespace-pre-wrap max-h-96 overflow-auto">
              {task.description}
            </p>
          </Wrapper.Body>
        </Wrapper>
        <section className="space-y-4 lg:col-span-2">
          {hasTarget && (
            <Wrapper>
              <Wrapper.Header className="flex items-center justify-between border-b-0 pb-0">
                <h6 className="text-base text-dark font-medium">
                  <Text>task.details.general.targetTitle</Text>
                </h6>
                <Types.CrmTarget id={task.target?.targetType} />
              </Wrapper.Header>
              <Wrapper.Body className="space-y-4">
                <section className="flex items-center border rounded gap-2 p-2">
                  {hasTargetIcon && (
                    <Icon
                      name={targetIcon}
                      size={25}
                      className="text-primary"
                    />
                  )}
                  <h6 className="flex-1 text-base text-dark">
                    {task.target?.targetName}
                  </h6>
                  {hasTargetLink && (
                    <Link to={targetLink} className="btn-link">
                      <Text>button.checkTaskTarget</Text>
                    </Link>
                  )}
                </section>
              </Wrapper.Body>
            </Wrapper>
          )}
          <Wrapper>
            <Wrapper.Body className="space-y-6">
              <Select
                label="task.details.general.priority"
                disabled={disabled}
                value={data.priority}
                setValue={handleSetValue("priority")}
                // rules={rules.required}
                items={types.taskPriority}
              />
              <Select
                label="task.details.general.duration"
                disabled={disabled}
                value={data.duration}
                setValue={handleSetValue("duration")}
                //  rules={rules.required}
                items={taskDurationItems}
              />
              <InputGroup
                label="task.details.general.dueDate"
                disabled={disabled}
                value={data.dueDate}
                setValue={handleSetValue("dueDate")}
                // rules={rules.required}
                type="date"
                append={
                  <span className="input-group-text">
                    <Icon name="Calendar" className="size-6" variant="Bold" />
                  </span>
                }
              />
            </Wrapper.Body>
          </Wrapper>
        </section>
        <Wrapper className="lg:col-span-3">
          <Wrapper.Body>
            <IncidentAssignedEmployee
              label="task.details.general.assignEmployee"
              disabled={disabled}
              value={employee}
              setValue={setEmployee}
            />
          </Wrapper.Body>
        </Wrapper>
      </section>
    </Form>
  );
}
