import { useState } from "react";
import {
  Accordion,
  Button,
  Drawer,
  Form,
  Icon,
  RadioButton,
  Text,
} from "../components";
import { config, status } from "../constants";
import { Offer, togglePropsType } from "../types";

type Params = Offer.Params;
type createOfferProps = togglePropsType & {
  onSubmit: (val: Params) => void;
};
export default function OfferFilter({
  isOpen,
  toggle,
  onSubmit,
}: createOfferProps) {
  const initData: Params = {
    status: 0,
    pageNumber: 1,
    pageSize: config.pageSize,
  };
  const [data, setData] = useState(initData);
  const resetFilter = () => {
    setData(initData);
    onSubmit(initData);
    toggle();
  };
  const submit = () => {
    toggle();
    onSubmit({ ...data, pageNumber: 1 });
  };
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center">
          <div className="flex-1"></div>
          <h6>
            <Text>global.filter</Text>
          </h6>
          <div className="flex-1 flex justify-end">
            <button
              type="button"
              className="text-warning"
              onClick={resetFilter}
            >
              <Icon name="ArrowForward" variant="Bold" />{" "}
              <Text>button.resetFilter</Text>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Accordion defaultActiveKey={[]}>
            <Accordion.Item>
              <Accordion.Toggle>
                <Text>page.customerDetails.offers.filter.status.label</Text>
              </Accordion.Toggle>
              <Accordion.Body>
                {/* {status.offer.map((e) => (
                    <CheckBox
                      key={e.id}
                      value={!!data.offerStatuses.includes(e.id)}
                      label={e.name}
                      setValue={(value) =>
                        setData((p) => ({
                          ...p,
                          offerStatuses: value
                            ? [...p.offerStatuses, e.id]
                            : p.offerStatuses.filter((id) => e.id !== id),
                        }))
                      }
                    />
                  ))} */}
                <section className="divide-y divide-gray-100">
                  {status.offer.map((e) => (
                    <RadioButton
                      key={e.id}
                      label={e.name}
                      onClick={() => setData((p) => ({ ...p, status: e.id }))}
                      isActive={e.id === data.status}
                    />
                  ))}
                </section>
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item>
              <Accordion.Toggle>
                <Text>page.customerDetails.offers.filter.sortBy.label</Text>
              </Accordion.Toggle>
              <Accordion.Body>
                {types.offerSortBy.map((e) => (
                  <div
                    key={e.id}
                    className="py-3 border-b border-gray-100 last:border-b-0"
                  >
                    <button
                      type="button"
                      onClick={() => setData((p) => ({ ...p, sortBy: e.id }))}
                      className="flex items-center gap-2"
                    >
                      <span
                        data-active={`${e.id}` === `${data.sortBy}`}
                        className="relative w-5 h-5 rounded-full bg-gray-100 before:content('') before:absolute before:inset-1 before:bg-primary before:rounded-full before:opacity-0 data-active:before:opacity-100 before:transition-opacity"
                      />
                      <Text>{e.name}</Text>
                    </button>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit">
            <Text>button.search</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
