import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Button,
  Drawer,
  Form,
  InputGroup,
  Select,
  Text,
} from "../../../../components";
import { rules } from "../../../../constants";
import { SearchAddress } from "../../../../containers";
import { AddressType } from "../../../../enums";
import { useAxios, useCountries, useValidateAddress } from "../../../../hooks";
import { Address, togglePropsType } from "../../../../types";
import { CustomerAddress } from "../../../../types/customer";

type AddressDrawerProps = {
  type: number;
  getData: () => void;
  addressInfo?: CustomerAddress;
  customerId: string;
} & togglePropsType;

export default function AddressDrawer({
  isOpen,
  toggle,
  type,
  getData,
  addressInfo,
  customerId,
}: AddressDrawerProps) {
  const [countries, countriesLoading] = useCountries(isOpen);
  const [validateAddress, validateLoading] = useValidateAddress();
  const { axios, loading } = useAxios();
  const isShipTo = type === AddressType.Shipping;
  const label = isShipTo ? "drawerTitles.shipTo" : "drawerTitles.billTo";
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState<Address>(
    addressInfo?.address ?? ({} as Address)
  );
  const handleSetValue = (key: keyof Address) => {
    return (value: any) => {
      setAddress((p) => {
        const isCountryCode = key === "countryCode";
        const data = cloneDeep(p);
        data[key] = value;
        if (isCountryCode) {
          const country = countries.find((e) => e.id === data.countryCode);
          data.country = country?.name ?? null;
          data.countryCodeISO3 = country?.countryIso3 ?? null;
        }
        return data;
      });
    };
  };

  const submit = () => {
    validateAddress(address).then(({ isValid, suggested }) => {
      if (isValid) return submitAddress();
      if (!suggested) return toast.error("errorCodes.inValidAddress");
      toast("toast.warning.suggestedAddress");
      setAddress(suggested);
    });
  };

  const submitAddress = () => {
    const body = {
      title: title,
      addressType: type,
      address,
    };
    if (!addressInfo) {
      const url = `/accountservice/api/customers/${customerId}/address`;
      axios.post(url, body).then(() => {
        toast.success("toast.success.addCustomerAddress");
        getData();
        toggle();
      });
    } else {
      const url = `/accountservice/api/customers/${customerId}/address/${addressInfo?.id}`;
      axios.put(url, body).then(() => {
        toast.success("toast.success.editCustomerAddress");
        getData();
        toggle();
      });
    }
  };

  useEffect(() => {
    setTitle(addressInfo?.title || "");
    setAddress(addressInfo?.address || ({} as Address));
  }, [addressInfo, isOpen]);

  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="space-y-2">
          <Text>{label}</Text>
        </Drawer.Header>
        <Drawer.Body className="space-y-6 mt-2">
          <InputGroup
            label="formControls.title"
            placeholder="placeholders.title"
            value={title}
            setValue={setTitle}
            rules={rules.required}
          />
          {isOpen && (
            <SearchAddress
              setValue={(address) =>
                setAddress((p) => ({ ...p, ...address, name: p.name }))
              }
              countries={countries}
              loading={countriesLoading}
            />
          )}
          <span className="block w-full text-center text-sm text-secondary !my-6">
            <Text>global.orEnterManual</Text>
          </span>
          <InputGroup
            label="formControls.name"
            placeholder="placeholders.name"
            value={address.name}
            setValue={handleSetValue("name")}
            rules={rules.required}
          />
          <section className="flex gap-4">
            <div className="flex-[2]">
              <InputGroup
                label="formControls.street"
                placeholder="placeholders.street"
                value={address.street}
                setValue={handleSetValue("street")}
              />
            </div>
            <div className="flex-1">
              <InputGroup
                label="formControls.number"
                placeholder="placeholders.number"
                value={address.number}
                setValue={handleSetValue("number")}
                rules={rules.required}
              />
            </div>
          </section>
          <section className="flex items-start gap-4">
            <div className="flex-1">
              <InputGroup
                label="formControls.postalCode"
                placeholder="placeholders.postalCode"
                value={address.postalCode}
                setValue={handleSetValue("postalCode")}
                rules={rules.required}
              />
            </div>
            <div className="flex-1">
              <InputGroup
                label="formControls.city"
                placeholder="placeholders.city"
                value={address.city}
                setValue={handleSetValue("city")}
                rules={rules.required}
              />
            </div>
          </section>
          <section className="flex items-start gap-4">
            <div className="flex-1">
              <Select
                label="formControls.country"
                placeholder="placeholders.country"
                items={countries}
                value={address.countryCode}
                setValue={handleSetValue("countryCode")}
                rules={rules.required}
              />
            </div>
            <div className="flex-1">
              <InputGroup
                label="formControls.state"
                placeholder="placeholders.state"
                value={address.state}
                setValue={handleSetValue("state")}
                rules={rules.required}
              />
            </div>
          </section>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button
            type="submit"
            loading={loading.post || validateLoading || loading.update}
          >
            <Text>button.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
