import { cloneDeep } from "lodash";
import { Fragment, useContext } from "react";
import { toast } from "react-hot-toast";
import { CompanyContext } from "..";
import mobileProgramingIcon from "../../../../assets/icons/mobile programming.svg";
import mobileIcon from "../../../../assets/icons/mobile.svg";
import notificationIcon from "../../../../assets/icons/notification.svg";
import signpostIcon from "../../../../assets/icons/signpost.svg";
import smsGreenIcon from "../../../../assets/icons/sms green.svg";
import smsRedIcon from "../../../../assets/icons/sms red.svg";
import telephoneIcon from "../../../../assets/icons/telephone-2.svg";
import { Button, Text, Toggler, Wrapper } from "../../../../components";
import { useAxios, useDataState } from "../../../../hooks";
import { Company } from "../../../../types";
type CustomerCommunicationChannel = Company.CommunicationChannel;
type CommunicationItem = {
  icon: string;
  title: string;
  state: keyof CustomerCommunicationChannel;
};

type channelItemType = {
  icon: string;
  title: string;
  state: keyof CustomerCommunicationChannel;
};
export default function CustomerCommunicationChannels() {
  const { axios, loading } = useAxios();
  const { companyData, setCompanyData } = useContext(CompanyContext);
  const [data, setData, setBaseData, isChanged] = useDataState(companyData);
  const submitChange = () => {
    const url = `/accountservice/api/customers/${companyData.id}/as-company`;
    const body = { ...data };
    axios.put(url, body).then(() => {
      const message = "toast.success.editCustomer";
      toast.success(message);
      setCompanyData(body);
      setBaseData(body);
    });
  };
  const handleChangeCommunication = (
    key: keyof CustomerCommunicationChannel
  ) => {
    return (val: boolean) =>
      setData((p) => {
        const data = cloneDeep(p);
        data.communicationChannel ??= {} as CustomerCommunicationChannel;
        data.communicationChannel[key] = val;
        return data;
      });
  };
  const communications: CommunicationItem[] = [
    {
      icon: telephoneIcon,
      title: "company.details.communicationChannels.phone",
      state: "phone",
    },
    {
      icon: mobileProgramingIcon,
      title: "company.details.communicationChannels.application",
      state: "application",
    },
    {
      icon: mobileIcon,
      title: "company.details.communicationChannels.socialMedia",
      state: "socialMedia",
    },
    {
      icon: smsGreenIcon,
      title: "company.details.communicationChannels.email",
      state: "email",
    },
    {
      icon: smsRedIcon,
      title: "company.details.communicationChannels.sms",
      state: "sms",
    },
    {
      icon: signpostIcon,
      title: "company.details.communicationChannels.post",
      state: "post",
    },
  ];
  const channels: channelItemType[] = [
    // {
    //   icon: peopleIcon,
    //   title: "page.customerDetails.communication.familyBonusApplied",
    //   state: "fa",
    // },
    {
      icon: smsGreenIcon,
      title: "company.details.communicationChannels.bulkEmail",
      state: "bulkEmail",
    },
    {
      icon: notificationIcon,
      title: "company.details.communicationChannels.newsletter",
      state: "newsletter",
    },
  ];
  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <h6>
          <Text>global.submit</Text>
        </h6>
        <Button
          onClick={submitChange}
          disabled={!isChanged}
          loading={loading.update}
        >
          <Text>button.submitChanges</Text>
        </Button>
      </div>
      <div className="grid grid-cols-12 gap-4">
        {communications.map((e) => (
          <Wrapper key={e.state} className="col-span-4">
            <Wrapper.Body className="space-y-4">
              <div className="flex-center gap-4">
                <div className="flex-center bg-gray-50 w-11 h-11 rounded">
                  <img src={e.icon} alt={e.title} className="w-7 h-7" />
                </div>
                <h6 className="font-normal flex-1">
                  <Text>{e.title}</Text>
                </h6>
              </div>
              <Toggler
                value={!!data.communicationChannel?.[e.state]}
                setValue={handleChangeCommunication(e.state)}
              />
            </Wrapper.Body>
          </Wrapper>
        ))}
        {channels.map((e) => (
          <Wrapper key={e.state} className="col-span-6">
            <Wrapper.Body className="space-y-4">
              <div className="flex-center gap-4">
                <div className="flex-center bg-gray-50 w-11 h-11 rounded">
                  <img src={e.icon} alt={e.title} className="w-7 h-7" />
                </div>
                <h6 className="font-normal flex-1">
                  <Text>{e.title}</Text>
                </h6>
              </div>
              <Toggler
                value={!!data.communicationChannel?.[e.state]}
                setValue={handleChangeCommunication(e.state)}
              />
            </Wrapper.Body>
          </Wrapper>
        ))}
      </div>
    </Fragment>
  );
}
