import OfferDetails from "../pages/Offers/Details";
import OffersList from "../pages/Offers/List";
const offer = [
  {
    path: "offers",
    element: <OffersList />,
  },
  {
    path: "offers/:offerId",
    element: <OfferDetails />,
  },
];
export default offer;
