import ReactDOM from "react-dom";
import { randomUUID } from "../methods";
import { childrenProps } from "../types";
const id = randomUUID();
const div = document.createElement("div");
div.id = id;
document.body.appendChild(div);
export default function Portal({ children }: childrenProps) {
  return ReactDOM.createPortal(children, div);
}
