import { cloneDeep } from "lodash";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import {
  AddressViewer,
  Button,
  CheckBox,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
} from "../components";
import { rules } from "../constants";
import { useAxios, useToggle } from "../hooks";
import { Company, Customer, Offer, togglePropsType } from "../types";
import ContactPersonCard from "./_ContactPersonCard";
import SelectContactPerson from "./_SelectContactPerson";

type Data = Offer.Form;
type CustomerType =
  | {
      company: Company.Details | null;
      customer?: never;
    }
  | {
      company?: never;
      customer: Customer.Details | null;
    };
type OfferFormProps = togglePropsType & {
  offer?: Offer.Item | Offer.Details | null;
  onSubmitted: () => void;
} & CustomerType;
export default function OfferForm({
  isOpen,
  toggle,
  offer = null,
  customer = null,
  company = null,
  onSubmitted,
}: OfferFormProps) {
  const isNew = !offer;
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const initData: Data = isNew
    ? {
        title: null,
        offerNote: null,
        customerNote: null,
        supplierNote: null,
        currencyCode: "EUR",
        customerCompanyContactPersonId: company?.primaryCompanyContactPersonId,
        customerId: null,
      }
    : {
        title: offer.title,
        offerNote: offer.offerNote,
        customerNote: offer.customerNote,
        supplierNote: offer.supplierNote,
        currencyCode: offer.currencyCode,
        customerCompanyContactPersonId:
          offer.customerCompanyContactPerson?.companyContactPersonId ?? "",
        customerId: offer.customer?.customerId,
      };
  const [data, setData] = useState(initData);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [showSelectPerson, toggleSelectPerson] = useToggle(false);

  const isCompany = !!company;
  const isCustomer = !!customer;
  const hasContactPerson = !!data.customerCompanyContactPersonId;

  const handleInitData = () => {
    if (!isOpen) return;
    setConfirm(false);
    setData(initData);
  };
  const createOffer = () => {
    const url = "/salesservice/api/offer";
    const body = cloneDeep(data);
    body.customerId = customer?.id || company?.id;
    body.offerNote ||= null;
    axios.post(url, body).then(({ data: id }) => {
      navigate(`/offers/${id}`);
    });
  };
  const editOffer = () => {
    const url = `/salesservice/api/offer/${offer?.id}`;
    const body = cloneDeep(data);
    body.customerId = customer?.id || company?.id;
    body.offerNote ||= null;
    axios.put(url, body).then(() => {
      toast.success("toast.success.editOffer");
      onSubmitted();
      toggle();
    });
  };
  useEffect(handleInitData, [isOpen]);
  return (
    <Fragment>
      <Drawer
        as={Form}
        onSubmit={isNew ? createOffer : editOffer}
        isOpen={isOpen}
        toggle={toggle}
      >
        <Drawer.Menu>
          <Drawer.Header>
            <h6 className="text-base text-dark">
              <Text>
                {isNew ? "drawerTitles.createOffer" : "drawerTitles.editOffer"}
              </Text>
            </h6>
          </Drawer.Header>
          {isCompany && (
            <Drawer.Header className="text-start space-y-2">
              <section className="flex items-center gap-2">
                <Icon.Wrapper rounded>
                  <Icon name="Home" variant="Bold" />
                </Icon.Wrapper>
                <h6 className="text-base text-dark">{company.companyName}</h6>
              </section>
              <ul className="space-y-2 text-secondary text-sm">
                <li>
                  <Icon
                    name="DocumentText"
                    variant="Bulk"
                    className="text-primary"
                  />{" "}
                  <Text>company.details.cimNumber</Text>: #{company.cimNumber}
                </li>
                <li>
                  <Icon
                    name="DocumentText"
                    variant="Bulk"
                    className="text-primary"
                  />{" "}
                  <Text>company.details.companyRegistrationNumber</Text>: #
                  {company.companyRegistrationNumber}
                </li>
                <li className="flex items-center gap-1">
                  <Icon
                    name="LocationTick"
                    variant="Bulk"
                    className="text-primary"
                  />{" "}
                  <AddressViewer
                    className="flex-1 whitespace-nowrap text-sm text-secondary truncate"
                    address={company.address}
                  />
                </li>
              </ul>
            </Drawer.Header>
          )}
          <Drawer.Body className="space-y-4">
            {isCustomer && (
              <Icon.Wrapper className="mx-auto">
                <Icon name="DiscountShape" variant="Bold" />
              </Icon.Wrapper>
            )}
            {isCompany && isNew && (
              <Fragment>
                {hasContactPerson ? (
                  <ContactPersonCard
                    personId={
                      isOpen
                        ? data.customerCompanyContactPersonId ?? null
                        : null
                    }
                    companyId={company.id}
                    onClick={toggleSelectPerson}
                  />
                ) : (
                  <InputGroup
                    label="company.details.offer.form.contactPerson"
                    value=""
                    placeholder="company.details.offer.form.contactPersonPlaceholder"
                    onClick={toggleSelectPerson}
                    readOnly
                    rules={rules.required}
                  />
                )}
              </Fragment>
            )}
            <InputGroup
              label="formControls.offerTitle"
              value={data.title}
              setValue={(title) => setData((p) => ({ ...p, title }))}
            />
            <InputGroup
              as="textarea"
              label="formControls.offerNote"
              value={data.offerNote}
              setValue={(offerNote) => setData((p) => ({ ...p, offerNote }))}
            />
          </Drawer.Body>
          <Drawer.Footer className="space-y-2">
            {isNew && (
              <div>
                <CheckBox
                  value={confirm}
                  setValue={setConfirm}
                  label="confirms.addOffer"
                />
              </div>
            )}
            <div className="flex items-center justify-end gap-4">
              <Button variant="danger" onClick={toggle}>
                <Text>button.cancel</Text>
              </Button>
              {isNew ? (
                <Button
                  type="submit"
                  disabled={!confirm}
                  loading={loading.post}
                >
                  <Text>button.nextAndCompleteOffer</Text>
                </Button>
              ) : (
                <Button type="submit" loading={loading.update}>
                  <Text>button.submitChanges</Text>
                </Button>
              )}
            </div>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      {isCompany && isOpen && (
        <SelectContactPerson
          isOpen={showSelectPerson}
          toggle={toggleSelectPerson}
          companyId={company.id}
          onSelect={(customerCompanyContactPersonId) =>
            setData((p) => ({ ...p, customerCompanyContactPersonId }))
          }
        />
      )}
    </Fragment>
  );
}
