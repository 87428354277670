import { useContext, useMemo } from "react";
import { OrderDetailsContext } from ".";
import { Drawer, Icon, IconButton, Text, Wrapper } from "../../../components";
import { useToggle } from "../../../hooks";
import { togglePropsType } from "../../../types";

export default function OrderNotesDrawer({ isOpen, toggle }: togglePropsType) {
  const { order } = useContext(OrderDetailsContext);
  const [showOrderNote, toggleOrderNote] = useToggle(false);
  const orderNote = useMemo(() => {
    if (showOrderNote) return order.orderNote;
    const result = order.orderNote
      ?.split(" ")
      .map((e) => "*".repeat(e.length))
      .join(" ")
      .slice(0, 100);
    return result;
  }, [showOrderNote, order.orderNote]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="bg-[#F5F7F9]">
        <Drawer.Header>
          <h6 className="text-start text-dark text-base font-medium">
            <Text>pickup.orderNote.title</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Wrapper>
            <Wrapper.Body>
              <section className="flex items-center flex-wrap gap-2">
                <Icon.Wrapper>
                  <Icon name="ReceiptText" variant="Bold" />
                </Icon.Wrapper>
                <div className="lg:flex-1">
                  <h1 className="text-base text-dark">{order?.title}</h1>
                  <p className="text-sm text-secondary">#{order?.number}</p>
                  <p className="text-sm text-secondary">
                    <Icon
                      name="Calendar"
                      variant="Bulk"
                      className="text-primary"
                    />{" "}
                    <Text>pickup.orderNote.deliveryDate</Text>:{" "}
                    <span className="text-dark">
                      <Text
                        kw={
                          order.desiredDeliveryCalendarWeek
                            ?.calendarWeekNumber ?? "--"
                        }
                      >
                        pickup.orderNote.deliveryDateValue
                      </Text>
                    </span>
                  </p>
                </div>
              </section>
            </Wrapper.Body>
          </Wrapper>
          <Wrapper>
            <Wrapper.Header className="border-b-0 flex items-center gap-4">
              <h6 className="flex-1 text-sm text-dark font-medium">
                <Text>pickup.orderNote.orderNote</Text>
              </h6>
              <IconButton
                type="button"
                className="bg-transparent"
                light
                onClick={toggleOrderNote}
              >
                <Icon
                  name={showOrderNote ? "Eye" : "EyeSlash"}
                  variant="Bold"
                />
              </IconButton>
            </Wrapper.Header>
            <Wrapper.Body>
              <p className="text-sm text-gray-900 font-medium">
                {orderNote || "--"}
              </p>
            </Wrapper.Body>
          </Wrapper>
          <Wrapper>
            <Wrapper.Header className="border-b-0">
              <h6 className="text-sm text-dark font-medium">
                <Text>pickup.orderNote.customerNote</Text>
              </h6>
            </Wrapper.Header>
            <Wrapper.Body>
              <p className="text-sm text-gray-900 font-medium">
                {order.customerNote || "--"}
              </p>
            </Wrapper.Body>
          </Wrapper>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
