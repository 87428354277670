import {
  ComponentProps,
  FormEvent,
  ForwardedRef,
  createContext,
  forwardRef,
  useRef,
} from "react";
import toast from "react-hot-toast";
type FormProps = ComponentProps<"form"> & { withoutChecker?: boolean };
type Element = HTMLElement;
type Callback = () => boolean;
type FormControl = {
  element: Element;
  callback: Callback;
};
type FormControls = {
  [key: string]: FormControl;
};
type FormContextType = {
  setFormControl?: (key: string, callback: FormControl) => void;
  removeFormControl?: (key: string) => void;
};
export const FormContext = createContext({} as FormContextType);
function Form(
  { withoutChecker = false, ...props }: FormProps,
  ref: ForwardedRef<HTMLFormElement>
) {
  const formControlsRef = useRef<FormControls>({});
  const setFormControl = (key: string, formControl: FormControl) => {
    formControlsRef.current[key] = formControl;
  };
  const removeFormControl = (key: string) => {
    delete formControlsRef.current[key];
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (withoutChecker) return props.onSubmit?.(e);
    const formControls = Object.values(formControlsRef.current);
    const result = formControls.map((e) => ({
      element: e.element,
      isValid: e.callback(),
    }));
    const canSubmit = result.map((e) => e.isValid).every(Boolean);
    if (canSubmit) return props.onSubmit?.(e);
    const errorElement = result.find((e) => !e.isValid)?.element;
    errorElement?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    props.onError?.(e);
    toast.error("errorCodes.form");
  };
  return (
    <form ref={ref} {...props} onSubmit={handleSubmit}>
      <FormContext.Provider value={{ setFormControl, removeFormControl }}>
        {props.children}
      </FormContext.Provider>
    </form>
  );
}
export default forwardRef<HTMLFormElement, FormProps>(Form);
