import { useEffect, useState } from "react";
import { Avatar, Button, Drawer, List, SearchBox, Text } from "../components";
import { useAxios } from "../hooks";
import { getRandomColor } from "../methods";
import { Company, togglePropsType } from "../types";

type SelectContactPersonProps = togglePropsType & {
  onSelect: (id: string) => void;
  companyId: string;
};

export default function SelectContactPerson({
  isOpen,
  toggle,
  onSelect,
  companyId,
}: SelectContactPersonProps) {
  const { axios, loading } = useAxios();
  const [data, setData] = useState<Company.Person[]>([]);
  const [keyword, setKeyword] = useState("");
  const contact5Persons = data.filter((e) =>
    [e.firstName, e.lastName].join(" ").includes(keyword)
  );
  const handleSelect = (e: Company.Person) => {
    return () => {
      onSelect(e.id);
      toggle();
    };
  };
  const getData = () => {
    if (!isOpen) return;
    const url = `/accountservice/api/customers/${companyId}/as-company/company-contact-persons`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [isOpen, companyId]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
      <Drawer.Menu>
        <Drawer.Header className="border-b-0">
          <h6 className="text-base text-dark">
            <Text>company.details.offer.form.selectContactPersonTitle</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox
            placeholder="Search"
            variant="gray"
            onSubmit={setKeyword}
          />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {contact5Persons.map((e, i) => (
              <List.Item key={e.id} onClick={handleSelect(e)}>
                <div className="flex items-center gap-3 w-fit">
                  <Avatar color={getRandomColor(i)} />
                  <div>
                    <h6 className="text-base text-dark mb-1">
                      {[e.firstName, e.lastName].join(" ")}
                    </h6>
                    {/* <p className="block text-secondary">{e.cimNumber || "-"}</p> */}
                  </div>
                </div>
                <p className="flex-1 truncate">{e.emailAddress}</p>
                <Button as="span" light>
                  <Text>company.details.offer.form.chooseContactPersonBtn</Text>
                </Button>
              </List.Item>
            ))}
          </List>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
