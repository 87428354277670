import { Fragment } from "react";
import { Icon, Image, Text } from "../components";
import { useDefaultSaleChannel, useSelector, useToggle } from "../hooks";
// import logo from "../assets/logos/logo.svg";
import { useDispatch } from "react-redux";
import { twMerge } from "tailwind-merge";
import logoText from "../assets/logos/logo text.svg";
import WithPermission from "../components/_WithPermission";
import { config } from "../constants";
import { toggleSelectSaleChannelModal } from "../redux/selectSaleChannelActions";
import MenuItem from "./MenuItem";
import ProfileModal from "./ProfileModal";
import SelectSaleChannelMenu from "./SelectSaleChannelMenu";
import SelectSaleChannelModal from "./SelectSaleChannelModal";
import SettingsMenu from "./SettingsMenu";

type Props = {
  isOpen: boolean;
  isOpenXl: boolean;
  toggle: () => void;
  toggleXl: () => void;
};

export default function Menu({ isOpen, isOpenXl, toggle, toggleXl }: Props) {
  const dispatch = useDispatch();
  const profile = useSelector((s) => s.profile);
  const saleChannel = useDefaultSaleChannel();
  const profileThumbnail = profile?.avatar?.thumbnailUrl;
  const [showProfile, toggleProfile] = useToggle(false);
  const [showSettings, toggleSettings] = useToggle(false);
  const toggles = [
    {
      classes: twMerge("block xl:hidden rotate-0", isOpen && "rotate-180"),
      action: toggle,
    },
    {
      classes: twMerge("hidden xl:block rotate-0", isOpenXl && "rotate-180"),
      action: toggleXl,
    },
  ];
  const toggleSelectSaleChannel = () => {
    dispatch(toggleSelectSaleChannelModal());
  };
  return (
    <Fragment>
      <div
        className={twMerge(
          "menu group fixed flex flex-col top-0 left-0 h-full bg-white p-4 pb-2 border-r border-gray-200 shadow transition-[width] z-20",
          isOpen ? "w-56" : "w-20",
          isOpenXl ? "xl:w-56" : "xl:w-20"
        )}
      >
        {toggles.map((e) => (
          <button
            key={e.classes}
            onClick={e.action}
            className={`flex flex-center absolute rounded bg-[#EEF6FF] -right-[1.125rem] top-[0.875rem] overflow-hidden size-9 transition-transform ${e.classes}`}
          >
            <Icon
              name="Login"
              variant="Bold"
              size={24}
              className="text-primary"
            />
          </button>
        ))}
        <div className="flex items-center h-8 mb-10 w-full overflow-hidden">
          <img
            className={twMerge(
              "min-w-[10rem] w-40 h-6 max-w-none rounded-sm object-contain",
              isOpen ? "block" : "hidden",
              isOpenXl ? "xl:block" : "xl:hidden"
            )}
            src={logoText}
            alt="mobelhof"
          />
          {/* <img
            className="block group-[.menu.active]:hidden min-w-[2rem] w-8 h-8 rounded-sm object-contain"
            src={logo}
            alt="mobelhof"
          /> */}
        </div>
        <div className="space-y-2">
          <h6 className="block text-sm uppercase truncate">
            <Text>menu.titles.customers</Text>
          </h6>
          <WithPermission
            permissions={["AS_CustomerFullAccess", "AS_GetCustomer"]}
          >
            <MenuItem
              label="menu.items.customers"
              icon="People"
              to="/customers"
              isOpen={isOpen}
              isOpenXl={isOpenXl}
            />
          </WithPermission>
          <WithPermission
            permissions={[
              "SC_SaleServiceOfferFullAccess",
              "SC_GetSaleServiceOffer",
            ]}
          >
            <MenuItem
              label="menu.items.offers"
              icon="DiscountShape"
              to="/offers"
              isOpen={isOpen}
              isOpenXl={isOpenXl}
            />
          </WithPermission>
          <WithPermission permissions={["SC_GetSaleServiceOrder"]}>
            <MenuItem
              label="menu.items.orders"
              icon="ReceiptText"
              to="/orders"
              isOpen={isOpen}
              isOpenXl={isOpenXl}
            />
          </WithPermission>
          <WithPermission
            permissions={["PS_PimProductFullAccess", "PS_GetPimProduct"]}
          >
            <MenuItem
              label="menu.items.products"
              icon="BoxSearch"
              to="/products"
              isOpen={isOpen}
              isOpenXl={isOpenXl}
            />
          </WithPermission>
          <MenuItem
            label="menu.items.sellOffProducts"
            icon="BoxTime"
            to="/sell-off-products"
            isOpen={isOpen}
            isOpenXl={isOpenXl}
          />
          {/* <MenuItem
            label="menu.items.calendar"
            icon="CalendarTick"
            to="/calendar"
            isOpen={isOpen}
            isOpenXl={isOpenXl}
          /> */}
          <WithPermission
            permissions={["CMS_IncidentFullAccess", "CMS_GetIncident"]}
          >
            <MenuItem
              label="menu.items.incidents"
              icon="DocumentText"
              to="/incidents"
              isOpen={isOpen}
              isOpenXl={isOpenXl}
            />
          </WithPermission>
          <WithPermission
            permissions={["PS_SupplierFullAccess", "PS_GetSupplier"]}
          >
            <MenuItem
              label="menu.items.suppliers"
              icon="Building"
              to="/suppliers"
              isOpen={isOpen}
              isOpenXl={isOpenXl}
            />
          </WithPermission>
          <MenuItem
            label="menu.items.tasks"
            icon="TaskSquare"
            to="/tasks"
            isOpen={isOpen}
            isOpenXl={isOpenXl}
          />
          <MenuItem
            label="menu.items.pickup"
            icon="TruckTick"
            to="/pickup"
            isOpen={isOpen}
            isOpenXl={isOpenXl}
          />
        </div>
        <hr className="block bg-gray my-4" />
        <div className="space-y-2">
          <h6 className="block text-sm uppercase truncate">
            <Text>menu.titles.account</Text>
          </h6>
          {/* <MenuItem
            label="menu.items.notifications"
            icon="Notification"
            to="/notifications"
            isOpen={isOpen}
            isOpenXl={isOpenXl}
          /> */}
          <MenuItem
            label="menu.items.settings"
            icon="Setting2"
            onClick={toggleSettings}
            isOpen={isOpen}
            isOpenXl={isOpenXl}
          />
        </div>

        <button
          type="button"
          className="menu-item group flex items-center w-full gap-1 text-start"
          onClick={toggleSelectSaleChannel}
        >
          <i className="w-11 h-11 flex-center">
            <Icon
              name="DirectboxDefault"
              variant="Bold"
              className="text-danger"
            />
          </i>
          <span
            className={twMerge(
              "flex-1 truncate",
              isOpen ? "block" : "hidden",
              isOpenXl ? "xl:block" : "xl:hidden"
            )}
          >
            {saleChannel?.title || <Text>global.selectChannel</Text>}
          </span>
        </button>
        <button
          type="button"
          className="mt-auto flex items-center gap-1 py-1.5"
          onClick={toggleProfile}
        >
          {!!profileThumbnail ? (
            <Image
              className="size-8 bg-secondary/10 rounded-full shadow object-cover"
              src={profileThumbnail}
              alt={profile.emailAddress}
              isDynamic
            />
          ) : (
            <Icon.Wrapper variant="secondary" className="!p-1">
              <Icon name="User" variant="Bold" className="text-primary" />
            </Icon.Wrapper>
          )}
          <div
            className={twMerge(
              "flex-1 text-start",
              isOpen ? "block" : "hidden",
              isOpenXl ? "xl:block" : "xl:hidden"
            )}
          >
            <span className="block text-sm truncate">
              {profile.firstName} {profile.lastName}
            </span>
            <span className="block text-sm text-secondary truncate">
              {profile.emailAddress}
            </span>
          </div>
          <Icon name="ArrowDown2" size={10} />
        </button>
        <p className="w-full px-2 py-1.5 text-center text-xs text-secondary border-t">
          {`Version ${config.version}`}
        </p>
      </div>
      <ProfileModal isOpen={showProfile} toggle={toggleProfile} />
      <SettingsMenu isOpen={showSettings} toggle={toggleSettings} />
      <SelectSaleChannelModal />
      <SelectSaleChannelMenu />
    </Fragment>
  );
}
