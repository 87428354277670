const commissions = {
  createNewBtn: "Create new Commission group",
  delivaryDate: "Delivery Date",
  title: "Title (Optional)",
  assignedOrders: "Assigned orders",
  assign: "Assigne orders",
  assignCustomerOrders: "assign Customer Orders",
  createCommissionGroup: "Create commission group",
  chooseOrder: "Choose order",
  updateCommissionSuccessfuly: "update Commission Successfuly",
  choose: "Choose",
  details: "Commission Group Details",
  edit: "Edit Commission Group",
  update: "Update Commission Group DE",
  addedToCommissionGroupWarning: "This Order has already been added to commission group",
  orderStatusInProgress: "Order status is In progress",
  orderStatusSentOut: "Order status is Sent out",
  orderStatusInvoiced: "Order status is Invoiced",
  orderStatusOrdered: "Order status is Ordered",
  commissionGroupDeliveryWarning: "This Order is not available on commission delivery date",
  notPublishWarning:"Order is not published yet",
};

export default commissions;
