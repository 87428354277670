import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
} from "../../../components";
import { status } from "../../../constants";
import { useTranslate } from "../../../hooks";
import { SellOffProduct, togglePropsType } from "../../../types";

type FilterProps = togglePropsType & {
  result: SellOffProduct.WithAzureSearch | null;
  params: SellOffProduct.SearchParams;
  handleSetParams: Dispatch<SetStateAction<SellOffProduct.SearchParams>>;
  loading: boolean;
  onReset: () => void;
};

export default function Filter({
  isOpen,
  toggle,
  result,
  params: initParams,
  handleSetParams: submit,
  loading,
  onReset,
}: FilterProps) {
  const translate = useTranslate();
  const [params, setParams] = useState(initParams);
  const handleSetParams = (
    key: keyof SellOffProduct.SearchParams,
    variant: "dropdown" | "input-group"
  ) => {
    const isDropdown = variant === "dropdown";
    return (value: any) => {
      const handler = (p: SellOffProduct.SearchParams) => ({
        ...p,
        pageNumber: 1,
        [key]: value,
      });
      isDropdown && submit(handler);
      setParams(handler);
    };
  };
  const handleReset = () => {
    onReset();
    // toggle();
  };
  const handleSubmit = () => {
    submit(params);
    toggle();
  };
  useEffect(() => {
    if (isOpen) setParams(initParams);
  }, [isOpen, initParams]);
  return (
    <Drawer as={Form} isOpen={isOpen} toggle={toggle} onSubmit={handleSubmit}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="text-dark text-base text-start flex-1">
            <Text>sellOffProducts.filterDrawer.title</Text>
          </h6>
          <button
            type="button"
            className="text-warning text-sm self-center"
            onClick={handleReset}
          >
            <Icon
              name="ArrowForwardSquare"
              variant="Bold"
              size={25}
              className="text-warning"
            />{" "}
            <Text>sellOffProducts.filterDrawer.resetButton</Text>
          </button>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Select
            label="sellOffProducts.filterDrawer.productFamily"
            value={params.pimProductFamilyCode ?? null}
            setValue={handleSetParams("pimProductFamilyCode", "dropdown")}
            items={result?.productFamily?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            loading={loading}
            searchable
            all
          />
          <Select
            label="sellOffProducts.filterDrawer.brand"
            value={params.brandCode ?? null}
            setValue={handleSetParams("brandCode", "dropdown")}
            items={result?.brand?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            loading={loading}
            searchable
            all
          />
          <Select
            label="sellOffProducts.filterDrawer.supplier"
            value={params.supplierCode ?? null}
            setValue={handleSetParams("supplierCode", "dropdown")}
            items={result?.supplier?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            loading={loading}
            searchable
            all
          />
          <Select
            label="sellOffProducts.filterDrawer.status"
            value={params.status ?? null}
            setValue={handleSetParams("status", "dropdown")}
            items={result?.status?.map((e) => ({
              name: [
                translate(
                  status.sellOffProduct.find(
                    (status) => `${status.id}` === `${e.title}`
                  )?.name ?? e.title
                ),
                `(${e.count})`,
              ].join(" "),
              id: e.value,
            }))}
            loading={loading}
            searchable
            all
          />
          {/* <Select
            label="sellOffProducts.filterDrawer.location"
            value={params.location ?? null}
            setValue={handleSetParams("location",'dropdown')}
            items={result?.location?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            loading={loading}
            searchable
            all
          /> */}
          <div className="grid grid-cols-2 items-center gap-2">
            <label className="text-base block w-full mb-2 truncate col-span-full">
              <Text>sellOffProducts.filterDrawer.startedDate</Text>
            </label>
            <InputGroup
              value={params.startedDateFrom}
              setValue={handleSetParams("startedDateFrom", "input-group")}
              placeholder="sellOffProducts.filterDrawer.from"
              type="date"
              maxDate={params.startedDateTo}
              //   rules={isEmptyValue(params.priceFrom)?[]:[rules.]}
            />
            <InputGroup
              value={params.startedDateTo}
              setValue={handleSetParams("startedDateTo", "input-group")}
              placeholder="sellOffProducts.filterDrawer.to"
              type="date"
              minDate={params.startedDateFrom}
              //   rules={isEmptyValue(params.priceTo)?[]:[rules.]}
            />
          </div>
          <div className="grid grid-cols-2 items-center gap-2">
            <label className="text-base block w-full mb-2 truncate col-span-full">
              <Text>sellOffProducts.filterDrawer.phaseChangedDate</Text>
            </label>
            <InputGroup
              value={params.phaseChangedFrom}
              setValue={handleSetParams("phaseChangedFrom", "input-group")}
              placeholder="sellOffProducts.filterDrawer.from"
              type="date"
              maxDate={params.phaseChangedTo}
              //   rules={isEmptyValue(params.priceFrom)?[]:[rules.]}
            />
            <InputGroup
              value={params.phaseChangedTo}
              setValue={handleSetParams("phaseChangedTo", "input-group")}
              placeholder="sellOffProducts.filterDrawer.to"
              type="date"
              minDate={params.phaseChangedFrom}
              //   rules={isEmptyValue(params.priceTo)?[]:[rules.]}
            />
          </div>
          <div className="grid grid-cols-2 items-center gap-2">
            <label className="text-base block w-full mb-2 truncate col-span-full">
              <Text>sellOffProducts.filterDrawer.soldDate</Text>
            </label>
            <InputGroup
              value={params.soldDateFrom}
              setValue={handleSetParams("soldDateFrom", "input-group")}
              placeholder="sellOffProducts.filterDrawer.from"
              type="date"
              maxDate={params.soldDateTo}
              //   rules={isEmptyValue(params.priceFrom)?[]:[rules.]}
            />
            <InputGroup
              value={params.soldDateTo}
              setValue={handleSetParams("soldDateTo", "input-group")}
              placeholder="sellOffProducts.filterDrawer.to"
              type="date"
              minDate={params.soldDateFrom}
              //   rules={isEmptyValue(params.priceTo)?[]:[rules.]}
            />
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button type="button" variant="danger" onClick={toggle}>
            <Text>sellOffProducts.filterDrawer.cancelButton</Text>
          </Button>
          <Button type="submit" variant="primary">
            <Text>sellOffProducts.filterDrawer.submitButton</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
