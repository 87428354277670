import { Link } from "react-router-dom";
import { Button, Icon, Modal, Text } from "../../../components";
import { iconNameType, togglePropsType } from "../../../types";
type SelectAccountTypeProps = togglePropsType & {};
export default function SelectAccountType({
  isOpen,
  toggle,
}: SelectAccountTypeProps) {
  const actions = [
    {
      label: "company.selectAccountType.companyLabel",
      description: "company.selectAccountType.companyDescription",
      icon: "User" as iconNameType,
      to: "new",
    },
    {
      label: "company.selectAccountType.personLabel",
      description: "company.selectAccountType.personDescription",
      icon: "Home" as iconNameType,
      to: "/companies/new",
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="space-y-1 border-b-0">
        <h6 className="text-base text-dark">
          <Text>company.selectAccountType.title</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        {actions.map((e) => (
          <Link
            key={e.label}
            className="w-full flex flex-center gap-4 p-6 border border-[#DEE8F1] bg-[#FBFCFD] rounded"
            to={e.to}
          >
            <Icon
              name={e.icon}
              variant="Bulk"
              size={40}
              className="text-primary"
            />
            <div className="text-start flex-1 space-y-1">
              <h6>
                <Text>{e.label}</Text>
              </h6>
              <p className="text-secondary">
                <Text>{e.description}</Text>
              </p>
            </div>
          </Link>
        ))}
      </Modal.Body>
      <Modal.Footer className="flex-center border-t-0">
        <Button type="button" variant="primary" light onClick={toggle}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
