import { cloneDeep } from "lodash";
import { useContext, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { OfferDetailsContext } from "..";
import { Button, Form, InputGroup, Modal, Text } from "../../../../components";
import { rules } from "../../../../constants";
import { useAxios, useConverters } from "../../../../hooks";
import { rulesType, togglePropsType } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";
export default function DownPaymentModal({ isOpen, toggle }: togglePropsType) {
  const { axios, loading } = useAxios();
  const { convertAmount } = useConverters();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const setOffer = offerContext?.setOffer;
  const order = orderContext?.order;
  const setOrder = orderContext?.setOrder;

  const isOffer = !!offer;

  const downPaymentAmount =
    offer?.downPaymentAmount || order?.downPaymentAmount;
  const totalAmount = (offer?.totalAmount || order?.totalAmount) ?? 0;
  const downPaymentDueDate =
    offer?.downPaymentDueDate || order?.downPaymentDueDate;
  const downPaymentReason =
    offer?.downPaymentReason || order?.downPaymentReason;
  const defaultDownPaymentAmount = Math.ceil((100 * totalAmount) / 2) / 100;

  const initDownPayment = downPaymentAmount || defaultDownPaymentAmount || "";
  const initDueDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 14);
    return downPaymentDueDate || date.toISOString();
  }, [downPaymentDueDate]);
  const initReason = downPaymentReason ?? "";

  const [downPayment, setDownPayment] = useState(initDownPayment.toString());
  const [dueDate, setDueDate] = useState(initDueDate ?? "");
  const [reason, setReason] = useState(initReason ?? "");
  const downPaymentIsValid =
    Number(downPayment || 0) >= defaultDownPaymentAmount;
  const range = { min: 0, max: Number(totalAmount) };
  const downPaymentRule: rulesType = [
    ...rules.required,
    (val) => (+val >= range.min && +val <= range.max) || "rules.minmax",
    // (val) => {
    //   const min = Number(totalAmount) / 2;
    //   const max = Number(totalAmount);
    //   return (
    //     (+val >= min && +val <= max) ||
    //     `value should be between ${convertAmount(min)} and ${convertAmount(
    //       max
    //     )}`
    //   );
    // },
  ];
  const submit = () => {
    const url = [
      "/salesservice",
      "api",
      isOffer ? "offer" : "orders",
      offer?.id || order?.id,
      "down-payment",
    ].join("/");
    const body = {
      downPayment: Number(downPayment),
      downPaymentDueDate: dueDate || null,
      downPaymentReason: reason,
    };
    axios.post(url, body).then(() => {
      toast.success("toast.success.setDownPayment");
      setOffer?.((p) => {
        const data = cloneDeep(p)!;
        data.downPaymentAmount = body.downPayment;
        data.downPaymentDueDate = body.downPaymentDueDate;
        data.downPaymentReason = body.downPaymentReason;
        return data;
      });
      setOrder?.((p) => {
        const data = cloneDeep(p)!;
        data.downPaymentAmount = body.downPayment;
        data.downPaymentDueDate = body.downPaymentDueDate ?? "";
        data.downPaymentReason = body.downPaymentReason ?? "";
        return data;
      });
      toggle();
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={submit}>
        <Modal.Header>
          <h6 className="text-center">
            <Text>modalTitles.downPayment</Text>
          </h6>
        </Modal.Header>
        <Modal.Body className="space-y-6">
          <InputGroup
            label="formControls.downPayment"
            value={downPayment}
            setValue={setDownPayment}
            type="price"
            rules={downPaymentRule}
            helperTextProps={{
              min: convertAmount(range.min) ?? "",
              max: convertAmount(range.max) ?? "",
            }}
          />
          <InputGroup
            label="formControls.downPaymentDueDate"
            value={dueDate}
            setValue={setDueDate}
            type="date"
          />
          <InputGroup
            as="textarea"
            label="formControls.downPaymentReason"
            value={reason}
            setValue={setReason}
            rules={downPaymentIsValid ? undefined : rules.required}
          />
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" loading={loading.post}>
            <Text>button.submit</Text>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
