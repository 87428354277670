import { ComponentProps, ElementType } from "react";
import { twMerge } from "tailwind-merge";
import { asProps } from "../types";

function Wrapper<E extends ElementType = "div">({
  as,
  className = "",
  children,
  ...props
}: asProps<E> & Omit<ComponentProps<E>, "as">) {
  const Component = as || "div";
  return (
    <Component
      className={twMerge("w-full bg-white rounded shadow px-4", className)}
      {...props}
    >
      {children}
    </Component>
  );
}
function WrapperHeader({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div
      className={twMerge("w-full border-b border-gray py-4", className)}
      {...props}
    >
      {children}
    </div>
  );
}
function WrapperBody({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div className={twMerge("w-full py-4", className)} {...props}>
      {children}
    </div>
  );
}
function WrapperFooter({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div
      className={twMerge("w-full border-t border-gray py-4", className)}
      {...props}
    >
      {children}
    </div>
  );
}
Wrapper.Header = WrapperHeader;
Wrapper.Body = WrapperBody;
Wrapper.Footer = WrapperFooter;
export default Wrapper;
