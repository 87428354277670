import { useContext, useEffect, useMemo, useState } from "react";
import { ProductDetailsContext } from ".";
import { Drawer, Icon, List, SearchBox, Text } from "../../../components";
import { config } from "../../../constants";
import { useAxios, useConverters, useDefaultSaleChannel } from "../../../hooks";
import { Product, togglePropsType } from "../../../types";

type Props = togglePropsType;
type Data = Product.PricePerChannelItem;

export default function PricePerChannelMenu({ isOpen, toggle }: Props) {
  const { convertAmount } = useConverters();
  const saleChannel = useDefaultSaleChannel();
  const { product } = useContext(ProductDetailsContext);
  const { axios, loading } = useAxios();
  const [data, setData] = useState<Data[]>([]);
  const [keyword, setKeyword] = useState<string | null>(null);
  const filteredData = useMemo(() => {
    if (!keyword) return data;
    return data.filter((e) =>
      [
        e.channelCode,
        e.channelIlnNumber,
        e.channelTitle,
        e.vk1Price?.amount,
        e.vk2Price?.amount,
      ]
        .filter(Boolean)
        .map(String)
        .some((e) => e.toLowerCase().includes(keyword.toLowerCase()))
    );
  }, [data, keyword]);
  const items = [
    {
      label: "products.details.pricePerChannel.myChannel",
      loadingLength: 1,
      items: filteredData.filter((e) => e.channelCode === saleChannel?.code),
    },
    {
      label: "products.details.pricePerChannel.otherChannels",
      loadingLength: config.pageSize,
      items: filteredData.filter((e) => e.channelCode !== saleChannel?.code),
    },
  ];
  const getData = () => {
    if (!isOpen) return;
    const url = `/salesservice/api/salestoolspimproduct/${product.id}/price-per-channel`;
    const config = { params: { channelCode: null } };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [isOpen, product.id]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base text-dark font-medium">
            <Text>products.details.pricePerChannel.title</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox
            onSubmit={setKeyword}
            variant="gray"
            searchType="immediately"
          />
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          {items.map((e, i) => {
            const hasItems = !!e.items.length || loading.get;
            const isMyChannel = i === 0;
            if (!hasItems) return null;
            return (
              <section key={e.label} className="space-y-1">
                <h6 className="text-base text-dark font-medium">
                  <Text>{e.label}</Text>:
                </h6>
                <List
                  loading={loading.get}
                  loadingLength={e.loadingLength}
                  className="divide-y divide-dashed"
                >
                  {e.items.map((e) => {
                    const hasVk2 =
                      !!e?.vk2Price &&
                      e?.vk1Price?.amount !== e?.vk2Price?.amount;
                    const key = [e.channelCode, e.channelTitle, e.id].join("");
                    const hasIln = !!e.channelIlnNumber;
                    return (
                      <List.Item
                        key={key}
                        className={
                          isMyChannel
                            ? "border !border-solid rounded p-2.5"
                            : undefined
                        }
                      >
                        <Icon.Wrapper rounded>
                          <Icon name="DirectboxNotif" variant="Bold" />
                        </Icon.Wrapper>
                        <div className="flex-1">
                          <h6 className="block text-base text-dark font-medium truncate">
                            {e.channelTitle}
                          </h6>
                          <p className="block text-secondary text-sm font-normal truncate">
                            #{e.channelCode}
                          </p>
                          {hasIln && (
                            <p className="block text-secondary text-sm font-normal truncate">
                              <Text number={e.channelIlnNumber}>
                                products.details.pricePerChannel.ilnNumber
                              </Text>
                            </p>
                          )}
                        </div>
                        <div>
                          <h6 className="block text-xs text-secondary font-medium">
                            <Text>
                              products.details.pricePerChannel.priceInChannel
                            </Text>
                          </h6>
                          {hasVk2 && (
                            <p className="block text-secondary text-sm font-medium line-through">
                              {convertAmount(
                                e.uvpPrice?.amount ?? e.vk2Price?.amount
                              )}
                            </p>
                          )}
                          <p className="block text-dark text-sm font-medium">
                            {convertAmount(e.vk1Price?.amount)}
                          </p>
                        </div>
                      </List.Item>
                    );
                  })}
                </List>
              </section>
            );
          })}
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
