import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CountryItem } from "../types";
import useAxios from "./useAxios";
import useSelector from "./useSelector";

export default function useCountries(
  force: boolean = true
): [
  { name: string; id: string; countryIso2: string; countryIso3: string }[],
  boolean
] {
  const { i18n } = useTranslation();
  const { axios, loading } = useAxios();
  const profile = useSelector((s) => s.profile);
  const [countries, setCountries] = useState<CountryItem[]>([]);
  const countriesItems = countries.map((e) => ({
    id: e.countryIso2,
    name:
      e.translates.find((e) => e.language === i18n.language)?.labelTitle ??
      e.countryIso2,
    countryIso2: e.countryIso2,
    countryIso3: e.countryIso3,
  }));
  const getCountries = () => {
    if (!force) return;
    if (!profile.tenantId) return;
    const url = `/accountservice/api/countries/active_countries/${profile.tenantId}`;
    const config = { cache: true };
    axios.get(url, config).then(({ data }) => {
      setCountries(data);
    });
  };
  useEffect(getCountries, [force, profile.tenantId]);
  return [countriesItems, loading.get];
}
