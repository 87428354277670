import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { OfferDetailsContext } from "..";
import {
  Button,
  Drawer,
  Icon,
  List,
  SearchBox,
  Text,
} from "../../../../components";
import { DocumentTemplateType, SalesDocumentType } from "../../../../enums";
import { useAxios, useDefaultSaleChannel } from "../../../../hooks";
import { getExtensionFromMimeType } from "../../../../methods";
import { Document, togglePropsType } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";

type Params = {
  keyword?: null | string;
  hasFile?: boolean;
  signMandatory?: boolean;
  needSendToErp?: boolean;
  needSendToCrm?: boolean;
  documentTemplateType?: DocumentTemplateType;
};
export default function ChooseDocumentMenu({
  isOpen,
  toggle,
}: togglePropsType) {
  const navigate = useNavigate();
  const loadingIdRef = useRef<string | null>(null);
  const saleChannel = useDefaultSaleChannel();
  const { axios, loading } = useAxios();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;

  const isOffer = !!offer;
  const isOrder = !!order;

  const [data, setData] = useState<Document.Template[]>([]);
  const [params, setParams] = useState<Params>({ hasFile: true });
  const handleSetParams = (key: keyof Params) => {
    return (value: any) => {
      setParams((p) => ({ ...p, [key]: value }));
    };
  };
  const getTemplates = () => {
    if (!isOpen) return;
    const url = `/salesservice/api/documenttemplates/channel`;
    const config = { params };
    config.params.documentTemplateType = isOffer
      ? DocumentTemplateType.Offer
      : DocumentTemplateType.Order;
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  const submitTemplate = (template: Document.Template) => {
    const url = [
      "/salesservice",
      "api",
      isOffer ? "offerdocuments" : "orderdocuments",
    ].join("/");
    const body: Document.Create = {
      offerId: offer?.id,
      orderId: order?.id,
      title: template.name,
      documentTemplateId: template.id,
      needSendToErp: template.needSendToErp,
      signMandatory: template.signMandatory,
      description: "",
      createdFromTemplate: true,
      documentFileInfo: null,
      isSigned: false,
      signUrl: null,
      [isOffer ? "offerLineItemId" : "orderLineItemId"]: null,
      salesDocumentType: SalesDocumentType.Main,
      tags: null,
    };
    loadingIdRef.current = template.id;
    axios.post(url, body).then(({ data: documentId }) => {
      isOffer && navigate(`/offers/${offer?.id}/documents/${documentId}`);
      isOrder &&
        navigate({
          pathname: `/orders/${order?.id}/documents/${documentId}`,
          search: `channelCode=${order.channelCode}`,
        });
    });
  };
  useEffect(getTemplates, [isOpen, params, saleChannel?.code]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="space-y-4">
          <h6 className="block">
            <Text>drawerTitles.documentTemplate</Text>
          </h6>
          <SearchBox variant="gray" onSubmit={handleSetParams("keyword")} />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {data
              .filter((e) => !!e.uploadedFile?.url)
              .map((e) => {
                const hasLoading =
                  loading.post && loadingIdRef.current === e.id;
                const disabled = loading.post;
                const type = e.uploadedFile?.type;
                const hasType = !!type;
                return (
                  <List.Item key={e.id}>
                    <Icon.Wrapper rounded>
                      <Icon name="DocumentText" variant="Bold" />
                    </Icon.Wrapper>
                    <div className="flex-1">
                      <h6 className="text-sm text-dark">{e.name}</h6>
                      {hasType && (
                        <p className="w-full text-xs truncate text-secondary uppercase">
                          {getExtensionFromMimeType(type)}
                        </p>
                      )}
                    </div>
                    <Button
                      light
                      onClick={() => submitTemplate(e)}
                      loading={hasLoading}
                      disabled={disabled}
                      className="text-sm"
                    >
                      <Text>button.choose</Text>
                    </Button>
                  </List.Item>
                );
              })}
          </List>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
