const customers = {
  addresses: {
    shippingAddresses: "Lieferadressen",
    billingAddresses: "Rechnungsadressen",
    mainAddress: "Hauptadresse",
    shippingAddress: "Lieferadresse",
    billingAddress: "Rechnungsadresse",
    noItem: {
      shipping: "Keine Lieferadresse hinzugefügt",
      billing: "Keine Rechnungsadresse hinzugefügt"
    }
  },
};
export default customers;

