import { Fragment, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { CustomerContext } from "..";
import {
  AddressViewer,
  Button,
  ConfirmModal,
  Icon,
  Loading,
  Text,
  Wrapper,
} from "../../../../components";
import { AddressType } from "../../../../enums";
import { useAxios, useToggle } from "../../../../hooks";
import { CustomerAddress } from "../../../../types/customer";
import { CompanyContext } from "../../../Companies/Details";
import AddressDrawer from "./AddressDrawer";

export default function Addresses() {
  const customerContext = useContext(CustomerContext);
  const companyContext = useContext(CompanyContext);
  const isCompany = !!companyContext.companyData;
  const id = isCompany
    ? companyContext.companyData.id
    : customerContext.customerData.id;
  const [showAddressDrawer, toggleAddressDrawer] = useToggle(false);
  const [showConfirmModal, toggleConfirmModal] = useToggle(false);
  const { axios, loading } = useAxios();
  const [shippingList, setShippingList] = useState([]);
  const [billingList, setBillingList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState<CustomerAddress>();
  const [selectedType, setSelectedType] = useState<number>(
    AddressType.Shipping
  );
  const AddressTypes = [
    {
      title: "customers.addresses.shippingAddresses",
      list: shippingList,
      type: AddressType.Shipping,
    },
    {
      title: "customers.addresses.billingAddresses",
      list: billingList,
      type: AddressType.Billing,
    },
  ];

  const handleOpenConfirmModal = (address: CustomerAddress) => {
    setSelectedAddress(address);
    toggleConfirmModal();
  };

  const handleDeleteAddress = () => {
    const url = `/accountservice/api/customers/${id}/address/${selectedAddress?.id}`;
    axios.delete(url).then(() => {
      toast.success("toast.success.removeCustomerAddress");
      getData();
    });
  };

  const handleOpenAddressDrawer = (
    type: number,
    address: CustomerAddress | undefined = undefined
  ) => {
    setSelectedAddress(address);
    setSelectedType(type);
    toggleAddressDrawer();
  };

  const getData = () => {
    const url = `/accountservice/api/customers/${id}/address`;
    axios.get(url).then(({ data }) => {
      setShippingList(
        data.filter(
          (item: CustomerAddress) => item.addressType === AddressType.Shipping
        )
      );
      setBillingList(
        data.filter(
          (item: CustomerAddress) => item.addressType === AddressType.Billing
        )
      );
    });
  };
  useEffect(getData, []);

  return (
    <section className="grid grid-cols-2 gap-4">
      {AddressTypes.map((item) => (
        <div key={item.type}>
          <Wrapper className=" self-start">
            <Wrapper.Header className="flex items-center">
              <h6>
                <Text>{item.title}</Text>
              </h6>
              <Button
                type="button"
                variant="primary"
                light
                className="ms-auto"
                onClick={() => handleOpenAddressDrawer(item.type)}
              >
                <Icon name="Add" />
              </Button>
            </Wrapper.Header>
            <Wrapper.Body className="space-y-4">
              {loading.get ? (
                <Loading.Address length={2} />
              ) : !item.list.length ? (
                <div className="w-full space-y-4 text-center p-4 text-2xl text-placeholder">
                  <p className="text-2xl">
                    <Text>global.noItems</Text>
                  </p>
                  <p className="text-base">
                    {item.type === AddressType.Shipping ? (
                      <Text>customers.addresses.noItem.shipping</Text>
                    ) : (
                      <Text>customers.addresses.noItem.billing</Text>
                    )}
                  </p>
                </div>
              ) : (
                <Fragment>
                  {item.list.map((address: CustomerAddress) => (
                    <div
                      key={address.id}
                      className="p-3 border border-gray rounded flex gap-1"
                    >
                      <Icon
                        name="LocationTick"
                        className="text-primary"
                        size={20}
                      />
                      <div className="space-y-2 flex-1">
                        <h6 className="text-sm">{address.title}</h6>
                        <div className="flex gap-2 flex-1">
                          <AddressViewer
                            className="text-sm text-secondary flex-1"
                            address={address.address}
                          />
                          <div className="flex gap-2 self-start items-center">
                            <Button
                              type="button"
                              variant="primary"
                              light
                              onClick={() =>
                                handleOpenAddressDrawer(item.type, address)
                              }
                            >
                              <Icon name="Edit2" />
                            </Button>
                            <Button
                              type="button"
                              variant="danger"
                              light
                              loading={
                                loading.delete &&
                                selectedAddress?.id === address.id
                              }
                              onClick={() => handleOpenConfirmModal(address)}
                            >
                              <Icon name="Trash" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Fragment>
              )}
            </Wrapper.Body>
          </Wrapper>
        </div>
      ))}
      <AddressDrawer
        isOpen={showAddressDrawer}
        toggle={toggleAddressDrawer}
        addressInfo={selectedAddress}
        type={selectedType}
        getData={getData}
        customerId={id}
      />
      <ConfirmModal
        isOpen={showConfirmModal}
        toggle={toggleConfirmModal}
        title="modalTitles.confirmDeleteAddress"
        text="global.confirmDeleteAddress"
        onConfirm={handleDeleteAddress}
      />
    </section>
  );
}
