import { useState } from "react";
import toast from "react-hot-toast";
import {
  Button,
  Drawer,
  Form,
  InputGroup,
  RadioButton,
  Text,
} from "../components";
import { status } from "../constants";
import { useAxios } from "../hooks";
import { Incident, togglePropsType } from "../types";

type IncidentChangeStatusProps = togglePropsType & {
  incident: Incident.Item | Incident.Details;
  onSubmitted: (id: string) => void;
};

export default function IncidentChangeStatus({
  isOpen,
  toggle,
  incident,
  onSubmitted,
}: IncidentChangeStatusProps) {
  const { axios, loading } = useAxios();
  const [data, setData] = useState({
    status: incident.statusReason,
    statusReason: "",
  });
  const submit = () => {
    const url = `/channelmanagerservice/api/incidents/${incident.crmIncidentId}/status`;
    const body = { ...data };
    axios.put(url, body).then(() => {
      toast.success("incident.changeStatus.successMessage");
      onSubmitted(incident.crmIncidentId);
      toggle();
    });
  };
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex-center flex-col text-center">
          <h6 className="text-base text-dark">
            <Text>incident.changeStatus.changeStatusTitle</Text>
          </h6>
          <p className="text-base text-dark">{incident.title}</p>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          {status.incident.map((e) => (
            <div key={e.name}>
              <h6 className="text-base text-dark">
                <Text>{e.name}</Text>:
              </h6>
              <div className="divide-y divide-gray-100">
                {status.incidentReason
                  .filter((item) => item.status === e.id)
                  .map((e) => (
                    <RadioButton
                      key={e.name}
                      label={e.name}
                      isActive={e.id === data.status}
                      onClick={() => setData((p) => ({ ...p, status: e.id }))}
                    />
                  ))}
              </div>
            </div>
          ))}
          <InputGroup
            as="textarea"
            label="incident.changeStatus.description"
            placeholder="incident.changeStatus.descriptionPlaceholder"
            value={data.statusReason}
            setValue={(statusReason) =>
              setData((p) => ({ ...p, statusReason }))
            }
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>incident.changeStatus.cancelBtn</Text>
          </Button>
          <Button type="submit" loading={loading.update}>
            <Text>incident.changeStatus.submitChangeBtn</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
