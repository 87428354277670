import { Link } from "react-router-dom";
import { Button, Image, Text } from "../../../components";
import { Supplier } from "../../../types";

type Props = { supplier: Supplier.SalesItem };

export default function SupplierItem({ supplier }: Props) {
  const hasCode = !!supplier.code;
  return (
    <Link
      to={supplier.supplierId}
      className="bg-white min-w-fit w-full flex items-center rounded shadow whitespace-nowrap gap-2.5 p-1.5 pe-5"
    >
      <Image
        src={supplier.logo?.thumbnailUrl}
        alt={supplier.name}
        className="min-w-36 h-20 w-36 rounded object-cover"
        isDynamic
      />
      <div className="min-w-fit flex-1 space-y-1">
        <h6 className="text-gray-800 text-sm font-medium">{supplier.name}</h6>
        {hasCode && (
          <p className="text-xs text-secondary font-medium">#{supplier.code}</p>
        )}
        {/* <p className="text-xs text-secondary font-medium">
          <AddressViewer address={supplier.address} />
          <Icon name="Building" className="size-4" /> Lindlbergstraße 26, 92331
          Parsberg
        </p> */}
        <p className="w-full text-xs text-secondary font-medium truncate">
          {supplier.description}
        </p>
      </div>
      <p className="min-w-fit flex-1 text-xs text-secondary font-medium text-center">
        <Text version={supplier.version}>suppliers.list.version</Text>
      </p>
      <Button as="span" variant="primary" light className="min-w-fit">
        <Text>suppliers.list.detailsButton</Text>
      </Button>
    </Link>
  );
}
