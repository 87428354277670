import { Fragment, useContext } from "react";
import { SupplierDetailsContext } from "..";
import { AddressViewer, Text, Types, Wrapper } from "../../../../components";

export default function GeneralInfo() {
  const { supplier } = useContext(SupplierDetailsContext);
  const generalItem = [
    { label: "suppliers.general.supplierName", value: supplier?.name },
    { label: "suppliers.general.code", value: "#" + supplier?.code },
    { label: "suppliers.general.email", value: supplier?.email },
    { label: "suppliers.general.iln", value: supplier?.iln },
    { label: "suppliers.general.phone", value: supplier?.phoneNumber },
    { label: "suppliers.general.taxId", value: supplier?.taxId },
    {
      label: "suppliers.general.supplierType",
      value: <Types.Supplier id={supplier?.supplierType} />,
    },
    { label: "suppliers.general.description", value: supplier?.description },
  ];
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Header className="border-b-0">
          <h2 className="text-dark text-sm font-medium">
            <Text>suppliers.general.title</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body>
          <ul className="w-full divide-y">
            {generalItem.map((e) => (
              <li
                key={e.label}
                className="flex items-start gap-5 py-3 first:pt-0 last:pb-0"
              >
                <h6 className="flex-1 text-secondary text-sm font-normal">
                  <Text>{e.label}</Text>
                </h6>
                <p className="flex-[2] text-gray-900 text-sm font-normal">
                  {e.value}
                </p>
              </li>
            ))}
          </ul>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header className="border-b-0">
          <h2 className="text-dark text-sm font-medium">
            <Text>suppliers.general.alternative</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body>
          <p className="flex items-center flex-wrap text-gray-900 text-sm font-normal gap-5">
            {supplier?.alternativeIln}
          </p>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header className="border-b-0">
          <h2 className="text-dark text-sm font-medium">
            <Text>suppliers.general.location</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body>
          <AddressViewer
            address={supplier?.address}
            className="text-start text-secondary font-medium"
          />
        </Wrapper.Body>
      </Wrapper>
    </Fragment>
  );
}
