const suppliers = {
  list: {
    searchBox: "Lieferanten gefunden...",
    title: "Lieferanten",
    version: "Aktuelle Version: {{ version }}",
    detailsButton: "Details",
  },
  details: {
    version: "Aktuelle Version: {{ version }}",
    generalInfo: "Allgemeine Informationen",
    bankInfo: "Bankdaten",
    program: "Programm",
    contact: "Kontakt",
    settings: "Einstellungen",
  },
  general: {
    title: "Allgemeine Informationen",
    supplierName: "Lieferantenname",
    code: "Code",
    email: "E-Mail-Adresse",
    iln: "ILN (Internationale Lokationsnummer)",
    phone: "Telefonnummer",
    taxId: "Steuernummer",
    supplierType: "Lieferantentyp",
    description: "Beschreibung",
    alternative: "Alternative ILN",
    location: "Standort",
  },
  bankInfo: {
    title: "Bankdaten",
    name: "Bankname",
    iban: "IBAN",
    ownerName: "Kontoinhabername",
    bic: "BIC",
  },
  contacts: {
    title: "Kontakte",
    department: "Abteilung {{ departmentName }}",
  },
  settings: {
    holydaysTitle: "Feiertage",
    holydayTitle: "Feiertagstitel",
    startDate: "Startdatum",
    endDate: "Enddatum",
    lastOrderDate: "Letzter Bestelltermin",
    earliestShipDate: "Frühester Versandtermin",

    shippingTitle: "Versand",
    defaultMinimumShippingTime: "Standard-Mindestversandzeit",
    defaultMinimumShippingTimeValue: "{{ value }} {{ unit }}",
    minimumResponseTime: "Minimale Antwortzeit",
    minimumResponseTimeValue: "{{ value }} Tage",
  },
  programs: {
    title: "Programme",
    searchBox: "Programm",
    version: "Aktuelle Version: {{ version }}",

    general: "Allgemeine Informationen",
    configuration: "Konfiguration",
    availability: "Verfügbarkeitsstatus",
  },
  programGeneral: {
    title: "Allgemeine Informationen",
    programTitle: "Titel",
    code: "Code",
    iwofurnCode: "Iwofurn-Code",
    zrn: "ZRN",
    brand: "Marke",
    productGroup: "Produktgruppe",
    description: "Beschreibung",
  },
  programConfiguration: {
    title: "Konfiguration",
    programType: "Programmart",
    useDefaultMinimumShippingTime: "Standard-Mindestversandzeit verwenden",
    minimumShippingTime: "Mindestversandzeit",
    useDefaultBankInfo: "Standard-Bankdaten verwenden",
    bankName: "Bankname",
    accountOwnerName: "Kontoinhabername",
    iban: "IBAN",
    bic: "BIC",
  },
  programAvailabilityStatus: {
    title: "Verfügbarkeitsstatus",
    isDefault: "(Standard)",
    statusDate: "Von {{ fromDate }} bis {{ toDate }}",
    addButton: "Hinzufügen",
    addTitle: "Verfügbarkeitsstatus hinzufügen",
    editTitle: "Verfügbarkeitsstatus bearbeiten",
    status: "Status",
    validationPeriod: "Gültigkeitszeitraum hinzufügen",
    fromDate: "Startdatum",
    toDate: "Enddatum",
    conflictErrorTitle: "Validation Error DE",
    conflictErrorText: "Date range has conflict DE",
    cancelButton: "Abbrechen",
    addStatusButton: "Hinzufügen",
    updateButton: "Aktualisieren",
    addMessage: "Status wurde erfolgreich erstellt.",
    updateMessage: "Status wurde erfolgreich aktualisiert.",
    removeStatusTitle: "Status entfernen",
    removeStatusText:
      "Dieser Vorgang kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie diese Anfrage stellen möchten?",
    cancelRemoveButton: "Abbrechen",
    removeStatusButton: "Status entfernen",
    removeSuccessMessage: "Verfügbarkeitsstatus wurde erfolgreich entfernt.",
  },
};
export default suppliers;
