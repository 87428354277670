const commissions = {
  createNewBtn: "Neue Verknüpfung erstellen",
  delivaryDate: "Lieferdatum",
  title: "Titel (optional)",
  assignedOrders: "Zugewiesene Aufträge",
  assign: "Aufträge zuweisen",
  assignCustomerOrders: "Kundenaufträge zuweisen",
  createCommissionGroup: "Verknüpfung erstellen",
  chooseOrder: "Auftrag auswählen",
  createCommissionSuccessfuly: "Verknüpfung erfolgreich erstellt",
  updateCommissionSuccessfuly: "Verknüpfung erfolgreich aktualisiert",
  choose: "Auswählen",
  details: "Details der Verknüpfung",
  edit: "Verknüpfung bearbeiten",
  update: "Verknüpfung aktualisieren",
  addedToCommissionGroupWarning: "Dieser Auftrag wurde bereits zur Verknüpfungsgruppe hinzugefügt",
  orderStatusInProgress: "Auftragsstatus ist in Bearbeitung",
  orderStatusSentOut: "Auftragsstatus ist versendet",
  orderStatusInvoiced: "Auftragsstatus ist Rechnungsstellung",
  orderStatusOrdered: "Auftragsstatus ist bestellt",
  commissionGroupDeliveryWarning: "Dieser Auftrag ist am Lieferdatum der Verknüpfung nicht verfügbar",
  notPublishWarning: "Auftrag ist noch nicht veröffentlicht"
};

export default commissions;


