import { useMemo } from "react";
import { dateFormats } from "../constants";
import { AttributeTemplateType } from "../enums";
import { useConverters, useTranslate } from "../hooks";
import { PimProduct } from "../types";
import Translate from "./_Translate";

type Props = {
  attribute: PimProduct.AttributeItem;
};

export default function AttributeValue({ attribute }: Props) {
  const translate = useTranslate();
  const { convertDate } = useConverters();
  const value = useMemo(() => {
    const isString = attribute.type === AttributeTemplateType.String;
    const isTextArea = attribute.type === AttributeTemplateType.TextArea;
    const isBool = attribute.type === AttributeTemplateType.Bool;
    const isList = attribute.type === AttributeTemplateType.List;
    const isDate = attribute.type === AttributeTemplateType.Date;
    const isTime = attribute.type === AttributeTemplateType.Time;
    const isDateTime = attribute.type === AttributeTemplateType.DateTime;
    const isMeasurement = attribute.type === AttributeTemplateType.Measurement;
    const isInteger = attribute.type === AttributeTemplateType.Integer;
    const isDecimal = attribute.type === AttributeTemplateType.Decimal;
    return attribute.pimProductAttribute?.pimProductAttributeValue?.map(
      (e) => ({
        language: e.language,
        labelTitle: [
          isString && e.value,
          isTextArea && e.value,
          isList && attribute.items?.find((item) => item.id === e.value)?.code,
          isDate && convertDate(e.value, dateFormats.dateOnly),
          isTime && convertDate(e.value, dateFormats.timeOnly),
          isDateTime && convertDate(e.value, dateFormats.fullDate),
          isBool &&
            translate(
              e.value.toString() === "true"
                ? "products.details.yesItem"
                : "products.details.noItem"
            ),
          isInteger && e.value,
          isDecimal && e.value,
          isMeasurement && e.value,
          isMeasurement && e.measurementFamilyUnitInfo?.symbol,
        ]
          .filter(Boolean)
          .join(" "),
        description: e.value,
      })
    );
  }, [attribute, convertDate, translate]);
  return <Translate value={value} defaultValue="--" />;
}
