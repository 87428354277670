import { Fragment, useContext, useEffect, useState } from "react";
import { OfferDetailsContext } from "../..";
import uploadIcon from "../../../../../assets/icons/document upload.svg";
import { Accordion, Loading, Text, Wrapper } from "../../../../../components";
import DocumentCard from "../../../../../components/_DocumentCard";
import { SalesDocumentType } from "../../../../../enums";
import { useAxios, useToggle } from "../../../../../hooks";
import { Document, LineItem } from "../../../../../types";
import { OrderDetailsContext } from "../../../../Orders/Details";
import AddDocumentDrawer from "../../AddDocumentDrawer";
import DocumentPreviewModal from "./DocumentPreviewModal";

type MediaProps = {
  lineItem: LineItem.Item;
  force: boolean;
  isEnabled?: boolean;
};

export default function Media({ lineItem, force, isEnabled }: MediaProps) {
  const { axios, loading } = useAxios();
  const [documents, setDocuments] = useState<Document.Item[]>([]);
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const [showAddDocumentDrawer, toggleAddDocumentDrawer] = useToggle(false);
  const [isOpenId, setIsOpenId] = useState("");

  const offer = offerContext?.offer;
  const order = orderContext?.order;

  const isOffer = !!offer;
  const isOrder = !!order;

  const clearIsOpenId = () => {
    setIsOpenId("");
  };
  const getDocuments = () => {
    if (!force) return;
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerdocuments",
      isOrder && "orderdocuments",
      offer?.id || order?.id,
    ]
      .filter(Boolean)
      .join("/");
    const config = {
      params: {
        salesDocumentType: SalesDocumentType.LineItem,
        [isOffer ? "offerLineItemId" : "orderLineItemId"]: lineItem.id,
      },
    };
    axios.get(url, config).then(({ data }) => {
      setDocuments(data);
    });
  };

  useEffect(getDocuments, [offer?.id, order?.id, lineItem.id, force]);
  return (
    <Wrapper>
      <Accordion.Item>
        <Accordion.Toggle className="flex-row-reverse">
          <h6 className="text-sm text-dark flex-1 text-start">
            <Text>global.media</Text>
          </h6>
        </Accordion.Toggle>
        <Accordion.Body>
          <div className="grid grid-cols-3 gap-4 w-full">
            {loading.get ? (
              <Loading.Document length={2} />
            ) : (
              documents.map((document) => (
                <Fragment key={document.id}>
                  <DocumentCard
                    document={document}
                    lineItemDocument
                    onClick={() => setIsOpenId(document.id)}
                    thumbnail
                  />
                  <DocumentPreviewModal
                    isOpen={document.id === isOpenId}
                    toggle={clearIsOpenId}
                    document={document}
                    getDocument={getDocuments}
                  />
                </Fragment>
              ))
            )}
            {isEnabled && (
              <button
                type="button"
                className="bg-primary/10 border border-dashed border-primary p-4 rounded text-center"
                onClick={toggleAddDocumentDrawer}
              >
                <img
                  className="block w-16 mx-auto mb-6"
                  src={uploadIcon}
                  alt="upload"
                />
                <h6 className="truncate block text-sm">
                  <Text>button.addDocument</Text>
                </h6>
              </button>
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <AddDocumentDrawer
        isOpen={showAddDocumentDrawer}
        toggle={toggleAddDocumentDrawer}
        lineItemId={lineItem.id}
        onSubmitted={getDocuments}
      />
    </Wrapper>
  );
}
