import { pimProductAttributeKey } from "../constants";
import { PimProduct } from "../types";

type Codes = keyof typeof pimProductAttributeKey;

export default function useFindAttribute() {
  const findAttribute = (
    attributeGroups: PimProduct.Attribute[] | null | undefined,
    codeKey: Codes
  ) => {
    if (!attributeGroups) return null;
    // const attributeGroup = attributeGroups.find(
    //   (e) => e.code === pimProductAttributeKey.group
    // );
    const attributes = attributeGroups.map((e) => e.attributes).flat();
    // const attribute = attributeGroup?.attributes?.find(
    //   (e) => e.code === pimProductAttributeKey[codeKey]
    // );
    const attribute = attributes?.find(
      (e) => e?.code === pimProductAttributeKey[codeKey]
    );
    const attributeValue =
      attribute?.pimProductAttribute?.pimProductAttributeValue?.map((e) => ({
        language: e.language || null,
        labelTitle: e.value || null,
        description: null,
      }));
    return attributeValue ?? null;
  };
  return findAttribute;
}
