type Input = string | number | Date;
export default function getWeekNumber(input: Input) {
  //   if (!input) return null;
  const date = new Date(input);
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // @ts-expect-error
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
}
