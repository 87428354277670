import { rulesType } from "../types";
const required: rulesType = [
  (val) => {
    const isNumber = typeof val === "number";
    const message = "rules.required";
    if (isNumber) {
      return !isNaN(val) || message;
    }
    return !!String(val ?? "").trim().length || message;
  },
];
// const required: rulesType = [
//   (val) => !!String(val ?? "").trim().length || "rules.required",
// ];
const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const email: rulesType = [
  ...required,
  (val) => emailRegex.test(val) || "rules.email",
];
const postalCode: rulesType = [
  ...required,
  (val) => String(val ?? "").length === 5 || "rules.postalCode",
];
const integer: rulesType = [
  ...required,
  (val: string) => +val === Math.floor(+val) || "rules.integer",
];
const phoneNumber: rulesType = [
  ...required,
  (val) => !String(val ?? "")?.startsWith("0") || "rules.phoneNumber",
];
const percent: rulesType = [
  ...required,
  (val) => (Number(val) > 0 && Number(val) <= 100) || "rules.percent",
];
const optionalPercent: rulesType = [
  (val) => !val || (Number(val) > 0 && Number(val) <= 100) || "rules.percent",
];
// const minPrice = (...min: number[]) =>
//   [
//     (val) => {
//       const isValid = Number(val) > Math.max(...min);
//       return isValid || "rules.min";
//     },
//   ] as rulesType;
// const maxPrice = (...max: number[]) =>
//   [
//     (val) => {
//       const isValid = Number(val) > Math.min(...max);
//       return isValid || "rules.max";
//     },
//   ] as rulesType;
const minmax = (min: number, max: number) =>
  [
    ...required,
    (val) => (Number(val) >= min && Number(val) <= max) || "rules.minmax",
  ] as rulesType;
const rules = {
  required,
  email,
  postalCode,
  integer,
  phoneNumber,
  percent,
  minmax,
  optionalPercent,
  // minPrice,
  // maxPrice,
};
export default rules;
