import { Fragment, useContext, useEffect, useState } from "react";
import { ImportBasketContext } from ".";
import {
  Button,
  Drawer,
  Image,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../../../../../components";
import { config } from "../../../../../constants";
import { SupplierType } from "../../../../../enums";
import { useAxios } from "../../../../../hooks";
import { Supplier, WithPaging } from "../../../../../types";

export default function Suppliers() {
  const { axios, loading } = useAxios();
  const { setSupplier } = useContext(ImportBasketContext);
  const [suppliers, setSuppliers] = useState<WithPaging<Supplier.Item>>({});
  const [params, setParams] = useState<Supplier.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    supplierType: SupplierType.TradingGoods,
  });
  const handleChoose = (supplier: Supplier.Item) => {
    return () => {
      setSupplier(supplier);
    };
  };
  const getSuppliers = () => {
    const url = "/salesservice/api/suppliers";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setSuppliers(data);
    });
  };
  useEffect(getSuppliers, [params]);
  return (
    <Fragment>
      <Drawer.Header className="border-b-0">
        <SearchBox
          variant="gray"
          onSubmit={(keyword) =>
            setParams((p) => ({ ...p, pageNumber: 1, keyword }))
          }
        />
      </Drawer.Header>
      <Drawer.Body>
        <List loading={loading.get}>
          {suppliers.items?.map((e) => {
            const hasIln = !!e.iln;
            return (
              <List.Item
                key={e.supplierId}
                onClick={handleChoose(e)}
                className="cursor-pointer"
              >
                <Image
                  src={e?.logo?.thumbnailUrl}
                  isDynamic
                  className="w-14 h-12 rounded-md object-contain bg-gray-100"
                />
                {/* <Icon.Wrapper rounded>
                <Icon name="Building" variant="Bold" />
              </Icon.Wrapper> */}
                <div className="flex-1">
                  <h6 className="block truncate">{e.name}</h6>
                  {hasIln && (
                    <p className="block truncate text-secondary">#{e.iln}</p>
                  )}
                  <p className="block truncate text-secondary">
                    <Text>global.supplierCode</Text>: {e.code}
                  </p>
                </div>
                <Button as="span" light>
                  <Text>button.choose</Text>
                </Button>
              </List.Item>
            );
          })}
        </List>
      </Drawer.Body>
      <Drawer.Footer>
        <Pagination
          pageNumber={+params.pageNumber}
          pageSize={params.pageSize}
          setActivePage={(pageNumber) =>
            setParams((p) => ({ ...p, pageNumber }))
          }
          totalItems={suppliers.totalItems}
          totalPages={suppliers.totalPages}
        />
      </Drawer.Footer>
    </Fragment>
  );
}
