import { Dispatch, Fragment, MouseEvent } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import {
  Badge,
  Button,
  Icon,
  Image,
  Progress,
  Status,
  Text,
  Types,
} from "../../../components";
import { SellOffProductStatus } from "../../../enums";
import { useAxios, useConverters, useToggle } from "../../../hooks";
import { cn } from "../../../methods";
import { SellOffProduct } from "../../../types";
import ChangePhaseDrawer from "../Details/ChangePhaseDrawer";
import ClosePhaseModal from "../Details/ClosePhaseModal";

type ProductCardProps = {
  product: SellOffProduct.SearchItem;
  isGrid: boolean;
  onUpdate?: Dispatch<SellOffProduct.SearchItem>;
};

export default function ProductCard({
  product,
  isGrid,
  onUpdate,
}: ProductCardProps) {
  const { axios, loading } = useAxios();
  const { convertAmount } = useConverters();
  const [showChangePhase, toggleChangePhase, hideChangePhase] =
    useToggle(false);
  const [showClosePhase, toggleClosePhase, hideClosePhase] = useToggle(false);
  const productId = product.sellOffProductId;
  const brandLogo = product.pimProduct?.supplier?.program?.brand?.logo?.url;
  const brandName = product.pimProduct?.supplier?.program?.brand?.name;
  const hasBrandLogo = !!brandLogo;
  const hasBrandName = !!brandName;
  const basePrice = product.pimProduct?.vk1Price?.amount;
  const hasBasePrice = !!basePrice;
  const hasCurrentDiscount = !!product.currentDiscount;
  const hasCurrentPhasePrice = !!product.currentPrice;
  const isPreparation = product.status === SellOffProductStatus.Preparation;
  const isActive = product.status === SellOffProductStatus.Active;
  const isClosed = product.status === SellOffProductStatus.Closed;
  const isSold = product.status === SellOffProductStatus.Sold;
  const hasTemplate = !!product.sellPhaseTemplate;
  const canStartProcess = !product?.currentPhaseDay;
  const needClose = !isClosed && !!product.closePhaseRequired;
  const needPhaseChange =
    !isClosed && !needClose && !!product.changePhaseRequired;
  const handleClickLink = (e: MouseEvent<HTMLAnchorElement>) => {
    const buttons = e.currentTarget.querySelectorAll("button");
    const isButton = [...buttons].some((btn) => btn.contains(e.target as Node));
    isButton && e.preventDefault();
  };
  const updateSellOffProduct = () => {
    const id = product.sellOffProductId;
    const basicInfoUrl = `/salesservice/api/selloffproducts/${id}/basic-info`;
    const statusInfoUrl = `/salesservice/api/selloffproducts/${id}/status-info`;
    const getBasicInfo = axios
      .get<SellOffProduct.BasicInfo>(basicInfoUrl)
      .then((e) => e.data)
      .catch(() => null);
    const getStatusInfo = axios
      .get<SellOffProduct.StatusInfo>(statusInfoUrl)
      .then((e) => e.data)
      .catch(() => null);
    Promise.all([getBasicInfo, getStatusInfo]).then(
      ([basicInfo, statusInfo]) => {
        if (!basicInfo || !statusInfo) return;
        onUpdate?.({
          sellOffProductId: id,
          tenantId: product.tenantId,
          channelCode: basicInfo.channelCode,
          sellPhaseTemplateId: basicInfo.sellPhaseTemplateId,
          currentSellPhaseId: basicInfo.currentSellPhaseId,
          status: basicInfo.status,
          startDateTime: statusInfo.startSellOffDate,
          lastPhaseChangedDate: product.lastUpdatedDate,
          assets: basicInfo.assets,
          avatar: basicInfo.avatar,
          fastSalesProvisionAmount: basicInfo.fastSalesProvisionAmount,
          baseSupplierPrice: product.baseSupplierPrice,
          isSold: basicInfo.isSold,
          isClosed: statusInfo.isClosed,
          soldBy: basicInfo.soldBy,
          soldByName: basicInfo.soldByName,
          soldDateTime: basicInfo.soldDateTime,
          currentPhaseDay: statusInfo.currentPhaseDay,
          changePhaseRequired: statusInfo.changePhaseRequired,
          closePhaseRequired: statusInfo.closePhaseRequired,
          needChangePhaseDate: statusInfo.needChangePhaseDate,
          needClosePhaseDate: statusInfo.needClosePhaseDate,
          sellPhases: product.sellPhases,
          commissionNumber: basicInfo.commissionNumber,
          currentDiscount: statusInfo.currentDiscount,
          currentPhaseName: statusInfo.currentPhaseName,
          currentPhaseTotalDays: statusInfo.currentPhaseTotalDays,
          currentPrice: basicInfo.currentPhasePrice,
          realSoldPrice: basicInfo.realSoldPrice,
          soldPrice: basicInfo.soldPrice,
          lastUpdatedDate: product.lastUpdatedDate,
          pimProduct: basicInfo.pimProduct,
          sellPhaseTemplate: basicInfo.sellPhaseTemplate,
          preparationPhase: basicInfo.preparationPhase,
        });
      }
    );
  };
  return (
    <Fragment>
      <Link
        to={product.sellOffProductId}
        onClick={handleClickLink}
        className={twMerge(
          "cursor-pointer group w-full flex bg-white text-start shadow rounded gap-2 p-2 pe-4 hover:shadow-xl transition-shadow",
          isGrid ? "flex-col" : "flex-row"
        )}
      >
        <div
          className={twMerge(
            "aspect-image rounded overflow-hidden",
            isGrid ? "w-full" : "h-28"
          )}
        >
          <Image
            src={product.avatar?.thumbnailUrl}
            alt={product.pimProduct?.originalName}
            className="size-full object-cover group-hover:scale-110 transition-transform"
            isDynamic
          />
        </div>
        <div className="flex-1 flex flex-col gap-1">
          <h6 className="w-full text-dark text-base font-medium break-words">
            {product.pimProduct?.originalName}
          </h6>
          <p className="text-sm text-secondary font-medium w-full">
            #{product.pimProduct?.articleNumber}
          </p>
          {!isGrid && (
            <Fragment>
              <p className="text-sm text-secondary font-medium w-full">
                <Text>sellOffProducts.list.productGroup</Text>:{" "}
                {product.pimProduct?.pimProductGroup?.name}
              </p>
              <p className="text-sm text-secondary font-medium w-full mt-auto space-x-2">
                {hasBrandLogo && (
                  <Image
                    src={brandLogo}
                    alt={brandName}
                    isDynamic
                    className="inline-block rounded h-7"
                  />
                )}
                {hasBrandName && <span>{brandName}</span>}
                <Types.PimProduct id={product.pimProduct?.pimProductType} />
              </p>
            </Fragment>
          )}
        </div>
        {!isGrid && (
          <div className="flex-1 max-w-60 flex flex-col gap-1 space-y-1">
            <p className="flex items-center gap-1">
              <Status.SellOffProduct id={product.status} />
              {needPhaseChange && (
                <Badge variant="warning" className="text-xs">
                  <Text>sellOffProducts.list.needPhaseChange</Text>
                </Badge>
              )}
              {needClose && (
                <Badge variant="danger" className="text-xs">
                  <Text>sellOffProducts.list.needClose</Text>
                </Badge>
              )}
            </p>
            {isPreparation && (
              <ul className="space-y-1">
                {product.preparationPhase?.checkListItems?.map((e) => (
                  <li
                    key={e.checkListItemId}
                    className="w-full flex items-center gap-1.5"
                  >
                    {e.isChecked ? (
                      <Icon
                        name="TickCircle"
                        variant="Bulk"
                        className="!size-5 text-success"
                      />
                    ) : (
                      <div className="flex-center size-4 rounded-full bg-[#B5B5C3]"></div>
                    )}
                    <h6 className="text-xs font-medium text-secondary">
                      {e.label}
                    </h6>
                  </li>
                ))}
              </ul>
            )}
            {isActive && (
              <div className="w-full space-y-1">
                <p className="text-xs text-placeholder font-medium">
                  {product.sellPhaseTemplate?.title}
                </p>
                <p
                  className={cn(
                    "text-xs font-medium",
                    needClose ? "text-danger" : "text-success"
                  )}
                >
                  <Text
                    currentPhaseName={product.currentPhaseName}
                    currentPhaseDay={product.currentPhaseDay}
                    currentPhaseTotalDays={product.currentPhaseTotalDays}
                  >{`sellOffProducts.list.currentPhaseDays`}</Text>
                </p>
                <Progress
                  value={product.currentPhaseDay}
                  total={product.currentPhaseTotalDays}
                  progressClassName={needClose ? "bg-danger" : "bg-success"}
                  className="w-24"
                />
              </div>
            )}
            {isSold && (
              <div className="w-full space-y-1">
                <p className="text-xs text-placeholder font-medium">
                  {product.sellPhaseTemplate?.title}
                </p>
                <p className="text-xs text-secondary font-medium">
                  <Text
                    currentPhaseName={product.currentPhaseName}
                    currentPhaseDay={product.currentPhaseDay}
                    currentPhaseTotalDays={product.currentPhaseTotalDays}
                  >
                    sellOffProducts.list.currentPhaseDays
                  </Text>
                </p>
                <p className="text-sm text-secondary font-medium">
                  <Icon name="UserTag" variant="Bold" className="!size-4" />{" "}
                  <Text soldByName={product.soldByName}>
                    sellOffProducts.list.soldBy
                  </Text>
                </p>
              </div>
            )}
          </div>
        )}
        <div
          className={twMerge(
            "flex items-center gap-3 text-sm text-[#A1A4AC] font-semibold pt-1",
            !isGrid && "min-w-24 flex-col self-center items-start gap-1"
          )}
        >
          {hasCurrentPhasePrice && (
            <p className="text-sm text-primary font-medium">
              {convertAmount(product.currentPrice)}
            </p>
          )}
          {hasCurrentDiscount && (
            <p className="text-sm text-danger font-medium">
              {product.currentDiscount}%
            </p>
          )}
          {hasBasePrice && (
            <p
              className={cn(
                "text-sm font-medium",
                hasCurrentDiscount
                  ? "text-placeholder line-through"
                  : "text-gray-900"
              )}
            >
              {convertAmount(basePrice)}
            </p>
          )}
        </div>
        <div className="self-center min-w-32">
          {!isGrid && (
            <Fragment>
              {canStartProcess && (
                <Button
                  type="button"
                  light
                  onClick={toggleChangePhase}
                  loading={loading.get}
                >
                  <Text>sellOffProducts.details.startsProcessButton</Text>
                </Button>
              )}
              {needPhaseChange && (
                <Button
                  type="button"
                  light
                  variant="warning"
                  onClick={toggleChangePhase}
                  loading={loading.get}
                >
                  <Text>sellOffProducts.details.changePhaseButton</Text>
                </Button>
              )}
              {needClose && (
                <Button
                  type="button"
                  light
                  variant="danger"
                  onClick={toggleClosePhase}
                  loading={loading.get}
                >
                  <Text>sellOffProducts.details.needCloseButton</Text>
                </Button>
              )}
            </Fragment>
          )}
        </div>
      </Link>
      <ChangePhaseDrawer
        isOpen={showChangePhase}
        toggle={hideChangePhase}
        sellOffProductId={productId}
        hasTemplate={hasTemplate}
        start={canStartProcess}
        onSubmitted={updateSellOffProduct}
      />
      <ClosePhaseModal
        isOpen={showClosePhase}
        toggle={hideClosePhase}
        sellOffProductId={productId}
        onSubmitted={updateSellOffProduct}
      />
    </Fragment>
  );
}
