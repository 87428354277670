import { useEffect, useState } from "react";
import { Icon, Text } from "../components";
import { useAxios } from "../hooks";
import { showCallNumber } from "../methods";
import { Company } from "../types";

type ContactPersonCardProps = {
  companyId: string | null;
  personId: string | null;
  onClick: () => void;
};

export default function ContactPersonCard({
  companyId,
  personId,
  onClick,
}: ContactPersonCardProps) {
  const { axios, loading } = useAxios();
  const [person, setPerson] = useState<Company.Person | null>(null);
  const hasPerson = !!person;
  const getPerson = () => {
    if (!personId || !companyId) return;
    setPerson(null);
    const url = `/accountservice/api/customers/${companyId}/as-company/company-contact-persons/${personId}`;
    axios.get(url).then(({ data }) => {
      setPerson(data);
    });
  };
  useEffect(getPerson, [companyId, personId]);
  if (loading.get)
    return (
      <section className="w-full animate-pulse space-y-2">
        <div className="h-4 w-1/3 rounded bg-gray"></div>
        <div className="h-16 rounded bg-gray"></div>
      </section>
    );
  if (!hasPerson)
    return (
      <div className="flex items-center gap-4 py-4">
        <p className="flex-1 text-danger">
          <Text>errorCodes.default</Text>{" "}
        </p>
        <button
          type="button"
          className="text-warning text-sm self-center"
          onClick={onClick}
        >
          <Icon
            name="ArrowForwardSquare"
            variant="Bold"
            size={25}
            className="text-warning"
          />{" "}
          <Text>company.details.offer.form.changeBtn</Text>
        </button>
      </div>
    );
  return (
    <section className="space-y-2">
      <h6 className="text-dark text-base">
        <Text>company.details.offer.form.contactPerson</Text>
      </h6>
      <div className="flex items-start gap-2 border border-gray rounded p-2 ">
        <Icon name="ProfileCircle" size={24} className="text-primary" />
        <div className="flex-1">
          <h6 className="text-base text-dark">
            {[person.firstName, person.lastName].join(" ")}
          </h6>
          <p className="text-secondary text-sm">
            {person.emailAddress} | {showCallNumber(person.mobileNumber)}
          </p>
        </div>
        <button
          type="button"
          className="text-warning text-sm self-center"
          onClick={onClick}
        >
          <Icon
            name="ArrowForwardSquare"
            variant="Bold"
            size={25}
            className="text-warning"
          />{" "}
          <Text>company.details.offer.form.changeBtn</Text>
        </button>
      </div>
    </section>
  );
}
