import { cloneDeep } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import {
  Button,
  Form,
  InputGroup,
  Layout,
  Select,
  Text,
  Wrapper,
} from "../../../components";
import { rules, taskDurationItems, types } from "../../../constants";
import { IncidentAssignedEmployee } from "../../../containers";
import { SelectedEmployee } from "../../../containers/_IncidentAssignedEmployee";
import { CRMTaskPriority } from "../../../enums";
import { useAxios } from "../../../hooks";
import { Task } from "../../../types";

type Data = Task.Create;

export default function CreateTask() {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const initData: Data = { priority: CRMTaskPriority.Normal, duration: 30 };
  const [data, setData] = useState(initData);
  const [employee, setEmployee] = useState<SelectedEmployee | null>(null);
  const onCancel = () => {
    navigate("/tasks");
  };
  const handleSetValue = (key: keyof Data) => {
    return (value: any) => {
      setData((p) => ({ ...p, [key]: value }));
    };
  };
  const submit = () => {
    const url = "/channelmanagerservice/api/activities/task";
    const body = cloneDeep(data);
    body.assignedId = employee?.id;
    body.assignedType = employee?.type;
    body.duration &&= Number(body.duration);
    body.duration ||= 0;
    axios.post(url, body).then(() => {
      const message = "task.create.successMessage";
      toast.success(message);
      navigate("/tasks");
    });
  };
  return (
    <Layout as={Form} onSubmit={submit}>
      <Layout.Header>
        <h1 className="flex-1 text-base text-dark font-medium">
          <Text>task.create.title</Text>
        </h1>
        <Button
          type="button"
          variant="danger"
          className="text-sm"
          onClick={onCancel}
        >
          <Text>task.create.cancelButton</Text>
        </Button>
        <Button
          type="submit"
          variant="success"
          loading={loading.post}
          className="text-sm"
        >
          <Text>task.create.submitButton</Text>
        </Button>
      </Layout.Header>
      <Layout.Body className="grid grid-cols-1 lg:grid-cols-5 gap-4">
        <Wrapper className="lg:col-span-3">
          <Wrapper.Body className="space-y-6">
            <InputGroup
              label="task.create.subject"
              placeholder="task.create.subjectPlaceholder"
              value={data.subject}
              setValue={handleSetValue("subject")}
              rules={rules.required}
            />
            <InputGroup
              as="textarea"
              label="task.create.description"
              placeholder="task.create.descriptionPlaceholder"
              value={data.description}
              setValue={handleSetValue("description")}
              rules={rules.required}
            />
          </Wrapper.Body>
        </Wrapper>
        <Wrapper className="lg:col-span-2">
          <Wrapper.Body className="space-y-6">
            <Select
              label="task.create.priority"
              value={data.priority}
              setValue={handleSetValue("priority")}
              // rules={rules.required}
              items={types.taskPriority}
            />
            <Select
              label="task.create.duration"
              value={data.duration}
              setValue={handleSetValue("duration")}
              //  rules={rules.required}
              items={taskDurationItems}
            />
            <InputGroup
              label="task.create.dueDate"
              value={data.dueDate}
              setValue={handleSetValue("dueDate")}
              // rules={rules.required}
              type="date"
            />
          </Wrapper.Body>
        </Wrapper>
        <Wrapper className="lg:col-span-3">
          <Wrapper.Body>
            <IncidentAssignedEmployee
              label="task.create.assignEmployee"
              value={employee}
              setValue={setEmployee}
            />
          </Wrapper.Body>
        </Wrapper>
      </Layout.Body>
    </Layout>
  );
}
