import { useContext } from "react";
import {
  Alert,
  Button,
  Icon,
  Modal,
  Text,
  Wrapper,
} from "../../../../components";
import { dateFormats, status } from "../../../../constants";
import { PickupStatus } from "../../../../enums";
import { useConverters } from "../../../../hooks";
import { cn } from "../../../../methods";
import { iconNameType, togglePropsType } from "../../../../types";
import { LineItemContext } from "./LineItemRow";

export default function PickupInfo({ isOpen, toggle }: togglePropsType) {
  const { convertDate } = useConverters();
  const { lineItem, isPickedUp, pickupStatus } = useContext(LineItemContext);
  const articleNumber = lineItem.product?.articleNumber;
  const hasArticleNumber = !!articleNumber;
  const reservationNumber = lineItem.reservationId;
  const activeStatus = status.pickup.find((e) => e.id === pickupStatus) ?? null;
  const isPickupRequested = pickupStatus === PickupStatus.PickupRequested;
  const hasReservationNumber = !!reservationNumber && isPickupRequested;
  const hasActiveStatus = !!activeStatus;
  const items = [
    {
      label: "pickup.pickupInfo.date",
      value: convertDate(
        lineItem.pickupStatus?.updatedAt,
        dateFormats.fullDate
      ),
      icon: "CalendarTick" as iconNameType,
    },
    {
      label: "pickup.pickupInfo.by",
      value: lineItem.pickupStatus?.updatedByName,
      icon: "User" as iconNameType,
    },
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6 className="text-base text-dark font-medium">
          <Text>pickup.pickupInfo.title</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <div className="flex-1 flex items-center text-start gap-4">
          <div className="flex-center size-11">
            <Icon.Wrapper variant="primary">
              <Icon name="Box1" variant="Bold" />
            </Icon.Wrapper>
          </div>
          <div className="flex-1 space-y-px">
            <h6 className="w-full text-base font-normal text-balance max-w-72 whitespace-normal line-clamp-2">
              {lineItem.title}
            </h6>
            {hasArticleNumber && (
              <p className="block text-secondary">#{articleNumber}</p>
            )}
          </div>
        </div>
        {hasActiveStatus && (
          <div className={cn("rounded p-0.5", activeStatus.bgClasses)}>
            <Alert
              variant={activeStatus.variant}
              light
              className="border-none bg-transparent p-2"
              icon={activeStatus.icon}
              IconProps={{ variant: isPickedUp ? "Bold" : "Bulk", size: 20 }}
            >
              <Alert.Text
                className={cn(activeStatus.colorClasses, "text-sm space-x-1")}
              >
                <span className="inline-block">
                  <Text>{activeStatus.name}</Text>
                </span>
                {hasReservationNumber && (
                  <span className="inline-block">({reservationNumber})</span>
                )}
              </Alert.Text>
            </Alert>
            <Wrapper>
              <Wrapper.Body>
                <table className="w-full text-sm font-medium [&_td]:py-3">
                  <tbody className="divide-y divide-dashed">
                    {items.map((e) => (
                      <tr key={e.label}>
                        <td className="text-secondary text-start whitespace-nowrap">
                          <Icon name={e.icon} variant="Bold" />{" "}
                          <Text>{e.label}</Text>:
                        </td>
                        <td className="text-gray-900 text-end">{e.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Wrapper.Body>
            </Wrapper>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button type="button" light onClick={toggle}>
          <Text>pickup.pickupInfo.closeButton</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
