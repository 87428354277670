import { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  Icon,
  Status,
  Text,
  Types,
  WithPermission,
} from "../../../../components";
import { dateFormats } from "../../../../constants";
import {
  IncidentAssignEmployee,
  IncidentChangeStatus,
  IncidentStatusInfo,
} from "../../../../containers";
import { useConverters, useToggle } from "../../../../hooks";
import { Incident } from "../../../../types";

type IncidentRowProps = {
  incident: Incident.Item;
  onActionEnd: (id: string) => void;
};
export default function IncidentRow({
  incident,
  onActionEnd,
}: IncidentRowProps) {
  const hasEmployee = !!incident.assignedName;
  const hasBranch = !!incident.branchName;
  const { convertDate } = useConverters();
  const [showStatusInfo, toggleStatusInfo] = useToggle(false);
  const [showChangeStatus, toggleChangeStatus] = useToggle(false);
  const [showAssignEmployee, toggleAssignEmployee] = useToggle(false);
  // const [showAction, toggleAction] = useToggle(false);
  return (
    <Fragment key={incident.crmIncidentId}>
      <tr>
        <td>
          <Link
            to={`/incidents/${incident.crmIncidentId}`}
            className="flex-center w-fit gap-4 text-start"
          >
            <Icon.Wrapper>
              <Icon name="DocumentText" variant="Bold" />
            </Icon.Wrapper>
            <div className="flex-1 *:leading-none space-y-2">
              <h6 className="flex items-center gap-1 w-fit text-dark text-base">
                <span className="inline-block flex-1 max-w-72 truncate">
                  {incident.title}
                </span>
                <Icon name="InfoCircle" className="text-primary" />
              </h6>
              <p className="block text-secondary text-sm">
                {incident.ticketNumber}
              </p>
              <Status.IncidentReason id={incident.statusReason} />
            </div>
          </Link>
        </td>
        <td className="space-y-1">
          <Types.IncidentPriority id={incident.priority} /> <br />
          <Types.IncidentOrigin id={incident.origin} />
        </td>
        <td>
          {hasBranch && (
            <Badge
              variant="danger"
              className="bg-gray-50 bg-opacity-100 text-danger mb-1 p-1 px-2 text-sm"
            >
              <Icon name="Location" variant="Bold" /> {incident.branchName}
            </Badge>
          )}
          <p className="text-sm text-secondary">
            <Text>global.createdAt</Text>:{" "}
            {convertDate(incident.createdAt, dateFormats.fullDate)}
          </p>
          <button
            type="button"
            className="text-sm text-secondary"
            onClick={toggleAssignEmployee}
          >
            <Icon name="UserTag" variant="Bulk" />{" "}
            <Text>incident.list.assignedTo</Text>
            {": "}
            <span
              className={`underline ${
                hasEmployee ? "text-primary" : "text-warning"
              }`}
            >
              {hasEmployee ? (
                incident.assignedName
              ) : (
                <Text>incident.list.chooseEmployeeBtn</Text>
              )}
            </span>
          </button>
        </td>
        <td data-sticky className="space-x-2">
          <Status.Incident
            status={incident.status}
            onClick={toggleStatusInfo}
          />
          <Button
            as={Link}
            to={`/incidents/${incident.crmIncidentId}`}
            light
            className="p-2"
          >
            <Text>incident.list.incidentDetails</Text>
          </Button>
        </td>
      </tr>
      <IncidentStatusInfo
        isOpen={showStatusInfo}
        toggle={toggleStatusInfo}
        incident={incident}
        onChangeStatus={toggleChangeStatus}
      />
      <WithPermission
        permissions={["CMS_IncidentFullAccess", "CMS_UpdateIncident"]}
      >
        <IncidentChangeStatus
          isOpen={showChangeStatus}
          toggle={toggleChangeStatus}
          incident={incident}
          onSubmitted={onActionEnd}
        />
        <IncidentAssignEmployee
          isOpen={showAssignEmployee}
          toggle={toggleAssignEmployee}
          incident={incident}
          onSubmitted={onActionEnd}
        />
      </WithPermission>
      {/* <IncidentActions
        isOpen={showAction}
        toggle={toggleAction}
        incident={incident}
        onEdit={() => {}}
        onChangeStatus={toggleChangeStatus}
        onAssigned={() => {}}
      /> */}
    </Fragment>
  );
}
