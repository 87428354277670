import {
  ComponentProps,
  createContext,
  ElementType,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Transition, TransitionStatus } from "react-transition-group";
import { twMerge } from "tailwind-merge";
import { asProps, sizeType, togglePropsType } from "../types";
import Portal from "./_Portal";

type DrawerContextType = {
  size: sizeType;
  transitionState: TransitionStatus;
};
type TransitionClasses = { [key in TransitionStatus]: string };
type BaseProps<E extends ElementType> = togglePropsType &
  asProps<E> & {
    size?: sizeType;
  };
type DrawerProps<E extends ElementType> = BaseProps<E> &
  Omit<ComponentProps<E>, keyof BaseProps<E>>;

const DrawerContext = createContext({} as DrawerContextType);

function Drawer<E extends ElementType = "div">({
  as,
  isOpen,
  toggle,
  className = "",
  children,
  size = null,
  ...props
}: DrawerProps<E>) {
  const divRef = useRef<HTMLDivElement | null>(null);
  const Component = as || "div";
  const transitionClasses: TransitionClasses = {
    entering: "opacity-100 pointer-events-auto active",
    entered: "opacity-100 pointer-events-auto active",
    exiting: "opacity-0 pointer-events-none",
    exited: "opacity-0 pointer-events-none",
    unmounted: "",
  };
  return (
    <Transition nodeRef={divRef} in={isOpen} timeout={300} unmountOnExit>
      {(state) => (
        <Portal>
          <Component
            ref={divRef}
            className={twMerge(
              "drawer group fixed inset-0 flex z-30 bg-black/20 transition-opacity",
              transitionClasses[state],
              className
            )}
            {...props}
          >
            <button
              type="button"
              onClick={toggle}
              className="h-full flex-1 opacity-0 cursor-default min-w-[5%]"
            />
            <DrawerContext.Provider value={{ size, transitionState: state }}>
              {children}
            </DrawerContext.Provider>
          </Component>
        </Portal>
      )}
    </Transition>
  );
}
function DrawerMenu({
  children,
  className = "",
  ...props
}: ComponentProps<"div">) {
  const drawerMenuRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef<boolean>(false);
  const startX = useRef<number>(0);
  const startWidth = useRef<number>(0);
  const { size, transitionState } = useContext(DrawerContext);
  const transitionClasses: TransitionClasses = {
    entering: "translate-x-0",
    entered: "translate-x-0",
    exiting: "translate-x-full",
    exited: "translate-x-full",
    unmounted: "",
  };
  const handleSizeWidth = useMemo<string>(() => {
    if (!size) return "w-[32.5rem]";
    const sizes: { [S in Exclude<sizeType, null>]: string } = {
      sm: "",
      md: "",
      lg: "w-[47.5rem]",
      xl: "w-[60rem]",
    };
    return sizes[size];
  }, [size]);
  const updateWidth = (value: number) => {
    const width = value >= 100 ? 100 : value <= 0 ? 0 : value;
    drawerMenuRef.current?.style.setProperty("width", `${width}%`);
  };
  const dragStart = (e: any) => {
    isDragging.current = true;
    startX.current = e.pageX || e.touches?.[0].pageX;
    const drawerMenuWidth = drawerMenuRef.current?.clientWidth ?? 0;
    const width = (drawerMenuWidth / window.innerWidth) * 100;
    startWidth.current = parseInt(`${width}`);
    drawerMenuRef.current?.classList.add("dragging");
  };
  const dragStop = () => {
    isDragging.current = false;
    drawerMenuRef.current?.classList.remove("dragging");
  };
  const dragging = (e: any) => {
    if (!isDragging.current) return;
    const delta = startX.current - (e.pageX || e.touches?.[0].pageX);
    const width = startWidth.current + (delta / window.innerWidth) * 100;
    updateWidth(width);
  };
  useEffect(() => {
    document.addEventListener("mouseup", dragStop);
    document.addEventListener("mousemove", dragging);
    document.addEventListener("touchend", dragStop);
    document.addEventListener("touchmove", dragging);
    return () => {
      document.removeEventListener("mouseup", dragStop);
      document.removeEventListener("mousemove", dragging);
      document.removeEventListener("touchend", dragStop);
      document.removeEventListener("touchmove", dragging);
    };
  }, []);
  return (
    <div
      ref={drawerMenuRef}
      className={twMerge(
        handleSizeWidth,
        "relative h-full flex flex-col bg-white rounded-l px-6 transition-[transform,width] [&.dragging]:transition-none min-w-[30rem] max-w-[95%]",
        transitionClasses[transitionState],
        className
      )}
      {...props}
    >
      {children}
      <button
        type="button"
        className="absolute w-1 h-10 bg-gray-300 rounded-full top-[calc(50%-1rem)] left-2 cursor-grab"
        onMouseDown={dragStart}
        onTouchStart={dragStart}
      />
    </div>
  );
}
function DrawerHeader({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div
      className={twMerge("py-3 text-center border-b border-gray", className)}
      {...props}
    >
      {children}
    </div>
  );
}
function DrawerBody({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div className={twMerge("py-3 flex-1 overflow-auto", className)} {...props}>
      {children}
    </div>
  );
}
function DrawerFooter({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div
      className={twMerge("w-full py-3 border-t border-gray", className)}
      {...props}
    >
      {children}
    </div>
  );
}
Drawer.Menu = DrawerMenu;
Drawer.Header = DrawerHeader;
Drawer.Body = DrawerBody;
Drawer.Footer = DrawerFooter;
export default Drawer;
