import { AxiosRequestConfig } from "axios";
import useAxios from "./useAxios";

export default function useGetFile(): [
  (src: string) => Promise<string | null>,
  boolean
] {
  const { axios, loading } = useAxios();
  const getFile = async (src: string) => {
    const url = src;
    const config: AxiosRequestConfig = {
      responseType: "blob",
      subscriptionKey: false,
      error: false,
      cache: true,
    };
    return axios
      .get(url, config)
      .then(({ data }) => URL.createObjectURL(data))
      .catch(() => null);
  };
  return [getFile, loading.get];
}
