import {
  AmountCalculationType,
  CRMActivityType,
  CRMTargetType,
  CRMTaskPriority,
  CalculationValueType,
  CustomerType,
  GenderType,
  IncidentPriority,
  LineItemType,
  PimProductType,
  ProductType,
  ProgramType,
  SellOffProductTimelineActionType,
  SupplierContactPersonDepartment,
  SupplierShippingDurationUnit,
  SupplierType,
} from "../enums";
import { iconNameType, variantType } from "../types";
type TypesItem = {
  name: string;
  id: number;
  variant?: variantType;
  icon?: iconNameType;
  [key: string]: any;
};
const customer: TypesItem[] = [
  {
    name: "types.customer.company",
    id: CustomerType.Company,
    variant: "warning",
    icon: "Home",
  },
  {
    name: "types.customer.person",
    id: CustomerType.Person,
    variant: "primary",
    icon: "User",
  },
];
const gender = [
  // { name: "types.gender.unknown", id: 0 },
  { name: "types.gender.notDefined", id: GenderType.NotDefined },
  { name: "types.gender.male", id: GenderType.Male },
  { name: "types.gender.female", id: GenderType.Female },
  { name: "types.gender.diverse", id: GenderType.Diverse },
];
const salutation = [
  { name: "types.salutation.unknown", id: 0 },
  { name: "types.salutation.company", id: 1 },
  { name: "types.salutation.family", id: 2 },
  { name: "types.salutation.mr", id: 3 },
  { name: "types.salutation.mrs", id: 4 },
  { name: "types.salutation.notProvided", id: 5 },
  { name: "types.salutation.divers", id: 6 },
  { name: "types.salutation.mrAndMrs", id: 7 },
];
const salutationTitle = [
  { name: "types.salutationTitle.dr", id: 0 },
  { name: "types.salutationTitle.prof", id: 1 },
  { name: "types.salutationTitle.mag", id: 2 },
];
const offerSortBy = [
  { name: "types.offerSortBy.none", id: 0 },
  { name: "types.offerSortBy.creationDate", id: 1 },
  { name: "types.offerSortBy.lastUpdate", id: 2 },
  { name: "types.offerSortBy.offerTitle", id: 3 },
];
const product: TypesItem[] = [
  { name: "types.product.pim", id: ProductType.Pim, variant: "warning" },
  {
    name: "types.product.programManual",
    id: ProductType.ProgramManual,
    variant: "warning",
  },
  {
    name: "types.product.programConfigurable",
    id: ProductType.ProgramConfigurable,
    variant: "success",
  },
  {
    name: "types.product.Iwofurn",
    id: ProductType.Iwofurn,
    variant: "success",
  },
];
const program: TypesItem[] = [
  { name: "types.program.none", id: ProgramType.None, variant: "dark" },
  {
    name: "types.program.manual",
    id: ProgramType.Manual,
    variant: "warning",
  },
  {
    name: "types.program.configurable",
    id: ProgramType.Configurable,
    variant: "success",
  },
];
const customerModes: TypesItem[] = [
  {
    name: "&#128544;",
    id: 1,
  },
  {
    name: "&#128577;",
    id: 2,
  },
  {
    name: "&#128528;",
    id: 3,
  },
  {
    name: "&#128578;",
    id: 4,
  },
  {
    name: "&#128522;",
    id: 5,
  },
];
const incidentPriority: TypesItem[] = [
  {
    name: "types.incidentPriority.notDefined",
    id: IncidentPriority.NotDefined,
    variant: "dark",
  },
  {
    name: "types.incidentPriority.high",
    id: IncidentPriority.High,
    variant: "success",
  },
  {
    name: "types.incidentPriority.normal",
    id: IncidentPriority.Normal,
    variant: "warning",
  },
  {
    name: "types.incidentPriority.low",
    id: IncidentPriority.Low,
    variant: "danger",
  },
];
const incidentOrigin: (TypesItem & { icon: iconNameType })[] = [
  { name: "types.incidentOrigin.notDefined", id: 0, icon: "CloseCircle" },
  { name: "types.incidentOrigin.phone", id: 1, icon: "CallCalling" },
  { name: "types.incidentOrigin.email", id: 2, icon: "SmsNotification" },
  { name: "types.incidentOrigin.web", id: 3, icon: "Global" },
];
const incidentTopic: TypesItem[] = [
  { name: "types.incidentTopic.generalRequest", id: 202480000 },
  { name: "types.incidentTopic.productRequest", id: 202480001 },
  { name: "types.incidentTopic.deliveryDateAgreement", id: 202480002 },
  { name: "types.incidentTopic.reserveVan", id: 202480003 },
  { name: "types.incidentTopic.purchaseAgreementChange", id: 202480004 },
  { name: "types.incidentTopic.customerComplaint", id: 202480005 },
];
const lineItem: TypesItem[] = [
  {
    name: "types.lineItem.pimProduct",
    id: LineItemType.PimProduct,
    variant: "warning",
  },
  {
    name: "types.lineItem.iwofurnProduct",
    id: LineItemType.IwofurnProduct,
    variant: "success",
  },
  {
    name: "types.lineItem.customProduct",
    id: LineItemType.ManualProduct,
    variant: "success",
  },
  {
    name: "types.lineItem.productService",
    id: LineItemType.ProductService,
    variant: "warning",
  },
  {
    name: "types.lineItem.bundle",
    id: LineItemType.ManualBundle,
    variant: "primary",
  },
  {
    name: "types.lineItem.pimBundle",
    id: LineItemType.PimBundle,
    variant: "warning",
  },
  {
    name: "types.lineItem.pimBundleItem",
    id: LineItemType.PimBundleItem,
    variant: "warning",
  },
  {
    name: "types.lineItem.sellOffProduct",
    id: LineItemType.SellOffProduct,
    variant: "dark",
  },
  {
    name: "types.lineItem.sellOffBundle",
    id: LineItemType.SellOffBundle,
    variant: "dark",
  },
  {
    name: "types.lineItem.sellOffBundleItem",
    id: LineItemType.SellOffBundleItem,
    variant: "dark",
  },
];
const incidentActivity = [
  {
    name: "types.incidentActivity.notDefined",
    id: CRMActivityType.NotDefined,
    icon: "CloseCircle" as iconNameType,
    selectable: false,
  },
  {
    name: "types.incidentActivity.email",
    id: CRMActivityType.Email,
    icon: "Sms" as iconNameType,
    selectable: false,
  },
  {
    name: "types.incidentActivity.phoneCall",
    id: CRMActivityType.PhoneCall,
    icon: "Call" as iconNameType,
    selectable: false,
  },
  {
    name: "types.incidentActivity.appointment",
    id: CRMActivityType.Appointment,
    icon: "CardTick" as iconNameType,
    selectable: false,
  },
  {
    name: "types.incidentActivity.task",
    id: CRMActivityType.Task,
    icon: "ClipboardTick" as iconNameType,
    selectable: true,
  },
  {
    name: "types.incidentActivity.post",
    id: CRMActivityType.Post,
    icon: "Document" as iconNameType,
    selectable: false,
  },
  {
    name: "types.incidentActivity.note",
    id: CRMActivityType.Note,
    icon: "Document" as iconNameType,
    selectable: false,
  },
  {
    name: "types.incidentActivity.incidentresolution",
    id: CRMActivityType.Incidentresolution,
    icon: "Document" as iconNameType,
    selectable: false,
  },
];
const taskPriority = [
  {
    name: "types.taskPriority.low",
    id: CRMTaskPriority.Low,
    variant: "success" as variantType,
  },
  {
    name: "types.taskPriority.normal",
    id: CRMTaskPriority.Normal,
    variant: "warning" as variantType,
  },
  {
    name: "types.taskPriority.high",
    id: CRMTaskPriority.High,
    variant: "danger" as variantType,
  },
];
const calculationValue = [
  {
    name: "types.calculationValue.percent",
    id: CalculationValueType.Percent,
    symbol: "%",
  },
  {
    name: "types.calculationValue.amount",
    id: CalculationValueType.Amount,
    symbol: "EUR",
  },
  {
    name: "types.calculationValue.multiply",
    id: CalculationValueType.Multiply,
    symbol: "x",
  },
  {
    name: "types.calculationValue.pureProfit",
    id: CalculationValueType.PureProfit,
    symbol: "Pure",
  },
];
const crmTarget = [
  {
    name: "types.crmTarget.incident",
    id: CRMTargetType.Incident,
    icon: "DocumentText" as iconNameType,
  },
  {
    name: "types.crmTarget.lead",
    id: CRMTargetType.Lead,
    icon: "UserTag" as iconNameType,
  },
  {
    name: "types.crmTarget.account",
    id: CRMTargetType.Account,
    icon: "Home" as iconNameType,
  },
  {
    name: "types.crmTarget.contact",
    id: CRMTargetType.Contact,
    icon: "ProfileCircle" as iconNameType,
  },
  {
    name: "types.crmTarget.order",
    id: CRMTargetType.Order,
    icon: "ReceiptText" as iconNameType,
  },
];
const documentTags = [
  { name: "types.documentTags.sendToCustomer", id: "Send To Customer" },
  { name: "types.documentTags.contract", id: "Contract" },
  { name: "types.documentTags.information", id: "Information" },
];
const supplier = [
  {
    name: "types.supplier.tradingGoods",
    id: SupplierType.TradingGoods,
    variant: "info" as variantType,
  },
  {
    name: "types.supplier.consumableGoods",
    id: SupplierType.ConsumableGoods,
    variant: "warning" as variantType,
  },
];
const supplierContactPersonDepartment = [
  {
    name: "types.supplierContactPersonDepartment.general",
    id: SupplierContactPersonDepartment.General,
  },
  {
    name: "types.supplierContactPersonDepartment.sales",
    id: SupplierContactPersonDepartment.Sales,
  },
  {
    name: "types.supplierContactPersonDepartment.logistics",
    id: SupplierContactPersonDepartment.Logistics,
  },
  {
    name: "types.supplierContactPersonDepartment.marketing",
    id: SupplierContactPersonDepartment.Marketing,
  },
  {
    name: "types.supplierContactPersonDepartment.support",
    id: SupplierContactPersonDepartment.Support,
  },
  {
    name: "types.supplierContactPersonDepartment.other",
    id: SupplierContactPersonDepartment.Other,
  },
];
const supplierShippingDurationUnit = [
  {
    name: "types.supplierShippingDurationUnit.none",
    id: SupplierShippingDurationUnit.None,
  },
  {
    name: "types.supplierShippingDurationUnit.workDay",
    id: SupplierShippingDurationUnit.WorkDay,
  },
  {
    name: "types.supplierShippingDurationUnit.calendarDay",
    id: SupplierShippingDurationUnit.CalendarDay,
  },
];
const pimProduct = [
  {
    name: "types.pimProduct.standard",
    id: PimProductType.Standard,
    classes: "bg-[#FDEDCD] text-[#87825B]",
  },
  {
    name: "types.pimProduct.bundle",
    id: PimProductType.Bundle,
    classes: "bg-[#C8EAC5] text-[#5B7A87]",
  },
];
const amountCalculation = [
  {
    name: "types.amountCalculation.amount",
    id: AmountCalculationType.Amount,
  },
  {
    name: "types.amountCalculation.percent",
    id: AmountCalculationType.Percent,
  },
];
const sellOffProductTimelineAction = [
  {
    name: "types.sellOffProductTimelineAction.none",
    id: SellOffProductTimelineActionType.None,
    bgClasses: "bg-gray-50",
    textClasses: "text-gray-900",
  },
  {
    name: "types.sellOffProductTimelineAction.productCreated",
    id: SellOffProductTimelineActionType.ProductCreated,
    bgClasses: "bg-primary-light",
    textClasses: "text-primary",
  },
  {
    name: "types.sellOffProductTimelineAction.phaseStarted",
    id: SellOffProductTimelineActionType.PhaseStarted,
    bgClasses: "bg-success-light",
    textClasses: "text-success",
  },
  {
    name: "types.sellOffProductTimelineAction.phaseChanged",
    id: SellOffProductTimelineActionType.PhaseChanged,
    bgClasses: "bg-warning-light",
    textClasses: "text-warning",
  },
  {
    name: "types.sellOffProductTimelineAction.productSold",
    id: SellOffProductTimelineActionType.ProductSold,
    bgClasses: "bg-info-light",
    textClasses: "text-info",
  },
  {
    name: "types.sellOffProductTimelineAction.phaseClosed",
    id: SellOffProductTimelineActionType.PhaseClosed,
    bgClasses: "bg-danger-light",
    textClasses: "text-danger",
  },
  {
    name: "types.sellOffProductTimelineAction.soldProductWithdrew",
    id: SellOffProductTimelineActionType.SoldProductWithdrew,
    bgClasses: "bg-warning-light",
    textClasses: "text-warning",
  },
];
const type = {
  gender,
  salutation,
  salutationTitle,
  offerSortBy,
  product,
  program,
  customerModes,
  incidentPriority,
  incidentTopic,
  incidentOrigin,
  lineItem,
  customer,
  incidentActivity,
  taskPriority,
  calculationValue,
  crmTarget,
  documentTags,
  supplier,
  supplierContactPersonDepartment,
  supplierShippingDurationUnit,
  pimProduct,
  amountCalculation,
  sellOffProductTimelineAction,
};
export default type;
