import { cloneDeep, now } from "lodash";
import { useContext, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { TaskDetailsContext } from "..";
import { Button, Drawer, Form, InputGroup, Text } from "../../../../components";
import { dateFormats, rules } from "../../../../constants";
import { useAxios, useConverters, useSelector } from "../../../../hooks";
import { Task, togglePropsType } from "../../../../types";

export default function CreateNote({ isOpen, toggle }: togglePropsType) {
  const profile = useSelector((s) => s.profile);
  const { axios, loading } = useAxios();
  const { convertDate } = useConverters();
  const { task, setTask } = useContext(TaskDetailsContext);
  const [data, setData] = useState<Task.CreateNote>({});
  const createdOn = useMemo(() => (isOpen ? now() : null), [isOpen]);
  const bodySubjectHandler = () => {
    const hasEmployee = !!profile.employee;
    const fullName = [profile.firstName, profile.lastName]
      .filter(Boolean)
      .join(" ");
    const employeeName = [
      profile.employee?.firstName,
      profile.employee?.lastName,
    ]
      .filter(Boolean)
      .join(" ");
    const employeeNumber = profile.employee?.employeeNumber;
    if (!hasEmployee) return fullName;
    return [employeeName, employeeNumber].filter(Boolean).join(" - ");
  };
  const submit = () => {
    const url = "/channelmanagerservice/api/activities/note";
    const body = cloneDeep(data);
    body.subject = bodySubjectHandler() || null;
    body.targetId = task.taskId;
    axios.post(url, body).then(() => {
      const message = "task.details.createNote.successMessage";
      toast.success(message);
      setTask((p) => {
        const task = cloneDeep(p);
        const note: Task.NoteItem = {
          body: body.body as string,
          subject: body.subject as string,
          createdOn: new Date(createdOn as number).toString(),
        };
        task?.noteList?.unshift(note);
        return task;
      });
      setData({});
      toggle();
    });
  };
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="space-y-1">
          <h6 className="text-base font-semibold text-dark">
            <Text>task.details.createNote.drawerTitle</Text>
          </h6>
          <p className="text-sm font-normal text-secondary">
            {convertDate(createdOn, dateFormats.fullDate)}
          </p>
        </Drawer.Header>
        <Drawer.Body>
          <InputGroup
            as="textarea"
            label="task.details.createNote.description"
            placeholder="task.details.createNote.descriptionPlaceholder"
            value={data.body}
            setValue={(body) => setData((p) => ({ ...p, body }))}
            rules={rules.required}
            readOnly={loading.post}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button
            type="button"
            variant="danger"
            onClick={toggle}
            disabled={loading.post}
          >
            <Text>task.details.createNote.cancelButton</Text>
          </Button>
          <Button type="submit" variant="primary" loading={loading.post}>
            <Text>task.details.createNote.submitButton</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
