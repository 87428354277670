import { ComponentProps, ElementType, createContext } from "react";
import { twMerge } from "tailwind-merge";
import Button from "./_Button";

type IconButtonContextType = { iconClassName: string };

export const IconButtonContext = createContext<
  IconButtonContextType | undefined
>(undefined);

export default function IconButton<E extends ElementType = "button">({
  className,
  children,
  ...props
}: ComponentProps<typeof Button<E>>) {
  return (
    <Button
      className={twMerge(
        "inline-flex justify-center items-center p-1.5",
        className
      )}
      {...props}
    >
      <IconButtonContext.Provider value={{ iconClassName: "size-5" }}>
        {children}
      </IconButtonContext.Provider>
    </Button>
  );
}
