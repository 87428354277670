import { Button, Icon, Text } from "../components";
import { SelectedOrder } from "./_ChooseOrder";

type IncidentOrderCardProps = {
  order: SelectedOrder;
  onClear?: (value: null) => void;
};
export default function IncidentOrderCard({
  order,
  onClear,
}: IncidentOrderCardProps) {
  const canClear = !!onClear;
  const hasNumber = !!order.number;
  return (
    <section className="border rounded p-2 space-y-4">
      <h6 className="text-base text-dark">
        <Text>incident.form.attachmentOrder</Text>
      </h6>
      <div className="flex items-start gap-2">
        <Icon name="ReceiptText" size={25} className="text-primary" />
        <div className="flex-1">
          <h6 className="text-base text-dark">{order.name}</h6>
          {hasNumber && (
            <p className="text-sm text-secondary">#{order.number}</p>
          )}
        </div>
        {canClear && (
          <Button
            type="button"
            variant="danger"
            light
            onClick={() => onClear(null)}
          >
            <Icon name="Trash" variant="Bold" />
          </Button>
        )}
      </div>
    </section>
  );
}
