import { Fragment } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { Image, Loading, Text, Translate } from "../../../../components";
import { useAxios, useConverters, useFindAttribute } from "../../../../hooks";
import { FavoriteProduct } from "../../../../types";

type Props = {
  product: FavoriteProduct.Item;
};

export default function ProductCard({ product }: Props) {
  const navigate = useNavigate();
  const findAttribute = useFindAttribute();
  // const { customerData } = useContext(CustomerContext);
  const { convertDate } = useConverters();
  const { axios, loading } = useAxios();
  const productTitleTranslate = findAttribute(
    product.favoriteProduct?.attributeGroups,
    "title"
  );
  // const [details, setDetails] = useState({} as favoriteProductDetails);
  // const [showDetails, toggleDetails] = useToggle(false);
  // const hasDetails = !isEmpty(details);
  const getDetails = async () => {
    if (loading.get) return;
    const productId = product.favoriteProduct?.id;
    const url = `/salesservice/api/salestoolspimproduct/${productId}`;
    const config = { cache: true, error: false };
    const hasData = await axios
      .get(url, config)
      .then(({ data }) => !!data)
      .catch(() => false);
    const message = "toast.error.favoriteProductNotExistsInCurrentChannel";
    if (hasData) return navigate(`/products/${productId}`);
    toast.error(message);
  };
  return (
    <Fragment>
      <button
        type="button"
        className="relative bg-white rounded text-start p-1 pb-2 shadow overflow-hidden"
        onClick={getDetails}
      >
        <div
          data-active={loading.get}
          className="absolute flex-center inset-0 bg-white/5 backdrop-blur-sm opacity-0 pointer-events-none data-active:opacity-100 data-active:pointer-events-auto transition-opacity"
        >
          <Loading.Inline />
        </div>
        <Image
          src={product.favoriteProduct?.avatar?.url}
          alt={product.favoriteProduct?.articleNumber}
          className="w-full aspect-image object-cover rounded-lg mb-2"
          isDynamic
        />
        <div className="space-y-1 px-1">
          <h6 className="whitespace-normal">
            <Translate
              value={productTitleTranslate}
              defaultValue={productTitleTranslate?.[0].labelTitle ?? "--"}
            />
          </h6>
          <p className="text-sm text-secondary">
            #{product.favoriteProduct?.articleNumber}
          </p>
          <p className="text-sm text-secondary">
            <Text>global.addedAt</Text> {convertDate(product.createdAt)}
          </p>
        </div>
      </button>
      {/* <ProductDetails
        isOpen={hasDetails && showDetails}
        toggle={toggleDetails}
        details={details}
      /> */}
    </Fragment>
  );
}
