import { cloneDeep } from "lodash";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { OrderDetailsContext } from ".";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  RadioButton,
  Select,
  Text,
} from "../../../components";
import { now, orderBlockReasons, rules } from "../../../constants";
import { OrderInternalStatus } from "../../../enums";
import { useAxios } from "../../../hooks";
import { Order, togglePropsType } from "../../../types";

type ChangeOrderStatusProps = togglePropsType & {
  onSubmitted: () => void;
};
type Data = Order.InternalState;

export default function ChangeOrderStatus({
  isOpen,
  toggle,
  onSubmitted,
}: ChangeOrderStatusProps) {
  const { axios, loading } = useAxios();
  const orderContext = useContext(OrderDetailsContext);
  const order = orderContext?.order;
  const initData: Data | null = order?.internalState ?? null;
  const [internalState, setInternalState] = useState(initData);
  const statusList = [
    {
      label: "radioButton.active",
      value: OrderInternalStatus.Active,
    },
    {
      label: "radioButton.block",
      value: OrderInternalStatus.Blocked,
    },
  ];
  const isActive = internalState?.internalStatus === OrderInternalStatus.Active;
  const handleSetValue = (key: keyof Data) => {
    return (value: any) => {
      setInternalState((p) => (!!p ? { ...p, [key]: value } : null));
    };
  };
  const submit = () => {
    if (!internalState) return;
    const url = `/salesservice/api/orders/${order?.id}/internal-state`;
    const body = cloneDeep(internalState);
    if (isActive) {
      body.reason = null;
      body.expireAt = null;
    }
    axios.post(url, body).then(() => {
      toast.success("toast.success.orderStatus");
      onSubmitted();
      toggle();
    });
  };
  useEffect(() => {
    if (isOpen) setInternalState(initData);
  }, [isOpen]);
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Text>drawerTitles.orderStatus</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header className="space-y-1">
          <Icon.Wrapper className="mx-auto">
            <Icon name="ReceiptItem" variant="Bold" />
          </Icon.Wrapper>
          <h6>{order?.title}</h6>
          <p className="text-secondary">#{order?.number}</p>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <div>
            {statusList.map((e) => (
              <RadioButton
                key={e.value}
                label={e.label}
                onClick={() => handleSetValue("internalStatus")(e.value)}
                isActive={internalState?.internalStatus === e.value}
                className="w-auto"
              />
            ))}
          </div>
          <Select
            label="formControls.blockReason"
            value={isActive ? "" : internalState?.reason}
            setValue={handleSetValue("reason")}
            placeholder="placeholders.select"
            items={orderBlockReasons}
            rules={isActive ? [] : rules.required}
            disabled={isActive}
          />
          <InputGroup
            label="formControls.expireAt"
            value={isActive ? "" : internalState?.expireAt}
            setValue={handleSetValue("expireAt")}
            type="date"
            // rules={isActive ? [] : rules.required}
            disabled={isActive}
            minDate={now}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button
            type="button"
            variant="danger"
            onClick={toggle}
            disabled={loading.post}
          >
            <Text>button.cancel</Text>
          </Button>
          <Button variant="primary" type="submit" loading={loading.post}>
            <Text>button.submitChanges</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
