import { useContext } from "react";
import { ProgramDetailsContext } from "..";
import { Icon, Text, Types, Wrapper } from "../../../../../components";
export default function Configuration() {
  const { program } = useContext(ProgramDetailsContext);
  const generalItem = [
    {
      label: "suppliers.programConfiguration.programType",
      value: <Types.Program id={program?.programType} />,
    },
    {
      label: "suppliers.programConfiguration.useDefaultMinimumShippingTime",
      value: !!program?.shipping?.durationUnit ? (
        <Icon
          name="TickCircle"
          variant="Bold"
          className="size-5 text-primary"
        />
      ) : (
        <Icon
          name="MinusCirlce"
          variant="Bold"
          className="size-5 text-placeholder"
        />
      ),
    },
    {
      label: "suppliers.programConfiguration.minimumShippingTime",
      value: program?.shipping?.minimumShippingDuration,
    },
    // { label: "suppliers.programConfiguration.useDefaultBankInfo", value:program?.bankInfo.  },
    {
      label: "suppliers.programConfiguration.bankName",
      value: program?.bankInfo?.bankName,
    },
    {
      label: "suppliers.programConfiguration.accountOwnerName",
      value: program?.bankInfo?.accountOwnerName,
    },
    {
      label: "suppliers.programConfiguration.iban",
      value: program?.bankInfo?.iban,
    },
    {
      label: "suppliers.programConfiguration.bic",
      value: program?.bankInfo?.bic,
    },
  ];
  return (
    <Wrapper>
      <Wrapper.Header className="border-b-0">
        <h2 className="text-dark text-sm font-medium">
          <Text>suppliers.programConfiguration.title</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body>
        <ul className="w-full divide-y">
          {generalItem.map((e) => (
            <li
              key={e.label}
              className="flex items-start gap-5 py-3 first:pt-0 last:pb-0"
            >
              <h6 className="flex-1 text-secondary text-sm font-normal">
                <Text>{e.label}</Text>
              </h6>
              <p className="flex-[2] text-gray-900 text-sm font-normal">
                {e.value}
              </p>
            </li>
          ))}
        </ul>
      </Wrapper.Body>
    </Wrapper>
  );
}
