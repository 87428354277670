import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

export default function usePrompt(message: string, when: boolean = true) {
  const { navigator } = useContext(NavigationContext);
  const reloadConfirm = () => {
    window.onbeforeunload = when ? () => message : null;
    return () => {
      window.onbeforeunload = null;
    };
  };
  const handleNavigator = () => {
    const push = navigator.push;
    navigator.push = (...args: Parameters<typeof push>) => {
      const result = when ? window.confirm(message) : true;
      result && push(...args);
    };
    return () => {
      navigator.push = push;
    };
  };
  useEffect(handleNavigator, [navigator, when]);
  useEffect(reloadConfirm, [message, when]);
}
