import noItemsIcon from "../assets/icons/not-approved.svg";
import Image from "./_Image";
import Text from "./_Text";
import Wrapper from "./_Wrapper";
export default function CompanyIsNotApproved() {
  return (
    <Wrapper>
      <Wrapper.Body>
        <div className="w-full space-y-4 text-center px-2 pt-20 pb-28 text-placeholder">
          <Image
            className="block w-64 mx-auto aspect-square object-contain"
            src={noItemsIcon}
            alt="Company Is Not Approved Yet"
          />
          <p className="text-lg font-semibold">
            <Text>global.companyIsNotApproved</Text>
          </p>
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
