import { Dispatch, Fragment } from "react";
import { Button, Icon, Image, Status, Text } from "../../../../components";
import { dateFormats, languages } from "../../../../constants";
import { PrintRequestStatus } from "../../../../enums";
import { useConverters, useGetFile, useToggle } from "../../../../hooks";
import { downloadFile } from "../../../../methods";
import { Product } from "../../../../types";
import PrintRequestDetails from "./PrintRequestDetails";
import RemoveConfirm from "./RemoveConfirm";

type Props = {
  printRequest: Product.PrintRequestItem;
  onRemoved: Dispatch<string>;
};

export default function PrintRequestRow({ printRequest, onRemoved }: Props) {
  const { convertDate, convertAmount } = useConverters();
  // const isOnProcess = printRequest.status === PrintRequestStatus.OnProcess;
  const [getFile, fileLoading] = useGetFile();
  const [showDetails, toggleDetails] = useToggle(false);
  const [showConfirm, toggleConfirm, hideConfirm] = useToggle(false);
  const isCompleted = printRequest.status === PrintRequestStatus.Completed;
  const fileUrl = printRequest.documentFile?.fileUrl;
  const hasFileUrl = !!fileUrl;
  const canDownload = hasFileUrl && isCompleted;
  const language = languages.find((e) => e.id === printRequest.language);
  const hasLanguage = !!language;
  const hasVk2 =
    !!printRequest.pimProductVk2Price &&
    printRequest.pimProductVk1Price?.amount !==
      printRequest.pimProductVk2Price?.amount;
  const download = () => {
    if (!hasFileUrl) return;
    getFile(fileUrl).then((data) => {
      downloadFile(data, printRequest.title ?? "");
    });
  };

  return (
    <Fragment>
      <tr>
        <td>
          <div className="flex items-center gap-4">
            <Icon.Wrapper>
              <Icon name="Printer" variant="Bold" size={28} />
            </Icon.Wrapper>
            <div className="flex-1 space-y-1">
              <h6 className="w-full text-base text-dark font-medium">
                {printRequest.title}
              </h6>
              {hasLanguage && (
                <div className="flex items-center gap-2">
                  <Image
                    src={language.icon}
                    alt={language.name}
                    className="inline-block w-5"
                  />
                  <span className="uppercase text-gray-900 text-xs font-medium">
                    {language.name}
                  </span>
                </div>
              )}
              <p className="w-full text-xs text-secondary font-medium">
                {convertDate(printRequest.createdAt, dateFormats.fullDate)}
              </p>
            </div>
          </div>
        </td>
        <td className="text-center">
          <Status.PrintRequest id={printRequest.status} />
        </td>
        <td className="text-center space-y-1">
          {hasVk2 && (
            <p className="text-sm text-secondary font-medium">
              {convertAmount(printRequest.pimProductVk2Price?.amount)}
            </p>
          )}
          <p className="text-sm text-dark font-medium">
            {convertAmount(printRequest.pimProductVk1Price?.amount)}
          </p>
        </td>
        <td className="space-x-2">
          <Button
            type="button"
            variant="primary"
            onClick={download}
            loading={fileLoading}
            disabled={!canDownload}
          >
            <Icon name="Import" variant="Bold" className="size-5" />{" "}
            <Text>products.details.printHistory.downloadButton</Text>
          </Button>
          {/* {isOnProcess && (
          <Button type="button" variant="danger" light>
          <Text>products.details.printHistory.cancelButton</Text>
          </Button>
          )} */}
          <Button type="button" variant="primary" light onClick={toggleDetails}>
            <Text>products.details.printHistory.detailsButton</Text>
          </Button>
        </td>
      </tr>
      <PrintRequestDetails
        isOpen={showDetails}
        toggle={toggleDetails}
        printRequest={printRequest}
        onRemove={toggleConfirm}
      />
      <RemoveConfirm
        isOpen={showConfirm}
        toggle={hideConfirm}
        onRemoved={onRemoved}
        printRequest={printRequest}
      />
    </Fragment>
  );
}
