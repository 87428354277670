import { useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import { Accordion, Table, Text, Wrapper } from "../../../../components";
import LineItemRow from "./LineItemRow";

export default function LineItems() {
  const { basicInfo } = useContext(SellOffProductDetailsContext);
  const bundleItems = basicInfo?.pimProduct?.bundleItems;
  return (
    <Accordion defaultActiveKey="sub-products">
      <Wrapper className="col-span-full">
        <Accordion.Item eventKey="sub-products">
          <Accordion.Toggle>
            <h2 className="text-sm text-dark">
              <Text>sellOffProducts.details.subProducts</Text>
            </h2>
          </Accordion.Toggle>
          <Accordion.Body>
            <Table>
              <thead>
                <tr className="text-sm text-secondary font-medium">
                  <td className="!ps-[4.75rem]">
                    <Text>sellOffProducts.details.product</Text>
                  </td>
                  <td>
                    <Text>sellOffProducts.details.quantity</Text>
                  </td>
                  <td>
                    <Text>sellOffProducts.details.price</Text>
                  </td>
                  <td>
                    <Text>sellOffProducts.details.action</Text>
                  </td>
                </tr>
              </thead>
              <Table.Body>
                {bundleItems?.map((e) => (
                  <LineItemRow key={e.id} lineItem={e} />
                ))}
              </Table.Body>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      </Wrapper>
    </Accordion>
  );
}
