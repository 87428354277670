const task = {
  list: {
    createNewButton: "Neu erstellen",
    assignedToMe: "Mir zugewiesen",
    assignedToDepartment: "Abteilung zugewiesen",
    createdAt: "Erstellt am",
    dueDate: "Fälligkeitsdatum",
    detailsButton: "Details"
  },
  filter: {
    title: "Filter",
    resetFilterBtn: "Filter zurücksetzen",
    taskStatus: "Aufgabenstatus",
    cancelBtn: "Abbrechen",
    submitBtn: "Filter anwenden"
  },
  create: {
    title: "Neue Aufgabe",
    cancelButton: "Abbrechen",
    submitButton: "Aufgabe erstellen",
    subject: "Aufgabenthema",
    subjectPlaceholder: "Thema eingeben",
    description: "Aufgabenbeschreibung",
    descriptionPlaceholder: "Eine Notiz hinzufügen",
    priority: "Priorität",
    duration: "Dauer",
    dueDate: "Fälligkeitsdatum",
    assignEmployee: "Zugewiesener Mitarbeiter",
    successMessage: "Aufgabe wurde erstellt."
  },
  details: {
    cancelButton: "Aufgabe abbrechen",
    completeButton: "Aufgabe abschließen",
    generalTab: "Allgemein",
    activitiesTab: "Aktivitäten",
    taskCreatedAt: "Erstellt am",
    taskDuration: "Dauer",
    taskDueDate: "Fälligkeitsdatum",
    general: {
      targetTitle: "Ziel",
      submitText: "Bitte Änderungen einreichen",
      submitButton: "Änderungen einreichen",
      subject: "Aufgabenthema",
      subjectPlaceholder: "Thema eingeben",
      description: "Aufgabenbeschreibung",
      descriptionPlaceholder: "Eine Notiz hinzufügen",
      priority: "Priorität",
      duration: "Dauer",
      dueDate: "Fälligkeitsdatum",
      assignEmployee: "Zugewiesener Mitarbeiter",
      successMessage: "Aufgabe wurde aktualisiert."
    },
    activities: {
      addNoteButton: "Aktivitätsnotiz hinzufügen"
    },
    createNote: {
      drawerTitle: "Neue Aktivitätsnotiz erstellen",
      description: "Beschreibung",
      descriptionPlaceholder: "Eine Notiz zu diesem Thema hinzufügen",
      cancelButton: "Abbrechen",
      submitButton: "Aktivitätsnotiz hinzufügen",
      successMessage: "Notiz erfolgreich erstellt."
    }
  },
  changeStatus: {
    title: "Aufgabenstatus",
    cancelButton: "Abbrechen",
    completedButton: "Abgeschlossen",
    discardButton: "Verwerfen und schließen",
    canceledButton: "Abgebrochen",
    successMessage: "Status wurde geändert."
  },
  durationItems: {
    "1m": "1 Minute",
    "15m": "15 Minuten",
    "30m": "30 Minuten",
    "45m": "45 Minuten",
    "1h": "1 Stunde",
    "1.5h": "1,5 Stunden",
    "2h": "2 Stunden",
    "2.5h": "2,5 Stunden",
    "3h": "3 Stunden",
    "3.5h": "3,5 Stunden",
    "4h": "4 Stunden",
    "4.5h": "4,5 Stunden",
    "5h": "5 Stunden",
    "5.5h": "5,5 Stunden",
    "6h": "6 Stunden",
    "6.5h": "6,5 Stunden",
    "7h": "7 Stunden",
    "7.5h": "7,5 Stunden",
    "8h": "8 Stunden",
    "1d": "1 Tag",
    "2d": "2 Tage",
    "3d": "3 Tage"
  }
};
export default task;
