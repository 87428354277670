import { useEffect, useMemo, useState } from "react";
import { LineItemType } from "../enums";
import { LineItem, OptionalDiscount } from "../types";
import useAxios from "./useAxios";

type Config = { lineItem: LineItem.Item; force?: boolean };
type UseOptionalDiscounts = [OptionalDiscount[], boolean];

export default function useOptionalDiscounts({
  lineItem,
  force = true,
}: Config): UseOptionalDiscounts {
  const { axios, loading } = useAxios();
  const [optionalDiscounts, setOptionalDiscounts] = useState<
    OptionalDiscount[]
  >([]);
  const lineItemType = useMemo(() => {
    // const availableTypes = [
    //   LineItemType.IwofurnProduct,
    //   LineItemType.ManualProduct,
    // ];
    const pimProductTypes = [
      LineItemType.PimProduct,
      LineItemType.PimBundle,
      LineItemType.PimBundleItem,
    ];
    // const isAvailable = availableTypes.includes(lineItem.lineItemType);
    const isPimProduct = pimProductTypes.includes(lineItem.lineItemType);
    if (isPimProduct) return LineItemType.PimProduct;
    // if (isAvailable) return lineItem.lineItemType;
    // return null;
    return lineItem.lineItemType;
  }, [lineItem]);
  const getOptionalDiscounts = () => {
    if (!force) return;
    const url =
      "/priceengineservice/api/sale-price-calculation/available-discounts";
    const body = {
      salePriceTemplateId: null,
      supplierId: lineItem.supplier?.supplierId ?? null,
      programId: lineItem.supplierProgram?.supplierProgramId ?? null,
      brandId: null,
      productId: lineItem.product?.pimProductId ?? null,
      lineItemType: lineItemType,
      // saleChannelId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      // calculationProcessType: 0,
    };
    axios.post(url, body).then(({ data }) => {
      setOptionalDiscounts(data);
    });
  };
  useEffect(getOptionalDiscounts, [lineItem, force]);
  return [optionalDiscounts, loading.post];
}
