import { Button, Icon, Modal, Status, Text } from "../components";
import { usePermission } from "../hooks";
import { Incident, togglePropsType } from "../types";

type IncidentStatusInfoProps = togglePropsType & {
  incident: Incident.Item | Incident.Details;
  onChangeStatus: (id: string) => void;
};

export default function IncidentStatusInfo({
  isOpen,
  toggle,
  incident,
  onChangeStatus,
}: IncidentStatusInfoProps) {
  const canEdit = usePermission("CMS_IncidentFullAccess", "CMS_UpdateIncident");
  const handleChangeStatus = () => {
    onChangeStatus(incident.crmIncidentId);
    toggle();
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6 className="text-dark text-base">
          <Text>incident.statusInfo.statusInfoTitle</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="flex-center flex-col text-center gap-1">
        <Icon name="DocumentText1" className="text-primary" size="2rem" />
        <h6 className="text-dark text-base">{incident.title}</h6>
        <Status.Incident
          status={incident.status}
          statusReason={incident.statusReason}
        />
        <br />
        {/* <p className="text-base text-secondary">
          {incident.statusReason ?? "--"}
        </p> */}
        {canEdit && (
          <Button type="button" variant="primary" onClick={handleChangeStatus}>
            <Text>incident.statusInfo.changeStatusBtn</Text>
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button type="button" variant="primary" light onClick={toggle}>
          <Text>incident.statusInfo.closeBtn</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
