import isEmpty from "lodash/isEmpty";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import emailIcon from "../../../assets/icons/email.svg";
import locationIcon from "../../../assets/icons/location.svg";
import telephoneIcon from "../../../assets/icons/telephone.svg";
import {
  BackButton,
  Button,
  Icon,
  Image,
  Layout,
  Loading,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { withPermission } from "../../../hoc";
import { useAxios, usePermission, useToggle } from "../../../hooks";
import { copyText, showCallNumber } from "../../../methods";
import { Customer } from "../../../types";
import AccountInfo from "./AccountInfo";
import Addresses from "./Addresses";
import Commissions from "./Commissions";
import Communication from "./Communication";
import Favorites from "./Favorites";
import Incidents from "./Incidents";
import Offers from "./Offers";
import Orders from "./Orders";
import Overview from "./Overview";
type customerContextType = {
  customerData: Customer.Details;
  setCustomerData: Dispatch<SetStateAction<Customer.Details | undefined>>;
  customerAccountInfo: Customer.Account | null;
  customerHasAccount: boolean;
  addressErrorMsg?: string;
};
export const CustomerContext = createContext({} as customerContextType);
function CustomerDetails() {
  const { customerId } = useParams();
  const { axios, loading } = useAxios();
  const offerActive = usePermission(
    "SC_SaleServiceOfferFullAccess",
    "SC_GetSaleServiceOffer"
  );
  const orderActive = usePermission("SC_GetSaleServiceOrder");
  const incidentActive = usePermission(
    "CMS_IncidentFullAccess",
    "CMS_GetIncident"
  );
  const commissionActive = usePermission(
    "SC_SaleServiceCommissionGroupFullAccess",
    "SC_GetSaleServiceCommissionGroup"
  );
  const initTabs = [
    {
      label: "tabs.overview",
      id: "0",
      Component: Overview,
      active: true,
    },
    {
      label: "tabs.communication",
      id: "1",
      Component: Communication,
      active: true,
    },
    {
      label: "tabs.offers",
      id: "2",
      Component: Offers,
      active: offerActive,
    },
    {
      label: "tabs.orders",
      id: "3",
      Component: Orders,
      active: orderActive,
    },
    {
      label: "tabs.favoritesProducts",
      id: "4",
      Component: Favorites,
      active: true,
    },
    {
      label: "tabs.customerIncidents",
      id: "5",
      Component: Incidents,
      active: incidentActive,
    },
    {
      label: "tabs.commissions",
      id: "6",
      Component: Commissions,
      active: commissionActive,
    },
    {
      label: "tabs.addresses",
      id: "7",
      Component: Addresses,
      active: true,
    },
  ];
  const tabs = initTabs.filter((e) => e.active);
  const [data, setData] = useState<Customer.Details>();
  const [accountInfo, setAccountInfo] = useState<Customer.Account | null>(null);
  const [showAccountInfo, toggleAccountInfo] = useToggle(false);
  const hasAccount = !!data?.customerAccountId;
  const hasAvatar = !!data?.avatar;
  const signInItems = [
    {
      icon: locationIcon,
      value: data?.address?.city,
    },
    {
      icon: emailIcon,
      value: data?.emailAddress,
    },
    {
      icon: telephoneIcon,
      value: showCallNumber(data?.mobileNumber),
    },
  ];
  const copyCimNumber = () => {
    copyText(data?.cimNumber).then(() => {
      toast.success("toast.success.copyCimNumber");
    });
  };
  const getAccountInfo = (id: string | null) => {
    if (!id) return;
    const url = `/accountservice/api/customeraccounts/${id}`;
    axios.get(url).then(({ data }) => {
      setAccountInfo(data);
    });
  };
  const getData = () => {
    const url = `/accountservice/api/customers/${customerId}`;
    axios.get(url).then(({ data }) => {
      const result = { ...data };
      result.communicationChannel ??= {
        application: false,
        email: false,
        phone: false,
        sms: false,
        post: false,
        socialMedia: false,
        newsletter: false,
      };
      setData(result);
      getAccountInfo(result.customerAccountId);
    });
  };
  useEffect(getData, [customerId]);
  if (loading.get) return <Loading.Layout />;
  if (!data) return null;
  return (
    <Layout>
      <Tabs activeKey={tabs[0].id}>
        <Layout.Header>
          <BackButton to="/customers" />
        </Layout.Header>
        <Layout.Body className="space-y-4">
          <Wrapper>
            <Wrapper.Body className="flex items-start gap-4">
              <section className="w-24 h-24 flex-center rounded text-success bg-light overflow-hidden">
                {hasAvatar ? (
                  <Image
                    src={data.avatar?.url}
                    alt={data.firstName + data.lastName}
                    className="w-full h-full object-cover"
                    isDynamic
                  />
                ) : (
                  <Icon name="User" variant="Bold" className="w-full h-full" />
                )}
              </section>
              <section className="flex-1 space-y-3">
                <div className="flex gap-4 items-center">
                  <h6 className="mr-auto">
                    {data?.firstName} {data?.lastName}
                  </h6>
                  <Button
                    type="button"
                    data-has-account={hasAccount}
                    variant={null}
                    className="w-fit flex-center border border-gray-200 rounded-md p-1 filter data-[has-account='false']:grayscale"
                    onClick={toggleAccountInfo}
                  >
                    <Icon
                      name="Mobile"
                      variant="Bold"
                      size={20}
                      className="text-danger me-1"
                    />
                    {hasAccount ? (
                      <Text>button.mobileAccount</Text>
                    ) : (
                      <Text>button.haveNotMobileAccount</Text>
                    )}
                  </Button>
                  <Status.Customer id={accountInfo?.status} />
                </div>
                <span
                  className="w-fit block text-secondary"
                  onClick={copyCimNumber}
                >
                  #{data.cimNumber}
                </span>
                <div className="text-secondary flex items-center gap-2 [&_img]:inline-block [&_img]:w-4 [&_img]:mr-1">
                  {signInItems.map((e) => (
                    <span key={e.icon} hidden={!e.value}>
                      <img src={e.icon} alt={e.value?.toString()} />
                      {e.value}
                    </span>
                  ))}
                </div>
              </section>
            </Wrapper.Body>
            <Wrapper.Footer className="py-0">
              <Tabs.ButtonGroup>
                {tabs.map((e) => (
                  <Tabs.Button key={e.id} eventKey={e.id}>
                    <Text>{e.label}</Text>
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
            </Wrapper.Footer>
          </Wrapper>
          <CustomerContext.Provider
            value={{
              customerData: data,
              setCustomerData: setData,
              customerHasAccount: hasAccount,
              customerAccountInfo: accountInfo,
            }}
          >
            {tabs.map(({ Component, ...e }) => (
              <Tabs.Item key={e.id} eventKey={e.id}>
                {!isEmpty(data) && <Component />}
              </Tabs.Item>
            ))}
            <AccountInfo isOpen={showAccountInfo} toggle={toggleAccountInfo} />
          </CustomerContext.Provider>
        </Layout.Body>
      </Tabs>
    </Layout>
  );
}
export default withPermission(CustomerDetails, [
  "AS_CustomerFullAccess",
  "AS_GetCustomer",
]);
