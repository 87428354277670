import { Button, Icon, Modal, Text } from "../../../../components";
import { LineItemType, PimProductType } from "../../../../enums";
import { iconNameType, LineItem, togglePropsType } from "../../../../types";
type selectProductModalType = togglePropsType & {
  parent: LineItem.Item | null;
  onConfigProduct: () => void;
  onSearchProduct: (type: PimProductType) => void;
  onSearchSellOffProduct: () => void;
  onImportBasket: () => void;
  onAddBundle: () => void;
  onAddBundleCatalogue: () => void;
  onCreateFromCatalogue: () => void;
};
type itemsType = {
  icon: iconNameType;
  label: string;
  desc: string;
  onClick: () => void;
  active: boolean;
};
export default function SelectProductModal({
  isOpen,
  toggle,
  parent,
  onConfigProduct,
  onSearchProduct,
  onSearchSellOffProduct,
  onImportBasket,
  onAddBundle,
  onAddBundleCatalogue,
  onCreateFromCatalogue,
}: selectProductModalType) {
  const parentIsBundle = parent?.lineItemType === LineItemType.ManualBundle;
  const initItems: itemsType[] = [
    // {
    //   icon: "SearchNormal1",
    //   label: "actionTitles.configProduct",
    //   desc: "actionDescriptions.configProduct",
    //   onClick: onConfigProduct,
    //   active: true,
    // },
    {
      icon: "SearchNormal1",
      label: "actionTitles.searchProduct",
      desc: "actionDescriptions.searchProduct",
      onClick: () => onSearchProduct(PimProductType.Standard),
      active: !parentIsBundle,
    },
    {
      icon: "BoxSearch",
      label: "actionTitles.searchBundleProduct",
      desc: "actionDescriptions.searchBundleProduct",
      onClick: () => onSearchProduct(PimProductType.Bundle),
      active: !parentIsBundle,
    },
    {
      icon: "SearchNormal1",
      label: "actionTitles.searchSellOffProduct",
      desc: "actionDescriptions.searchSellOffProduct",
      onClick: onSearchSellOffProduct,
      active: !parentIsBundle,
    },
    {
      icon: "Dropbox",
      label: "actionTitles.importBasket",
      desc: "actionDescriptions.importBasket",
      onClick: onImportBasket,
      active: true,
    },
    // {
    //   icon: "Dropbox",
    //   label: "actionTitles.addBundleLineItem",
    //   desc: "actionDescriptions.addBundleLineItem",
    //   onClick: onAddBundle,
    //   active: !parentIsBundle,
    // },
    {
      icon: "Dropbox",
      label: "actionTitles.addBundleCatalogueLineItem",
      desc: "actionDescriptions.addBundleCatalogueLineItem",
      onClick: onAddBundleCatalogue,
      active: !parentIsBundle,
    },
    {
      icon: "Element",
      label: "actionTitles.createProductFromCatalogue",
      desc: "actionDescriptions.createProductFromCatalogue",
      onClick: onCreateFromCatalogue,
      active: true,
    },
  ];
  const items = initItems.filter((e) => e.active);
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="text-center">
        <Text>modalTitles.selectProductList</Text>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        {items.map((e) => (
          <button
            key={e.label}
            type="button"
            className="w-full flex flex-center gap-4 p-6 border border-[#DEE8F1] bg-[#FBFCFD] rounded"
            onClick={() => {
              e.onClick();
              toggle();
            }}
          >
            <Icon
              name={e.icon}
              variant="Bulk"
              size={40}
              className="text-primary"
            />
            <div className="text-start flex-1 space-y-1">
              <h6>
                <Text>{e.label}</Text>
              </h6>
              <p className="text-secondary">
                <Text>{e.desc}</Text>
              </p>
            </div>
          </button>
        ))}
      </Modal.Body>
      <Modal.Footer className="flex flex-center">
        <Button light onClick={toggle}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
