import OrderDetails from "../pages/Pickup/OrderDetails";
import OrderList from "../pages/Pickup/OrderList";

const pickup = [
  {
    path: "pickup",
    element: <OrderList />,
  },
  {
    path: "pickup/:orderId",
    element: <OrderDetails />,
  },
];

export default pickup;
