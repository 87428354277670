import { useContext } from "react";
import { ProductDetailsContext } from "..";
import { Icon, Table, Text, Translate, Wrapper } from "../../../../components";
import { useConverters, useFindAttribute } from "../../../../hooks";
import { ProductPriceContext } from "../ProductPriceProvider";
import LineItemRow from "./LineItemRow";

export default function Items() {
  const findAttribute = useFindAttribute();
  const { bundleItems, salesPriceLoading } = useContext(ProductPriceContext);
  const { convertAmount } = useConverters();
  const { product, hasPrice2 } = useContext(ProductDetailsContext);
  const length = bundleItems?.length ?? 0;

  return (
    <Wrapper>
      <Wrapper.Header>
        <h6 className="text-base to-dark font-medium">
          <Text>products.details.itemsTitle</Text>
        </h6>
      </Wrapper.Header>
      <Wrapper.Body className="space-y-5">
        <section className="flex items-start border rounded gap-2 p-2">
          <Icon name="Dropbox" className="text-primary size-5" />
          <div className="flex-1 space-y-1.5">
            <h6 className="flex-1 text-sm text-dark font-medium">
              <Translate
                value={findAttribute(product.attributeGroups, "title")}
                defaultValue={product.originalName}
              />
            </h6>
            <p className="text-xs text-secondary font-medium">
              <Text length={length}>products.details.itemsLength</Text>
            </p>
            {hasPrice2 && (
              <p className="text-xs text-secondary font-medium">
                <Text>products.details.vk2</Text>:{" "}
                <span className="line-through">
                  {convertAmount(product.vk2Price?.amount)}
                </span>
              </p>
            )}
            <p className="text-xs text-dark font-medium">
              <Text>products.details.vk1</Text>:{" "}
              {convertAmount(product.vk1Price?.amount)}
            </p>
          </div>
        </section>
        <Table.Wrapper>
          <Table>
            {!salesPriceLoading && (
              <thead>
                <tr className="text-sm text-secondary font-medium">
                  <td className="!ps-[4.75rem]">
                    <Text>products.details.headerProducts</Text>
                  </td>
                  <td></td>
                  <td>
                    <Text>products.details.headerQuantity</Text>
                  </td>
                  <td>
                    <Text>products.details.headerItemPrice</Text>
                  </td>
                  <td>
                    <Text>products.details.headerPrice</Text>
                  </td>
                  <td>
                    <Text>products.details.headerAction</Text>
                  </td>
                </tr>
              </thead>
            )}
            <Table.Body loading={salesPriceLoading}>
              {bundleItems?.map((e) => (
                <LineItemRow key={e.id} lineItem={e} />
              ))}
            </Table.Body>
          </Table>
        </Table.Wrapper>
      </Wrapper.Body>
    </Wrapper>
  );
}
