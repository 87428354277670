import { Fragment, useContext, useEffect, useState } from "react";
import { CompanyContext } from "..";
import { Button, Icon, Table, Text, Wrapper } from "../../../../components";
import { useAxios, useToggle } from "../../../../hooks";
import { showCallNumber } from "../../../../methods";
import { Company } from "../../../../types";
import ContactPersonForm from "./ContactPersonForm";
import PersonRow from "./PersonRow";

export default function ContactPerson() {
  const { axios, loading } = useAxios();
  const { companyData } = useContext(CompanyContext);
  const [persons, setPersons] = useState<Company.Person[]>([]);
  const [showAddPerson, toggleAddPerson] = useToggle(false);
  const [showEditDefaultPerson, toggleEditDefaultPerson] = useToggle(false);
  const hasPosition = !!companyData.position;
  const getPersons = () => {
    const url = `/accountservice/api/customers/${companyData.id}/as-company/company-contact-persons`;
    axios.get(url).then(({ data }) => {
      setPersons(data);
    });
  };
  useEffect(getPersons, []);
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Header>
          <h6 className="text-lg text-dark">
            <Text>company.details.contactPerson.defaultPersonTitle</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-4">
          <section className="flex items-start gap-2">
            <span className="leading-6">
              <Icon name="ProfileCircle" className="text-primary" />
            </span>
            <div>
              <h6 className="text-base text-dark">
                {companyData.firstName} {companyData.lastName}
              </h6>
              <p className="text-secondary text-sm">
                {companyData.emailAddress} |{" "}
                {showCallNumber(companyData.mobileNumber)}
              </p>
              {hasPosition && (
                <p className="text-secondary text-sm">
                  <Text>company.details.contactPerson.position</Text>:{" "}
                  {companyData.position}
                </p>
              )}
            </div>
          </section>
          <Button
            type="button"
            variant="primary"
            light
            onClick={toggleEditDefaultPerson}
          >
            <Icon name="Edit2" />{" "}
            <Text>company.details.contactPerson.editBtn</Text>
          </Button>
        </Wrapper.Body>
      </Wrapper>
      <section className="flex items-center justify-between">
        <h6 className="text-base text-dark">
          <Text>company.details.contactPerson.contactPersonsTitle</Text>
        </h6>
        <Button type="button" variant="primary" onClick={toggleAddPerson}>
          <Icon name="Add" />{" "}
          <Text>company.details.contactPerson.addPersonBtn</Text>
        </Button>
      </section>
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              <Table.Body loading={loading.get}>
                {persons
                  .filter((e) => !e.isPrimary)
                  .map((e) => (
                    <PersonRow key={e.id} person={e} onSubmitted={getPersons} />
                  ))}
              </Table.Body>
            </Table>
          </Table.Wrapper>
        </Wrapper.Body>
      </Wrapper>
      <ContactPersonForm
        isDefault
        isOpen={showEditDefaultPerson}
        toggle={toggleEditDefaultPerson}
      />
      <ContactPersonForm
        isOpen={showAddPerson}
        toggle={toggleAddPerson}
        onSubmitted={getPersons}
      />
    </Fragment>
  );
}
