import { max } from "lodash";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  Button,
  Drawer,
  Icon,
  InputGroup,
  Loading,
  Text,
} from "../../../../components";
import { useAxios, useConverters, useToggle } from "../../../../hooks";
import { CommissionGroup } from "../../../../types";
import ChooseOrderEdit from "./ChooseOrderOnEdit";
interface ChooseOrderProps {
  isOpen?: boolean;
  toggle: () => void;
  toggleEdit: () => void;
  getData: () => void;
  commissionGroup: CommissionGroup.CommissionGroupsItemProps;
}

interface CreateCommisionPut {
  title: string;
  deliveryDate: string;
}

export default function EditCommissionGroup({
  isOpen = false,
  toggle,
  commissionGroup,
  toggleEdit,
  getData,
}: ChooseOrderProps) {
  const { axios, loading } = useAxios();
  const [isOpenChoosser, toggleChooser] = useToggle();
  const [orders, setOrders] = useState<CommissionGroup.OrderProps[]>([]);
  const [updateData, setUpdateData] = useState<any>({});
  const [maxOrderDate, setMaxORderDate] = useState<string | undefined>("");
  const { convertAmount } = useConverters();

  useEffect(() => {
    if (isOpen) {
      setUpdateData(commissionGroup);
      setOrders(commissionGroup?.orders);
    }
  }, [isOpen]);

  useEffect(() => {
    const orderDates = orders?.map(
      (order: any) => new Date(order?.desiredDeliveryDate)
    );
    const maxDate = max(orderDates);
    setMaxORderDate(maxDate?.toISOString());
  }, [orders]);

  const onChangeHandler = (key: keyof CreateCommisionPut) => {
    return (value: any) => setUpdateData((p: any) => ({ ...p, [key]: value }));
  };

  const onSubmitHandler = () => {
    const url = `salesservice/api/commissiongroups/${commissionGroup.id}`;
    const body = {
      ...updateData,
      deliveryDate: new Date(updateData?.deliveryDate).toISOString(),
    };
    axios.put(url, body).then(() => {
      const message = "commissions.updateCommissionSuccessfuly";
      toast.success(message);
      getData();
    });
  };

  const onDeleteOrders = (id: string) => {
    const url = `salesservice/api/commissiongroups/${commissionGroup?.id}/order/${id}`;

    axios.delete(url).then(() => {
      const message = "commissions.updateCommissionSuccessfuly";
      toast.success(message);
      getData();
      const clone = orders?.filter((order: any) => {
        if (order?.id) {
          return order?.id !== id;
        }
        return order?.orderId !== id;
      });
      if (!clone?.length) {
        toggle();
        toggleEdit();
      }
      setOrders(clone);
    });
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
        <Drawer.Menu>
          <Drawer.Header>
            <Text>commissions.edit</Text>
          </Drawer.Header>
          <Drawer.Body className="space-y-6">
            {loading.get ? (
              <Loading.Inline />
            ) : (
              <>
                <InputGroup
                  label="commissions.delivaryDate"
                  value={updateData?.deliveryDate}
                  setValue={onChangeHandler("deliveryDate")}
                  type="date"
                  minDate={maxOrderDate}
                />
                <InputGroup
                  label="commissions.title"
                  value={updateData?.title}
                  setValue={onChangeHandler("title")}
                />
                <div className="flex justify-between items-center">
                  <p className="font-medium">
                    <Text>commissions.assignedOrders</Text>
                  </p>
                  <Button light onClick={toggleChooser}>
                    {/* <Button light> */}
                    <Icon name="Add" />{" "}
                    <Text>commissions.assignCustomerOrders</Text>
                  </Button>
                </div>
                {orders?.map((order: any) => {
                  return (
                    <div
                      className="flex space-x-2 border justify-between rounded-md p-2"
                      key={order.id}
                    >
                      <div className="flex space-x-2 ">
                        <Icon name="ReceiptText" className="text-primary" />
                        <div className="flex flex-col space-y-2">
                          <p className="text-sm font-medium">{order?.title}</p>
                          <p className="text-xs text-gray-400">
                            #{order.number}
                          </p>
                          <p className="text-xs text-gray-400">
                            {convertAmount(order.totalAmount)}
                          </p>
                          <span className="flex items-center gap-1">
                            <p className="text-xs text-gray-600 space-x-1">
                              <Icon
                                name="LocationTick"
                                className="text-danger text-sm"
                              />
                              <span>
                                {order?.store?.storeName || order?.storeName}
                              </span>
                            </p>
                          </span>
                        </div>
                      </div>
                      {loading.delete ? (
                        <Icon
                          name="Menu"
                          variant="Bold"
                          className="text-primary animate-spin"
                        />
                      ) : (
                        <Icon
                          name="Trash"
                          variant="Bold"
                          className="text-gray-400 cursor-pointer hover:text-danger"
                          onClick={() =>
                            onDeleteOrders(order.orderId || order.id)
                          }
                        />
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-end gap-4">
            <Button type="button" variant="danger" onClick={toggle}>
              <Text>button.cancel</Text>
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={onSubmitHandler}
              loading={loading.update}
              disabled={!updateData?.deliveryDate}
            >
              <Text>commissions.update</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <ChooseOrderEdit
        isOpen={isOpenChoosser}
        toggle={toggleChooser}
        orders={orders}
        setOrders={setOrders}
        id={commissionGroup?.id}
        getCommissionsData={getData}
        deliveryDate={updateData?.deliveryDate}
      />
    </>
  );
}
