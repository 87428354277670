import { Icon, IconButton, Text } from "../components";
import { Optional, Order } from "../types";

type SecondSalesPersonCardProps = {
  salesPerson: Optional<Order.SecondSalesPerson>;
  onClear?: (value: null) => void;
};
export default function SecondSalesPersonCard({
  salesPerson,
  onClear,
}: SecondSalesPersonCardProps) {
  const canClear = !!onClear;
  const hasFullName = [salesPerson.firstName, salesPerson.lastName].some(
    Boolean
  );
  const hasNumber = !!salesPerson.salesNumber;
  const hasShare = !!salesPerson.sharedProvision;
  return (
    <section className="border rounded p-2 space-y-4">
      <h6 className="text-sm text-dark font-medium">
        <Text>global.orderSecondSalesPersonTitle</Text>
      </h6>
      <div className="flex items-start gap-2">
        <Icon name="UserTag" className="text-primary size-5" />
        <div className="flex-1 space-y-1">
          {hasFullName && (
            <h6 className="text-sm leading-4 text-dark">
              {[salesPerson?.firstName, salesPerson?.lastName].join(" ")}
            </h6>
          )}
          {hasNumber && (
            <p className="text-xs text-secondary font-medium">
              {salesPerson?.salesNumber}
            </p>
          )}
          {hasShare && (
            <p className="text-xs text-secondary font-medium">
              <span className="text-dark">
                <Text>global.orderSecondSalesPersonShareTitle</Text>:
              </span>{" "}
              {salesPerson?.sharedProvision}%
            </p>
          )}
        </div>
        {canClear && (
          <IconButton
            type="button"
            variant="danger"
            light
            onClick={() => onClear(null)}
            className="size-10"
          >
            <Icon name="Trash" variant="Bold" />
          </IconButton>
        )}
      </div>
    </section>
  );
}
