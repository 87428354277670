const suppliers = {
  list: {
    searchBox: "Found suppliers...",
    title: "Suppliers",
    version: "Current version: {{ version }}",
    detailsButton: "Details",
  },
  details: {
    version: "Current version: {{ version }}",
    generalInfo: "General info",
    bankInfo: "Bank info",
    program: "Program",
    contact: "Contact",
    settings: "Settings",
  },
  general: {
    title: "General info",
    supplierName: "Supplier name",
    code: "Code",
    email: "Email address",
    iln: "ILN (International Location...)",
    phone: "Phone number",
    taxId: "Tax Id",
    supplierType: "Supplier Type",
    description: "Description",
    alternative: "Alternative ILN",
    location: "Location",
  },
  bankInfo: {
    title: "Bank info",
    name: "Bank name",
    iban: "IBAN",
    ownerName: "Account Owner Name",
    bic: "BIC",
  },
  contacts: {
    title: "Contacts",
    department: "Department {{ departmentName }}",
  },
  settings: {
    holydaysTitle: "Holidays",
    holydayTitle: "Holiday title",
    startDate: "Start date",
    endDate: "End date",
    lastOrderDate: "Last Order Date",
    earliestShipDate: "Earliest Ship Date",

    shippingTitle: "Shipping",
    defaultMinimumShippingTime: "Default minimum shipping time",
    defaultMinimumShippingTimeValue: "{{ value }} {{ unit }}",
    minimumResponseTime: "Minimum Response Time",
    minimumResponseTimeValue: "{{ value }} days",
  },
  programs: {
    title: "Programs",
    searchBox: "Program",
    version: "Current version: {{ version }}",

    general: "General info",
    configuration: "Configuration",
    availability: "Availability status",
  },
  programGeneral: {
    title: "General info",
    programTitle: "Title",
    code: "Code",
    iwofurnCode: "Iwofurn Code",
    zrn: "ZRN",
    brand: "Brand",
    productGroup: "Product group",
    description: "Description",
  },
  programConfiguration: {
    title: "Configuration",
    programType: "Program type",
    useDefaultMinimumShippingTime: "Use Default Minimum Shipping Time",
    minimumShippingTime: "Minimum shipping Time",
    useDefaultBankInfo: "Use Default Bank Info",
    bankName: "Bank Name",
    accountOwnerName: "Account Owner Name",
    iban: "IBAN",
    bic: "BIC",
  },
  programAvailabilityStatus: {
    // List
    title: "Availability status",
    isDefault: "(Default)",
    statusDate: "From {{ fromDate }} to {{ toDate }}",
    addButton: "Add",
    // Form
    addTitle: "Add availability status",
    editTitle: "Edit availability status",
    status: "Status",
    validationPeriod: "Add validation period",
    fromDate: "From Date",
    toDate: "To Date",
    conflictErrorTitle: "Validation Error",
    conflictErrorText: "Date range has conflict",
    cancelButton: "Cancel",
    addStatusButton: "Add",
    updateButton: "Update",
    addMessage: "Status has been created successfully.",
    updateMessage: "Status has been updated successfully.",
    // Remove Confirm
    removeStatusTitle: "Remove status",
    removeStatusText:
      "This operation cannot be reversed. Are you sure about your request?",
    cancelRemoveButton: "Cancel",
    removeStatusButton: "Remove status",
    removeSuccessMessage: "Availability status has been Removed successfully.",
  },
};
export default suppliers;
