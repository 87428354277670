import {
  ComponentProps,
  createContext,
  ElementType,
  forwardRef,
  MouseEvent as ReactMouseEvent,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import { asProps } from "../types";
import Button from "./_Button";

type DropdownProps = Omit<ComponentProps<"div">, "onSelect"> & {
  onSelect?: (val: any) => void;
  drop?: "left" | "right";
};
type DropdownMenuProps = ComponentProps<"div">;
type DropdownItemProps = ComponentProps<"button"> & {
  eventKey?: string;
};
export type DropdownHandler = {
  show: () => void;
  hide: () => void;
  toggle: () => void;
};
type ContextType = {
  active: boolean;
  toggle: () => void;
  onSelect: (val: any) => void;
  drop: "left" | "right";
};

const DropdownContext = createContext({} as ContextType);

const Dropdown = forwardRef<DropdownHandler, DropdownProps>(
  (
    { className = "", onSelect = () => {}, drop = "right", children, ...props },
    ref
  ) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [active, setActive] = useState(false);
    const show = () => {
      setActive(true);
    };
    const hide = () => {
      setActive(false);
    };
    const toggle = () => {
      setActive((p) => !p);
    };
    useImperativeHandle(ref, () => ({ show, hide, toggle }), []);
    const handleClick = (e: MouseEvent) => {
      const outside = !dropdownRef.current?.contains(e.target as Node);
      outside && setActive(false);
    };
    useEffect(() => {
      window.addEventListener("click", handleClick);
      return () => {
        window.removeEventListener("click", handleClick);
      };
    }, []);
    return (
      <div
        ref={dropdownRef}
        data-active={active}
        className={twMerge("dropdown inline-block relative", className)}
        {...props}
      >
        <DropdownContext.Provider value={{ active, toggle, onSelect, drop }}>
          {children}
        </DropdownContext.Provider>
      </div>
    );
  }
);
function DropdownToggle<E extends ElementType = typeof Button<"button">>({
  as,
  className = "",
  children = null,
  ...props
}: ComponentProps<E> & asProps<E>) {
  const Component = as || Button;
  const { toggle } = useContext(DropdownContext);
  return (
    <Component
      className={twMerge("dropdown-toggle", className)}
      onClick={toggle}
      {...props}
    >
      {children}
    </Component>
  );
}
function DropdownMenu({ className = "", children = null }: DropdownMenuProps) {
  const { active, drop } = useContext(DropdownContext);
  return (
    <div
      style={{ [drop]: 0, transformOrigin: `top ${drop}` }}
      className={twMerge(
        "dropdown-menu absolute w-full min-w-[13rem] bg-white rounded shadow-sm border border-gray-200 top-full z-10 transition-[transform,opacity] pointer-events-none opacity-0 scale-90 py-2 px-4",
        active && "pointer-events-auto opacity-100 scale-100",
        className
      )}
    >
      {children}
    </div>
  );
}
function DropdownItem({
  className,
  eventKey = "",
  onClick,
  ...props
}: DropdownItemProps) {
  const { toggle, onSelect } = useContext(DropdownContext);
  const handleClick = (e: ReactMouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    onSelect(eventKey);
    toggle();
  };
  return (
    <button
      type="button"
      className={twMerge(
        "dropdown-item w-full text-start py-2 px-4 text-dark transition-colors border-b border-gray-100 last:border-b-0 truncate",
        className
      )}
      onClick={handleClick}
      {...props}
    />
  );
}

export { DropdownItem, DropdownMenu, DropdownToggle };
export default Dropdown;
