import { cloneDeep } from "lodash";
import { Fragment, useContext } from "react";
import { AddCompanyContext } from ".";
import { InputGroup, Select, Text, Wrapper } from "../../../components";
import { SearchAddress } from "../../../containers";
import { useCountries } from "../../../hooks";
import { Address, rulesType } from "../../../types";
type sectionItem = {
  label: string[];
  items: formItem[];
};
type formItem = {
  state: keyof Address;
  placeholder: string;
  rules?: rulesType;
  [key: string]: any;
};
export default function Location() {
  const { data, setData } = useContext(AddCompanyContext);
  const [countries, loading] = useCountries();
  const formControls: sectionItem[] = [
    {
      label: ["formControls.name"],
      items: [
        {
          state: "name",
          placeholder: "placeholders.name",
        },
      ],
    },
    {
      label: ["formControls.street", "formControls.number"],
      items: [
        {
          state: "street",
          placeholder: "placeholders.street",
        },
        {
          state: "number",
          placeholder: "placeholders.number",
        },
      ],
    },
    {
      label: ["formControls.postalCode", "formControls.city"],
      items: [
        {
          state: "postalCode",
          placeholder: "placeholders.postalCode",
        },
        {
          state: "city",
          placeholder: "placeholders.city",
        },
      ],
    },
    {
      label: ["formControls.country", "formControls.state"],
      items: [
        {
          Element: Select,
          state: "countryCode",
          placeholder: "placeholders.country",
          items: countries,
        },
        {
          state: "state",
          placeholder: "placeholders.state",
        },
      ],
    },
  ];
  const handleSetAddress = (address: Address) => {
    setData((p) => {
      const data = cloneDeep(p);
      data.address = address;
      data.address.name = p.address.name;
      return data;
    });
  };
  const handleSetData = (key: keyof Address) => {
    return (value: any) =>
      setData((p) => {
        const isCountryCode = key === "countryCode";
        const data = cloneDeep(p);
        data.address[key] = value;
        if (isCountryCode) {
          const country = countries.find(
            (e) => e.id === data.address.countryCode
          );
          data.address.country = country?.name ?? null;
          data.address.countryCodeISO3 = country?.countryIso3 ?? null;
        }
        return data;
      });
  };
  return (
    <Wrapper>
      <Wrapper.Body className="space-y-6">
        <div className="flex w-full gap-2">
          <span className="flex-[2] leading-11 truncate">
            <Text>formControls.searchAddress</Text>
          </span>
          <div className="flex-[5] flex gap-4">
            <SearchAddress
              countries={countries}
              setValue={handleSetAddress}
              loading={loading}
              className="flex-1"
            />
          </div>
        </div>
        <span className="block text-secondary text-center">
          <Text>global.orEnterManual</Text>
        </span>
        {formControls.map((e) => (
          <div key={e.label.join(".")} className="flex w-full gap-2">
            <span className="flex-[2] leading-11 truncate">
              {e.label.map((label, i) => (
                <Fragment key={label}>
                  {i !== 0 && " / "}
                  <Text key={label}>{label}</Text>
                </Fragment>
              ))}
            </span>
            <div className="flex-[5] flex gap-4">
              {e.items.map(
                ({ Element = InputGroup, state, flex, ...e }: any) => (
                  <div key={state} style={{ flex: flex || 1 }}>
                    <Element
                      // @ts-ignore: Unreachable code error
                      value={data.address[state]}
                      setValue={handleSetData(state)}
                      {...e}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </Wrapper.Body>
    </Wrapper>
  );
}
