import { useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import { Badge, Button, Status, Text, Wrapper } from "../../../../components";
import { cn } from "../../../../methods";

export default function CurrentPhase() {
  const {
    basicInfo,
    statusInfo,
    canChangePhase,
    canClosePhase,
    toggleChangePhase,
    toggleClosePhase,
  } = useContext(SellOffProductDetailsContext);
  return (
    <Wrapper className="lg:col-span-2">
      <Wrapper.Header className="flex items-center gap-1">
        <h6 className="flex-1 text-sm text-dark font-medium">
          <Text>sellOffProducts.details.currentPhase</Text>
        </h6>
        <Status.SellOffProduct id={basicInfo?.status} />
        {canChangePhase && (
          <Badge variant="warning" className="text-xs">
            <Text>sellOffProducts.details.needPhaseChange</Text>
          </Badge>
        )}
        {canClosePhase && (
          <Badge variant="danger" className="text-xs">
            <Text>sellOffProducts.details.needClose</Text>
          </Badge>
        )}
      </Wrapper.Header>
      <Wrapper.Body className="flex items-start gap-4">
        <div className="flex-1 space-y-2.5">
          <h6 className="text-sm text-dark font-medium">
            {statusInfo?.sellPhaseTemplateTitle}
          </h6>
          <p
            className={cn(
              "text-sm font-medium",
              canChangePhase && "text-warning",
              canClosePhase && "text-danger"
            )}
          >
            {statusInfo?.currentPhaseName}
          </p>
        </div>
        <div className="space-y-2.5">
          <div className="flex items-center gap-2">
            {canChangePhase && (
              <Button
                type="button"
                variant="warning"
                onClick={toggleChangePhase}
              >
                <Text>sellOffProducts.details.changePhaseButton</Text>
              </Button>
            )}
            {canClosePhase && (
              <Button type="button" variant="danger" onClick={toggleClosePhase}>
                <Text>sellOffProducts.details.needCloseButton</Text>
              </Button>
            )}
          </div>
          <p className="text-sm text-secondary font-medium text-center">
            <Text
              currentPhaseDay={statusInfo?.currentPhaseDay ?? ""}
              currentPhaseTotalDays={statusInfo?.currentPhaseTotalDays ?? ""}
            >
              sellOffProducts.details.currentPhaseDays
            </Text>
          </p>
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
