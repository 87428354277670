import { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Icon,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../components";
import { config } from "../constants";
import { CustomerType } from "../enums";
import { useAxios, useConverters } from "../hooks";
import { Order, WithPaging, togglePropsType } from "../types";

export type SelectedOrder = {
  name: string;
  id: string;
  number?: string | null;
  totalAmount?: number | null;
};

type ChooseOrderProps = togglePropsType & {
  customerId?: string | null;
  onSelect: (
    order: SelectedOrder | null,
    customer: { name: string; id: string; type: CustomerType } | null
  ) => void;
};

export default function ChooseOrder({
  isOpen,
  toggle,
  onSelect,
  customerId,
}: ChooseOrderProps) {
  const { axios, loading } = useAxios();
  const { convertAmount } = useConverters();
  const initParams: Order.Params = {
    pageNumber: 1,
    pageSize: config.pageSize,
    // salesStatus: SalesStatus.None,
  };
  const [data, setData] = useState<WithPaging<Order.Item>>({});
  const [params, setParams] = useState(initParams);
  const handleToggle = () => {
    setParams(initParams);
    toggle();
  };
  const handleClearOrder = () => {
    onSelect(null, null);
    handleToggle();
  };
  const handleSelect = (e: Order.Item) => {
    return () => {
      const isCompany = e.customer?.customerType === CustomerType.Company;
      const customerName = isCompany
        ? e.customer?.companyName
        : [e.customer?.firstName, e.customer?.lastName].join(" ");
      onSelect(
        {
          id: e.id,
          name: e.title,
          number: e.number,
          totalAmount: e.totalAmount,
        },
        {
          id: e.customer?.customerId ?? "",
          name: customerName ?? "",
          type: e.customer?.customerType ?? CustomerType.Person,
        }
      );
      handleToggle();
    };
  };
  const handleSetParams = (key: string) => {
    return (val: any) => {
      const isPageNumber = key === "pageNumber";
      setParams((p) => ({
        ...p,
        [key]: val,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
    };
  };
  const getData = () => {
    if (!isOpen) return;
    const url = "/salesservice/api/orders";
    const config = { params };
    config.params.customerId = customerId;
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [isOpen, params]);
  return (
    <Drawer isOpen={isOpen} toggle={handleToggle} className="z-[31]">
      <Drawer.Menu>
        <Drawer.Header className="flex items-center border-b-0">
          <h6 className="text-start text-base text-dark flex-1">
            <Text>incident.chooseOrder.title</Text>
          </h6>
          <Button
            type="button"
            variant="danger"
            outline
            onClick={handleClearOrder}
            className="border-0"
          >
            <Text>incident.chooseOrder.clearBtn</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox
            placeholder="incident.chooseOrder.search"
            variant="gray"
            onSubmit={handleSetParams("keyword")}
          />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {data?.items?.map((e) => (
              <List.Item key={e.id} onClick={handleSelect(e)}>
                <div className="flex-center w-10 h-10 rounded-full text-primary bg-primary/5">
                  <Icon name="ReceiptText" variant="Bold" size={28} />
                </div>
                <div className="flex-1 space-y-1">
                  <span>{e.title}</span>
                  <span className="block text-secondary">#{e.number}</span>
                </div>
                <p className="text-center">{convertAmount(e.totalAmount)}</p>
                <Button as="span" light>
                  <Text>incident.chooseOrder.chooseBtn</Text>
                </Button>
              </List.Item>
            ))}
          </List>
        </Drawer.Body>
        <Drawer.Footer>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            totalItems={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
