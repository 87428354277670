import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
} from "../../../components";
import { Product, togglePropsType } from "../../../types";

type FilterProps = togglePropsType & {
  result: Product.WithAzureSearch;
  params: Product.Params;
  handleSetParams: Dispatch<SetStateAction<Product.Params>>;
  loading: boolean;
  onReset: () => void;
};

export default function Filter({
  isOpen,
  toggle,
  result,
  params: initParams,
  handleSetParams: submit,
  loading,
  onReset,
}: FilterProps) {
  const [params, setParams] = useState(initParams);
  const handleSetParams = (
    key: keyof typeof params,
    variant: "dropdown" | "input-group"
  ) => {
    const isPrice = key === "priceFrom" || key === "priceTo";
    const isDropdown = variant === "dropdown";
    return (value: any) => {
      const handler = (p: Product.Params) => ({
        ...p,
        pageNumber: 1,
        [key]: isPrice ? value || null : value,
      });
      isDropdown && submit(handler);
      setParams(handler);
    };
  };
  const handleReset = () => {
    onReset();
    // toggle();
  };
  const handleSubmit = () => {
    submit(params);
    toggle();
  };
  useEffect(() => {
    if (isOpen) setParams(initParams);
  }, [isOpen, initParams]);
  return (
    <Drawer as={Form} isOpen={isOpen} toggle={toggle} onSubmit={handleSubmit}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="text-dark text-base text-start flex-1">
            <Text>products.filter.filterTitle</Text>
          </h6>
          <button
            type="button"
            className="text-warning text-sm self-center"
            onClick={handleReset}
          >
            <Icon
              name="ArrowForwardSquare"
              variant="Bold"
              size={25}
              className="text-warning"
            />{" "}
            <Text>products.filter.resetFilterBtn</Text>
          </button>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Select
            label="products.filter.productFamily"
            value={params.pimProductFamilyId ?? null}
            setValue={handleSetParams("pimProductFamilyId", "dropdown")}
            items={result.pimProductFamily?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            loading={loading}
            searchable
            all
          />
          <Select
            label="products.filter.brand"
            value={params.brandCode ?? null}
            setValue={handleSetParams("brandCode", "dropdown")}
            items={result.brand?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            loading={loading}
            searchable
            all
          />
          <Select
            label="products.filter.supplier"
            value={params.supplierId ?? null}
            setValue={handleSetParams("supplierId", "dropdown")}
            items={result.supplier?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            loading={loading}
            searchable
            all
          />
          <Select
            label="products.filter.program"
            value={params.programId ?? null}
            setValue={handleSetParams("programId", "dropdown")}
            items={result.program?.map((e) => ({
              name: `${e.title} (${e.count})`,
              id: e.value,
            }))}
            loading={loading}
            searchable
            all
          />
          <div className="grid grid-cols-2 items-center gap-2">
            <label className="text-base block w-full mb-2 truncate col-span-full">
              <Text>products.filter.priceRange</Text>
            </label>
            <InputGroup
              value={params.priceFrom}
              setValue={handleSetParams("priceFrom", "input-group")}
              placeholder="products.filter.priceFrom"
              type="price"
            />
            <InputGroup
              value={params.priceTo}
              setValue={handleSetParams("priceTo", "input-group")}
              placeholder="products.filter.priceTo"
              type="price"
            />
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button type="button" variant="danger" onClick={toggle}>
            <Text>products.filter.cancelBtn</Text>
          </Button>
          <Button type="submit" variant="primary">
            <Text>products.filter.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
