import { useCallback, useContext } from "react";
import { useParams } from "react-router";
import { SellOffProductDetailsContext } from ".";
import { useAxios } from "../../../hooks";

export default function useUpdateSellOffProduct() {
  const context = useContext(SellOffProductDetailsContext);
  const { setBasicInfo, setStatusInfo, setTimelines } = context;
  const { productId } = useParams();
  const { axios, loading } = useAxios();
  const getBasicInfo = useCallback(async () => {
    if (!productId) return Promise.resolve(null);
    const url = `/salesservice/api/selloffproducts/${productId}/basic-info`;
    return await axios.get(url).then(({ data }) => {
      setBasicInfo?.(data);
    });
  }, [productId]);
  const getPriceInfo = useCallback(async () => {
    if (!productId) return Promise.resolve(null);
    const url = `/salesservice/api/selloffproducts/${productId}/status-info`;
    return await axios.get(url).then(({ data }) => {
      setStatusInfo?.(data);
    });
  }, [productId]);
  const getTimelines = useCallback(async () => {
    if (!productId) return Promise.resolve(null);
    const url = `/salesservice/api/selloffproducttimelines/${productId}`;
    return await axios.get(url).then(({ data }) => {
      setTimelines?.(data);
    });
  }, [productId]);
  const updateSellOffProduct = async () => {
    return Promise.all([getBasicInfo(), getPriceInfo(), getTimelines()]);
  };
  return [updateSellOffProduct, loading.get] as const;
}
