import { Fragment, useContext, useEffect, useState } from "react";
import { AddBundleCatalogueContext } from ".";
import {
  Button,
  Drawer,
  Icon,
  List,
  SearchBox,
  Text,
  Types,
} from "../../../../../components";
import { ProgramType } from "../../../../../enums";
import { useAxios } from "../../../../../hooks";
import { Program } from "../../../../../types";
export default function Programs() {
  const { axios, loading } = useAxios();
  const { supplier, setProgram } = useContext(AddBundleCatalogueContext);
  const [programs, setPrograms] = useState<Program.Item[]>([]);
  const [params, setParams] = useState<Program.Params>({
    supplierId: supplier?.supplierId,
    programType: ProgramType.Manual,
    keyword: null,
    isActive: true,
  });
  const handleSetParams = (key: keyof Program.Params) => {
    // const isPageNumber = key === "pageNumber";
    return (value: any) =>
      setParams((p) => ({
        ...p,
        [key]: value,
        // ...(!isPageNumber && { pageNumber: 1 }),
      }));
  };
  const handleChoose = (program: Program.Item) => {
    return () => setProgram(program);
  };
  const getPrograms = () => {
    const url = "/salesservice/api/supplierprograms";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setPrograms(data);
    });
  };
  useEffect(getPrograms, [params]);
  return (
    <Fragment>
      <Drawer.Header className="border-b-0">
        <SearchBox variant="gray" onSubmit={handleSetParams("keyword")} />
      </Drawer.Header>
      <Drawer.Body>
        <List loading={loading.get}>
          {programs?.map((e) => (
            <List.Item
              key={e.id}
              onClick={handleChoose(e)}
              className="cursor-pointer"
            >
              <Icon.Wrapper rounded>
                <Icon name="DocumentText" variant="Bold" />
              </Icon.Wrapper>
              <div className="flex-1">
                <h6 className="truncate">{e.title}</h6>
                <span className="block text-secondary truncate">#{e.code}</span>
              </div>
              <div className="flex flex-center flex-1">
                <Types.Program id={e.programType} />
              </div>
              <Button as="span" light>
                <Text>button.choose</Text>
              </Button>
            </List.Item>
          ))}
        </List>
      </Drawer.Body>
      {/* <Drawer.Footer>
        <Pagination
          pageNumber={+params.pageNumber}
          pageSize={+params.pageSize}
          setActivePage={handleSetParams("pageNumber")}
          totalItems={programs?.totalItems ?? 0}
          totalPages={programs?.totalPages ?? 0}
        />
      </Drawer.Footer> */}
    </Fragment>
  );
}
