import { cloneDeep } from "lodash";
import {
  createContext,
  ReactNode,
  useContext,
  useId,
  useMemo,
  useState,
} from "react";
import AnimateHeight from "react-animate-height";
import { twMerge } from "tailwind-merge";
import Icon from "./_Icon";
type AccordionProps = {
  children: ReactNode;
  defaultActiveKey?: null | string | string[];
};
type AccordionItemProps = {
  className?: string;
  eventKey?: string;
  children?: ReactNode;
};
type AccordionToggleProps = {
  id?: string;
  className?: string;
  children?: ReactNode;
};
type AccordionBodyProps = {
  className?: string;
  children?: ReactNode;
};
type AccordionContextType = {
  activeKey: null | string | string[];
  setActiveKey: (val: string) => void;
};
type AccordionItemContextType = {
  eventKey: string;
  isActive: boolean;
};
const AccordionContext = createContext({} as AccordionContextType);
const AccordionItemContext = createContext({} as AccordionItemContextType);

function Accordion({
  children = null,
  defaultActiveKey = null,
}: AccordionProps) {
  const [activeKey, setActiveKey] = useState<null | string | string[]>(
    defaultActiveKey
  );
  const handleSetActiveKey = (id: string) => {
    setActiveKey((p) => {
      const activeKey = cloneDeep(p);
      const isArray = Array.isArray(activeKey);
      if (isArray) {
        const index = activeKey.findIndex((e) => e === id);
        const has = index !== -1;
        has ? activeKey.splice(index, 1) : activeKey.push(id);
        return activeKey;
      } else {
        const has = id === activeKey;
        return has ? null : id;
      }
    });
  };
  return (
    <AccordionContext.Provider
      value={{ activeKey, setActiveKey: handleSetActiveKey }}
    >
      {children}
    </AccordionContext.Provider>
  );
}
function AccordionItem({
  className = "",
  eventKey,
  children,
}: AccordionItemProps) {
  const id = useId();
  const key = eventKey ?? id;
  const { activeKey } = useContext(AccordionContext);
  const isActive = useMemo(() => {
    const isArray = Array.isArray(activeKey);
    if (isArray) return activeKey.includes(key);
    return key === activeKey;
  }, [key, activeKey]);
  return (
    <AccordionItemContext.Provider value={{ eventKey: key, isActive }}>
      <div
        className={twMerge(
          "accordion-item w-full border-b border-gray-100",
          isActive && "active",
          className
        )}
      >
        {children}
      </div>
    </AccordionItemContext.Provider>
  );
}
function AccordionToggle({
  id,
  className = "",
  children = null,
}: AccordionToggleProps) {
  const { setActiveKey } = useContext(AccordionContext);
  const { eventKey, isActive } = useContext(AccordionItemContext);
  const handleSetActiveKey = () => {
    setActiveKey(eventKey);
  };
  return (
    <button
      id={id}
      type="button"
      onClick={handleSetActiveKey}
      className={twMerge(
        "w-full flex items-center gap-4 py-5 font-bold overflow-hidden",
        className
      )}
    >
      {children}
      <Icon
        name="ArrowDown2"
        data-active={isActive}
        className="transition-transform ml-auto data-active:rotate-180"
      />
    </button>
  );
}
function AccordionBody({
  className = "",
  children = null,
}: AccordionBodyProps) {
  const { isActive } = useContext(AccordionItemContext);
  return (
    <AnimateHeight
      duration={300}
      height={isActive ? "auto" : 0}
      className="w-full"
      animationStateClasses={{
        animating: "",
        animatingUp: "",
        animatingDown: "",
        static: "",
        animatingToHeightZero: "",
        animatingToHeightAuto: "",
        animatingToHeightSpecific: "",
        staticHeightZero: "",
        staticHeightAuto: "",
        staticHeightSpecific: "",
      }}
    >
      <div className={twMerge("w-full py-2", className)}>{children}</div>
    </AnimateHeight>
  );
}

Accordion.Item = AccordionItem;
Accordion.Toggle = AccordionToggle;
Accordion.Body = AccordionBody;

export default Accordion;
