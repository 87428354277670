import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { ProductDetailsContext } from "..";
import {
  Button,
  Drawer,
  Form,
  InputGroup,
  Select,
  Text,
} from "../../../../components";
import { rules, types } from "../../../../constants";
import { AmountCalculationType } from "../../../../enums";
import { useAxios } from "../../../../hooks";
import { togglePropsType } from "../../../../types";
import { BundleItemWithSalesPrice } from "../ProductPriceProvider";

type Props = togglePropsType & {
  lineItem: BundleItemWithSalesPrice;
};

export default function SalesPersonCommission({
  isOpen,
  toggle,
  lineItem,
}: Props) {
  const { axios, loading } = useAxios();
  const { product, setProduct } = useContext(ProductDetailsContext);
  const initAmount = String(lineItem.salesPersonCommission?.amount ?? "");
  const initCalculationType =
    lineItem.salesPersonCommission?.calculationType ??
    AmountCalculationType.Amount;
  const [amount, setAmount] = useState(initAmount);
  const [calculationType, setCalculationType] = useState(initCalculationType);
  const hasAmount = !!Number(amount);
  const isPercent = calculationType === AmountCalculationType.Percent;
  const submit = () => {
    const url = `/salesservice/api/salestoolspimproduct/${product.id}/bundle-item/${lineItem.id}`;
    const body = {
      salesPersonCommission: hasAmount
        ? { amount: Number(amount), calculationType }
        : null,
    };
    axios.put(url, body).then(() => {
      const message = "products.salesPersonCommission.successMessage";
      toast.success(message);
      setProduct((p) => {
        const product = cloneDeep(p!);
        const bundleItems = product.bundleItems;
        if (!bundleItems) return product;
        const index = bundleItems.findIndex((e) => e.id === lineItem.id);
        const has = index !== -1;
        if (has)
          bundleItems[index].salesPersonCommission = body.salesPersonCommission;
        product.bundleItems = bundleItems;
        return product;
      });
      toggle();
    });
  };
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base text-dark font-medium">
            <Text>products.salesPersonCommission.title</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <InputGroup
            label="products.salesPersonCommission.amount"
            value={amount}
            setValue={setAmount}
            rules={isPercent ? rules.optionalPercent : []}
          />
          <Select
            label="products.salesPersonCommission.type"
            value={calculationType}
            setValue={setCalculationType}
            items={types.amountCalculation}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button
            type="button"
            variant="danger"
            onClick={toggle}
            disabled={loading.update}
          >
            <Text>products.salesPersonCommission.cancelButton</Text>
          </Button>
          <Button type="submit" variant="primary" loading={loading.update}>
            <Text>products.salesPersonCommission.submitButton</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
