import { cloneDeep } from "lodash";
import { Dispatch, Fragment, useEffect, useState } from "react";
import {
  Button,
  CheckBox,
  Drawer,
  Icon,
  InputGroup,
  RadioButton,
  SearchBox,
  Text,
} from "../../../components";
import { status } from "../../../constants";
import { SecondSalesPersonCard } from "../../../containers";
import { useToggle } from "../../../hooks";
import { Employee, Order, togglePropsType } from "../../../types";
import EmployeeDrawer from "./EmployeeDrawer";

type Props = togglePropsType & {
  initParams: Order.Params;
  params: Order.Params;
  onSubmit: Dispatch<Order.Params>;
};

export default function FilterDrawer({
  isOpen,
  toggle,
  initParams,
  params,
  onSubmit,
}: Props) {
  const [data, setData] = useState(params);
  const [addDateRange, toggleDateRange] = useToggle(false);
  const [showSalesPersonDrawer, toggleSalesPersonDrawer] = useToggle(false);
  const [salesPerson, setSalesPerson] = useState<Employee.Item | null>(null);
  const allItem = { name: "page.orders.filterMenu.allItem", id: null };
  const hasSalesPerson = !!salesPerson;
  const clearSalesPerson = () => {
    setSalesPerson(null);
  };
  const handleSetValue = (key: keyof Order.Params) => {
    return (value: any) =>
      setData((p) => ({ ...p, [key]: value, pageNumber: 1 }));
  };
  const resetFilter = () => {
    clearSalesPerson();
    setData(initParams);
  };
  const submit = () => {
    const params = cloneDeep(data);
    if (!addDateRange) {
      params.fromDate = null;
      params.toDate = null;
    }
    if (!!salesPerson) {
      params.salesPersonName = [
        salesPerson.firstName,
        salesPerson.lastName,
      ].join(" ");
    }
    onSubmit(params);
    toggle();
  };
  useEffect(() => {
    isOpen && setData(params);
  }, [params, isOpen]);
  return (
    <Fragment>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center gap-4">
            <h6 className="text-dark text-base flex-1 text-start">
              <Text>page.orders.filterMenu.title</Text>
            </h6>
            <button
              type="button"
              className="text-warning"
              onClick={resetFilter}
            >
              <Icon
                name="ArrowForwardSquare"
                variant="Bold"
                className="text-warning"
              />{" "}
              <Text>page.orders.filterMenu.resetButton</Text>
            </button>
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            <SearchBox
              value={data.keyword}
              variant="gray"
              searchType="immediately"
              onSubmit={handleSetValue("keyword")}
            />
            <section className="w-full grid grid-cols-2 gap-x-3 gap-y-4 border rounded p-3">
              <CheckBox
                label="page.orders.filterMenu.addDateRange"
                value={addDateRange}
                setValue={toggleDateRange}
                className="col-span-full"
              />
              <InputGroup
                label="page.orders.filterMenu.fromDate"
                value={data.fromDate}
                setValue={handleSetValue("fromDate")}
                type="date"
                disabled={!addDateRange}
                maxDate={data.toDate}
              />
              <InputGroup
                label="page.orders.filterMenu.toDate"
                value={data.toDate}
                setValue={handleSetValue("toDate")}
                type="date"
                disabled={!addDateRange}
                minDate={data.fromDate}
              />
            </section>
            {hasSalesPerson ? (
              <SecondSalesPersonCard
                salesPerson={{
                  employeeId: salesPerson.employeeId,
                  firstName: salesPerson.firstName,
                  lastName: salesPerson.lastName,
                  salesNumber: salesPerson.salesPersonNumber,
                }}
                onClear={clearSalesPerson}
              />
            ) : (
              <section className="border rounded p-2 space-y-4">
                <h6 className="text-base text-dark">
                  <Text>page.orders.filterMenu.salesPerson</Text>
                </h6>
                <Button
                  type="button"
                  variant="primary"
                  light
                  onClick={toggleSalesPersonDrawer}
                >
                  <Icon name="Add" />{" "}
                  <Text>page.orders.filterMenu.selectSalesPerson</Text>
                </Button>
              </section>
            )}
            <section className="w-full grid grid-cols-4 gap-x-4 border rounded p-3">
              <h6 className="text-base text-dark font-medium col-span-full">
                <Text>page.orders.filterMenu.processStatus</Text>
              </h6>
              {[allItem, ...status.orderInternal].map((e) => {
                const active = e.id === (data.internalStatus ?? null);
                return (
                  <RadioButton
                    key={String(e.id)}
                    label={e.name}
                    isActive={active}
                    onClick={() => handleSetValue("internalStatus")(e.id)}
                  />
                );
              })}
            </section>
            <section className="w-full grid grid-cols-2 gap-x-4 border rounded p-3">
              <h6 className="text-base text-dark font-medium col-span-full">
                <Text>page.orders.filterMenu.salesStatus</Text>
              </h6>
              {[allItem, ...status.sales].map((e) => {
                const active = e.id === (data.status ?? null);
                return (
                  <RadioButton
                    key={String(e.id)}
                    label={e.name}
                    isActive={active}
                    onClick={() => handleSetValue("status")(e.id)}
                  />
                );
              })}
            </section>
            <section className="w-full grid grid-cols-2 gap-x-4 border rounded p-3">
              <h6 className="text-base text-dark font-medium col-span-full">
                <Text>page.orders.filterMenu.salesDocumentStatus</Text>
              </h6>
              {[allItem, ...status.document].map((e) => {
                const active = e.id === (data.salesDocumentStatus ?? null);
                return (
                  <RadioButton
                    key={String(e.id)}
                    label={e.name}
                    isActive={active}
                    onClick={() => handleSetValue("salesDocumentStatus")(e.id)}
                  />
                );
              })}
            </section>
            <section className="w-full grid grid-cols-2 gap-x-4 border rounded p-3">
              <h6 className="text-base text-dark font-medium col-span-full">
                <Text>page.orders.filterMenu.purchaseStatus</Text>
              </h6>
              {[allItem, ...status.purchase].map((e) => {
                const active = e.id === (data.purchaseStatus ?? null);
                return (
                  <RadioButton
                    key={String(e.id)}
                    label={e.name}
                    isActive={active}
                    onClick={() => handleSetValue("purchaseStatus")(e.id)}
                  />
                );
              })}
            </section>
            <section className="w-full grid grid-cols-2 gap-x-4 border rounded p-3">
              <h6 className="text-base text-dark font-medium col-span-full">
                <Text>page.orders.filterMenu.purchaseDocumentStatus</Text>
              </h6>
              {[allItem, ...status.document].map((e) => {
                const active = e.id === (data.purchaseDocumentStatus ?? null);
                return (
                  <RadioButton
                    key={String(e.id)}
                    label={e.name}
                    isActive={active}
                    onClick={() =>
                      handleSetValue("purchaseDocumentStatus")(e.id)
                    }
                  />
                );
              })}
            </section>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-end gap-4">
            <Button type="button" variant="danger" onClick={toggle}>
              <Text>page.orders.filterMenu.cancelButton</Text>
            </Button>
            <Button type="button" variant="primary" onClick={submit}>
              <Text>page.orders.filterMenu.submitFilterButton</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <EmployeeDrawer
        isOpen={showSalesPersonDrawer}
        toggle={toggleSalesPersonDrawer}
        onSelect={setSalesPerson}
      />
    </Fragment>
  );
}
