import { ComponentProps, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { variantType } from "../types";
type BadgeType =
  | { fill?: boolean; outline?: never }
  | { fill?: never; outline?: boolean };
type BadgeProps = {
  variant?: variantType;
} & BadgeType;
type ColorsType = {
  [V in Exclude<variantType, null>]: string;
};
type Props = BadgeProps & Omit<ComponentProps<"span">, keyof BadgeProps>;
export default function Badge({
  children,
  variant = "primary",
  className = "",
  fill = false,
  outline = false,
  ...props
}: Props) {
  const activeVariant = useMemo(() => {
    if (!variant) return "";
    const backgroundColors: ColorsType = {
      primary: "bg-[#345EAC]",
      info: "bg-info",
      danger: "bg-danger",
      warning: "bg-warning",
      success: "bg-success",
      dark: "bg-dark",
      secondary: "bg-secondary",
      light: "",
      white: "",
    };
    const colors: ColorsType = {
      primary: "text-[#345EAC]",
      info: "text-info",
      danger: "text-danger",
      warning: "text-warning",
      success: "text-success",
      dark: "text-dark",
      secondary: "text-gray-900",
      light: "",
      white: "",
    };
    const borderColors: ColorsType = {
      primary: "border-[#345EAC]",
      info: "border-info",
      danger: "border-danger",
      warning: "border-warning",
      success: "border-success",
      dark: "border-dark",
      secondary: "border-gray-900",
      light: "",
      white: "",
    };
    if (fill)
      return [
        backgroundColors[variant],
        "text-white",
        "py-1 px-2",
        "text-xs",
      ].join(" ");
    if (outline)
      return [
        "border",
        borderColors[variant],
        colors[variant],
        "bg-transparent",
        "py-1 px-3",
        "text-base",
      ].join(" ");
    return [
      backgroundColors[variant],
      colors[variant],
      "bg-opacity-10",
      "py-1 px-3",
      "text-base",
    ].join(" ");
  }, [variant, fill, outline]);
  return (
    <span
      className={twMerge(
        "inline-block align-middle whitespace-nowrap rounded-md font-medium",
        activeVariant,
        className
      )}
      {...props}
    >
      {children}
    </span>
  );
}
