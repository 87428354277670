import { useContext, useEffect, useState } from "react";
import { SupplierDetailsContext } from "..";
import {
  AddressViewer,
  Icon,
  Loading,
  NoItems,
  Pagination,
  Text,
  Wrapper,
} from "../../../../components";
import { config, types } from "../../../../constants";
import { useAxios, useTranslate } from "../../../../hooks";
import { Supplier, WithPaging } from "../../../../types";

type Params = Supplier.ContactPersonParams;
type Date = Supplier.ContactPersonItem;

export default function Contact() {
  const translate = useTranslate();
  const { supplier } = useContext(SupplierDetailsContext);
  const { axios, loading } = useAxios();
  const [params, setParams] = useState<Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const [contacts, setContacts] = useState<WithPaging<Date>>({});
  const hasContacts = !!contacts.items?.length;
  const handleSetParams = (key: keyof Params) => {
    return (val: any) => {
      const isPageNumber = key === "pageNumber";
      setParams((p) => ({
        ...p,
        [key]: val,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
    };
  };
  const getContacts = () => {
    const supplierId = supplier?.supplierId;
    if (!supplierId) return;
    const url = "/salesservice/api/suppliercontactpersons";
    const config = { params: { ...params, supplierId } };
    axios.get(url, config).then(({ data }) => {
      setContacts(data);
    });
  };
  useEffect(getContacts, [params, supplier?.supplierId]);
  return (
    <Wrapper>
      <Wrapper.Header className="border-b-0">
        <h2 className="text-dark text-sm font-medium">
          <Text>suppliers.contacts.title</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        {loading.get ? (
          <Loading.SupplierContactPerson />
        ) : !hasContacts ? (
          <NoItems className="col-span-full" />
        ) : (
          contacts.items?.map((e) => {
            const departmentName =
              types.supplierContactPersonDepartment.find(
                ({ id }) => id === e.department
              )?.name ?? "";
            return (
              <section
                key={e.id}
                className="flex items-start border rounded gap-2 p-2"
              >
                <Icon name="ProfileCircle" className="text-primary size-5" />
                <div className="flex-1 space-y-1.5">
                  <h6 className="flex-1 text-sm text-dark font-medium">
                    {e.firstName} {e.lastName}
                  </h6>
                  <p className="text-xs text-secondary font-medium">
                    {[
                      e.emailAddress1,
                      e.emailAddress2,
                      e.phoneNumber1,
                      e.phoneNumber2,
                    ]
                      .filter(Boolean)
                      .join(" | ")}
                  </p>
                  <p className="text-xs text-secondary font-medium">
                    <Icon name="Building" className="size-4" />{" "}
                    <Text departmentName={translate(departmentName)}>
                      suppliers.contacts.department
                    </Text>
                  </p>
                  <AddressViewer
                    address={e.address}
                    className="text-xs text-secondary font-medium whitespace-normal [&>svg]:text-current"
                    withIcon
                  />
                </div>
              </section>
            );
          })
        )}
      </Wrapper.Body>
      <Wrapper.Footer>
        <Pagination
          pageNumber={params?.pageNumber ?? 0}
          totalItems={contacts?.totalItems ?? 0}
          totalPages={contacts?.totalPages ?? 0}
          setActivePage={handleSetParams("pageNumber")}
        />
      </Wrapper.Footer>
    </Wrapper>
  );
}
