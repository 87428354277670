import { useContext } from "react";
import { SellOffProductDetailsContext } from ".";
import { Wrapper } from "../../../components";
import { useConverters } from "../../../hooks";
import { cn } from "../../../methods";

export default function PriceInfo() {
  const { convertAmount } = useConverters();
  const { basicInfo, statusInfo } = useContext(SellOffProductDetailsContext);
  const basePrice = basicInfo?.pimProduct?.vk1Price?.amount;
  const hasBasePrice = !!basePrice;
  const hasCurrentDiscount = !!statusInfo?.currentDiscount;
  const hasCurrentPhasePrice = !!basicInfo?.currentPhasePrice;
  return (
    <Wrapper>
      <Wrapper.Body>
        {hasBasePrice && (
          <p
            className={cn(
              "text-sm font-medium",
              hasCurrentDiscount
                ? "text-placeholder line-through"
                : "text-gray-900"
            )}
          >
            {convertAmount(basePrice)}
          </p>
        )}
        {hasCurrentDiscount && (
          <p className="text-sm text-danger font-medium">
            {statusInfo.currentDiscount}%
          </p>
        )}
        {hasCurrentPhasePrice && (
          <p className="text-sm text-gray-900 font-medium">
            {convertAmount(basicInfo.currentPhasePrice)}
          </p>
        )}
      </Wrapper.Body>
    </Wrapper>
  );
}
