import { Dispatch, useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import { Image } from "../../../../components";
import { Image as ImageType } from "../../../../types";

type Props = {
  media: ImageType;
  onClick: Dispatch<string>;
};

export default function MediaItem({ media, onClick }: Props) {
  const { basicInfo } = useContext(SellOffProductDetailsContext);
  const isCover = basicInfo?.avatar?.mediaId === media.id;
  return (
    <button
      type="button"
      className="group relative w-full rounded aspect-image overflow-hidden shadow hover:shadow-lg transition-shadow"
      onClick={() => onClick(media.id)}
    >
      {/* {isCover && (
        <span className="absolute top-2 left-2 text-2xs font-medium text-gray-900 z-10 bg-gray-50 rounded-[0.25rem] py-1 px-1.5">
          <Text>Covert EN</Text>
        </span>
      )} */}
      {/* <span className="absolute top-2 left-2 text-2xs font-medium text-gray-900 z-10 bg-gray-50 rounded-[0.25rem] py-1 px-1.5">
        <Text>Covert EN API</Text>
      </span> */}
      <Image
        src={media.thumbnailUrl}
        alt={media.type}
        className="absolute inset-0 size-full object-cover z-0 group-hover:scale-110 transition-transform"
        isDynamic
      />
    </button>
  );
}
