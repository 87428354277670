import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";
import Icon from "./_Icon";
import Text from "./_Text";

type Props = Omit<ComponentProps<"button">, "children">;

export default function ChangeButton({ className, ...props }: Props) {
  return (
    <button
      type="button"
      className={twMerge("text-warning text-sm", className)}
      {...props}
    >
      <Icon
        name="ArrowForwardSquare"
        variant="Bold"
        size={25}
        className="text-warning"
      />{" "}
      <Text>button.change</Text>
    </button>
  );
}
