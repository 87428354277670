import { Fragment, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { Button, Icon, Text, Translate } from "../../../../components";
import { useConverters, useFindAttribute, useToggle } from "../../../../hooks";
import { iconNameType } from "../../../../types";
import { BundleItemWithSalesPrice } from "../ProductPriceProvider";
import LineItemPriceDetails from "./LineItemPriceDetails";
import SalesPersonCommissionDrawer from "./SalesPersonCommissionDrawer";

type Props = {
  lineItem: BundleItemWithSalesPrice;
};

export default function LineItemRow({ lineItem }: Props) {
  const salesPrice = lineItem.salesPrice;
  const findAttribute = useFindAttribute();
  const { convertAmount, convertNumber } = useConverters();
  const [showDetails, toggleDetails] = useToggle(false);
  const [showCommission, toggleCommission, hideCommission] = useToggle(false);
  const icon = useMemo(() => {
    if (salesPrice?.isWarning) return "Danger" as iconNameType;
    if (salesPrice?.isError) return "Danger" as iconNameType;
    return "InfoCircle" as iconNameType;
  }, [salesPrice]);

  return (
    <Fragment>
      <tr className="!border-t-[#E4E6EF]">
        <td>
          <div className="flex items-center gap-4">
            <div className="flex-1 flex items-center text-start gap-4">
              <div className="flex-center size-11">
                <Icon.Wrapper variant="primary">
                  <Icon name="Box1" variant="Linear" className="size-6" />
                </Icon.Wrapper>
              </div>
              <div className="flex-1 space-y-1">
                <h6 className="w-full text-base font-medium text-dark text-balance max-w-72 whitespace-normal line-clamp-2">
                  <Translate
                    value={findAttribute(lineItem.attributeGroups, "title")}
                    defaultValue={lineItem.originalName}
                  />
                </h6>
                <p className="text-xs text-secondary font-medium">
                  #{lineItem.articleNumber}
                </p>
              </div>
            </div>
          </div>
        </td>
        <td className="text-xs text-secondary font-medium space-y-0.5">
          <p>
            <Text>products.details.lineSupplier</Text>:{" "}
            {lineItem.supplier?.name ?? "--"}
          </p>
          <p>
            <Text>products.details.lineProgram</Text>:{" "}
            {lineItem.supplier?.program?.name ?? "--"}
          </p>
          <p>
            <Text>products.details.lineProductFamily</Text>:{" "}
            <Translate
              value={lineItem.productFamily?.translates}
              defaultValue="--"
            />
          </p>
        </td>
        <td>
          <p className="text-sm text-dark font-medium">
            x{convertNumber(lineItem.quantity)}
          </p>
        </td>
        <td className="text-xs font-medium space-y-0.5">
          <p className="text-secondary">
            <Text>products.details.lineVk2</Text>:{" "}
            {convertAmount(lineItem.vk2Price?.amount)}
          </p>
          <p className="text-dark">
            <Text>products.details.lineVk1</Text>:{" "}
            {convertAmount(lineItem.originalVk1Price?.amount)}
          </p>
          {salesPrice?.isOverwritten && (
            <p className="text-dark">
              <Text>products.details.lineOverwritten</Text>:{" "}
              {convertAmount(lineItem.vk1Price?.amount)}
            </p>
          )}
        </td>
        <td className="text-dark text-sm">
          {convertAmount(lineItem.linePrice)}
        </td>
        <td className="space-x-2">
          <Button
            type="button"
            variant={null}
            onClick={toggleDetails}
            className={twMerge(
              "inline-flex items-center gap-2",
              salesPrice?.color?.bgButton,
              salesPrice?.color?.border,
              salesPrice?.color?.text
            )}
          >
            <Icon name={icon} variant="Bold" />
            <Icon name="ArrowDown2" variant="Bold" />
          </Button>
          <Button
            type="button"
            variant="primary"
            light
            onClick={toggleCommission}
            className="inline-flex items-center"
          >
            <Icon name="Setting4" variant="Bold" />
          </Button>
        </td>
        {/* <td>
    <Button type="button" light className="btn-sm">
    <Text>products.details.lineDetailsButton</Text>
    </Button>
  </td> */}
      </tr>
      <LineItemPriceDetails
        isOpen={showDetails}
        toggle={toggleDetails}
        lineItem={lineItem}
      />
      <SalesPersonCommissionDrawer
        isOpen={showCommission}
        toggle={hideCommission}
        lineItem={lineItem}
      />
    </Fragment>
  );
}
