import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../types";

const profile = createSlice({
  name: "profile",
  initialState: {} as Profile.Me,
  reducers: {
    setProfile: (state, action: PayloadAction<Profile.Me>) => {
      return action.payload;
    },
  },
});

export const { setProfile } = profile.actions;
export default profile.reducer;
