import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Icon,
  Layout,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
  Wrapper,
} from "../../../components";
import { config } from "../../../constants";
import { OfferFilter } from "../../../containers";
import { CustomerType } from "../../../enums";
import { withPermission } from "../../../hoc";
import { useAxios, useConverters, useToggle } from "../../../hooks";
import { Offer, WithPaging } from "../../../types";
import OfferActions from "./OfferActions";
type dataType = WithPaging<Offer.Item>;
type Params = Offer.Params;
function Offers() {
  const { axios, loading } = useAxios();
  const { convertAmount, convertDate } = useConverters();
  const [data, setData] = useState({} as dataType);
  const [showFilterMenu, toggleFilterMenu] = useToggle(false);
  const [activeActionId, setActiveActionId] = useState<string | null>(null);
  const activeAction = data.items?.find((e) => e.id === activeActionId) ?? null;
  const [params, setParams] = useState<Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const clearActiveActionId = () => {
    setActiveActionId(null);
  };
  const handleSetParams = (key: keyof Params) => {
    return (value: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: value, pageNumber: 1 }));
      }
      return setParams((p) => ({ ...p, [key]: value }));
    };
  };
  const getData = () => {
    const url = "salesservice/api/offer";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Layout>
      <Layout.Header>
        <div className="flex-1">
          <SearchBox onSubmit={handleSetParams("keyword")} />
        </div>
        <Button light onClick={toggleFilterMenu}>
          <Icon name="Filter" variant="Bold" size={20} />
        </Button>
      </Layout.Header>
      <Layout.Body>
        <Wrapper>
          <Wrapper.Body>
            <Table.Wrapper>
              <Table>
                <Table.Body loading={loading.get}>
                  {data.items?.map((e) => {
                    const isCompany =
                      e.customer?.customerType === CustomerType.Company;
                    return (
                      <tr key={e.id}>
                        <td>
                          <Link to={e.id} className="flex-center w-fit gap-4">
                            <Icon.Wrapper>
                              <Icon name="DiscountShape" variant="Bold" />
                            </Icon.Wrapper>
                            <div className="space-y-1">
                              <h6 className="block">{e.title}</h6>
                              <span className="block text-secondary">
                                #{e.number}
                              </span>
                            </div>
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/${isCompany ? "companies" : "customers"}/${
                              e.customer?.customerId
                            }`}
                            className="space-y-1"
                          >
                            <h6 className="block">
                              <Icon
                                name="Link1"
                                size={18}
                                className="text-primary"
                              />{" "}
                              {isCompany
                                ? e.customer?.companyName
                                : [
                                    e.customer?.firstName,
                                    e.customer?.lastName,
                                  ].join(" ")}
                            </h6>
                            <span className="block text-secondary">
                              #{e.customer?.cimNumber}
                            </span>
                          </Link>
                        </td>
                        <td>{convertAmount(e.totalAmount)}</td>
                        <td>
                          <span className="block">
                            <Text>global.version</Text> {e.version}
                          </span>
                          <span className="block text-secondary">
                            <Text>global.updatedAt</Text> (
                            {convertDate(e.lastUpdatedAt || e.createdAt)})
                          </span>
                        </td>
                        <td>
                          <Status.Offer id={e.status} />
                        </td>
                        <td data-sticky>
                          <Button light onClick={() => setActiveActionId(e.id)}>
                            <Text>button.action</Text>
                            <Icon
                              name="ArrowDown2"
                              size={18}
                              className="ml-1"
                            />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </Table.Body>
              </Table>
            </Table.Wrapper>
          </Wrapper.Body>
        </Wrapper>
        <Pagination
          pageNumber={params.pageNumber}
          setActivePage={handleSetParams("pageNumber")}
          totalItems={data.totalItems}
          totalPages={data.totalPages}
        />
      </Layout.Body>
      <OfferFilter
        isOpen={showFilterMenu}
        toggle={toggleFilterMenu}
        onSubmit={(params) => setParams((p) => ({ ...p, ...params }))}
      />
      <OfferActions
        isOpen={!!activeActionId}
        toggle={clearActiveActionId}
        data={activeAction}
      />
    </Layout>
  );
}
export default withPermission(Offers, [
  "SC_SaleServiceOfferFullAccess",
  "SC_GetSaleServiceOffer",
]);
