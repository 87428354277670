import { Fragment } from "react";
import { useLanguage } from "../hooks";
import { cleanText, isEmptyValue } from "../methods";

type item = {
  language: string | null;
  labelTitle: string | null;
  description: string | null;
};

type TranslateProps = {
  value?: item[] | null;
  defaultValue?: string | null;
};

export default function Translate({ value, defaultValue }: TranslateProps) {
  const [language] = useLanguage();
  const text =
    value?.find(
      (e) =>
        isEmptyValue(e.language) ||
        e.language?.toLowerCase() === language.toLowerCase()
    )?.labelTitle || defaultValue;
  return <Fragment>{cleanText(text)}</Fragment>;
}
