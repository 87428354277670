import { useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import {
  Dropdown,
  Icon,
  InputGroup,
  Loading,
  Select,
  Text,
} from "../components";
import {
  DropdownHandler,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../components/_Dropdown";
import { useAxios, useDebounce } from "../hooks";
import { Address, classNameProps } from "../types";
type SearchResult = {
  type: string;
  id: string;
  address: {
    buildingNumber: string | null;
    country: string | null;
    countryCode: string | null;
    countryCodeISO3: string | null;
    countrySecondarySubdivision: string | null;
    countrySubdivision: string | null;
    countrySubdivisionName: string | null;
    countryTertiarySubdivision: string | null;
    crossStreet: string | null;
    extendedPostalCode: string | null;
    freeformAddress: string | null;
    municipality: string | null;
    municipalitySubdivision: string | null;
    postalCode: string | null;
    street: string | null;
    streetName: string | null;
    streetNameAndNumber: string | null;
    streetNumber: string | null;
  };
  position: { lat: number; lon: number };
};
type AddressItem = {
  name: string;
  id: string;
  address: Address;
};
type searchAddressProps = {
  label?: string;
  setValue: (val: Address) => void;
  countries: { name: string; id: string }[];
  loading?: boolean;
} & classNameProps;
export default function SearchAddress({
  label,
  setValue,
  className,
  countries = [],
  loading: countryLoading,
}: searchAddressProps) {
  const debounce = useDebounce();
  const dropdownRef = useRef<DropdownHandler>(null);
  const { axios, loading } = useAxios();
  const [search, setSearch] = useState("");
  const [items, setItems] = useState<AddressItem[]>([]);
  const [country, setCountry] = useState("DE");
  const handleSetSearch = (query: string) => {
    setSearch(query);
    debounce(() => {
      const hasQuery = !!query;
      if (!hasQuery) {
        setItems([]);
        return dropdownRef.current?.hide();
      }
      dropdownRef.current?.show();
      handleSearchAddress({ countrySet: country, query });
    });
  };
  const handleSearchAddress = (params: {
    countrySet: string | null;
    query: string;
  }) => {
    const url = "/addressservice/api/address/search";
    const config = { params, cache: true };
    axios.get(url, config).then(({ data }) => {
      const result = data.results.map((e: SearchResult) => {
        const hasPosition = !!e.position;
        return {
          name: e.address?.freeformAddress,
          id: e.id,
          address: {
            name: null,
            street: e.address?.streetName ?? null,
            number: e.address?.streetNumber ?? null,
            postalCode: e.address?.postalCode ?? null,
            city: e.address?.municipality ?? null,
            state: e.address?.countrySubdivision ?? null,
            country: e.address?.country ?? null,
            countryCode: e.address.countryCode ?? null,
            countryCodeISO3: e.address.countryCodeISO3 ?? null,
            countrySubdivision: e.address.countrySubdivision ?? null,
            countrySubdivisionName: e.address.countrySubdivisionName ?? null,
            location: {
              latitude: e.position?.lat ?? null,
              longitude: e.position?.lon ?? null,
            },
          } as Address,
        };
      });
      setItems(result);
    });
  };
  const handleSetAddress = (addressItem: AddressItem) => {
    return () => {
      setSearch(addressItem.name);
      setItems([addressItem]);
      setValue(addressItem.address);
    };
  };
  return (
    <div
      className={twMerge("w-full grid grid-cols-5 gap-x-4 gap-y-2", className)}
    >
      {!!label && (
        <h6 className="block col-span-full truncate text-base font-normal">
          <Text>{label}</Text>
        </h6>
      )}
      <Select
        value={country}
        setValue={setCountry}
        className="col-span-2"
        placeholder="placeholders.searchAddressCountry"
        items={countries}
        loading={countryLoading}
      />
      <Dropdown ref={dropdownRef} className="col-span-3">
        <DropdownToggle
          // @ts-expect-error
          as={InputGroup}
          value={search}
          setValue={handleSetSearch}
          // label={label}
          placeholder="placeholders.searchAddress"
          append={
            <span className="input-group-text text-primary">
              <Icon name="ArrowDown2" size={18} />
            </span>
          }
        />
        <DropdownMenu>
          {loading.get ? (
            <Loading.Inline />
          ) : (
            items.map((e) => (
              <DropdownItem
                key={e.id}
                eventKey={e.id}
                onClick={handleSetAddress(e)}
              >
                {e.name}
              </DropdownItem>
            ))
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
