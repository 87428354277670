import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Button, Drawer, Icon, InputGroup, Text } from "../../../../components";
import { useAxios, useConverters, useToggle } from "../../../../hooks";

import ChooseOrder from "./ChooseOrder";

interface CreateCommisionGroupProps {
  isOpen?: boolean;
  toggle: () => void;
  getData: () => void;
  id: string;
}

interface CreateCommisionPost {
  title: string;
  customerId: string;
  deliveryDate: string;
  orderIds: string[];
}

export default function CreateCommissionGroup({
  isOpen = false,
  id,
  toggle,
  getData,
}: CreateCommisionGroupProps) {
  const { axios, loading } = useAxios();
  const { convertAmount } = useConverters();
  const [isOpenChoosser, toggleChooser] = useToggle();
  const [orders, setOrders] = useState<string[]>([]);

  const [postData, SetPostData] = useState<CreateCommisionPost>({
    title: "",
    customerId: id,
    deliveryDate: "",
    orderIds: [],
  });

  useEffect(() => {
    SetPostData({
      title: "",
      customerId: id,
      deliveryDate: "",
      orderIds: [],
    });
  }, [isOpen]);

  const onChangeHandler = (key: keyof CreateCommisionPost) => {
    return (value: any) => SetPostData((p) => ({ ...p, [key]: value }));
  };

  const onSubmitHandler = () => {
    const url = "salesservice/api/commissiongroups";
    const orderIds = orders?.map((order: any) => order.id);
    const body = {
      ...postData,
      deliveryDate: new Date(postData?.deliveryDate).toISOString(),
      orderIds,
    };
    //Create Commission
    axios.post(url, body).then(() => {
      const message = "commissions.createCommissionSuccessfuly";
      toast.success(message);
      getData();
      toggle();
    });
  };

  const onDeleteOrders = (id: string) => {
    const clone = orders.filter((order: any) => order.id !== id);
    setOrders(clone);
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
        <Drawer.Menu>
          <Drawer.Header>
            <Text>commissions.createNewBtn</Text>
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            <InputGroup
              label="commissions.delivaryDate"
              value={postData?.deliveryDate}
              setValue={onChangeHandler("deliveryDate")}
              type="date"
              minDate={new Date()}
            />
            <InputGroup
              label="commissions.title"
              value={postData?.title}
              setValue={onChangeHandler("title")}
            />
            <div className="flex justify-between items-center">
              <p className="font-medium">
                <Text>commissions.assignedOrders</Text>
              </p>
              <Button
                light
                onClick={toggleChooser}
                disabled={!postData?.deliveryDate}
              >
                <Icon name="Add" />{" "}
                <Text>commissions.assignCustomerOrders</Text>
              </Button>
            </div>
            {orders?.map((order: any) => {
              return (
                <div
                  className="flex space-x-2 border justify-between rounded-md p-2"
                  key={order.id}
                >
                  <div className="flex space-x-2 ">
                    <Icon name="ReceiptText" className="text-primary" />
                    <div className="flex flex-col space-y-2">
                      <p className="text-sm font-medium">{order?.title}</p>
                      <p className="text-xs text-gray-400">#{order.number}</p>
                      <p className="text-xs text-gray-400">
                        {convertAmount(order.totalAmount)}
                      </p>
                      {/* <span className="flex items-center gap-1">
                        <p className="text-xs text-gray-600 space-x-1">
                          <Icon
                            name="LocationTick"
                            className="text-danger text-sm"
                          />
                          <span>{order?.store?.storeName}</span>
                        </p>
                      </span> */}
                    </div>
                  </div>
                  <Icon
                    name="Trash"
                    variant="Bold"
                    className="text-gray-400 cursor-pointer"
                    onClick={() => onDeleteOrders(order.id)}
                  />
                </div>
              );
            })}
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-end gap-4">
            <Button type="button" variant="danger" onClick={toggle}>
              <Text>button.cancel</Text>
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={onSubmitHandler}
              loading={loading.post}
              disabled={!postData?.deliveryDate}
            >
              <Text>commissions.createCommissionGroup</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <ChooseOrder
        isOpen={isOpenChoosser}
        toggle={toggleChooser}
        orders={orders}
        setOrders={setOrders}
        deliveryDate={postData?.deliveryDate}
      />
    </>
  );
}
