import { Button, Icon, Modal, Text } from "../../../../components";
import { iconNameType, togglePropsType } from "../../../../types";

type AddDeliveryDateModalProps = togglePropsType & {
  onAssignToCommission: () => void;
  onSetManual: () => void;
};
type Item = {
  icon: iconNameType;
  label: string;
  onClick: () => void;
};

export default function AddDeliveryDateModal({
  isOpen,
  toggle,
  onAssignToCommission,
  onSetManual,
}: AddDeliveryDateModalProps) {
  const items: Item[] = [
    {
      icon: "CalendarTick",
      label: "actionTitles.assignToCommissionGroup",
      onClick: onAssignToCommission,
    },
    {
      icon: "CalendarTick",
      label: "actionTitles.setManual",
      onClick: onSetManual,
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="text-center">
        <Text>modalTitles.addDeliveryDate</Text>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        {items.map((e) => (
          <button
            key={e.label}
            type="button"
            className="w-full flex flex-center gap-4 p-5 border border-[#DEE8F1] bg-[#FBFCFD] rounded"
            onClick={() => {
              e.onClick();
              toggle();
            }}
          >
            <Icon
              name={e.icon}
              variant="Bulk"
              size={25}
              className="text-primary"
            />
            <div className="text-start flex-1 space-y-1">
              <h6 className="text-dark text-sm font-medium ">
                <Text>{e.label}</Text>
              </h6>
            </div>
          </button>
        ))}
      </Modal.Body>
      <Modal.Footer className="flex flex-center">
        <Button light onClick={toggle}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
