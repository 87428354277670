import SellOffProductDetails from "../pages/SellOffProducts/Details";
import SellOffProductList from "../pages/SellOffProducts/List";

const sellOffProducts = [
  {
    path: "sell-off-products",
    element: <SellOffProductList />,
  },
  {
    path: "sell-off-products/:productId",
    element: <SellOffProductDetails />,
  },
];
export default sellOffProducts;
