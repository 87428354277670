import { useCallback } from "react";
import { twMerge } from "tailwind-merge";
import { Text } from "../components";
import { types } from "../constants";
import { CalculationOperationType, SalePriceTemplateItemStep } from "../enums";
import { useConverters } from "../hooks";
import { PriceEngine } from "../types";

type PriceEngineProps = {
  data?: PriceEngine | null;
  quantity?: number | string;
  loading?: boolean;
};

export default function PriceEngineViewer({
  data,
  quantity = 1,
  loading = false,
}: PriceEngineProps) {
  const { convertAmount } = useConverters();
  const totalLineItemPrice =
    (data?.finalPrice ?? 0) * Math.max(Math.abs(+quantity), 1);
  const discounts = data?.discounts?.sort((a, b) => a.order - b.order) ?? [];
  const autoDiscounts = discounts.filter(
    (e) => e.step === SalePriceTemplateItemStep.AutomaticDiscounts
  );
  const otherDiscounts = discounts.filter(
    (e) => e.step !== SalePriceTemplateItemStep.AutomaticDiscounts
  );
  const renderRow = useCallback(
    (e: (typeof discounts)[number]) => {
      const isIncrement =
        e.operationType === CalculationOperationType.Increment;
      const isDecrement =
        e.operationType === CalculationOperationType.Decrement;
      const calculationValue = types.calculationValue.find(
        ({ id }) => id === e.valueType
      );
      const amountSymbols = {
        [CalculationOperationType.Increment]: "+",
        [CalculationOperationType.Decrement]: "-",
      };
      const amountSymbol = amountSymbols?.[e.operationType];
      return (
        <tr key={e.discountIdentifier} className="text-sm">
          <td className="text-start">{e.title}</td>
          <td
            className={twMerge(
              "text-start",
              isIncrement && "text-success",
              isDecrement && "text-danger"
            )}
          >
            {amountSymbol}
            {e.itemValue}
            {calculationValue?.symbol}
          </td>
          <td
            className={twMerge(
              "text-end",
              isIncrement && "text-success",
              isDecrement && "text-danger"
            )}
          >
            {amountSymbol}
            {convertAmount(e.discountAmount)}
          </td>
        </tr>
      );
    },
    [convertAmount]
  );
  if (loading) return <PriceEngineLoading />;
  if (!data) return null;
  return (
    <table className="w-full [&_td]:py-1 [&_td]:px-4 text-gray-900">
      <tbody>
        <tr className="text-sm border-b border-gray-50">
          <td className="text-start">
            <Text>global.originalPrice</Text>:
          </td>
          <td></td>
          <td className="text-end">{convertAmount(data.originalPrice)}</td>
        </tr>
        {autoDiscounts.map(renderRow)}
        <tr className="text-sm">
          <td className="text-start">
            <Text>global.productPrice</Text>:
          </td>
          <td></td>
          <td className="text-end">{convertAmount(data.productPrice)}</td>
        </tr>
        {otherDiscounts.map(renderRow)}
        <tr className="border-t border-gray-50 [&_td]:py-2">
          <td className="text-start">
            <Text>global.finalPrice</Text> (€)
          </td>
          <td></td>
          <td className="text-end">{convertAmount(data.finalPrice)}</td>
        </tr>
        <tr className="text-sm border-t border-gray-50">
          <td className="text-start">
            <Text>global.quantityTitle</Text>:
          </td>
          <td></td>
          <td className="text-end">{quantity}</td>
        </tr>
        <tr className="border-t border-gray-50 [&_td]:py-2">
          <td className="text-start">
            <Text>global.totalLineItemPrice</Text> (€)
          </td>
          <td></td>
          <td className="text-end">{convertAmount(totalLineItemPrice)}</td>
        </tr>
      </tbody>
    </table>
  );
}
function PriceEngineLoading() {
  return (
    <table className="w-full [&_td]:py-1 [&_td]:px-4 text-gray-900">
      <tbody>
        {[...Array(5).keys()].map((key) => (
          <tr key={key} className="animate-pulse">
            <td>
              <span className="block w-full h-2 rounded-full bg-gray-100" />
            </td>
            <td>
              <span className="block w-4 h-2 rounded-full bg-gray-100 mx-auto" />
            </td>
            <td>
              <span className="block w-full h-2 rounded-full bg-gray-100" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
