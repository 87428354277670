import { Fragment, useState } from "react";
import {
  Button,
  CheckBox,
  Drawer,
  Form,
  Icon,
  Text,
} from "../../../components";
import { config, status } from "../../../constants";
import { SelectedEmployee } from "../../../containers/_IncidentAssignedEmployee";
import { AssignedType } from "../../../enums";
import { isEmptyValue } from "../../../methods";
import { Task, togglePropsType } from "../../../types";

type TaskFilterProps = togglePropsType & {
  initParams: Task.Params;
  onSubmit: (params: Task.Params) => void;
};

export default function TaskFilter({
  isOpen,
  toggle,
  initParams,
  onSubmit,
}: TaskFilterProps) {
  const [params, setParams] = useState(initParams);
  const [assignedType, setAssignedType] = useState<AssignedType | null>(null);
  const [employeeName, setEmployeeName] = useState<string | null>(null);
  const hasEmployee = ![params.assignedId, employeeName, assignedType].some(
    isEmptyValue
  );
  const employee: SelectedEmployee | null = hasEmployee
    ? {
        name: employeeName!,
        id: params.assignedId!,
        type: assignedType!,
      }
    : null;
  const handleSetEmployee = (employee: SelectedEmployee | null) => {
    setParams((p) => ({ ...p, assignedId: employee?.id }));
    setEmployeeName(employee?.name ?? null);
    setAssignedType(employee?.type ?? null);
  };
  const resetFilter = () => {
    setParams({ pageNumber: 1, pageSize: config.pageSize });
  };
  const submit = () => {
    onSubmit({ ...params, pageNumber: 1 });
    toggle();
  };
  return (
    <Fragment>
      <Drawer
        as={Form}
        onSubmit={submit}
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
      >
        <Drawer.Menu>
          <Drawer.Header className="flex items-center gap-4">
            <h6 className="text-dark text-base flex-1 text-start">
              <Text>task.filter.title</Text>
            </h6>
            <button
              type="button"
              className="text-warning"
              onClick={resetFilter}
            >
              <Icon
                name="ArrowForwardSquare"
                variant="Bold"
                className="text-warning"
              />{" "}
              <Text>task.filter.resetFilterBtn</Text>
            </button>
          </Drawer.Header>
          <Drawer.Body className="space-y-5">
            {/* <IncidentAssignedEmployee
              value={employee}
              setValue={handleSetEmployee}
            /> */}
            <section className="border rounded p-2 space-y-4">
              <h6 className="text-base text-dark">
                <Text>task.filter.taskStatus</Text>
              </h6>
              <div className="flex items-center flex-wrap gap-2">
                {status.CRMActivity.map((e) => (
                  <CheckBox
                    key={e.name}
                    label={e.name}
                    value={e.id === params.status}
                    setValue={() => setParams((p) => ({ ...p, status: e.id }))}
                    className="w-auto flex-1"
                  />
                ))}
              </div>
            </section>
          </Drawer.Body>
          <Drawer.Footer className="flex items-center justify-end gap-4">
            <Button type="button" variant="danger" onClick={toggle}>
              <Text>task.filter.cancelBtn</Text>
            </Button>
            <Button type="submit" variant="primary">
              <Text>task.filter.submitBtn</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
    </Fragment>
  );
}
