import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ProductDetailsContext } from ".";
import { PurchasePriceType } from "../../../enums";
import { useAxios, useDefaultSaleChannel } from "../../../hooks";
import { childrenProps, PimProduct } from "../../../types";

type Props = {} & childrenProps;
type Context = {
  productSalesPrice: Data | null;
  salesPriceLoading: boolean;
  bundleItems: BundleItemWithSalesPrice[] | null;
};
type SalesPriceData = {
  vk1: number;
  vk2: number;
};
type Color = {
  bg: string;
  bgButton: string;
  border: string;
  text: string;
};
type Data = SalesPriceData & {
  productId: string;
  isOverwritten: boolean;
  isWarning: boolean;
  isError: boolean;
  color: Color | null;
};
type SalesPriceDto = { [key: string]: SalesPriceData };
export type BundleItemWithSalesPrice = PimProduct.BundleItem & {
  salesPrice: null | Data;
};
export const ProductPriceContext = createContext({} as Context);

export default function ProductPriceProvider({ children }: Props) {
  const salesPriceRef = useRef<SalesPriceDto>({});
  const { product, isStandard, isBundle, isOverwritten } = useContext(
    ProductDetailsContext
  );
  const { axios, loading } = useAxios();
  const saleChannel = useDefaultSaleChannel();
  const [productSalesPrice, setProductSalesPrice] = useState<Data | null>(null);
  const [bundleItems, setBundleItems] = useState<
    BundleItemWithSalesPrice[] | null
  >(null);
  const calculateSalesPrice = useCallback(
    async (product: PimProduct.Details | PimProduct.BundleItem) => {
      const cachedData = salesPriceRef.current[product.id];
      if (!!cachedData) return Promise.resolve(cachedData);
      const url =
        "/priceengineservice/api/sale-price-calculation/calculate-pim-sale-price";
      const body = {
        productId: product.id,
        supplierId: product.supplier?.supplierId ?? null,
        programId: product.supplier?.program?.supplierProgramId ?? null,
        brandId: product.supplier?.program?.brand?.brandId ?? null,
        basePrice: product.grossPrice?.amount ?? 0,
        saleChannelId: saleChannel?.saleChannelId ?? null,
        purchasePriceType: PurchasePriceType.Gross,
      };
      return await axios
        .post<SalesPriceData>(url, body)
        .then((e) => {
          salesPriceRef.current[product.id] = e.data;
          return e.data;
        })
        .catch(() => null);
    },
    [saleChannel?.saleChannelId]
  );
  const generateColor = useCallback(
    ({ isWarning, isError }: { isWarning: boolean; isError: boolean }) => {
      if (isWarning)
        return {
          bg: "bg-[#FFE6D0]",
          bgButton: "bg-[#FFE6D0]",
          border: "border-[#FFE6D0]",
          text: "text-warning",
        };
      if (isError)
        return {
          bg: "bg-[#FFEBEB]",
          bgButton: "bg-[#FFEBEB]",
          border: "border-[#FFEBEB]",
          text: "text-danger",
        };
      return {
        bg: "bg-gray-50",
        bgButton: "bg-[#40CFBE1A]",
        border: "border-[#40CFBE1A]",
        text: "text-success",
      };
    },
    []
  );
  const getProductSalesPrice = useCallback(async () => {
    if (!isStandard) return;
    const salesPrice = await calculateSalesPrice(product);
    if (!salesPrice) return;
    const isEqual = salesPrice?.vk1 === product.originalVk1Price?.amount;
    const isWarning = !isEqual && !isOverwritten;
    const isError = !isEqual && isOverwritten;
    setProductSalesPrice({
      ...salesPrice,
      productId: product.id,
      isOverwritten,
      isWarning,
      isError,
      color: generateColor({ isWarning, isError }),
    });
  }, [isStandard, calculateSalesPrice, generateColor, isOverwritten, product]);
  const getItemsSalesPrice = useCallback(async () => {
    if (!isBundle) return;
    const bundleItems = product.bundleItems ?? [];
    const salesPricesMap = bundleItems.map((e) => calculateSalesPrice(e));
    const salesPricesResult = await Promise.all(salesPricesMap);
    const salesPrices = salesPricesResult.map((salesPrice, index) => {
      const product = bundleItems?.[index];
      const isOverwritten =
        !!product?.hasOverwrittenPrice &&
        product?.vk1Price?.amount !== product?.originalVk1Price?.amount;
      const isEqual = salesPrice?.vk1 === product?.originalVk1Price?.amount;
      const isWarning = !isEqual && !isOverwritten;
      const isError = !isEqual && isOverwritten;
      const color = generateColor({ isWarning, isError });
      return {
        ...salesPrice,
        productId: product?.id,
        isOverwritten,
        isWarning,
        isError,
        color,
      } as Data;
    });
    const bundleItemsWithSalesPrice = bundleItems.map((e) => ({
      ...e,
      salesPrice:
        salesPrices.find(({ productId }) => productId === e.id) ?? null,
    }));
    const itemSalesPrice =
      salesPrices.find((e) => e.isError || e.isWarning) ?? salesPrices?.[0];

    const productVk2 = bundleItemsWithSalesPrice
      ?.map((e) => (e.salesPrice?.vk2 ?? 0) * e.quantity)
      .reduce((a, b) => a + b, 0);
    const productVk1 = bundleItemsWithSalesPrice
      ?.map((e) => (e.salesPrice?.vk1 ?? 0) * e.quantity)
      .reduce((a, b) => a + b, 0);
    const productSalesPrice: Data = {
      productId: product?.id,
      vk2: productVk2 ?? 0,
      vk1: productVk1 ?? 0,
      color: itemSalesPrice?.color,
      isOverwritten: itemSalesPrice?.isOverwritten,
      isWarning: itemSalesPrice?.isWarning,
      isError: itemSalesPrice?.isError,
    };
    setProductSalesPrice(!!itemSalesPrice ? productSalesPrice : null);
    setBundleItems(bundleItemsWithSalesPrice);
  }, [
    isBundle,
    calculateSalesPrice,
    generateColor,
    product.bundleItems,
    product.id,
  ]);
  useEffect(() => {
    getProductSalesPrice();
  }, [getProductSalesPrice]);
  useEffect(() => {
    getItemsSalesPrice();
  }, [getItemsSalesPrice]);
  return (
    <ProductPriceContext.Provider
      value={{
        productSalesPrice,
        salesPriceLoading: loading.post,
        bundleItems,
      }}
    >
      {children}
    </ProductPriceContext.Provider>
  );
}
