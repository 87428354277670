import {
  createContext,
  createElement,
  Fragment,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  AddressViewer,
  BackButton,
  Image,
  Layout,
  Loading,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { useAxios, useDefaultSaleChannel } from "../../../hooks";
import { Supplier } from "../../../types";
import BankInfo from "./BankInfo";
import Contact from "./Contact";
import GeneralInfo from "./GeneralInfo";
import Program from "./Program";
import Settings from "./Settings";

type Data = Supplier.SalesDetails;
type Context = { supplier: Data | null };

export const SupplierDetailsContext = createContext<Context>({
  supplier: null,
});
export default function SupplierDetails() {
  const tabs = [
    { label: "suppliers.details.generalInfo", id: "0", component: GeneralInfo },
    { label: "suppliers.details.bankInfo", id: "1", component: BankInfo },
    { label: "suppliers.details.program", id: "2", component: Program },
    { label: "suppliers.details.contact", id: "3", component: Contact },
    { label: "suppliers.details.settings", id: "4", component: Settings },
  ];
  const { axios, loading } = useAxios();
  const saleChannel = useDefaultSaleChannel();
  const { supplierId } = useParams();
  const [data, setData] = useState<Data | null>({} as any);
  const hasData = !!data;
  const getData = () => {
    if (!supplierId || !saleChannel?.code) return;
    setData(null);
    const url = `/salesservice/api/suppliers/${supplierId}/${saleChannel.code}`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [saleChannel, supplierId]);
  return (
    <Tabs activeKey={tabs[0].id}>
      <Layout>
        <Layout.Header>
          <BackButton to="/suppliers" />
        </Layout.Header>
        <Layout.Body className="space-y-3">
          {loading.get ? (
            <Loading.Header />
          ) : hasData ? (
            <Fragment>
              <Wrapper>
                <Wrapper.Body className="flex flex-wrap items-stretch gap-2.5">
                  <Image
                    src={data.logo?.url}
                    alt={data.name}
                    className="w-48 h-28 rounded object-cover"
                    isDynamic
                  />
                  <section className="flex-1 flex flex-col gap-1.5 whitespace-nowrap min-w-max">
                    <h1 className="text-gray-800 text-sm font-medium">
                      {data.name}
                    </h1>
                    <p className="text-xs text-secondary font-medium">
                      #{data.code}
                    </p>
                    <p className="text-xs text-secondary font-medium">
                      <Text version={data.version}>
                        suppliers.details.version
                      </Text>
                    </p>
                    <AddressViewer
                      address={data.address}
                      className="text-xs text-secondary font-medium mt-auto"
                    />
                  </section>
                </Wrapper.Body>
                <Wrapper.Footer className="py-0">
                  <Tabs.ButtonGroup>
                    {tabs.map((e) => (
                      <Tabs.Button key={e.id} eventKey={e.id}>
                        <Text>{e.label}</Text>
                      </Tabs.Button>
                    ))}
                  </Tabs.ButtonGroup>
                </Wrapper.Footer>
              </Wrapper>
              <SupplierDetailsContext.Provider value={{ supplier: data }}>
                {tabs.map((e) => (
                  <Tabs.Item key={e.id} eventKey={e.id}>
                    {createElement(e.component)}
                  </Tabs.Item>
                ))}
              </SupplierDetailsContext.Provider>
            </Fragment>
          ) : null}
        </Layout.Body>
      </Layout>
    </Tabs>
  );
}
