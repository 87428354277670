import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Drawer,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../components";
import { config } from "../constants";
import { CustomerType } from "../enums";
import { useAxios } from "../hooks";
import { getRandomColor } from "../methods";
import { Address, Customer, WithPaging, togglePropsType } from "../types";

export type SelectedCustomer = {
  id: string;
  name: string;
  cimNumber: string | null;
  emailAddress: string;
  phoneNumber: Customer.CallNumber | null;
  type: CustomerType;
  address: Address | null;
};

type ChooseCustomerProps = togglePropsType & {
  onSelect: (customer: SelectedCustomer) => void;
};

export default function ChooseCustomer({
  isOpen,
  toggle,
  onSelect,
}: ChooseCustomerProps) {
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Customer.Item>>();
  const [params, setParams] = useState<Customer.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const handleSelect = (e: Customer.Item) => {
    return () => {
      const isCompany = e.customerType === CustomerType.Company;
      const name = isCompany
        ? e.companyName
        : [e.firstName, e.lastName].join(" ");
      onSelect({
        id: e.id,
        name,
        type: e.customerType,
        cimNumber: e.cimNumber,
        emailAddress: e.emailAddress,
        phoneNumber: e.phoneNumber,
        address: e.address,
      });
      toggle();
    };
  };
  const handleSetParams = (key: string) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p) => ({ ...p, [key]: val }));
    };
  };
  const getData = () => {
    if (!isOpen) return;
    const url = "/accountservice/api/customers";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [isOpen, params, axios]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
      <Drawer.Menu>
        <Drawer.Header className="border-b-0">
          <h6 className="text-base text-dark">
            <Text>incident.chooseCustomer.title</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox
            placeholder="page.customerList.searchBox"
            variant="gray"
            onSubmit={handleSetParams("keyword")}
          />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {data?.items?.map((e, i) => {
              const isCompany = e.customerType === CustomerType.Company;
              return (
                <List.Item key={e.id} onClick={handleSelect(e)}>
                  {/* <td>
                    <Link to={to} className="flex items-center gap-3 w-fit">
                      {!!e.avatar ? (
                        <Image
                          src={e.avatar.thumbnailUrl}
                          alt={e.firstName + e.lastName}
                          className="w-10 h-10 rounded-full bg-light overflow-hidden object-cover"
                          isDynamic
                        />
                      ) : (
                        <Avatar
                          color={getRandomColor(i)}
                          icon={isCompany ? "Home" : "User"}
                        />
                      )}
                      <div>
                        <h6 className="text-dark text-base">
                          {isCompany
                            ? e.companyName
                            : [e.firstName, e.lastName].join(" ")}
                        </h6>
                        <p className="text-secondary text-sm">#{e.cimNumber}</p>
                      </div>
                    </Link>
                  </td> */}
                  <div className="flex items-center gap-3 w-fit">
                    <Avatar
                      icon={isCompany ? "Home" : "User"}
                      color={getRandomColor(i)}
                    />
                    <div>
                      <h6 className="text-base text-dark mb-1">
                        {isCompany
                          ? e.companyName
                          : [e.firstName, e.lastName].join(" ")}
                      </h6>
                      <p className="block text-secondary">
                        {e.cimNumber || "-"}
                      </p>
                    </div>
                  </div>
                  <p className="flex-1 truncate">{e.emailAddress}</p>
                  <Button as="span" light>
                    <Text>incident.chooseCustomer.chooseBtn</Text>
                  </Button>
                </List.Item>
              );
            })}
          </List>
        </Drawer.Body>
        <Drawer.Footer>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            totalItems={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
