import { ComponentType } from "react";
import { useSearchParams } from "react-router-dom";
import { AxiosProvider } from "../providers";

export default function axiosChannelCode<P extends object>(
  Component: ComponentType<P>
) {
  return (props: P) => {
    const [searchParams] = useSearchParams();
    const channelCode = searchParams.get("channelCode") || undefined;
    return (
      <AxiosProvider config={{ params: { channelCode } }}>
        <Component {...props} />
      </AxiosProvider>
    );
  };
}
