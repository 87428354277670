import { Fragment, useContext } from "react";
import { OfferDetailsContext } from "..";
import {
  AddressViewer,
  Button,
  Icon,
  Table,
  Text,
  Wrapper,
} from "../../../../components";
import { useConverters, useToggle } from "../../../../hooks";
import { OrderDetailsContext } from "../../../Orders/Details";
import AddressDrawer from "../AddressDrawer";
import DownPayment from "./DownPayment";
import IsFinance from "./IsFinance";

export default function Billing() {
  const { convertAmount } = useConverters();

  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const [showAddressDrawer, toggleAddressDrawer] = useToggle(false);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;

  const billingAddress = offer?.billingAddress || order?.billingAddress;
  const hasAddress = !!billingAddress;
  const totalAmount = offer?.totalAmount ?? order?.totalAmount;
  const hasTotalAmount =
    offerContext?.hasTotalAmount ?? orderContext?.hasTotalAmount ?? false;

  return (
    <Fragment>
      <Wrapper className="lg:!col-span-2">
        <Wrapper.Header className="flex items-center">
          <h6 className="text-base text-dark">
            <Text>drawerTitles.billTo</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="flex items-start gap-4">
          {hasAddress && (
            <AddressViewer
              className="text-sm text-secondary flex-1"
              address={billingAddress}
            />
          )}
          {isEnabled && (
            <Button
              type="button"
              variant="primary"
              light
              className={`p-[0.375rem] ${
                hasAddress ? "text-lg" : "text-base"
              } leading-none`}
              onClick={toggleAddressDrawer}
            >
              <Icon name="Edit2" />
              {!hasAddress && (
                <Fragment>
                  {" "}
                  <Text>button.addBillingAddress</Text>
                </Fragment>
              )}
            </Button>
          )}
        </Wrapper.Body>
      </Wrapper>
      {hasTotalAmount ? (
        <Wrapper className="lg:!col-span-3">
          <Wrapper.Header>
            <h6 className="text-base text-dark">
              <Text>offerPaymentDetails.text</Text>
            </h6>
          </Wrapper.Header>
          <Wrapper.Body className="p-0">
            <Table>
              <tbody>
                <tr>
                  <th className="text-start">
                    <Text>offerPaymentDetails.charges</Text>
                  </th>
                  <th className="text-end">
                    <Text>offerPaymentDetails.total</Text>
                  </th>
                </tr>
                <tr>
                  <td className="text-start">
                    <Text>offerPaymentDetails.lineItems</Text>
                  </td>
                  {/* <td>{"offer.quantity"}</td> */}
                  <td className="text-end">{convertAmount(totalAmount)}</td>
                </tr>
              </tbody>
            </Table>
          </Wrapper.Body>
        </Wrapper>
      ) : (
        <DownPayment />
      )}
      <IsFinance />
      {hasTotalAmount && <DownPayment />}
      {isEnabled && (
        <AddressDrawer
          isOpen={showAddressDrawer}
          toggle={toggleAddressDrawer}
          type="billing"
        />
      )}
    </Fragment>
  );
}
