import { Fragment, useContext } from "react";
import toast from "react-hot-toast";
import { OfferDetailsContext } from "..";
import { Button, Icon, Text, Wrapper } from "../../../../components";
import { SetDesireDeliveryDate } from "../../../../containers";
import { useAxios, useConverters, useToggle } from "../../../../hooks";
import { OrderDetailsContext } from "../../../Orders/Details";
import AddDeliveryDateModal from "./AddDeliveryDateModal";
import ChooseCommissionGroup from "./ChooseCommissionGroup";
import SelAllLineItemsShippingMethod from "./SelAllLineItemsShippingMethod";

export default function Offer() {
  const { convertDate } = useConverters();
  const { axios, loading } = useAxios();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const getLineItems =
    offerContext?.updateLineItems ||
    orderContext?.updateLineItems ||
    (() => Promise.resolve([]));

  const offer = offerContext?.offer;
  const updateOffer = offerContext?.updateOffer;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const order = orderContext?.order;
  const updateOrder = orderContext?.updateOrder;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;

  const isOffer = !!offer;
  const isOrder = !!order;

  const hasLoading = [
    loading.delete,
    loading.post,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);
  // const [showAction, toggleAction] = useToggle(false);
  const [showCommissions, toggleCommissions] = useToggle(false);
  const [showCalendar, toggleCalendar] = useToggle(false);
  const [showAddDeliveryDate, toggleAddDeliveryDate] = useToggle(false);
  const [showSetLineItemShippingMethod, toggleSetLineItemShippingMethod] =
    useToggle(false);

  const desiredDeliveryDate =
    offer?.desiredDeliveryDate ?? order?.desiredDeliveryDate;
  const desiredDeliveryCalendarWeek =
    offer?.desiredDeliveryCalendarWeek ?? order?.desiredDeliveryCalendarWeek;

  const hasDeliveryDate = [
    desiredDeliveryDate,
    desiredDeliveryCalendarWeek,
  ].some(Boolean);
  const commissionGroup = order?.commissionGroup ?? null;
  const hasCommissionGroup = !!commissionGroup;

  const removeCommissionGroup = () => {
    const url = `/salesservice/api/commissiongroups/${commissionGroup?.commissionGroupId}/order/${order?.id}`;
    axios.delete(url).then(() => {
      updateOrder?.().then(() => {
        const message = "commissions.updateCommissionSuccessfuly";
        toast.success(message);
      });
    });
  };
  // const removeDate = () => {
  //   const url = [
  //     "/salesservice",
  //     "api",
  //     isOffer && "offer",
  //     isOrder && "orders",
  //     offer?.id ?? order?.id,
  //     "desire-delivery-date",
  //   ]
  //     .filter(Boolean)
  //     .join("/");
  //   const body = {
  //     desiredDeliveryDate: null,
  //     desiredDeliveryCalendarWeek: null,
  //   };
  //   axios.post(url, body).then(() => {
  //     updateOffer?.().then(() => {
  //       toast.success("toast.success.offerDesiredDeliveryDate");
  //     });
  //     updateOrder?.().then(() => {
  //       toast.success("toast.success.orderDesiredDeliveryDate");
  //     });
  //   });
  // };
  return (
    <Fragment>
      <Wrapper className="lg:!col-span-3">
        <Wrapper.Header>
          <h6 className="text-dark text-base">
            <Text>global.offerLogisticText</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="py-0 *:py-4 divide-y">
          <div>
            {hasCommissionGroup ? (
              <Fragment>
                <h6 className="text-sm text-secondary font-medium mb-3">
                  <Text>global.orderAssignedToCommissionGroup</Text>
                </h6>
                <div className="flex items-center gap-4">
                  <Icon.Wrapper variant="primary">
                    <Icon name="CalendarTick" variant="Bold" />
                  </Icon.Wrapper>
                  <div className="flex-1">
                    {/* <h6 className="text-base text-dark font-medium truncate">
                      {commissionGroup.title}
                    </h6> */}
                    <p className="text-sm text-secondary font-medium">
                      #{commissionGroup.code}
                    </p>
                    <p className="text-sm text-secondary font-medium">
                      <Icon name="CalendarTick" variant="Bold" size={14} />{" "}
                      {convertDate(commissionGroup.deliveryDate)}
                    </p>
                  </div>
                  {isEnabled && (
                    <Button
                      type="button"
                      variant="danger"
                      light
                      className="p-[0.375rem] text-lg leading-none"
                      onClick={removeCommissionGroup}
                      loading={hasLoading}
                    >
                      <Icon name="Trash" variant="Bold" />
                    </Button>
                  )}
                </div>
              </Fragment>
            ) : hasDeliveryDate ? (
              <div className="flex items-center gap-2">
                <p className="text-gray-900 text-base flex-1">
                  <Icon name="CalendarTick" variant="Bold" />{" "}
                  <Text>global.calendarWeek</Text>{" "}
                  {desiredDeliveryCalendarWeek?.calendarWeekNumber ?? "--"}
                </p>
                {isEnabled && (
                  <Fragment>
                    <Button
                      type="button"
                      variant="primary"
                      light
                      className="p-[0.375rem] text-lg leading-none"
                      onClick={toggleCalendar}
                    >
                      <Icon name="Edit2" />
                    </Button>
                    {/* <Button
                      type="button"
                      variant="danger"
                      light
                      className="p-[0.375rem] text-lg leading-none"
                      onClick={removeDate}
                      loading={hasLoading}
                    >
                      <Icon name="Trash" variant="Bold" />
                    </Button> */}
                  </Fragment>
                )}
              </div>
            ) : (
              <Fragment>
                <Button
                  type="button"
                  light
                  className="btn-sm"
                  onClick={isOrder ? toggleAddDeliveryDate : toggleCalendar}
                  loading={hasLoading}
                  disabled={!isEnabled}
                >
                  <Icon name="Calendar" variant="Bold" />{" "}
                  <Text>button.setOfferDesireDeliveryDate</Text>
                </Button>
              </Fragment>
            )}
          </div>
          <div>
            <Button
              type="button"
              light
              className="btn-sm"
              onClick={toggleSetLineItemShippingMethod}
              disabled={!isEnabled}
            >
              <Icon name="Add" />{" "}
              <Text>button.setShippingMethodToAllLineItems</Text>
            </Button>
          </div>
        </Wrapper.Body>
      </Wrapper>
      {isEnabled && (
        <Fragment>
          <SetDesireDeliveryDate
            isOpen={showCalendar}
            toggle={toggleCalendar}
            onApplyForLineItems={getLineItems}
          />
          <SelAllLineItemsShippingMethod
            isOpen={showSetLineItemShippingMethod}
            toggle={toggleSetLineItemShippingMethod}
          />
          {isOrder && (
            <Fragment>
              <AddDeliveryDateModal
                isOpen={showAddDeliveryDate}
                toggle={toggleAddDeliveryDate}
                onAssignToCommission={toggleCommissions}
                onSetManual={toggleCalendar}
              />
              <ChooseCommissionGroup
                isOpen={showCommissions}
                toggle={toggleCommissions}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
