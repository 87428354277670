import { Fragment, useContext, useEffect, useState } from "react";
import { CustomerContext } from "..";
import {
  Button,
  Icon,
  Loading,
  NoItems,
  Text,
  Wrapper,
} from "../../../../components";
import { config } from "../../../../constants";
import { withPermission } from "../../../../hoc";
import { useAxios, useConverters, useToggle } from "../../../../hooks";
import { CommissionGroup } from "../../../../types";
import CreateCommissionGroup from "./CreateNewCommision";
import CommissionGroupDetails from "./Details";

function Commissions() {
  const { convertDate, convertAmount } = useConverters();
  const { customerData } = useContext(CustomerContext);
  const { axios, loading } = useAxios();
  const [data, setData] =
    useState<CommissionGroup.CommissionGroupsItemProps[]>();
  const [selectedGroup, setSelectedGroup] =
    useState<CommissionGroup.CommissionGroupsItemProps>();
  const [isOpen, toggle] = useToggle();
  const [isOpenDetails, toggleDetails] = useToggle();
  const [params, setParams] = useState<CommissionGroup.ParamsProps>({
    customerId: customerData.id,
    pageNumber: 1,
    pageSize: config.pageSize,
  });

  const getData = () => {
    const url = "salesservice/api/commissiongroups";
    const config = { params };
    axios.get(url, config).then(({ data }: any) => {
      setData(data?.items);
    });
  };

  useEffect(getData, []);

  useEffect(() => {
    if (selectedGroup) {
      const updateSelectGroup = data?.find(
        (item: any) => item.id === selectedGroup.id
      );
      setSelectedGroup(updateSelectGroup);
    }
  }, [data]);

  return (
    <Fragment>
      {loading.get ? (
        <Loading.Inline />
      ) : (
        <>
          <section className="flex items-center gap-4">
            <Button
              type="button"
              variant="primary"
              className="ms-auto"
              onClick={toggle}
            >
              <Icon name="Add" /> <Text>commissions.createNewBtn</Text>
            </Button>
          </section>
          {data?.length ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {data?.map(
                (commissions: CommissionGroup.CommissionGroupsItemProps) => {
                  return (
                    <Wrapper>
                      <Wrapper.Body className="space-y-4">
                        <div className="flex justify-between">
                          <div className="space-y-2">
                            <p className="font-medium">{commissions?.title}</p>
                            <p className="text-xs font-medium text-gray-400">
                              #{commissions?.code}
                            </p>
                            <p className="text-xs flex items-center">
                              <Icon
                                name="Calendar"
                                className="mr-2 text-primary"
                                variant="Bulk"
                              />
                              <span className="text-gray-400 mr-2">
                                <Text>commissions.delivaryDate</Text>
                              </span>
                              <span className="text-gray-500">
                                {convertDate(commissions?.deliveryDate)}
                              </span>
                            </p>
                          </div>
                          <div>
                            <div className="flex items-center gap-2">
                              <span className="flex items-center gap-1">
                                <p className="text-xs text-gray-600 space-x-1">
                                  <Icon
                                    name="LocationTick"
                                    className="text-danger text-sm"
                                  />
                                  <span>{commissions?.storeName}</span>
                                </p>
                              </span>
                              <Button
                                light
                                onClick={() => {
                                  setSelectedGroup(commissions);
                                  toggleDetails();
                                }}
                              >
                                <Text>button.details</Text>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                          {commissions?.orders?.map(
                            (order: CommissionGroup.OrderProps) => {
                              return (
                                <div className="flex space-x-2 border rounded-md p-2">
                                  <Icon
                                    name="ReceiptText"
                                    className="text-primary"
                                  />
                                  <div className="flex flex-col space-y-2">
                                    <p className="text-sm font-medium">
                                      {order?.title}
                                    </p>
                                    <p className="text-xs text-gray-400">
                                      #{order?.number}
                                    </p>
                                    <p className="text-xs text-gray-400">
                                      {convertAmount(order?.totalAmount)}
                                    </p>
                                    <span className="flex items-center gap-1">
                                      <p className="text-xs text-gray-600 space-x-1">
                                        <Icon
                                          name="LocationTick"
                                          className="text-danger text-sm"
                                        />
                                        <span>{order?.storeName}</span>
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </Wrapper.Body>
                    </Wrapper>
                  );
                }
              )}
            </div>
          ) : (
            <NoItems />
          )}
        </>
      )}
      <CreateCommissionGroup
        isOpen={isOpen}
        toggle={toggle}
        id={customerData?.id}
        getData={getData}
      />
      <CommissionGroupDetails
        isOpen={isOpenDetails}
        toggle={toggleDetails}
        commissionGroup={selectedGroup}
        getData={getData}
      />
    </Fragment>
  );
}

export default withPermission(Commissions, [
  "SC_SaleServiceCommissionGroupFullAccess",
  "SC_GetSaleServiceCommissionGroup",
]);
