import { Dispatch } from "react";
import {
  Accordion,
  Button,
  Drawer,
  Icon,
  IconButton,
  Image,
  Status,
  Text,
  Wrapper,
} from "../../../../components";
import { dateFormats, languages } from "../../../../constants";
import { PrintRequestStatus } from "../../../../enums";
import { useConverters } from "../../../../hooks";
import { Product, togglePropsType } from "../../../../types";

type Props = {
  printRequest: Product.PrintRequestItem;
  onRemove: Dispatch<string>;
} & togglePropsType;

export default function PrintRequestDetails({
  isOpen,
  toggle,
  printRequest,
  onRemove,
}: Props) {
  const { convertDate, convertAmount } = useConverters();
  const isFailed = printRequest.status === PrintRequestStatus.Failed;
  const isOnProcess = printRequest.status === PrintRequestStatus.OnProcess;
  const language = languages.find((e) => e.id === printRequest.language);
  const hasLanguage = !!language;
  const items = [
    {
      label: "products.details.printHistoryDetails.title",
      value: printRequest.title,
    },
    {
      label: "products.details.printHistoryDetails.requestDate",
      value: convertDate(printRequest.createdAt, dateFormats.fullDate),
    },
    {
      label: "products.details.printHistoryDetails.vk2",
      value: convertAmount(printRequest.pimProductVk2Price?.amount) ?? "--",
    },
    {
      label: "products.details.printHistoryDetails.vk1",
      value: convertAmount(printRequest.pimProductVk1Price?.amount) ?? "--",
    },
    {
      label: "products.details.printHistoryDetails.language",
      value: hasLanguage ? (
        <div className="inline-flex items-center gap-2">
          <Image
            src={language.icon}
            alt={language.name}
            className="inline-block w-6"
          />
          <span className="uppercase">{language.name}</span>
        </div>
      ) : (
        "--"
      ),
    },
    {
      label: "products.details.printHistoryDetails.status",
      value: <Status.PrintRequest id={printRequest.status} />,
    },
  ];
  const handleRemove = () => {
    onRemove(printRequest.id);
    toggle();
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="bg-[#F5F7F9]">
        <Drawer.Header className="flex items-center text-start">
          <h6 className="flex-1 text-base text-dark font-semibold">
            <Text>products.details.printHistoryDetails.drawerTitle</Text>
          </h6>
          {!isOnProcess && (
            <IconButton
              type="button"
              variant="danger"
              onClick={handleRemove}
              light
            >
              <Icon name="Trash" className="!size-5" variant="Bold" />
            </IconButton>
          )}
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Accordion defaultActiveKey={["info", "description"]}>
            <Wrapper>
              <Accordion.Item eventKey="info">
                <Accordion.Toggle className="flex-row-reverse">
                  <h6 className="text-sm text-dark flex-1 text-start">
                    <Text>products.details.printHistoryDetails.info</Text>
                  </h6>
                </Accordion.Toggle>
                <Accordion.Body>
                  <Wrapper.Body className="py-0">
                    <table className="w-full text-sm font-medium [&_td]:py-3">
                      <tbody className="divide-y divide-dashed">
                        {items.map((e) => (
                          <tr key={e.label}>
                            <td className="text-secondary text-start whitespace-nowrap">
                              <Text>{e.label}</Text>
                            </td>
                            <td className="text-gray-900 text-end">
                              {e.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Wrapper.Body>
                </Accordion.Body>
              </Accordion.Item>
            </Wrapper>
            {isFailed && (
              <Wrapper>
                <Accordion.Item eventKey="description">
                  <Accordion.Toggle className="flex-row-reverse">
                    <h6 className="text-sm text-dark flex-1 text-start">
                      <Text>
                        products.details.printHistoryDetails.errorNote
                      </Text>
                    </h6>
                  </Accordion.Toggle>
                  <Accordion.Body>
                    <Wrapper.Body
                      dangerouslySetInnerHTML={{
                        __html: printRequest.statusDescription || "--",
                      }}
                      className="text-secondary text-justify break-words py-0 min-h-60"
                    ></Wrapper.Body>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
            )}
          </Accordion>
        </Drawer.Body>
        <Drawer.Footer className="flex-center">
          <Button type="button" light onClick={toggle}>
            <Text>products.details.printHistoryDetails.closeButton</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
