import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  Button,
  CheckBox,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
} from "../components";
import { useAxios } from "../hooks";
import { Offer, togglePropsType } from "../types";
type rejectMenuProps = togglePropsType & {
  offerData: Offer.Item | Offer.Details;
  onSubmitted: () => void;
};
export default function RejectOfferMenu({
  isOpen,
  toggle,
  offerData,
  onSubmitted,
}: rejectMenuProps) {
  const { axios, loading } = useAxios();
  const [reason, setReason] = useState<string>("");
  const [confirm, setConfirm] = useState<boolean>(false);
  const resetData = () => {
    setConfirm(false);
    setReason("");
  };
  const rejectOffer = () => {
    const url = `/salesservice/api/offer/${offerData.id}/reject`;
    const data = { reason };
    axios.post(url, data).then(() => {
      toast.success("toast.success.rejectOffer");
      onSubmitted();
      toggle();
    });
  };
  useEffect(resetData, [isOpen]);
  return (
    <Drawer as={Form} onSubmit={rejectOffer} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Icon
              name="CloseCircle"
              variant="Bold"
              size={20}
              className="text-danger"
            />{" "}
            <Text>drawerTitles.rejectOffer</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header className="space-y-2 text-center">
          <Icon.Wrapper className="mx-auto">
            <Icon name="DiscountShape" variant="Bold" />
          </Icon.Wrapper>
          <h6>{offerData.title}</h6>
          <p className="text-secondary">#{offerData.number}</p>
        </Drawer.Header>
        <Drawer.Body>
          <InputGroup
            as="textarea"
            label="formControls.reason"
            value={reason}
            setValue={setReason}
          />
        </Drawer.Body>
        <Drawer.Footer className="border-t-0">
          <CheckBox
            label="confirms.rejectOffer"
            value={confirm}
            setValue={setConfirm}
          />
        </Drawer.Footer>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button light onClick={toggle} disabled={loading.post}>
            <Text>button.cancel</Text>
          </Button>
          <Button
            variant="danger"
            type="submit"
            disabled={!confirm}
            loading={loading.post}
          >
            <Text>button.rejectOffer</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
