import { twMerge } from "tailwind-merge";
import { types } from "../constants";
import { CustomerType, LineItemType } from "../enums";
import { cn } from "../methods";
import { classNameProps } from "../types";
import Badge from "./_Badge";
import Icon from "./_Icon";
import Text from "./_Text";
type TypesProps<T = number> = {
  id?: T;
};
function Customer({ id }: TypesProps<CustomerType>) {
  const activeType = types.customer.find((e) => e.id === id);
  const isCompany = id === CustomerType.Company;
  return (
    <Badge
      variant={activeType?.variant}
      className={`text-sm ${
        isCompany
          ? "bg-[#FFFAE866] text-[#CDB250]"
          : "bg-[#E8F9FF66] text-[#508ACD]"
      }`}
    >
      <Icon name={activeType?.icon ?? "User"} variant="Bold" size="1.5rem" />{" "}
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function Program({ id }: TypesProps) {
  const activeType = types.program.find((e) => e.id === id);
  return (
    <Badge variant={activeType?.variant ?? "dark"} fill>
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function Product({ id }: TypesProps) {
  const activeType = types.product.find((e) => e.id === id);
  return (
    <Badge
      variant={activeType?.variant ?? "dark"}
      fill
      // className="py-1 px-2 text-sm"
    >
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function IncidentPriority({ id }: TypesProps) {
  const activeType = types.incidentPriority.find((e) => e.id === id);
  return (
    <Badge
      variant={activeType?.variant ?? "dark"}
      className="py-1 px-2 text-sm"
    >
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function IncidentOrigin({ id }: TypesProps) {
  const activeType = types.incidentOrigin.find((e) => e.id === id);
  return (
    <Badge variant={null} className="py-1 px-2 bg-gray-50 text-primary text-sm">
      {activeType?.icon && (
        <Icon
          name={activeType.icon}
          variant="Bulk"
          size={18}
          className="mr-1"
        />
      )}
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function LineItem({ id }: TypesProps) {
  if (id === undefined) return null;
  const activeType = types.lineItem.find((e) => e.id === id);
  const isManual = id === LineItemType.ManualProduct;
  const isPim = [
    LineItemType.PimBundle,
    LineItemType.PimBundleItem,
    LineItemType.PimProduct,
  ].includes(id);

  const isSellOff = [
    LineItemType.SellOffBundle,
    LineItemType.SellOffBundleItem,
    LineItemType.SellOffProduct,
  ].includes(id);

  return (
    <Badge
      variant={activeType?.variant ?? "dark"}
      fill
      className={cn(
        isManual && "bg-[#50CDCD]",
        isPim && "bg-[#F26F52]",
        isSellOff && "text-dark bg-[#f0f4f5]"
      )}
      // className="py-1 px-2 text-sm"
    >
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function TaskPriority({ id }: TypesProps) {
  const activeType = types.taskPriority.find((e) => e.id === id);
  return (
    <Badge
      variant={activeType?.variant ?? "dark"}
      className="py-1 px-2 text-sm"
    >
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function CrmTarget({ id }: TypesProps) {
  const activeType = types.crmTarget.find((e) => e.id === id);
  return (
    <Badge variant="primary" className="py-1 px-2 text-sm">
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function Supplier({ id }: TypesProps) {
  const activeType = types.supplier.find((e) => e.id === id);
  return (
    <Badge variant={activeType?.variant} className="py-1 px-2 text-sm">
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}
function PimProduct({ id, className }: TypesProps & classNameProps) {
  const activeType = types.pimProduct.find((e) => e.id === id);
  return (
    <Badge
      variant={null}
      className={twMerge(
        "text-xs font-medium py-1 px-2 bg-opacity-100",
        activeType?.classes,
        className
      )}
    >
      <Text>{activeType?.name ?? ""}</Text>
    </Badge>
  );
}

const Types = {
  Customer,
  Program,
  Product,
  IncidentPriority,
  IncidentOrigin,
  LineItem,
  TaskPriority,
  CrmTarget,
  Supplier,
  PimProduct,
};
export default Types;
