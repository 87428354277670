export default function calendarWeekToTime(
  year: number | null | undefined,
  week: number | null | undefined
) {
  const isEmptyWeek = week === null || week === undefined;
  const isEmptyYear = year === null || year === undefined;
  if (isEmptyWeek || isEmptyYear) return null;
  var day = 1 + (week - 1) * 7;
  return new Date(year, 0, day).getTime();
}
