import { cloneDeep } from "lodash";
import { Dispatch, Fragment, useContext, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { AvailabilityStatusContext } from ".";
import { ProductDetailsContext } from "..";
import {
  Alert,
  Button,
  CheckBox,
  Drawer,
  Form,
  InputGroup,
  Select,
  Text,
} from "../../../../components";
import { rules, status } from "../../../../constants";
import { AvailabilityStatus } from "../../../../enums";
import { useAxios } from "../../../../hooks";
import { datesHasConflict } from "../../../../methods";
import { Product, togglePropsType } from "../../../../types";

type Props = {
  availabilityStatus?: Product.AvailabilityStatusItem | null;
  onSubmitted: Dispatch<string>;
} & togglePropsType;

export default function AvailabilityStatusForm({
  isOpen,
  toggle,
  availabilityStatus = null,
  onSubmitted,
}: Props) {
  const isNew = !availabilityStatus;
  const isDefault = !!availabilityStatus?.isDefault;
  const initData: Product.CreateAvailabilityStatus = {
    availabilityStatus:
      availabilityStatus?.availabilityStatus ?? AvailabilityStatus.Active,
    fromDate: availabilityStatus?.fromDate ?? null,
    toDate: availabilityStatus?.toDate ?? null,
    validationPeriod: !!availabilityStatus?.validationPeriod,
  };
  const { availabilityStatuses } = useContext(AvailabilityStatusContext);
  const { product, updateProduct, updateProductLoading } = useContext(
    ProductDetailsContext
  );
  const { axios, loading } = useAxios();
  const [data, setData] = useState(initData);
  const hasLoading = loading.post || loading.update || updateProductLoading;

  const dates = useMemo(() => {
    if (!!availabilityStatus?.isDefault) return [];
    return availabilityStatuses
      .filter((e) => !e.isDefault && availabilityStatus?.id !== e.id)
      .map((e) => ({ start: e.fromDate, end: e.toDate }));
  }, [availabilityStatuses, availabilityStatus]);

  const hasConflict = useMemo(() => {
    const secondDate = { start: data.fromDate, end: data.toDate };
    if (!data.validationPeriod) return false;
    if (!dates.length) return false;
    if (!data.fromDate) return false;
    return dates.some((e) => datesHasConflict(e, secondDate));
  }, [data, dates]);

  const disabledButton = useMemo(() => {
    if (!!availabilityStatus?.isDefault) return false;
    return !data.validationPeriod || hasConflict;
  }, [availabilityStatus, data, hasConflict]);

  const handleSetValue = (key: keyof typeof data) => {
    const isFromDate = key === "fromDate";
    const isToDate = key === "toDate";
    return (value: any) => {
      setData((p) => {
        const data = cloneDeep({ ...p, [key]: value });
        if (isFromDate && !!value) {
          const date = new Date(value);
          date.setHours(0, 0, 0);
          data[key] = date.toDateString();
        }
        if (isToDate && !!value) {
          const date = new Date(value);
          date.setHours(23, 59, 59);
          data[key] = date.toDateString();
        }
        return data;
      });
    };
  };

  const create = () => {
    if (!product?.id) return;
    const url = `/salesservice/api/salestoolspimproduct/${product.id}/availability-status`;
    const body = { ...data };
    axios.post(url, body).then(({ data: id }) => {
      updateProduct().then(() => {
        const message = "products.details.availabilityStatus.addMessage";
        toast.success(message);
        setData(initData);
        onSubmitted(id);
        toggle();
      });
    });
  };

  const update = () => {
    if (!product?.id) return;
    if (!availabilityStatus?.id) return;
    const url = `/salesservice/api/salestoolspimproduct/${product.id}/availability-status/${availabilityStatus?.id}`;
    const body = { ...data };
    axios.put(url, body).then(() => {
      updateProduct().then(() => {
        const message = "products.details.availabilityStatus.updateMessage";
        toast.success(message);
        onSubmitted(availabilityStatus?.id);
        toggle();
      });
    });
  };
  const submit = isNew ? create : update;
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="flex-1 text-start text-heading-2 font-semibold text-gray-800">
            <Text>
              {isNew
                ? "products.details.availabilityStatus.addTitle"
                : "products.details.availabilityStatus.editTitle"}
            </Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body>
          <section className="grid grid-cols-2 items-start gap-6">
            <div className="col-span-full">
              <Select
                label="products.details.availabilityStatus.status"
                value={data.availabilityStatus}
                setValue={handleSetValue("availabilityStatus")}
                items={status.availability}
                rules={rules.required}
              />
            </div>
            {!isDefault && (
              <Fragment>
                <div className="col-span-full">
                  <CheckBox
                    label="products.details.availabilityStatus.validationPeriod"
                    value={data.validationPeriod}
                    setValue={handleSetValue("validationPeriod")}
                  />
                </div>
                <InputGroup
                  label="products.details.availabilityStatus.fromDate"
                  value={data.fromDate}
                  setValue={handleSetValue("fromDate")}
                  type="date"
                  disabled={!data.validationPeriod}
                  maxDate={data.toDate}
                  rules={data.validationPeriod ? rules.required : []}
                />
                <InputGroup
                  label="products.details.availabilityStatus.toDate"
                  value={data.toDate}
                  setValue={handleSetValue("toDate")}
                  type="date"
                  disabled={!data.validationPeriod}
                  minDate={data.fromDate}
                />
              </Fragment>
            )}
          </section>
        </Drawer.Body>
        {hasConflict && (
          <Drawer.Footer>
            <Alert variant="danger" light className="col-span-full">
              <Alert.Title>
                <Text>
                  products.details.availabilityStatus.conflictErrorTitle
                </Text>
              </Alert.Title>
              <Alert.Text>
                <Text>
                  products.details.availabilityStatus.conflictErrorText
                </Text>
              </Alert.Text>
            </Alert>
          </Drawer.Footer>
        )}
        <Drawer.Footer className="flex items-center justify-end gap-2">
          <Button
            type="button"
            variant="danger"
            onClick={toggle}
            disabled={hasLoading}
          >
            <Text>products.details.availabilityStatus.cancelButton</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={hasLoading}
            disabled={disabledButton}
          >
            <Text>
              {isNew
                ? "products.details.availabilityStatus.addStatusButton"
                : "products.details.availabilityStatus.updateButton"}
            </Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
