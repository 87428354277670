import { useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import {
  Accordion,
  AttributeValue,
  NoItems,
  Translate,
  Wrapper,
} from "../../../../components";

export default function Attributes() {
  const { basicInfo } = useContext(SellOffProductDetailsContext);
  const attributes = basicInfo?.pimProduct?.attributeGroups;
  const defaultActiveKey = attributes?.map((e) => e.code) ?? [];
  if (!attributes?.length) return <NoItems />;
  return (
    <Accordion defaultActiveKey={defaultActiveKey}>
      {attributes.map((e) => (
        <Wrapper key={e.code} className="col-span-full">
          <Accordion.Item eventKey={e.code}>
            <Accordion.Toggle>
              <h2 className="flex-1 text-base text-start text-dark">
                <Translate value={e.translates} defaultValue="--" />
              </h2>
            </Accordion.Toggle>
            <Accordion.Body>
              <table className="w-full">
                <tbody className="divide-y">
                  {!!e.attributes?.length ? (
                    e.attributes
                      ?.sort((a, b) => a.sortOrder - b.sortOrder)
                      ?.map((e) => (
                        <tr key={e.code} className="*:py-3">
                          <td className="text-secondary whitespace-nowrap">
                            <Translate value={e.translates} defaultValue="--" />
                          </td>
                          <td className="text-gray-900 font-medium px-3 text-end">
                            <AttributeValue attribute={e} />
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={2}>--</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
        </Wrapper>
      ))}
    </Accordion>
  );
}
