import { Fragment, useContext } from "react";
import { twMerge } from "tailwind-merge";
import { ProductDetailsContext } from ".";
import {
  Badge,
  Button,
  Icon,
  Image,
  Text,
  Translate,
  Types,
} from "../../../components";
import { status } from "../../../constants";
import { AvailabilityStatus } from "../../../enums";
import {
  useConverters,
  useFindAttribute,
  useToggle,
  useTranslate,
} from "../../../hooks";
import EditPriceMenu from "./EditPriceMenu";
import EditUvpPriceMenu from "./EditUvpPriceMenu";
import PricePerChannelMenu from "./PricePerChannelMenu";

export default function ProductInfo() {
  const { product, canEditPrice, hasPrice2, hasPrice1, isOverwritten } =
    useContext(ProductDetailsContext);
  const translate = useTranslate();
  const findAttribute = useFindAttribute();
  const { convertAmount } = useConverters();
  const [showEditVk1Price, toggleEditVk1Price, hideEditVk1Price] =
    useToggle(false);
  const [showEditUvpPrice, toggleUEditUvpPrice, hideEditUvpPrice] =
    useToggle(false);
  const [showPricePerChannel, togglePricePerChannel] = useToggle(false);

  const hasArticleNumber = !!product.articleNumber;
  // const hasSupplier = !!product.supplier?.name;
  // const hasProgram = !!product.supplier?.program?.name;
  // const hasPimProductGroup = !!product.pimProductGroup?.name;
  // const hasProductFamily = !!product.productFamily?.translates;
  const availabilityStatusId =
    // product.supplierProgramAvailabilityStatus ??
    product.availabilityStatus?.availabilityStatus ?? AvailabilityStatus.Active;
  const availabilityStatus = status.availability.find(
    (e) => e.id === availabilityStatusId
  );
  const hasAvailabilityStatus = !!availabilityStatus;
  return (
    <Fragment>
      <section className="flex flex-col lg:flex-row lg:items-start gap-4">
        <Image
          src={product.avatar?.url}
          alt={product.originalName ?? ""}
          className="w-full max-w-sm lg:w-48 aspect-image object-contain bg-gray-100 rounded"
          isDynamic
        />
        <div className="flex-1 flex flex-col items-start gap-1">
          <h6 className="flex flex-wrap items-center gap-1 w-full text-dark text-base font-medium break-words">
            <Translate
              value={findAttribute(product.attributeGroups, "title")}
              defaultValue={product.originalName}
            />
            <Types.PimProduct
              id={product.pimProductType}
              className="px-2 py-1.5"
            />
            {hasAvailabilityStatus && (
              <Badge
                variant={availabilityStatus.variant}
                className="text-xs font-semibold px-2 py-1.5 whitespace-normal break-words"
              >
                <Text status={translate(availabilityStatus.name)}>
                  products.details.productAvailabilityStatus
                </Text>
              </Badge>
            )}
          </h6>
          {hasArticleNumber && (
            <p className="text-sm text-secondary font-medium w-full">
              #{product.articleNumber}
            </p>
          )}
          {/* {hasSupplier && (
            <p className="text-sm text-secondary font-medium w-full">
              <Text>products.details.supplier</Text>: {product.supplier?.name}
            </p>
          )}
          {hasProgram && (
            <p className="text-sm text-secondary font-medium w-full">
              <Text>products.details.program</Text>:{" "}
              {product.supplier?.program?.name}
            </p>
          )}
          {hasPimProductGroup && (
            <p className="text-sm text-secondary font-medium w-full">
              <Text>products.details.productGroup</Text>:{" "}
              {product.pimProductGroup?.name}
            </p>
          )}
          {hasProductFamily && (
            <p className="text-sm text-secondary font-medium w-full">
              <Text>products.details.productFamily</Text>:{" "}
              <Translate value={product.productFamily?.translates} />
            </p>
          )} */}
        </div>
        <section className="flex flex-col items-stretch gap-3">
          <ul
            className={twMerge(
              "w-full lg:w-72 bg-gray-50 px-2 py-1 rounded-md text-base text-start *:py-1 [&>li>*]:flex [&>li>*]:items-center [&>li>*]:gap-1",
              canEditPrice ? "cursor-pointer" : "cursor-default"
            )}
          >
            <li onClick={toggleUEditUvpPrice} className="cursor-pointer">
              <p>
                <Icon
                  name="DollarSquare"
                  variant="Bold"
                  size={22}
                  className="text-dark"
                />
                <span>
                  <Text>products.details.vk2</Text>:
                </span>
                <Icon name="Edit2" className="size-5 text-primary" />
                <span className="text-secondary line-through ms-auto">
                  {hasPrice2
                    ? convertAmount(
                        product.uvpPrice?.amount ?? product.vk2Price?.amount
                      )
                    : "--"}
                </span>
              </p>
            </li>
            <li onClick={toggleEditVk1Price}>
              <p>
                <Icon
                  name="DollarSquare"
                  variant="Bold"
                  size={22}
                  className="text-dark"
                />
                <span>
                  <Text>products.details.vk1</Text>:
                </span>
                {canEditPrice && !isOverwritten && (
                  <Icon name="Edit2" className="size-5 text-primary" />
                )}
                <span
                  className={twMerge(
                    "ms-auto",
                    isOverwritten ? "text-secondary line-through" : "text-dark"
                  )}
                >
                  {hasPrice1
                    ? convertAmount(product.originalVk1Price?.amount)
                    : "--"}
                </span>
              </p>
            </li>
            {isOverwritten && (
              <li onClick={toggleEditVk1Price}>
                <p>
                  <Icon
                    name="DollarSquare"
                    variant="Bold"
                    size={22}
                    className="text-dark"
                  />
                  <span>
                    <Text>products.details.overwrittenPrice</Text>:
                  </span>
                  {canEditPrice && isOverwritten && (
                    <Icon name="Edit2" className="size-5 text-primary" />
                  )}
                  <span className="text-dark ms-auto">
                    {convertAmount(product.vk1Price?.amount) ?? "--"}
                  </span>
                </p>
              </li>
            )}
          </ul>
          <Button
            type="button"
            variant="primary"
            light
            onClick={togglePricePerChannel}
          >
            <Text>products.details.priceInOtherChannel</Text>
          </Button>
        </section>
      </section>
      {canEditPrice && (
        <EditPriceMenu isOpen={showEditVk1Price} toggle={hideEditVk1Price} />
      )}
      <EditUvpPriceMenu isOpen={showEditUvpPrice} toggle={hideEditUvpPrice} />
      <PricePerChannelMenu
        isOpen={showPricePerChannel}
        toggle={togglePricePerChannel}
      />
    </Fragment>
  );
}
