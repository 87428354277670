import { Dispatch } from "react";
import { Button, Text } from "../../../components";

type Props = {
  value: boolean | null;
  setValue: Dispatch<boolean | null>;
  disabled?: boolean;
};

export default function PimProductTypeFilter({
  value,
  setValue,
  disabled,
}: Props) {
  const items = [
    { name: "sellOffProducts.list.all", id: null },
    { name: "sellOffProducts.list.single", id: false },
    { name: "sellOffProducts.list.bundle", id: true },
  ];
  return (
    <section className="col-span-full flex flex-wrap items-center gap-2 py-3">
      {items.map((e) => {
        const active = e.id === value;
        return (
          <Button
            key={e.name}
            type="button"
            variant={active ? "primary" : "white"}
            onClick={() => setValue(e.id)}
            disabled={disabled}
          >
            <Text>{e.name}</Text>
          </Button>
        );
      })}
    </section>
  );
}
