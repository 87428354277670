import { Fragment, createContext, useMemo } from "react";
import { Icon, Status, Text } from "../../../../../components";
import { dateFormats, types } from "../../../../../constants";
import { CRMActivityType } from "../../../../../enums";
import { useConverters, useToggle } from "../../../../../hooks";
import { cleanText } from "../../../../../methods";
import { Incident } from "../../../../../types";
import ActivityInfo from "./ActivityInfo";
// import CancelConfirm from "./CancelConfirm";
// import CompleteConfirm from "./CompleteConfirm";
// import RemoveConfirm from "./RemoveConfirm";

type ActivityRowProps = {
  activity: Incident.TimeLineItem;
};
type ContextType = {
  activity: Incident.TimeLineItem;
};

export const ActivityContext = createContext({} as ContextType);

export default function ActivityRow({ activity }: ActivityRowProps) {
  const { convertDate } = useConverters();
  // const [showRemove, toggleRemove] = useToggle(false);
  // const [showCancel, toggleCancel] = useToggle(false);
  // const [showComplete, toggleComplete] = useToggle(false);
  const [showMore, toggleMore] = useToggle(false);

  const activityType = types.incidentActivity.find(
    (e) => e.id === activity.type
  );
  const hasType = !!activityType;
  const hasSubject = !!activity.subject;
  const hasDescription = !!activity.content;
  const showStatus = useMemo(() => {
    if (activity.status === null) return false;
    return ![
      CRMActivityType.Post,
      CRMActivityType.Note,
      CRMActivityType.Incidentresolution,
    ].includes(activity.type);
  }, [activity]);
  return (
    <Fragment>
      <div className="w-full p-2 border border-[#DEEBF4] rounded space-y-2">
        <div className="flex items-start">
          <div className="flex-1 space-y-2">
            <p className="text-xs text-secondary font-medium">
              {convertDate(activity.createdOn, dateFormats.fullDate)}
            </p>
          </div>
          {/* <div className="flex-center gap-2">
            <IconButton
              type="button"
              variant="dark"
              light
              onClick={toggleRemove}
            >
              <Icon name="Trash" />
            </IconButton>
            <IconButton
              type="button"
              variant="danger"
              light
              onClick={toggleCancel}
            >
              <Icon name="CloseCircle" />
            </IconButton>
            <IconButton
              type="button"
              variant="primary"
              light
              onClick={toggleComplete}
            >
              <Icon name="TickCircle" />
            </IconButton>
          </div> */}
        </div>
        <p className="text-xs text-secondary font-medium">
          {hasType && <Icon name={activityType.icon} className="me-1" />}
          {hasType && <Text>{activityType.name}</Text>}
          {/* <Text>incident.activities.activityModifiedBy</Text>{" "} */}{" "}
          {showStatus && <Status.IncidentActivity id={activity.status} />}
        </p>
        <div className="w-full space-y-1">
          {hasSubject && (
            <h6 className="text-sm text-gray-900">
              {activity.subject || "--"}
            </h6>
          )}
          <div className="flex items-center gap-4">
            {hasDescription && (
              <Fragment>
                <p className="flex-1 text-sm text-gray-900 font-medium truncate">
                  {cleanText(activity.content)}
                </p>
                <button
                  type="button"
                  className="text-warning underline font-medium"
                  onClick={toggleMore}
                >
                  <Text>incident.activities.moreDetailsBtn</Text>
                </button>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <ActivityContext.Provider value={{ activity }}>
        {/* <RemoveConfirm isOpen={showRemove} toggle={toggleRemove} /> */}
        {/* <CancelConfirm isOpen={showCancel} toggle={toggleCancel} /> */}
        {/* <CompleteConfirm isOpen={showComplete} toggle={toggleComplete} /> */}
        <ActivityInfo isOpen={showMore} toggle={toggleMore} />
      </ActivityContext.Provider>
    </Fragment>
  );
}
