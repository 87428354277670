import { createContext, useContext, useEffect, useMemo, useRef } from "react";
import { Calendar } from "react-multi-date-picker";
import { twMerge } from "tailwind-merge";
import { childrenProps, togglePropsType } from "../types";
import Button from "./_Button";
import Icon from "./_Icon";
import Modal from "./_Modal";
import Text from "./_Text";
type datePickerProps = {
  value?: string | number | Date | null;
  setValue?: (val: string | null) => void;
  minDate?: string | number | Date;
  maxDate?: string | number | Date;
  disabled?: boolean;
  disabledWeekend?: boolean;
};
type DatePickerContextType = { toggle?: () => void };

const DatePickerContext = createContext<DatePickerContextType>({});

function DatePicker({
  value,
  setValue,
  minDate,
  maxDate,
  disabled,
  disabledWeekend,
}: datePickerProps) {
  const { toggle } = useContext(DatePickerContext);
  const calendarRef = useRef<any>();
  const handleValue = useMemo(() => {
    if (!value) return "";
    return new Date(value);
  }, [value]);
  useEffect(() => {
    if (!minDate || value) return;
    const year = new Date(minDate).getFullYear();
    const month = new Date(minDate).getMonth() + 1;
    calendarRef.current?.set("year", year);
    calendarRef.current?.set("month", month);
  }, [minDate, value]);
  // function updateYear(val: number) {
  //   const date = calendarRef.current.date;
  //   calendarRef.current?.set("year", date["year"] + val);
  // }
  return (
    <Calendar
      ref={calendarRef}
      value={handleValue}
      minDate={minDate}
      maxDate={maxDate}
      weekStartDayIndex={1}
      renderButton={(dir: "right" | "left", onClick: () => {}) => {
        if (dir === "left")
          return (
            <button type="button" onClick={onClick}>
              <Icon name="ArrowCircleLeft" variant="Broken" size={30} />
            </button>
          );
        return (
          <button type="button" onClick={onClick}>
            <Icon name="ArrowCircleRight" variant="Broken" size={30} />
          </button>
        );
      }}
      showOtherDays
      shadow={false}
      className={twMerge(
        "date-picker !w-full !border-0 min-h-[24rem]",
        disabled && "opacity-50 pointer-events-none"
      )}
      onChange={(e: any) => {
        setValue?.(e?.toDate?.() ?? null);
        toggle?.();
      }}
      mapDays={({ date }) => {
        let isWeekend = [0, 6].includes(date.weekDay.index);
        if (disabledWeekend && isWeekend) return { disabled: true };
      }}
    />
  );
}
function DatePickerModal({
  isOpen,
  toggle,
  children,
}: togglePropsType & childrenProps) {
  return (
    <Modal modalClassName="z-40" isOpen={isOpen} toggle={toggle}>
      <Modal.Body>
        <DatePickerContext.Provider value={{ toggle }}>
          {children}
        </DatePickerContext.Provider>
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button light onClick={toggle}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
DatePicker.Modal = DatePickerModal;
export default DatePicker;
// import { useMemo, useState } from "react";
// import { monthNames } from "../constants";
// import { convertNumber } from "../methods";
// import Dropdown from "./_Dropdown";
// import Text from "./_Text";
// type datePickerProps = {
//   value: any;
//   setValue: (val: any) => void;
// };
// type dateType = {
//   year: number | null;
//   month: number | null;
//   day: number | null;
// };
// const years = [...Array(124)]
//   .map((e, i) => 1900 + i)
//   .map((e) => ({ name: e, id: e }))
//   .reverse();
// const month = monthNames.map((e, i) => ({ name: e, id: i + 1 }));
// function daysInMonth({
//   month,
//   year,
// }: {
//   month?: number | null;
//   year?: number | null;
// }) {
//   return new Date(year ?? 1900, month ?? 12, 0).getDate();
// }
// export default function DatePicker({ value, setValue }: datePickerProps) {
//   const [yearValue, monthValue, dayValue] = useMemo(() => {
//     if (!value) return [null, null, null];
//     const date = new Date(value);
//     const year = date.getFullYear();
//     const month = date.getUTCMonth() + 1;
//     const day = date.getDate();
//     return [year, month, day];
//   }, [value]);
//   const [date, setDate] = useState<dateType>({
//     year: yearValue,
//     month: monthValue,
//     day: dayValue,
//   });

//   const days = useMemo(() => {
//     const length =
//       date.month && date.year
//         ? daysInMonth({ year: date.year, month: date.month })
//         : 31;
//     return [...Array(length)]
//       .map((e, i) => i + 1)
//       .map((e) => ({ name: convertNumber(e), id: e }));
//   }, [date]);
//   const handleChange = (key: keyof dateType, value: number) => {
//     return () => {
//       const dateValue = { ...date, [key]: value };
//       const lastDay = daysInMonth({
//         year: dateValue.year,
//         month: dateValue.month,
//       });
//       if (dateValue.day && dateValue.day > lastDay) {
//         dateValue.day = lastDay;
//       }
//       const hasValue = Object.values(dateValue).every(Boolean);
//       const valueArr = [dateValue.year, dateValue.month, dateValue.day];
//       const valueStr = valueArr.join("/");
//       hasValue && setValue(new Date(`${valueStr} 7:00`).toString());
//       setDate(dateValue);
//     };
//   };
//   return (
//     <div className="py-8">
//       <Dropdown className="grid grid-cols-3 gap-4">
//         <Dropdown.Toggle light className="w-full">
//           {dayValue ? convertNumber(dayValue) : <Text>button.day</Text>}
//         </Dropdown.Toggle>
//         <Dropdown.Toggle light className="w-full">
//           {monthValue ? monthNames[monthValue - 1] : <Text>button.month</Text>}
//         </Dropdown.Toggle>
//         <Dropdown.Toggle light className="w-full">
//           {yearValue ?? <Text>button.year</Text>}
//         </Dropdown.Toggle>
//         <Dropdown.Menu className="h-[17.5rem] grid grid-cols-3 [&>div]:h-full [&>div]:snap-y [&>div]:snap-proximity [&>div]:px-1 [&>div]:overflow-auto [&>div>button]:block [&>div>button]:snap-center [&>div>button]:w-full [&>div>button]:p-4 [&>div>button]:text-dark [&>div>button]:transition-colors [&>div>button[data-active='true']]:bg-primary/5 [&>div>button[data-active='true']]:text-primary">
//           <div>
//             {days.map((e) => (
//               <button
//                 key={e.id}
//                 data-active={(date.day ?? dayValue) === e.id}
//                 type="button"
//                 onClick={handleChange("day", e.id)}
//               >
//                 {e.name}
//               </button>
//             ))}
//           </div>
//           <div>
//             {month.map((e) => (
//               <button
//                 key={e.id}
//                 data-active={(date.month ?? monthValue) === e.id}
//                 type="button"
//                 onClick={handleChange("month", e.id)}
//               >
//                 {e.name}
//               </button>
//             ))}
//           </div>
//           <div>
//             {years.map((e) => (
//               <button
//                 key={e.id}
//                 data-active={(date.year ?? yearValue) === e.id}
//                 type="button"
//                 onClick={handleChange("year", e.id)}
//               >
//                 {e.name}
//               </button>
//             ))}
//           </div>
//         </Dropdown.Menu>
//       </Dropdown>
//     </div>
//   );
// }
