import ProductDetails from "../pages/Products/Details";
import ProductsList from "../pages/Products/List";

const product = [
  {
    path: "products",
    element: <ProductsList />,
  },
  {
    path: "products/:productId",
    element: <ProductDetails />,
  },
];
export default product;
