import { cloneDeep } from "lodash";
import { useContext } from "react";
import { AddCustomerDataContext } from ".";
import { InputGroup, Select, Text, Wrapper } from "../../../components";
import { countryCodes, rules } from "../../../constants";
import { Customer } from "../../../types";
export default function ContactInfo() {
  const { data, setData, hasPhoneNumber } = useContext(AddCustomerDataContext);
  const handleSetValue = (key: keyof Customer.personInfo) => {
    const isMobileNumber = key === "mobileNumber";
    const isPhoneNumber = key === "phoneNumber";
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        if (isMobileNumber || isPhoneNumber) {
          const countryCode = data[key]?.countryCode ?? NaN;
          data[key] = { countryCode, number: value };
          return data;
        }
        // @ts-ignore: Unreachable code error
        data[key] = value;
        return data;
      });
  };
  const handleSetCountryCodeValue = (key: "mobileNumber" | "phoneNumber") => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        const number = data[key]?.number ?? NaN;
        data[key] = { countryCode: value, number };
        return data;
      });
  };
  return (
    <Wrapper>
      <Wrapper.Body className="space-y-6 max-w-screen-md mx-auto">
        <section className="flex w-full gap-2">
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>formControls.email</Text>
          </h6>
          <InputGroup
            className="w-auto flex-[5]"
            value={data.emailAddress}
            setValue={handleSetValue("emailAddress")}
            placeholder="placeholders.email"
            rules={rules.email}
            type="email"
          />
        </section>
        <section className="flex w-full gap-2">
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>formControls.mobileNumber</Text>
          </h6>
          <Select
            className="w-auto flex-[1]"
            value={data.mobileNumber?.countryCode}
            setValue={handleSetCountryCodeValue("mobileNumber")}
            placeholder="+49"
            items={countryCodes}
          />
          <InputGroup
            className="w-auto flex-[4]"
            value={data.mobileNumber?.number}
            setValue={handleSetValue("mobileNumber")}
            placeholder="placeholders.mobileNumber"
            rules={rules.phoneNumber}
            type="integer"
          />
        </section>
        <section className="flex w-full gap-2">
          <h6 className="flex-[2] text-base font-normal leading-11 truncate">
            <Text>formControls.phoneNumber</Text>
          </h6>
          <Select
            className="w-auto flex-[1]"
            value={data.phoneNumber?.countryCode}
            setValue={handleSetCountryCodeValue("phoneNumber")}
            placeholder="+49"
            items={countryCodes}
          />
          <InputGroup
            className="w-auto flex-[4]"
            value={data.phoneNumber?.number}
            setValue={handleSetValue("phoneNumber")}
            placeholder="placeholders.phoneNumber"
            type="integer"
            rules={hasPhoneNumber ? rules.phoneNumber : undefined}
          />
        </section>
      </Wrapper.Body>
    </Wrapper>
  );
}
