import CreateCompany from "../pages/Companies/AddNew";
import CompanyDetails from "../pages/Companies/Details";

const company = [
  {
    path: "/companies/new",
    element: <CreateCompany />,
  },
  {
    path: "/companies/:companyId",
    element: <CompanyDetails />,
  },
];
export default company;
