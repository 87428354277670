import { Fragment, useContext } from "react";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { OfferDetailsContext } from "../..";
import {
  Button,
  Embed,
  Icon,
  Image,
  Modal,
  Text,
} from "../../../../../components";
import DocumentCard from "../../../../../components/_DocumentCard";
import { fileType } from "../../../../../constants";
import { useAxios, useGetFile, useToggle } from "../../../../../hooks";
import { downloadFile } from "../../../../../methods";
import { Document, togglePropsType } from "../../../../../types";
import ConfirmModal from "../../../../Documents/Details/ConfirmModal";
import { OrderDetailsContext } from "../../../../Orders/Details";

type documentPreviewModalType = togglePropsType & {
  document: Document.Item;
  getDocument: () => void;
};

export default function DocumentPreviewModal({
  isOpen,
  toggle,
  document,
  getDocument,
}: documentPreviewModalType) {
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const [showConfirm, toggleConfirm] = useToggle(false);
  const { axios, loading } = useAxios();
  const [getFile, fileLoading] = useGetFile();
  const fileInfo = document.documentFileInfo;
  const mimeType = document.documentFileInfo.mimeType;
  const isWordOrExcel = [
    fileType.word,
    fileType.doc,
    fileType.xls,
    fileType.xlsx,
  ].includes(mimeType);
  const isOtherFile = !Object.values(fileType).includes(mimeType);
  const downloadable = [isWordOrExcel, isOtherFile].some(Boolean);
  const isImage = fileInfo.mimeType.includes(fileType.image);
  const hasAspectRatio = !downloadable && !isImage;

  const isOffer = !!offerContext;
  const isOrder = !!orderContext;

  const download = () => {
    getFile(fileInfo.fileUrl).then((data) => {
      downloadFile(data);
    });
  };
  const removeDocument = () => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerdocuments",
      isOrder && "orderdocuments",
      document.id,
    ]
      .filter(Boolean)
      .join("/");
    axios.delete(url).then(() => {
      toast.success("toast.success.removeDocument");
      toggle();
      getDocument();
    });
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={toggle}>
        <Modal.Header className="text-center">
          <div className="flex flex-row-reverse gap-2">
            <Button
              variant="danger"
              onClick={toggleConfirm}
              loading={loading.delete}
            >
              <Icon name="Trash" variant="Bold" size={20} />
            </Button>
            <Button variant="primary" loading={fileLoading} onClick={download}>
              <Icon name="DocumentDownload" variant="Bold" />{" "}
              <Text>button.documentDownload</Text>
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="space-y-4 border-red-50">
          {isWordOrExcel ? (
            <DocumentCard document={document} lineItemDocument />
          ) : (
            <div
              className={twMerge(
                "relative w-full cursor-pointer border-2 border-gray rounded",
                isImage && "aspect-image",
                hasAspectRatio && "aspect-[4/5]"
              )}
            >
              {isOpen && (
                <Fragment>
                  {isImage ? (
                    <Image
                      src={fileInfo.fileUrl}
                      alt={fileInfo.mimeType}
                      className="size-full rounded object-contain bg-gray-100"
                      isDynamic
                    />
                  ) : (
                    <Embed fileInfo={document.documentFileInfo} />
                  )}
                </Fragment>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="flex flex-center">
          <Button light onClick={toggle}>
            <Text>button.back</Text>
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfirmModal
        isOpen={showConfirm}
        toggle={toggleConfirm}
        onConfirm={removeDocument}
      />
    </Fragment>
  );
}
