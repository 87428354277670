const sellOffProducts = {
  list: {
        allItems: "Alle Abverkaufsprodukte {{ count }}",
    all: "Alle",
    single: "Einzelprodukt",
    bundle: "Paket",
    productGroup: "Produktgruppe",
    needPhaseChange: "Phase ändern erforderlich",
    needClose: "Schließen erforderlich",
    currentPhaseDays:
      "{{ currentPhaseName }} ({{ currentPhaseDay }}/{{ currentPhaseTotalDays }} Tage)",
    soldBy: "Verkauft von {{ soldByName }}",
  },
  details: {
    startsProcessButton: "Prozess starten",
    changePhaseButton: "Phase ändern",
    needCloseButton: "Phase schließen",
    needPhaseChange: "Phase ändern erforderlich",
    needClose: "Schließen erforderlich",
    supplier: "Lieferant",
    supplierArticleNumber: "Lieferantenartikelnummer",
    program: "Programm",
    productFamily: "Produktfamilie",
    brand: "Marke",
    productGroup: "Produktgruppe",
    basicInfoTab: "Grundinformationen",
    lineItemsTab: "Positionen",
    mediaTab: "Medien",
    attributesTab: "Attribute",
    aboutProduct: "Über das Produkt",
    generalInfo: "Allgemeine Informationen",
    articleNumber: "Artikelnummer",
    subProducts: "Unterprodukte",
    product: "Produkt",
    quantity: "Menge",
    price: "Preis",
    action: "Aktion",
    subProductDetailsButton: "Details",
    media: "Medien",
    addMediaButton: "Medien hinzufügen",
    salesTemplate: "Verkaufsvorlage",
    submitTemplateButton: "Senden",
    templateSuccessMessage: "Abverkaufsprodukt wurde erfolgreich aktualisiert.",
    currentPhase: "Aktuelle Phase",
    currentPhaseDays:
      "({{ currentPhaseDay }}/{{ currentPhaseTotalDays }} Tage)",
    salesTimeline: "Verkaufszeitplan",
    timelinePerformed: "{{ performedBy }} am {{ performedAt }}",
    timelineDays: "{{ days }} Tage",
    priceInfo: "Preis",
    regularPrice: "Regulärer Preis",
    discount: "Rabatt",
    phasePrice: "Phasenpreis",
    salesStatus: "Verkaufsstatus",
    sold: "Verkauft",
    notSold: "Nicht verkauft",
    soldPrice: "Verkaufspreis",
    soldDate: "Verkaufsdatum",
    seller: "Verkäufer",
    sellerCommission: "Verkäuferprovision",
    fastSaleCommission: "Schnellverkaufsprovision",
  },
  subProductDrawer: {
    title: "Positionen",
    info: "Informationen",
    quantity: "Menge",
    price: "Preis",
    description: "Beschreibung",
    subProductTitle: "Unterprodukt-Titel",
    articleNumber: "Artikelnummer",
    channel: "Kanal",
    vk2: "VK2",
    vk1: "VK1",
  },
  changePhaseDrawer: {
    startPhaseTitle: "Abverkaufsprozess starten",
    changePhaseTitle: "Phasenprozess ändern",
    currentPhase: "Aktuelle Phase",
    preparation: "Vorbereitung",
    nextPhase: "Nächste Phase",
    days: "0/{{ days }} Tage",
    checklistTitle: "Checkliste",
    checklistDescription:
      "Alle Artikel in dieser Phase müssen für den Abverkauf vorbereitet werden",
    templateWarning: "Wir können diesen Prozess nicht berechnen und starten",
    cancelButton: "Abbrechen",
    startSubmitButton: "Starten",
    changeSubmitButton: "Phase ändern",
    startSuccessMessage: "Phase wurde erfolgreich gestartet.",
    changeSuccessMessage: "Phase wurde erfolgreich geändert.",
  },
  filterDrawer: {
    title: "Filter",
    resetButton: "Zurücksetzen",
    productFamily: "Produktfamilie",
    brand: "Marke",
    supplier: "Lieferant",
    status: "Status",
    location: "Standort",
    startedDate: "Gestartet am",
    phaseChangedDate: "Phase geändert am",
    soldDate: "Verkauft am",
    from: "Von",
    to: "Bis",
    cancelButton: "Abbrechen",
    submitButton: "Filter anwenden",
  },
  closePhaseModal: {
    title: "Phase schließen",
    text1: "Sind Sie sicher, dass Sie diese Phase schließen möchten?",
    text2:
      "Nach dem Schließen der Phase können Sie sie nicht mehr aktivieren.",
    cancelButton: "Abbrechen",
    submitButton: "Phase schließen",
    successMessage: "Phase wurde erfolgreich geschlossen.",
  },
};
export default sellOffProducts;
