import isEmpty from "lodash/isEmpty";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import emailIcon from "../../../assets/icons/email.svg";
import locationIcon from "../../../assets/icons/location.svg";
import telephoneIcon from "../../../assets/icons/telephone.svg";
import {
  BackButton,
  Button,
  CompanyIsNotApproved,
  Icon,
  Image,
  Layout,
  Loading,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { CompanyApprovalStatus } from "../../../enums";
import { withPermission } from "../../../hoc";
import { useAxios, usePermission, useToggle } from "../../../hooks";
import { copyText, showCallNumber } from "../../../methods";
import { Company } from "../../../types";
import Addresses from "../../Customers/Details/Addresses";
import ChangeStatus from "./ChangeStatus";
import Commissions from "./Commissions";
import CustomerCommunicationChannels from "./CommunicationChannels";
import ContactPerson from "./ContactPerson";
import Incidents from "./Incidents";
import Offers from "./Offers";
import Orders from "./Orders";
import Overview from "./Overview";

type CompanyContextType = {
  companyData: Company.Details;
  setCompanyData: Dispatch<SetStateAction<Company.Details>>;
  getCompanyData: () => void;
};
export const CompanyContext = createContext({} as CompanyContextType);
function CompanyDetails() {
  const { companyId } = useParams();
  const { axios, loading } = useAxios();
  const [data, setData] = useState({} as Company.Details);
  const [showChangeStatus, toggleChangeStatus] = useToggle(false);

  const offerActive = usePermission(
    "SC_SaleServiceOfferFullAccess",
    "SC_GetSaleServiceOffer"
  );
  const orderActive = usePermission("SC_GetSaleServiceOrder");
  const incidentActive = usePermission(
    "CMS_IncidentFullAccess",
    "CMS_GetIncident"
  );
  const commissionActive = usePermission(
    "SC_SaleServiceCommissionGroupFullAccess",
    "SC_GetSaleServiceCommissionGroup"
  );

  // const [accountInfo, setAccountInfo] = useState<Company.Account | null>(null);
  // const [showAccountInfo, toggleAccountInfo] = useToggle(false);
  // const hasAccount = !!data?.customerAccountId;
  const hasAvatar = !!data?.avatar;
  const isPending =
    data.approvalStatus === CompanyApprovalStatus.PendingApproval;
  const signInItems = [
    {
      icon: locationIcon,
      value: data?.address?.city,
    },
    {
      icon: emailIcon,
      value: data?.emailAddress,
    },
    {
      icon: telephoneIcon,
      value: showCallNumber(data?.mobileNumber),
    },
  ];
  const initTabs = [
    {
      label: "company.details.tabs.overview",
      id: "0",
      Component: Overview,
      active: true,
    },
    {
      label: "company.details.tabs.contactPerson",
      id: "1",
      Component: ContactPerson,
      active: true,
    },
    {
      label: "company.details.tabs.customerCommunicationChannels",
      id: "2",
      Component: CustomerCommunicationChannels,
      active: true,
    },
    {
      label: "company.details.tabs.offers",
      id: "3",
      Component: isPending ? CompanyIsNotApproved : Offers,
      active: offerActive,
    },
    {
      label: "company.details.tabs.orders",
      id: "4",
      Component: isPending ? CompanyIsNotApproved : Orders,
      active: orderActive,
    },
    {
      label: "company.details.tabs.incidents",
      id: "5",
      Component: isPending ? CompanyIsNotApproved : Incidents,
      active: incidentActive,
    },
    {
      label: "tabs.commissions",
      id: "6",
      Component: Commissions,
      active: commissionActive,
    },
    {
      label: "tabs.addresses",
      id: "7",
      Component: Addresses,
      active: true,
    },
  ];
  const tabs = initTabs.filter((e) => e.active);
  const copyCimNumber = () => {
    copyText(data?.cimNumber).then(() => {
      toast.success("toast.success.copyCimNumber");
    });
  };
  // const getAccountInfo = (id: string | null) => {
  //   if (!id) return;
  //   const url = `/accountservice/api/customeraccounts/${id}`;
  //   axios.get(url).then(({ data }) => {
  //     setAccountInfo(data);
  //   });
  // };
  const getData = () => {
    const url = `/accountservice/api/customers/${companyId}/as-company`;
    axios.get(url).then(({ data }) => {
      const result = { ...data };
      result.communicationChannel ??= {
        application: false,
        email: false,
        phone: false,
        sms: false,
        post: false,
        socialMedia: false,
        newsletter: false,
      };
      setData(result);
      // getAccountInfo(result.customerAccountId);
    });
  };
  useEffect(getData, [companyId]);

  if (loading.get) return <Loading.Layout />;
  if (!data) return null;
  return (
    <Layout>
      <Tabs activeKey={tabs[0].id}>
        <Layout.Header>
          <BackButton to="/customers" />
        </Layout.Header>
        <Layout.Body className="space-y-4">
          <Wrapper>
            <Wrapper.Body className="flex flex-wrap gap-4">
              <section className="size-24 flex-center rounded text-placeholder bg-gray-50 overflow-hidden">
                {hasAvatar ? (
                  <Image
                    src={data.avatar?.url}
                    alt={data.firstName + data.lastName}
                    className="w-full h-full object-cover"
                    isDynamic
                  />
                ) : (
                  <Icon name="Home" variant="Bold" className="size-[4.5rem]" />
                )}
              </section>
              <section className="lg:flex-1">
                <h6 className="text-dark text-lg font-medium mb-1">
                  {data.companyName}
                </h6>
                <p
                  className="text-secondary text-sm font-normal"
                  onClick={copyCimNumber}
                >
                  <Text>company.details.cimNumber</Text>: #
                  {data.cimNumber || "--"}
                </p>
                <p className="text-secondary text-sm font-normal">
                  <Text>company.details.taxId</Text>: {data.taxId || "--"}
                </p>
                <p className="text-secondary text-sm font-normal">
                  <Text>company.details.companyRegistrationNumber</Text>: #
                  {data.companyRegistrationNumber}
                </p>
              </section>
              <section className="flex flex-col items-end">
                <div className="flex-center gap-2 mb-auto">
                  <Button
                    type="button"
                    variant="primary"
                    outline
                    className="btn-sm border-0"
                    onClick={toggleChangeStatus}
                  >
                    <Icon name="Refresh" />{" "}
                    <Text>company.details.changeStatus.changeBtn</Text>
                  </Button>
                  <Status.Company id={data.approvalStatus} />
                </div>
                <div className="text-secondary flex items-center gap-2">
                  {signInItems.map((e) => (
                    <span key={e.icon} hidden={!e.value}>
                      <img
                        src={e.icon}
                        alt={e.value?.toString()}
                        className="inline-block w-4 mr-1"
                      />
                      {e.value}
                    </span>
                  ))}
                </div>
              </section>
            </Wrapper.Body>
            <Wrapper.Footer className="py-0">
              <Tabs.ButtonGroup>
                {tabs.map((e) => (
                  <Tabs.Button key={e.id} eventKey={e.id}>
                    <Text>{e.label}</Text>
                  </Tabs.Button>
                ))}
              </Tabs.ButtonGroup>
            </Wrapper.Footer>
          </Wrapper>
          {!isEmpty(data) && (
            <CompanyContext.Provider
              value={{
                companyData: data,
                setCompanyData: setData,
                getCompanyData: getData,
              }}
            >
              {tabs.map(({ Component, ...e }) => (
                <Tabs.Item key={e.id} eventKey={e.id}>
                  <Component />
                </Tabs.Item>
              ))}
              <ChangeStatus
                isOpen={showChangeStatus}
                toggle={toggleChangeStatus}
              />
            </CompanyContext.Provider>
          )}
        </Layout.Body>
      </Tabs>
    </Layout>
  );
}
export default withPermission(CompanyDetails, [
  "AS_CustomerFullAccess",
  "AS_GetCustomer",
]);
