import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Icon,
  Layout,
  Pagination,
  SearchBox,
  Table,
  Text,
  Wrapper,
} from "../../../components";
import { config } from "../../../constants";
import { useAxios, useSelector, useToggle } from "../../../hooks";
import { Task, WithPaging } from "../../../types";
import TaskFilter from "./TaskFilter";
import TaskRow from "./TaskRow";

function TaskList() {
  const profile = useSelector((s) => s.profile);
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Task.Item>>({});
  const [params, setParams] = useState<Task.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    assignedId: profile.employee?.id,
  });
  const [showFilter, toggleFilter] = useToggle(false);
  const handleSetParams = (key: keyof typeof params) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p: any) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p: any) => ({ ...p, [key]: val }));
    };
  };

  const getData = () => {
    const url = "/channelmanagerservice/api/activities/task";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params, axios]);
  return (
    <React.Fragment>
      <Layout>
        <Layout.Header>
          <SearchBox
            placeholder="page.customerList.searchBox"
            onSubmit={handleSetParams("keyword")}
            className="w-auto flex-1"
          />
          <Button type="button" variant="primary" light onClick={toggleFilter}>
            <Icon name="Filter" variant="Bold" size="1.25rem" />
          </Button>
          <Button as={Link} to="create" variant="primary">
            <Icon name="Add" /> <Text>task.list.createNewButton</Text>
          </Button>
        </Layout.Header>
        <Layout.Body>
          <Wrapper>
            <Wrapper.Body>
              <Fragment>
                <Table.Wrapper>
                  <Table>
                    <Table.Body loading={loading.get}>
                      {data?.items?.map((e) => (
                        <TaskRow key={e.taskId} task={e} />
                      ))}
                    </Table.Body>
                  </Table>
                </Table.Wrapper>
              </Fragment>
            </Wrapper.Body>
          </Wrapper>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            totalItems={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Layout.Body>
      </Layout>
      <TaskFilter
        isOpen={showFilter}
        toggle={toggleFilter}
        initParams={params}
        onSubmit={setParams}
      />
    </React.Fragment>
  );
}

export default TaskList;
