import { ComponentProps, useMemo } from "react";
import fileIcon from "../assets/icons/file vector.svg";
import gifIcon from "../assets/icons/gif vector.svg";
import jpegIcon from "../assets/icons/jpeg vector.svg";
import jpgIcon from "../assets/icons/jpg vector.svg";
import pdfIcon from "../assets/icons/pdf vector.svg";
import pngIcon from "../assets/icons/png vector.svg";
import svgIcon from "../assets/icons/svg vector.svg";
import webpIcon from "../assets/icons/webp vector.svg";
import wordIcon from "../assets/icons/word vector.svg";
import xlsIcon from "../assets/icons/xls vector.svg";
import { fileType } from "../constants";
import Image from "./_Image";

type BasicDocumentIconProps = { type: string | null; src?: never };
type DocumentIconProps = BasicDocumentIconProps &
  Omit<ComponentProps<typeof Image>, keyof BasicDocumentIconProps>;

export default function DocumentIcon({
  type = null,
  ...props
}: DocumentIconProps) {
  const iconSrc = useMemo(() => {
    switch (type) {
      case fileType.pdf:
        return pdfIcon;
      case fileType.png:
        return pngIcon;
      case fileType.word:
        return wordIcon;
      case fileType.jpeg:
        return jpegIcon;
      case fileType.jpg:
        return jpgIcon;
      case fileType.gif:
        return gifIcon;
      case fileType.xls:
        return xlsIcon;
      case fileType.xlsx:
        return xlsIcon;
      case fileType.doc:
        return wordIcon;
      case fileType.svg:
        return svgIcon;
      case fileType.webp:
        return webpIcon;
      default:
        return fileIcon;
    }
  }, [type]);
  return <Image src={iconSrc} {...props} />;
}
