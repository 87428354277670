import { useContext } from "react";
import { twMerge } from "tailwind-merge";
import { OrderDetailsContext } from ".";
import {
  Badge,
  Button,
  Drawer,
  Icon,
  List,
  Status,
  Text,
  Types,
} from "../../../components";
import { LineItemType, PickupStatus } from "../../../enums";
import { useAxios } from "../../../hooks";
import { togglePropsType } from "../../../types";

type Props = togglePropsType;

export default function SubmitRequestToPickupDrawer({ isOpen, toggle }: Props) {
  const { axios, loading } = useAxios();
  const {
    order,
    selectedLineItemIds,
    setSelectedLineItemIds,
    deactivateSelectMode,
    lineItems,
    setLineItems,
  } = useContext(OrderDetailsContext);
  const hasLoading = loading.get || loading.post;
  const selectedLineItems = lineItems.filter((e) =>
    selectedLineItemIds.includes(e.id)
  );
  const getLineItems = async () => {
    const url = "/salesservice/api/orderlineitems";
    const config = { params: { orderId: order.id, isCanceled: false } };
    return await axios.get(url, config).then(({ data }) => {
      setLineItems(data);
    });
  };
  const submit = () => {
    const url = "/salesservice/api/orderlineitems/change-pickup-status";
    const body = {
      orderId: order.id,
      orderLineItemIds: selectedLineItemIds,
      pickupRequestType: PickupStatus.PickupRequested,
    };
    axios.post(url, body).then(() => {
      getLineItems().then(() => {
        toggle();
        setSelectedLineItemIds([]);
        deactivateSelectMode();
      });
    });
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base text-dark font-medium">
            <Text>pickup.submitRequestToPickup.title</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body>
          <List>
            {selectedLineItems.map((e) => {
              const isBundle = e.lineItemType === LineItemType.ManualBundle;
              const articleNumber = e.product?.articleNumber;
              const hasArticleNumber = !!articleNumber;
              const shippingMethod = e.shippingMethodCode;
              const hasShippingMethod = !!shippingMethod;
              const pickupStatus = e.pickupStatus?.status ?? PickupStatus.None;

              return (
                <List.Item key={e.id}>
                  <Icon.Wrapper
                    variant="primary"
                    className={twMerge(isBundle && "bg-opacity-100 text-white")}
                  >
                    {isBundle ? (
                      <Icon name="Dropbox" variant="Bold" size={28} />
                    ) : (
                      <Icon name="Box1" variant="Linear" size={28} />
                    )}
                  </Icon.Wrapper>
                  <div className="flex-1 space-y-1">
                    <h6 className="w-full text-base font-normal truncate">
                      {e.title}
                    </h6>
                    {hasArticleNumber && (
                      <p className="block text-secondary">#{articleNumber}</p>
                    )}
                    <p className="space-x-1 !mt-2">
                      <Types.LineItem id={e.lineItemType} />
                      <Badge
                        variant={hasShippingMethod ? "dark" : "warning"}
                        className="p-1 text-xs leading-none"
                      >
                        <Icon name="TruckFast" variant="Bulk" />
                        {hasShippingMethod ? (
                          shippingMethod
                        ) : (
                          <Icon name="Danger" variant="Bold" />
                        )}
                      </Badge>
                    </p>
                    <p>
                      <Status.Pickup
                        id={pickupStatus}
                        reservationNumber={e.reservationId}
                      />
                    </p>
                  </div>
                </List.Item>
              );
            })}
          </List>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button
            type="button"
            variant="danger"
            onClick={toggle}
            disabled={hasLoading}
          >
            <Text>pickup.submitRequestToPickup.cancelButton</Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={submit}
            loading={hasLoading}
          >
            <Text>pickup.submitRequestToPickup.submitButton</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
