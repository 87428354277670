import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SalesChannel } from "../types";

const salesChannels = createSlice({
  name: "sales-channels",
  initialState: [] as SalesChannel.Item[],
  reducers: {
    setSalesChannels: (state, action: PayloadAction<SalesChannel.Item[]>) => {
      return action.payload;
    },
  },
});

export const { setSalesChannels } = salesChannels.actions;
export default salesChannels.reducer;
