import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Button,
  Icon,
  Image,
  Layout,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
  Types,
  WithPermission,
  Wrapper,
} from "../../../components";
import { config } from "../../../constants";
import { CustomerType } from "../../../enums";
import { withPermission } from "../../../hoc";
import { useAxios, useToggle } from "../../../hooks";
import { getRandomColor, showCallNumber } from "../../../methods";
import { Address, Customer, WithPaging } from "../../../types";
import FilterMenu from "./FilterMenu";
import SelectAccountType from "./SelectAccountType";

function CustomersList() {
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Customer.Item>>({});
  const [params, setParams] = useState<Customer.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const [showFilterMenu, toggleFilterMenu] = useToggle(false);
  const [showSelectAccountType, toggleSelectAccountType] = useToggle(false);
  const handleSetParams = (key: string) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p: any) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p: any) => ({ ...p, [key]: val }));
    };
  };
  const showAddress = (address: Address | null) => {
    const addressArray = [address?.postalCode, address?.city];
    const hasAddress = addressArray.some(Boolean);
    if (!hasAddress) return "--";
    return addressArray.filter(Boolean).join(", ");
  };
  const getData = () => {
    const url = "/accountservice/api/customers";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params, axios]);
  return (
    <React.Fragment>
      <Layout>
        <Layout.Header>
          <div className="flex-1">
            <SearchBox
              placeholder="page.customerList.searchBox"
              onSubmit={handleSetParams("keyword")}
            />
          </div>
          {/* <Button light onClick={toggleFilterMenu}>
            <Icon name="Filter" variant="Bold" size={20} />
          </Button> */}
          <WithPermission
            permissions={["AS_CustomerFullAccess", "AS_CreateCustomer"]}
          >
            <Button
              type="button"
              variant="primary"
              onClick={toggleSelectAccountType}
            >
              <Icon name="Add" size={20} className="mr-1" />
              <Text>button.addNew</Text>
            </Button>
          </WithPermission>
        </Layout.Header>
        <Layout.Body>
          <Wrapper>
            <Wrapper.Body>
              <Fragment>
                <Table.Wrapper>
                  <Table>
                    <Table.Body loading={loading.get}>
                      {data?.items?.map((e, i) => {
                        const isCompany =
                          e.customerType === CustomerType.Company;
                        const to = isCompany
                          ? `/companies/${e.id}`
                          : `/customers/${e.id}`;
                        return (
                          <tr key={e.id}>
                            <td>
                              <Link
                                to={to}
                                className="flex items-center gap-3 w-fit"
                              >
                                {!!e.avatar ? (
                                  <Image
                                    src={e.avatar.thumbnailUrl}
                                    alt={e.firstName + e.lastName}
                                    className="w-10 h-10 rounded-full bg-light overflow-hidden object-cover"
                                    isDynamic
                                  />
                                ) : (
                                  <Avatar
                                    color={getRandomColor(i)}
                                    icon={isCompany ? "Home" : "User"}
                                  />
                                )}
                                <div>
                                  <h6 className="text-dark text-base">
                                    {isCompany
                                      ? e.companyName
                                      : [e.firstName, e.lastName].join(" ")}
                                  </h6>
                                  <p className="text-secondary text-sm">
                                    #{e.cimNumber}
                                  </p>
                                </div>
                              </Link>
                            </td>
                            <td>
                              <Types.Customer id={e.customerType} />
                            </td>
                            <td>
                              {isCompany ? (
                                <Status.Company id={e.approvalStatus} />
                              ) : (
                                <p className="text-sm text-dark font-medium truncate max-w-32">
                                  {e.emailAddress}
                                </p>
                              )}
                            </td>
                            <td className="text-gray-900 text-sm">
                              {isCompany ? (
                                <Fragment>
                                  <Icon
                                    name="Location"
                                    variant="Bold"
                                    size={18}
                                  />{" "}
                                  {showAddress(e.address)}
                                </Fragment>
                              ) : (
                                showCallNumber(e.mobileNumber)
                              )}
                            </td>
                            <td data-sticky>
                              <Button as={Link} to={to} light>
                                <Text>button.viewDetails</Text>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Table.Wrapper>
              </Fragment>
            </Wrapper.Body>
          </Wrapper>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            totalItems={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Layout.Body>
      </Layout>
      <FilterMenu
        isOpen={showFilterMenu}
        toggle={toggleFilterMenu}
        onSubmit={(params) => setParams((p) => ({ ...p, ...params }))}
      />
      <WithPermission
        permissions={["AS_CustomerFullAccess", "AS_CreateCustomer"]}
      >
        <SelectAccountType
          isOpen={showSelectAccountType}
          toggle={toggleSelectAccountType}
        />
      </WithPermission>
    </React.Fragment>
  );
}

export default withPermission(CustomersList, [
  "AS_CustomerFullAccess",
  "AS_GetCustomer",
]);
