const mask = {
  date: {
    de: "99.99.9999",
    en: "99/99/9999",
    // de: "DD.MM.YYYY",
    // en: "MM/DD/YYYY",
  },
  format: {
    de: "DD.MM.YYYY",
    en: "MM/DD/YYYY",
  },
};
export default mask;
