import { Fragment } from "react";
import { Button, Icon, Modal, Text } from "../../../../components";
import {
  IconVariantType,
  iconNameType,
  togglePropsType,
} from "../../../../types";
type LineItemActionProps = togglePropsType & {
  label: string;
  number: string | null | undefined;
  onEdit: () => void;
  onRemove: () => void;
};
type ActionItem = {
  icon: iconNameType;
  variant: IconVariantType;
  className: string;
  label: string;
  action: () => void;
};
export default function LogisticAction({
  isOpen,
  toggle,
  label,
  number,
  onEdit,
  onRemove,
}: LineItemActionProps) {
  const items: ActionItem[] = [
    // {
    //   icon: detailsIcon,
    //   label: "Show product details",
    //   action: () => {},
    // },
    {
      icon: "Edit2",
      variant: "Bold",
      className: "text-primary",
      label: "actionTitles.editDeliveryDate",
      action: onEdit,
    },
    {
      icon: "Trash",
      variant: "Bold",
      className: "text-danger",
      label: "actionTitles.deleteDeliveryDate",
      action: onRemove,
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="text-center space-y-1">
        <h6 className="text-dark text-base">{label}</h6>
        <p className="block text-sm text-secondary">#{number}</p>
      </Modal.Header>
      <Modal.Body>
        {items.map((e, i) => (
          <Fragment key={e.label}>
            {i !== 0 && <hr className="bg-gray-100 w-full my-4" />}
            <button
              className="flex items-center w-full gap-4"
              onClick={() => {
                e.action();
                toggle();
              }}
            >
              <Icon.Wrapper variant={null} className="bg-gray">
                <Icon
                  name={e.icon}
                  variant={e.variant}
                  className={e.className}
                />
              </Icon.Wrapper>
              <span>
                <Text>{e.label}</Text>
              </span>
            </button>
          </Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer className="flex flex-center">
        <Button light onClick={toggle}>
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
