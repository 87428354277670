import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useState } from "react";
import { SearchSellOffProductContext } from ".";
import {
  Badge,
  Drawer,
  Image,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../../../../../components";
import { config } from "../../../../../constants";
import { SellOffProductStatus } from "../../../../../enums";
import {
  useAxios,
  useConverters,
  useDefaultSaleChannel,
} from "../../../../../hooks";
import { SellOffProduct, WithPaging } from "../../../../../types";

export default function SellOffProducts() {
  const saleChannel = useDefaultSaleChannel();
  const { axios, loading } = useAxios();
  const { convertAmount } = useConverters();
  const { setProductId } = useContext(SearchSellOffProductContext);
  const [SellOffProducts, setSellOffProducts] = useState<
    WithPaging<SellOffProduct.SearchItem>
  >({});
  const [params, setParams] = useState<SellOffProduct.SearchParams>({
    pageNumber: 1,
    pageSize: config.pageSize,
    channelCode: saleChannel?.code ?? null,
    status: SellOffProductStatus.Active,
  });
  const handleSelect = (e: SellOffProduct.SearchItem) => {
    return () => {
      setProductId(e.sellOffProductId);
    };
  };
  const getSellOffProducts = () => {
    // const url = "/salesservice/api/selloffproducts";
    const url = "/salesservice/api/selloffproducts/search-sell-off";
    const body = cloneDeep(params);
    body.channelCode = saleChannel?.code ?? null;
    body.keyword ||= null;
    axios.post(url, body).then(({ data }) => {
      setSellOffProducts({
        items: data.searchResult ?? [],
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
        totalItems: data.totalItems,
        totalPages: data.totalPages,
      });
    });
  };
  useEffect(getSellOffProducts, [params, saleChannel]);
  return (
    <Fragment>
      <Drawer.Header>
        <h6 className="text-dark text-base">
          <Text>drawerTitles.searchSellOffProducts</Text>
        </h6>
      </Drawer.Header>
      <Drawer.Header className="flex items-center gap-4 border-b-0">
        <SearchBox
          variant="gray"
          value={params.keyword}
          onSubmit={(keyword) =>
            setParams((p) => ({ ...p, keyword, pageNumber: 1 }))
          }
          className="w-auto flex-1"
        />
        {/* <Button light onClick={toggleFilter}>
          <Icon name="Filter" variant="Bold" size={20} />
        </Button> */}
      </Drawer.Header>
      <Drawer.Body>
        <List loading={loading.post}>
          {SellOffProducts.items?.map((e) => {
            const hasSupplierArticleNumber =
              !!e.pimProduct?.supplierArticleNumber;
            return (
              <List.Item key={e.sellOffProductId} onClick={handleSelect(e)}>
                <Image
                  src={e.assets?.[0]?.url}
                  alt={e.pimProduct?.originalName}
                  className="w-32 h-20 object-cover rounded"
                  isDynamic
                />
                <div className="flex-1">
                  <h6 className="block truncate text-dark text-base text-justify">
                    {e.pimProduct?.originalName}
                  </h6>
                  <span className="block truncate text-secondary text-sm">
                    <Text>global.articleNumber</Text>: #
                    {e.pimProduct?.articleNumber}
                  </span>
                  {hasSupplierArticleNumber && (
                    <span className="block truncate text-secondary text-base">
                      <Text>global.supplierArticleNumber</Text>: #
                      {e.pimProduct?.supplierArticleNumber}
                    </span>
                  )}
                </div>
                {/* <div className="min-w-fit flex items-center justify-between gap-2 px-4"> */}
                {/* <Status.SellOff id={e.status} /> */}
                <Badge
                  variant="light"
                  className="bg-gray-50 text-dark bg-opacity-100"
                >
                  {convertAmount(e.currentPrice)}
                </Badge>
                {/* </div> */}
                {/* <Button as="span" light>
                <Text>button.choose</Text>
              </Button> */}
              </List.Item>
            );
          })}
        </List>
      </Drawer.Body>
      <Drawer.Footer>
        <Pagination
          setActivePage={(pageNumber) =>
            setParams((p) => ({ ...p, pageNumber }))
          }
          pageNumber={params.pageNumber}
          totalItems={SellOffProducts.totalItems}
          totalPages={SellOffProducts.totalPages}
        />
      </Drawer.Footer>
    </Fragment>
  );
}
