import { Fragment } from "react";
import { useRoutes } from "react-router";
import { Toaster } from "./components";
import routes from "./routes";

export default function App() {
  const elements = useRoutes(routes);
  return (
    <Fragment>
      {elements}
      <Toaster />
    </Fragment>
  );
}
