import { Fragment, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { fileType } from "../constants";
import { useGetFile } from "../hooks";
import { downloadFile } from "../methods";
import { Document } from "../types";
import DocumentIcon from "./_DocumentIcon";
import Image from "./_Image";
import Loading from "./_Loading";
import Text from "./_Text";

type DocumentCardProps = {
  document: Document.Item;
  onRequestSign?: (id: string) => void;
  isEnabled?: boolean;
  offerId?: string;
  orderId?: string;
  lineItemDocument?: boolean;
  thumbnail?: boolean;
  onClick?: () => void;
};

export default function DocumentCard({
  document,
  offerId,
  orderId,
  onRequestSign,
  isEnabled,
  lineItemDocument = false,
  thumbnail = false,
  onClick,
}: DocumentCardProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const channelCode = searchParams.get("channelCode");
  const [getFile, fileLoading] = useGetFile();
  const mimeType = document.documentFileInfo.mimeType;
  const isWord = mimeType === fileType.word;
  const isImage = mimeType.startsWith("image");
  const isOtherFile = !Object.values(fileType).includes(mimeType);
  const downloadable = [isWord, isOtherFile].some(Boolean);
  const showThumbnail = isImage && thumbnail;
  const isOrderDocument = !!orderId;
  const href = useMemo(() => {
    const pathname = [
      `/${isOrderDocument ? "orders" : "offers"}`,
      isOrderDocument ? orderId : offerId,
      "documents",
      document.id,
    ].join("/");
    const search = isOrderDocument ? `channelCode=${channelCode}` : undefined;
    return { pathname, search };
  }, [document.id, isOrderDocument, offerId, orderId, channelCode]);
  const isPersonal = !document.templateCode;
  const download = () => {
    getFile(document.documentFileInfo.fileUrl).then((data) => {
      downloadFile(data);
    });
  };
  const handleClickRequiredSign = () => {
    isPersonal ? navigate(href) : onRequestSign?.(document.id);
  };
  return (
    <Fragment>
      {lineItemDocument ? (
        <div
          className="relative flex-center bg-gray rounded px-8 py-7 overflow-hidden"
          onClick={onClick}
        >
          {showThumbnail ? (
            <Image
              src={document.documentFileInfo?.fileUrl}
              alt={document.documentFileInfo?.mimeType}
              className="absolute size-full object-cover"
              isDynamic
            />
          ) : (
            <DocumentIcon
              className="block size-16 object-contain mx-auto"
              type={mimeType}
              alt={document.title}
            />
          )}
        </div>
      ) : (
        <div
          className="bg-white rounded px-2 py-4 text-center"
          onClick={onClick}
        >
          <Link to={href} className="block">
            <DocumentIcon
              className="block size-16 object-contain mx-auto mb-6"
              type={mimeType}
              alt={document.title}
            />
            <h6 className="block text-sm">{document.title ?? "--"}</h6>
          </Link>
          {downloadable ? (
            <button
              type="button"
              data-loading={fileLoading}
              className="group relative block w-full btn-link text-sm text-center mx-auto truncate"
              onClick={download}
            >
              {fileLoading && <Loading.Button />}
              <span className="group-data-loading:opacity-0">
                <Text>button.documentDownload</Text>
              </span>
            </button>
          ) : (
            <Fragment>
              {document.isSigned ? (
                <span className="block text-secondary text-sm">
                  <Text>global.signedByCustomer</Text>
                </span>
              ) : (
                <button
                  type="button"
                  disabled={!isEnabled}
                  className="block w-full btn-link text-sm text-center mx-auto truncate"
                  onClick={handleClickRequiredSign}
                >
                  <Text>
                    {document.signMandatory
                      ? "button.requireSigned"
                      : "button.optionalSigned"}
                  </Text>
                </button>
              )}
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
}
