import { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { Icon, InputGroup, RadioButton, Text } from "../components";
import { rules } from "../constants";
import { AssignedType } from "../enums";
import { useSelector, useToggle } from "../hooks";
import { classNameProps } from "../types";
import ChooseDepartment from "./_ChooseDepartment";
import ChooseEmployee from "./_ChooseEmployee";

export type SelectedEmployee = { name: string; id: string; type: AssignedType };

type IncidentAssignedEmployeeProps = {
  label?: string | null;
  value: SelectedEmployee | null;
  setValue: (value: SelectedEmployee | null) => void;
  disabled?: boolean;
} & classNameProps;

export default function IncidentAssignedEmployee({
  label = "incident.form.assignedEmployee",
  value,
  setValue,
  className,
  disabled,
}: IncidentAssignedEmployeeProps) {
  const hasLabel = !!label;
  const profile = useSelector((s) => s.profile);

  const [showMenu, toggleMenu] = useToggle(false);

  const profileEmployeeId = profile.employee?.id;
  const profileEmployeeName = [
    profile.employee?.firstName,
    profile.employee?.lastName,
  ].join(" ");
  const hasProfileEmployee = !!profileEmployeeId;

  const isEmployee = value?.type === AssignedType.Employee;
  const isDepartment = value?.type === AssignedType.Department;

  const isProfileEmployee =
    value?.id === profileEmployeeId && hasProfileEmployee;
  const isEmployeeNotSet = value === null;
  const isEmployeeOthers =
    !isProfileEmployee && !isEmployeeNotSet && isEmployee;
  const isEmployeeDepartment =
    !isProfileEmployee && !isEmployeeNotSet && isDepartment;
  const disabledAssignedEmployee = [isProfileEmployee, isEmployeeNotSet].some(
    Boolean
  );
  const assignedToMe = () => {
    if (!hasProfileEmployee) return;
    setValue({
      name: profileEmployeeName,
      id: profileEmployeeId,
      type: AssignedType.Employee,
    });
  };
  const notSet = () => {
    setValue(null);
  };
  const assignedToOthers = () => {
    setValue({
      name: "",
      id: "",
      type: AssignedType.Employee,
    });
  };
  const assignedToDepartment = () => {
    setValue({
      name: "",
      id: "",
      type: AssignedType.Department,
    });
  };
  const employeeItems = [
    {
      name: "incident.form.assignToMe",
      action: assignedToMe,
      isActive: isProfileEmployee,
      disabled: !hasProfileEmployee,
    },
    {
      name: "incident.form.assignToOthers",
      action: assignedToOthers,
      isActive: isEmployeeOthers,
      disabled: false,
    },
    {
      name: "incident.form.assignToDepartment",
      action: assignedToDepartment,
      isActive: isEmployeeDepartment,
      disabled: false,
    },
    {
      name: "incident.form.noAssign",
      action: notSet,
      isActive: isEmployeeNotSet,
      disabled: false,
    },
  ];
  return (
    <Fragment>
      <section className={twMerge("border rounded p-2 space-y-4", className)}>
        {hasLabel && (
          <h6 className="text-base text-dark">
            <Text>{label}</Text>:
          </h6>
        )}
        <div className="flex items-center justify-start flex-wrap gap-6">
          {employeeItems.map((e) => (
            <RadioButton
              key={e.name}
              label={e.name}
              onClick={e.action}
              isActive={e.isActive}
              className="w-auto"
              disabled={e.disabled || disabled}
            />
          ))}
        </div>
        <InputGroup
          value={value?.name}
          placeholder="incident.form.assignedEmployeePlaceholder"
          rules={!disabledAssignedEmployee ? rules.required : []}
          onClick={toggleMenu}
          readOnly
          disabled={disabledAssignedEmployee || disabled}
          append={
            <span className="input-group-text text-primary">
              <Icon
                name={isDepartment ? "Building" : "User"}
                variant="Bold"
                size={22}
              />
            </span>
          }
        />
      </section>
      {isEmployeeOthers && (
        <ChooseEmployee
          isOpen={showMenu}
          toggle={toggleMenu}
          onSelect={({ name, id }) =>
            setValue({ name, id, type: AssignedType.Employee })
          }
        />
      )}
      {isEmployeeDepartment && (
        <ChooseDepartment
          isOpen={showMenu}
          toggle={toggleMenu}
          onSelect={({ name, id }) =>
            setValue({ name, id, type: AssignedType.Department })
          }
        />
      )}
    </Fragment>
  );
}
