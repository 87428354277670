import { Fragment, useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import {
  Accordion,
  Icon,
  Image,
  Text,
  Translate,
  Wrapper,
} from "../../../../components";
import { useFindAttribute } from "../../../../hooks";
import { iconNameType } from "../../../../types";
export default function BasicInfo() {
  const { basicInfo } = useContext(SellOffProductDetailsContext);
  const findAttribute = useFindAttribute();
  const brand = basicInfo?.pimProduct?.supplier?.program?.brand;
  const hasBrand = !!brand;
  const moreInfo = [
    {
      icon: "Hashtag" as iconNameType,
      label: "sellOffProducts.details.articleNumber",
      value: `#${basicInfo?.pimProduct?.articleNumber}`,
    },
    {
      icon: "Building" as iconNameType,
      label: "sellOffProducts.details.supplier",
      value: basicInfo?.pimProduct?.supplier?.name ?? "--",
    },
    {
      icon: "Building" as iconNameType,
      label: "sellOffProducts.details.supplierArticleNumber",
      value: basicInfo?.pimProduct?.supplierArticleNumber ?? "--",
    },
    {
      icon: "Document" as iconNameType,
      label: "sellOffProducts.details.program",
      value: basicInfo?.pimProduct?.supplier?.program?.name ?? "--",
    },
    {
      icon: "TableDocument" as iconNameType,
      label: "sellOffProducts.details.brand",
      value: hasBrand ? (
        <div className="flex items-center gap-1">
          <Image
            src={brand.logo?.url}
            alt={brand.name}
            isDynamic
            className="inline-block rounded h-7"
          />
          <span>{brand.name}</span>
        </div>
      ) : (
        "--"
      ),
    },
    {
      icon: "Element3" as iconNameType,
      label: "sellOffProducts.details.productGroup",
      value: basicInfo?.pimProduct?.pimProductGroup?.name,
    },
    {
      icon: "Element3" as iconNameType,
      label: "sellOffProducts.details.productFamily",
      value: (
        <Translate
          value={basicInfo?.pimProduct?.productFamily?.translates}
          defaultValue="--"
        />
      ),
    },
  ];
  return (
    <Fragment>
      <Accordion defaultActiveKey={["about-product", "general-info"]}>
        <Wrapper className="col-span-full">
          <Accordion.Item eventKey="about-product">
            <Accordion.Toggle>
              <h2 className="text-start text-base flex-1 text-dark">
                <Text>sellOffProducts.details.aboutProduct</Text>
              </h2>
            </Accordion.Toggle>
            <Accordion.Body>
              <div className="space-y-2">
                <p className="text-secondary text-base font-medium">
                  {/* <Text>products.details.aboutProductText</Text> */}
                  <Translate
                    value={findAttribute(
                      basicInfo?.pimProduct?.attributeGroups,
                      "shortDescription"
                    )}
                    defaultValue="--"
                  />
                </p>
                <p className="text-secondary text-base font-medium">
                  <Translate
                    value={findAttribute(
                      basicInfo?.pimProduct?.attributeGroups,
                      "descriptions"
                    )}
                    defaultValue="--"
                  />
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Wrapper>
        <Wrapper className="col-span-full">
          <Accordion.Item eventKey="general-info">
            <Accordion.Toggle>
              <h2 className="text-sm text-dark">
                <Text>sellOffProducts.details.generalInfo</Text>
              </h2>
            </Accordion.Toggle>
            <Accordion.Body>
              <table className="w-full">
                <tbody className="divide-y">
                  {moreInfo.map((e) => (
                    <tr key={e.label} className="*:py-3">
                      <td className="text-secondary max-w-96">
                        <div className="w-fit">
                          <Icon
                            name={e.icon}
                            variant="Bold"
                            className="size-4 me-2"
                          />{" "}
                          <Text>{e.label}</Text>
                        </div>
                      </td>
                      <td className="text-gray-900 font-medium px-3">
                        {e.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
        </Wrapper>
      </Accordion>
    </Fragment>
  );
}
