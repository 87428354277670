import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export default function useTranslate() {
  const { t } = useTranslation();
  const translate = useCallback(
    (key: string) => {
      return t(key) ?? "";
    },
    [t]
  );
  return translate;
}
