import { cloneDeep } from "lodash";
import { Fragment, useContext } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router";
import { CustomerContext } from ".";
import mobileProgramingIcon from "../../../assets/icons/mobile programming.svg";
import mobileIcon from "../../../assets/icons/mobile.svg";
import notificationIcon from "../../../assets/icons/notification.svg";
import signpostIcon from "../../../assets/icons/signpost.svg";
import smsGreenIcon from "../../../assets/icons/sms green.svg";
import smsRedIcon from "../../../assets/icons/sms red.svg";
import telephoneIcon from "../../../assets/icons/telephone-2.svg";
import {
  Button,
  Text,
  Toggler,
  WithPermission,
  Wrapper,
} from "../../../components";
import { useAxios, useDataState } from "../../../hooks";
import { Customer } from "../../../types";
type CommunicationChannel = Customer.CommunicationChannel;
type communicationItemType = {
  icon: string;
  title: string;
  state: keyof CommunicationChannel;
};

type channelItemType = {
  icon: string;
  title: string;
  state: keyof CommunicationChannel;
};
export default function Communication() {
  const { axios, loading } = useAxios();
  const { customerId } = useParams();
  const { customerData, setCustomerData } = useContext(CustomerContext);
  const [data, setData, setBaseData, isChanged] = useDataState(customerData);
  const submitChange = () => {
    const url = `/accountservice/api/customers/${customerId}`;
    const body = { ...data };
    axios.put(url, body).then(() => {
      const message = "toast.success.editCustomer";
      toast.success(message);
      setCustomerData(body);
      setBaseData(body);
    });
  };
  const handleChangeCommunication = (key: keyof CommunicationChannel) => {
    return (val: boolean) =>
      setData((p) => {
        const data = cloneDeep(p);
        data.communicationChannel ??= {} as CommunicationChannel;
        data.communicationChannel[key] = val;
        return data;
      });
  };
  const communications: communicationItemType[] = [
    {
      icon: telephoneIcon,
      title: "page.customerDetails.communication.byPhone",
      state: "phone",
    },
    {
      icon: mobileProgramingIcon,
      title: "page.customerDetails.communication.byApplication",
      state: "application",
    },
    {
      icon: mobileIcon,
      title: "page.customerDetails.communication.bySocialMedia",
      state: "socialMedia",
    },
    {
      icon: smsGreenIcon,
      title: "page.customerDetails.communication.byEmail",
      state: "email",
    },
    {
      icon: smsRedIcon,
      title: "page.customerDetails.communication.bySMS",
      state: "sms",
    },
    {
      icon: signpostIcon,
      title: "page.customerDetails.communication.byPost",
      state: "post",
    },
  ];
  const channels: channelItemType[] = [
    // {
    //   icon: peopleIcon,
    //   title: "page.customerDetails.communication.familyBonusApplied",
    //   state: "fa",
    // },
    {
      icon: smsGreenIcon,
      title: "page.customerDetails.communication.bulkEmail",
      state: "bulkEmail",
    },
    {
      icon: notificationIcon,
      title: "page.customerDetails.communication.newsletterSubscribed",
      state: "newsletter",
    },
  ];
  return (
    <Fragment>
      <WithPermission permissions={["AS_UpdateCustomer"]}>
        <div className="flex items-center justify-between">
          <h6>
            <Text>global.submit</Text>
          </h6>
          <Button
            onClick={submitChange}
            disabled={!isChanged}
            loading={loading.update}
          >
            <Text>button.submitChanges</Text>
          </Button>
        </div>
      </WithPermission>
      <div className="grid grid-cols-12 gap-4">
        {communications.map((e) => (
          <Wrapper key={e.state} className="col-span-4">
            <Wrapper.Body className="space-y-4">
              <div className="flex-center gap-4">
                <div className="flex-center bg-gray-50 w-11 h-11 rounded">
                  <img src={e.icon} alt={e.title} className="w-7 h-7" />
                </div>
                <h6 className="font-normal flex-1">
                  <Text>{e.title}</Text>
                </h6>
              </div>
              <Toggler
                value={!!data.communicationChannel?.[e.state]}
                setValue={handleChangeCommunication(e.state)}
              />
            </Wrapper.Body>
          </Wrapper>
        ))}
        {channels.map((e) => (
          <Wrapper key={e.state} className="col-span-6">
            <Wrapper.Body className="space-y-4">
              <div className="flex-center gap-4">
                <div className="flex-center bg-gray-50 w-11 h-11 rounded">
                  <img src={e.icon} alt={e.title} className="w-7 h-7" />
                </div>
                <h6 className="font-normal flex-1">
                  <Text>{e.title}</Text>
                </h6>
              </div>
              <Toggler
                value={!!data.communicationChannel?.[e.state]}
                setValue={handleChangeCommunication(e.state)}
              />
            </Wrapper.Body>
          </Wrapper>
        ))}
      </div>
    </Fragment>
  );
}
