import { cloneDeep } from "lodash";
import {
  createContext,
  Fragment,
  MouseEvent,
  useContext,
  useMemo,
} from "react";
import { twMerge } from "tailwind-merge";
import { OrderDetailsContext } from "..";
import {
  Badge,
  Button,
  Icon,
  IconButton,
  Status,
  Text,
  Types,
} from "../../../../components";
import { LineItemType, PickupStatus } from "../../../../enums";
import { useAxios, useConverters, useToggle } from "../../../../hooks";
import { cn } from "../../../../methods";
import { LineItem } from "../../../../types";
import LineItemNoteDrawer from "./LineItemNoteDrawer";
import PickupConfirm from "./PickupConfirm";
import PickupInfo from "./PickupInfo";
type LineItemRowProps = {
  lineItem: LineItem.Item;
  hasSubLineItem?: boolean;
};
type ContextType = {
  lineItem: LineItem.Item;
  updateLineItem: () => Promise<LineItem.Item | null>;
  lineItemLoading: boolean;
  isPickedUp: boolean;
  pickupStatus: PickupStatus;
  hasArticleNumber: boolean;
  hasShippingMethod: boolean;
};
export const LineItemContext = createContext({} as ContextType);
export default function LineItemRow({
  lineItem,
  hasSubLineItem,
}: LineItemRowProps) {
  const {
    setLineItems,
    selectMode,
    selectedLineItemIds,
    setSelectedLineItemIds,
  } = useContext(OrderDetailsContext);
  const { axios, loading } = useAxios();
  const { convertAmount, convertDate } = useConverters();
  const [showInfoModal, toggleInfoModal, hideInfoModal] = useToggle(false);
  const [showConfirmModal, toggleConfirmModal, hideConfirmModal] =
    useToggle(false);
  const [showLineItemNote, toggleLineItemNote] = useToggle(false);
  const isLineItem = !lineItem.parentId;
  const isSubLineItem = !!lineItem.parentId;
  const isBundle = lineItem.lineItemType === LineItemType.ManualBundle;
  const showArrowBottom = !isSubLineItem && hasSubLineItem;
  const withOutBorder = isSubLineItem;
  const hasDiscount = useMemo(() => {
    return lineItem.originalPrice !== lineItem.unitPriceWithDiscount;
  }, [lineItem]);
  const shippingMethod = lineItem.shippingMethodCode;
  const hasShippingMethod = !!shippingMethod;
  const articleNumber = lineItem.product?.articleNumber;
  const pickupStatus = lineItem.pickupStatus?.status ?? PickupStatus.None;
  const isNone = pickupStatus === PickupStatus.None;
  const isPickupRequested = pickupStatus === PickupStatus.PickupRequested;
  const isPickupRequestCanceled =
    pickupStatus === PickupStatus.PickupRequestCanceled;
  const isPickedUp = pickupStatus === PickupStatus.PickedUp;
  const hasArticleNumber = !!articleNumber;
  const hasLoading = loading.get || loading.post;
  const canRequestToPickup = isNone || isPickupRequestCanceled;
  const isSelected = selectedLineItemIds.includes(lineItem.id);
  const canSelect = useMemo(() => {
    if (!selectMode) return true;
    return !isPickupRequested && !isPickedUp;
  }, [selectMode, isPickupRequested, isPickedUp]);
  const handleTableClick = (e: MouseEvent<HTMLTableRowElement>) => {
    if (isSubLineItem) return;
    const tr = e.currentTarget;
    const selector = "button";
    const buttons = tr.querySelectorAll(selector);
    const buttonsArray = [...buttons];
    const isButton = buttonsArray.some((btn) => btn.contains(e.target as Node));
    if (isButton) return;
    if (selectMode) return handleSelect();
    isPickedUp || isPickupRequested ? toggleInfoModal() : toggleConfirmModal();
  };
  const handleSelect = () => {
    setSelectedLineItemIds((p) => {
      const ids = cloneDeep(p);
      const has = ids.includes(lineItem.id);
      if (has) return ids.filter((e) => e !== lineItem.id);
      ids.push(lineItem.id);
      return ids;
    });
  };
  const updateLineItem = async () => {
    const url = `/salesservice/api/orderlineitems/${lineItem.id}`;
    return await axios
      .get<LineItem.Item>(url)
      .then(({ data }) => {
        setLineItems((p) => {
          const lineItems = cloneDeep(p);
          const index = lineItems.findIndex((e) => e.id === lineItem.id);
          const has = index !== -1;
          if (has) lineItems[index] = data;
          return lineItems;
        });
        return data;
      })
      .catch(() => null);
  };
  // const cancelProcess = () => {
  //   const url = "/salesservice/api/orderlineitems/change-pickup-status";
  //   const body = {
  //     orderId: order.id,
  //     orderLineItemIds: [lineItem.id],
  //     pickupRequestType: PickupStatus.PickupRequestCanceled,
  //   };
  //   axios.post(url, body).then(() => {
  //     updateLineItem();
  //   });
  // };
  return (
    <Fragment>
      <tr
        key={lineItem.id}
        className={cn(
          "transition-opacity",
          !isSubLineItem && "cursor-pointer",
          withOutBorder ? "!border-t-0" : "!border-t-[#A1A4AC]",
          !isSubLineItem && "[&>td]:!py-6",
          canSelect ? "opacity-100" : "opacity-50 pointer-events-none"
        )}
        onClick={handleTableClick}
      >
        <td>
          <div className="flex items-center gap-2">
            {isLineItem && selectMode && (
              <div className="size-6 bg-gray-50 rounded">
                <Icon
                  name="TickSquare"
                  data-active={isSelected}
                  variant="Bold"
                  className="size-full text-primary opacity-0 transition-opacity data-active:opacity-100"
                />
              </div>
            )}
            <Icon
              name="ArrowDown2"
              variant="Bold"
              size={22}
              data-active={showArrowBottom}
              className="text-primary opacity-0 pointer-events-none data-active:opacity-100 data-active:pointer-events-auto"
            />
            {/* {isSubLineItem && <span className="w-10 h-10" />} */}
            <div className="flex-1 flex items-center text-start gap-4">
              <div className="flex-center size-11">
                <Icon.Wrapper
                  variant="primary"
                  className={twMerge(
                    isBundle && "bg-opacity-100 text-white",
                    isSubLineItem && "bg-opacity-100 p-1"
                  )}
                >
                  {isBundle ? (
                    <Icon
                      name="Dropbox"
                      variant="Bold"
                      size={isSubLineItem ? 24 : 28}
                    />
                  ) : (
                    <Icon
                      name={isBundle ? "Dropbox" : "Box1"}
                      variant={isSubLineItem ? "Bold" : "Linear"}
                      size={isSubLineItem ? 0 : 28}
                      className={isSubLineItem ? "opacity-0" : ""}
                    />
                  )}
                </Icon.Wrapper>
              </div>
              <div className="flex-1 space-y-1">
                <h6 className="w-full text-base font-normal text-balance max-w-72 whitespace-normal line-clamp-2">
                  {lineItem.title}
                </h6>
                {hasArticleNumber && (
                  <p className="block text-secondary">#{articleNumber}</p>
                )}
                <p className="space-x-1 !mt-2">
                  <Types.LineItem id={lineItem.lineItemType} />
                  <Badge
                    variant={hasShippingMethod ? "dark" : "warning"}
                    className="p-1 text-xs leading-none"
                  >
                    <Icon name="TruckFast" variant="Bulk" />
                    {hasShippingMethod ? (
                      shippingMethod
                    ) : (
                      <Icon name="Danger" variant="Bold" />
                    )}
                  </Badge>
                </p>
                {isLineItem && (
                  <p>
                    <Status.Pickup
                      id={pickupStatus}
                      reservationNumber={lineItem.reservationId}
                    />
                  </p>
                )}
              </div>
            </div>
          </div>
        </td>
        <td className="space-y-px">
          <p className="text-sm text-dark font-medium">x{lineItem.quantity}</p>
          <p className="text-sm text-dark font-medium">
            <Text>KW</Text>{" "}
            {lineItem.desiredDeliveryCalendarWeek?.calendarWeekNumber}
          </p>
        </td>
        <td className="text-sm">
          {hasDiscount && (
            <p className="text-sm font-medium text-secondary line-through">
              <Text>pickup.details.lineItems.vk2</Text>{" "}
              {convertAmount(lineItem.originalPrice)}
            </p>
          )}
          <p className="text-sm font-medium text-dark">
            <Text>pickup.details.lineItems.vk1</Text>{" "}
            {convertAmount(lineItem.unitPriceWithDiscount)}
          </p>
        </td>
        <td className="text-dark text-sm">
          {convertAmount(lineItem.totalSalePrice)}
        </td>
        <td data-sticky className="space-x-2">
          {isLineItem && selectMode && canSelect && (
            <Fragment>
              <Button
                light
                className="btn-sm border-none"
                variant={isSelected ? "danger" : "primary"}
                onClick={handleSelect}
              >
                <Text>
                  {isSelected
                    ? "pickup.details.lineItems.unSelectButton"
                    : "pickup.details.lineItems.selectButton"}
                </Text>
              </Button>
            </Fragment>
          )}
          {isLineItem && (!selectMode || !canSelect) && (
            <Fragment>
              <IconButton type="button" light onClick={toggleLineItemNote}>
                <Icon name="DocumentText" variant="Bold" />
              </IconButton>
              {canRequestToPickup && (
                <Fragment>
                  <Button
                    light
                    className="btn-sm border-none"
                    onClick={toggleConfirmModal}
                  >
                    <Text>pickup.details.lineItems.requestToPickupButton</Text>
                  </Button>
                  {isPickupRequestCanceled && (
                    <p className="text-sm text-center font-medium text-gray-900 mt-2">
                      <Text
                        date={
                          convertDate(lineItem.pickupStatus?.updatedAt) ?? "--"
                        }
                      >
                        pickup.details.lineItems.canceledAt
                      </Text>
                    </p>
                  )}
                </Fragment>
              )}
              {isPickupRequested && (
                <Fragment>
                  {/* <Button
                    light
                    variant="danger"
                    className="btn-sm border-none"
                    onClick={cancelProcess}
                    loading={hasLoading}
                  >
                    <Text>pickup.details.lineItems.cancelProcessButton</Text>
                  </Button> */}
                  <Button
                    light
                    variant="warning"
                    className="btn-sm border-none"
                    onClick={toggleConfirmModal}
                  >
                    <Text>pickup.details.lineItems.resentRequestButton</Text>
                  </Button>
                </Fragment>
              )}
              {isPickedUp && (
                <div className="text-sm text-center font-medium space-y-2">
                  <p className="text-success font-semibold">
                    <Icon name="TickCircle" variant="Bold" />{" "}
                    <Text>pickup.details.lineItems.pickedUpByCustomer</Text>
                  </p>
                  <p className="text-gray-900">
                    <Text
                      date={
                        convertDate(lineItem.pickupStatus?.updatedAt) ?? "--"
                      }
                    >
                      pickup.details.lineItems.pickedUpAt
                    </Text>
                  </p>
                </div>
              )}
            </Fragment>
          )}
        </td>
      </tr>
      {isLineItem && !selectMode && (
        <LineItemContext.Provider
          value={{
            lineItem,
            updateLineItem,
            lineItemLoading: loading.get,
            isPickedUp,
            pickupStatus,
            hasArticleNumber,
            hasShippingMethod,
          }}
        >
          <PickupInfo isOpen={showInfoModal} toggle={hideInfoModal} />
          <PickupConfirm isOpen={showConfirmModal} toggle={hideConfirmModal} />
          <LineItemNoteDrawer
            isOpen={showLineItemNote}
            toggle={toggleLineItemNote}
          />
        </LineItemContext.Provider>
      )}
    </Fragment>
  );
}
