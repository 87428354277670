import { Fragment, useContext } from "react";
import { CreateIncidentContext } from ".";
import { Select, Text, Wrapper } from "../../../components";
import { rules, types } from "../../../constants";
import { IncidentAssignedEmployee } from "../../../containers";

export default function OtherInfo() {
  const { data, setData, employee, setEmployee } = useContext(
    CreateIncidentContext
  );
  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => {
      setData((p) => ({ ...p, [key]: value }));
    };
  };

  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Body className="space-y-8 max-w-screen-md mx-auto">
          <IncidentAssignedEmployee value={employee} setValue={setEmployee} />
          <section className="flex items-start gap-3">
            <div className="flex-1">
              <Select
                label="incident.form.priority"
                value={data.priority}
                placeholder="incident.form.priorityPlaceholder"
                setValue={handleSetValue("priority")}
                items={types.incidentPriority}
                rules={rules.required}
              />
            </div>
            <div className="flex-1">
              <Select
                label="incident.form.origin"
                value={data.origin}
                placeholder="incident.form.originPlaceholder"
                setValue={handleSetValue("origin")}
                items={types.incidentOrigin}
                rules={rules.required}
              />
            </div>
          </section>
          <section>
            <label className="block w-full mb-2 truncate after:content-['*'] after:text-danger">
              <Text>incident.form.customerMood</Text>
            </label>
            <div className="flex-center gap-3">
              {types.customerModes.map((e) => (
                <button
                  key={e.id}
                  type="button"
                  data-active={data.customerMood === e.id}
                  className="flex-center bg-gray-50 border border-gray-50 flex-1 text-3xl rounded opacity-50 data-active:opacity-100 data-active:border-primary py-1 transition-[opacity,border-color]"
                  dangerouslySetInnerHTML={{ __html: e.name }}
                  onClick={() => handleSetValue("customerMood")(e.id)}
                />
              ))}
            </div>
          </section>
        </Wrapper.Body>
      </Wrapper>
    </Fragment>
  );
}
