import { Fragment, useContext, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { ProductDetailsContext } from "..";
import {
  Alert,
  Button,
  Icon,
  NoItems,
  SearchBox,
  Text,
  Wrapper,
} from "../../../../components";
import {
  useAxios,
  useDefaultSaleChannel,
  useSelector,
} from "../../../../hooks";
import { Product } from "../../../../types";
import InventoryLoading from "./InventoryLoading";

export default function Inventory() {
  const profile = useSelector((s) => s.profile);
  const saleChannels = profile.availableSaleChannels;
  const saleChannel = useDefaultSaleChannel();
  const { axios, loading } = useAxios();
  const { product } = useContext(ProductDetailsContext);
  const hasLoading = [loading.get].some(Boolean);
  const [inventory, setInventory] = useState<Product.InventoryItem[]>([]);
  const [keyword, setKeyword] = useState<null | string>(null);
  const [error, setError] = useState<null | string>(null);
  const [channelCode, setChannelCode] = useState(saleChannel?.code ?? "");

  const filteredInventory = inventory.filter((e) =>
    [e.dimensions.locationid, e.quantities?.iv?.availabletoreserve ?? ""].some(
      (e) =>
        String(e)
          .toLowerCase()
          .includes((keyword ?? "").toLowerCase())
    )
  );

  const hasInventory = !!inventory.length;
  const hasError = !!error;

  const getInventory = () => {
    const url = `/salesservice/api/salestoolspimproduct/${product.id}/product-inventory`;
    const config = { params: { channelCode }, error: false };
    axios
      .get(url, config)
      .then(({ data }) => {
        setInventory(data);
        setError(null);
      })
      .catch((e) => {
        setError(e?.response?.data?.detail ?? null);
        setInventory([]);
      });
  };
  useEffect(getInventory, [product.id, channelCode]);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <SearchBox className="w-auto basis-1/3" onSubmit={setKeyword} />
      </section>
      <section className="flex flex-wrap gap-2">
        {saleChannels.map((e) => {
          const isActive = e.code === channelCode;
          return (
            <Button
              key={e.code}
              type="button"
              variant="primary"
              onClick={() => setChannelCode(e.code)}
              disabled={hasLoading}
              className={twMerge(
                "text-sm py-1 px-2",
                !isActive && "text-secondary bg-white border-white"
              )}
            >
              <Icon name="LocationTick" className="size-5" /> {e.title}
            </Button>
          );
        })}
      </section>
      {hasLoading ? (
        <InventoryLoading />
      ) : (
        <Wrapper>
          {/* <Wrapper.Header className="flex flex-wrap items-center gap-4">
            <h6 className="min-w-fit flex-1 text-dark text-base">
              {activeStore?.title}
            </h6>
            <AddressViewer
              address={activeStore?.address}
              className="w-fit text-sm text-secondary whitespace-nowrap"
            />
          </Wrapper.Header> */}
          <Wrapper.Body>
            {hasError ? (
              <Alert variant="danger" light>
                <Alert.Text>
                  <Text>{`errorCodes.${error}`}</Text>
                </Alert.Text>
              </Alert>
            ) : !hasInventory ? (
              <NoItems />
            ) : (
              <ul className="w-full divide-y">
                {filteredInventory.map((e, i) => {
                  const isAvailable = !!e.quantities?.iv?.availabletoreserve;
                  return (
                    <li key={i} className="flex items-center w-full py-3">
                      <h6 className="w-1/3 text-base font-normal text-secondary">
                        Stock{" "}
                        <span className="uppercase">
                          #{e.dimensions.locationid}
                        </span>
                      </h6>
                      {isAvailable ? (
                        <p className="flex-1 text-base font-normal text-gray-900">
                          x{e.quantities?.iv?.availabletoreserve ?? 0}
                        </p>
                      ) : (
                        <p className="flex-1 text-base font-normal text-warning">
                          <Icon name="InfoCircle" />{" "}
                          <Text>products.details.inventoryNotAvailable</Text>
                        </p>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </Wrapper.Body>
        </Wrapper>
      )}
    </Fragment>
  );
}
