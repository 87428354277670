import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Drawer,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../../../components";
import { config } from "../../../constants";
import { useAxios } from "../../../hooks";
import { getRandomColor } from "../../../methods";
import { Employee, WithPaging, togglePropsType } from "../../../types";

type EmployeeDrawerProps = togglePropsType & {
  onSelect: (customer: Employee.Item) => void;
};

export default function EmployeeDrawer({
  isOpen,
  toggle,
  onSelect,
}: EmployeeDrawerProps) {
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Employee.Item>>();
  const [params, setParams] = useState<Employee.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
  });
  const handleSelect = (e: Employee.Item) => {
    return () => {
      onSelect(e);
      toggle();
    };
  };
  const handleSetParams = (key: keyof Employee.Params) => {
    return (val: any) => {
      const isPageNumber = key !== "pageNumber";
      setParams((p) => ({
        ...p,
        [key]: val,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
    };
  };
  const getData = () => {
    if (!isOpen) return;
    const url = "/accountservice/api/employees";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [isOpen, params, axios]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
      <Drawer.Menu>
        <Drawer.Header className="border-b-0">
          <h6 className="text-base text-dark">
            <Text>page.orders.filterMenu.salesPersonDrawerTitle</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox
            placeholder="page.orders.filterMenu.salesPersonSearchBox"
            variant="gray"
            onSubmit={handleSetParams("keyword")}
          />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {data?.items?.map((e, i) => {
              const hasNumber = !!e.employeeNumber;
              return (
                <List.Item key={e.employeeId} onClick={handleSelect(e)}>
                  <Avatar icon="User" color={getRandomColor(i)} />
                  <div className="flex-1 space-y-px">
                    <h6 className="text-base text-dark">
                      {[e.firstName, e.lastName].join(" ")}
                    </h6>
                    {hasNumber && (
                      <p className="block text-secondary text-sm">
                        #{e.salesPersonNumber}
                      </p>
                    )}
                  </div>
                  <Button as="span" light>
                    <Text>page.orders.filterMenu.salesPersonChooseButton</Text>
                  </Button>
                </List.Item>
              );
            })}
          </List>
        </Drawer.Body>
        <Drawer.Footer>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            totalItems={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
