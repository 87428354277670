import {
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  BackButton,
  Icon,
  Layout,
  Loading,
  NoItems,
  Status,
  Tabs,
  Text,
  Types,
  Wrapper,
} from "../../../components";
import { taskDurationItems } from "../../../constants";
import { CRMActivityStatus } from "../../../enums";
import { useAxios, useConverters } from "../../../hooks";
import { Task } from "../../../types";
import Activities from "./Activities";
import ChangeStatusSection from "./ChangeStatusSection";
import General from "./General";

type Data = Task.Details;
type ContextType = {
  task: Data;
  setTask: Dispatch<SetStateAction<Data | null>>;
};
export const TaskDetailsContext = createContext({} as ContextType);

export default function TaskDetails() {
  const { convertDate } = useConverters();
  const { axios, loading } = useAxios();
  const { taskId } = useParams();
  const [task, setTask] = useState<Data | null>(null);

  const hasData = !!task;
  const duration = taskDurationItems.find((e) => e.id === task?.duration)?.name;
  const hasDuration = !!duration;
  const isOpen = task?.status === CRMActivityStatus.Open;
  const tabs = [
    {
      label: "task.details.generalTab",
      id: "0",
      component: General,
    },
    {
      label: "task.details.activitiesTab",
      id: "1",
      component: Activities,
    },
  ];

  const getData = () => {
    const url = `/channelmanagerservice/api/activities/task/${taskId}`;
    axios.get<Task.Details>(url).then(({ data }) => {
      setTask(data);
    });
  };
  useEffect(getData, [taskId]);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <BackButton to="/tasks" className="me-auto" />
          {!loading.get && isOpen && (
            <ChangeStatusSection task={task} onSubmitted={getData} />
          )}
        </Layout.Header>
        <Layout.Body className="space-y-5">
          {loading.get ? (
            <Loading.Header />
          ) : !hasData ? (
            <NoItems />
          ) : (
            <Fragment>
              <section className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                <Wrapper className="lg:col-span-3">
                  <Wrapper.Body className="space-y-4">
                    <div className="flex items-center gap-4">
                      <Icon.Wrapper rounded className="size-12">
                        <Icon
                          name="TaskSquare"
                          variant="Bold"
                          className="size-6"
                        />
                      </Icon.Wrapper>
                      <h1 className="flex-1 text-base font-semibold text-dark">
                        {task.subject}
                      </h1>
                    </div>
                    <div className="flex flex-wrap items-center gap-3">
                      <Status.Task
                        status={task.status}
                        statusReason={task.statusReason}
                      />
                      <Types.TaskPriority id={task.priority} />
                    </div>
                  </Wrapper.Body>
                </Wrapper>
                <Wrapper className="flex-center lg:col-span-1">
                  <Wrapper.Body className="w-full space-y-2">
                    <p className="text-xs text-placeholder font-medium">
                      <Icon
                        name="Calendar"
                        variant="Bulk"
                        className="size-4 text-primary"
                      />{" "}
                      <Text>task.details.taskCreatedAt</Text>{" "}
                      <span className="text-secondary">
                        {convertDate(task.createdAt) || "--"}
                      </span>
                    </p>
                    <p className="text-xs text-placeholder font-medium">
                      <Icon
                        name="Clock"
                        variant="Bulk"
                        className="size-4 text-primary"
                      />{" "}
                      <Text>task.details.taskDuration</Text>{" "}
                      <span className="text-secondary">
                        {hasDuration ? <Text>{duration}</Text> : "--"}
                      </span>
                    </p>
                    <p className="text-xs text-placeholder font-medium">
                      <Icon
                        name="CalendarCircle"
                        variant="Bulk"
                        className="size-4 text-primary"
                      />{" "}
                      <Text>task.details.taskDueDate</Text>{" "}
                      <span className="text-secondary">
                        {convertDate(task.dueDate) || "--"}
                      </span>
                    </p>
                  </Wrapper.Body>
                </Wrapper>
              </section>
              <Tabs activeKey={tabs[0].id}>
                <Wrapper className="col-span-full">
                  <Wrapper.Body className="py-0">
                    <Tabs.ButtonGroup>
                      {tabs.map((e) => (
                        <Tabs.Button key={e.id} eventKey={e.id}>
                          <Text>{e.label}</Text>
                        </Tabs.Button>
                      ))}
                    </Tabs.ButtonGroup>
                  </Wrapper.Body>
                </Wrapper>
                {hasData && (
                  <Fragment>
                    <TaskDetailsContext.Provider value={{ task, setTask }}>
                      {tabs.map((e) => (
                        <Tabs.Item key={e.id} eventKey={e.id}>
                          {createElement(e.component)}
                        </Tabs.Item>
                      ))}
                    </TaskDetailsContext.Provider>
                  </Fragment>
                )}
              </Tabs>
            </Fragment>
          )}
        </Layout.Body>
      </Layout>
    </Fragment>
  );
}
