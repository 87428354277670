import { ComponentType } from "react";
import noPermissions from "../assets/images/no-permission.svg";
import { Image, Layout } from "../components";
import { usePermission } from "../hooks";
import { Permission } from "../types";

export default function withPermission<P extends object>(
  Component: ComponentType<P>,
  permissions: Permission[]
) {
  const Error = () => {
    return (
      <Layout>
        <Layout.Header></Layout.Header>
        <Layout.Body>
          <Image
            src={noPermissions}
            alt="No Permission"
            className="block w-full max-w-sm mx-auto"
          />
        </Layout.Body>
      </Layout>
    );
  };
  return (props: P) => {
    const hasPermission = usePermission(...permissions);
    if (!hasPermission) return <Error />;
    return <Component {...props} />;
  };
}
