import { Fragment } from "react";
import { config } from "../../../constants";

export default function SupplierLoading() {
  return (
    <Fragment>
      {[...Array(config.pageSize).keys()].map((e) => (
        <section
          key={e}
          className="bg-white min-w-fit w-full flex items-center rounded shadow whitespace-nowrap gap-2.5 p-1.5 pe-5"
        >
          <div className="min-w-36 h-20 w-36 loading" />
          <div className="min-w-fit flex-1 space-y-1">
            <div className="w-52 h-5 loading"></div>
            <div className="w-28 h-4 loading"></div>
            <div className="w-36 h-4 loading"></div>
          </div>
          <div className="flex-1">
            <div className="w-28 h-4 loading mx-auto"></div>
          </div>
          <div className="w-24 h-10 loading"></div>
        </section>
      ))}
    </Fragment>
  );
}
