import { useContext } from "react";
import { ProgramDetailsContext } from "..";
import { Text, Wrapper } from "../../../../../components";

export default function GeneralInfo() {
  const { program } = useContext(ProgramDetailsContext);
  const generalItem = [
    { label: "suppliers.programGeneral.programTitle", value: program?.title },
    {
      label: "suppliers.programGeneral.code",
      value: "#" + (program?.code ?? ""),
    },
    {
      label: "suppliers.programGeneral.iwofurnCode",
      value: program?.iwofurnCode,
    },
    { label: "suppliers.programGeneral.zrn", value: program?.zrNumber },
    // { label: "suppliers.programGeneral.brand", value: program?.brandId },
    // {
    //   label: "suppliers.programGeneral.productGroup",
    //   value: program?.pimProductGroupId,
    // },
    {
      label: "suppliers.programGeneral.description",
      value: program?.shortDescription,
    },
  ];
  return (
    <Wrapper>
      <Wrapper.Header className="border-b-0">
        <h2 className="text-dark text-sm font-medium">
          <Text>suppliers.programGeneral.title</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body>
        <ul className="w-full divide-y">
          {generalItem.map((e) => (
            <li
              key={e.label}
              className="flex items-start gap-5 py-3 first:pt-0 last:pb-0"
            >
              <h6 className="flex-1 text-secondary text-sm font-normal">
                <Text>{e.label}</Text>
              </h6>
              <p className="flex-[2] text-gray-900 text-sm font-normal">
                {e.value}
              </p>
            </li>
          ))}
        </ul>
      </Wrapper.Body>
    </Wrapper>
  );
}
