import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type BaseProps = {
  value: number;
  total?: number;
  children?: never;
  progressClassName?: string;
};
type Props = BaseProps & Omit<ComponentProps<"div">, keyof BaseProps>;
export default function Progress({
  value,
  total = 100,
  className,
  progressClassName,
}: Props) {
  const handleValue = Math.ceil((100 * value) / total);

  return (
    <div
      className={twMerge(
        "w-full h-2 rounded-full overflow-hidden bg-[#E1E1E7]",
        className
      )}
    >
      <span
        style={{ width: `${handleValue}%` }}
        className={twMerge(
          "block max-w-full h-full bg-primary rounded-full transition-[width]",
          progressClassName
        )}
      />
    </div>
  );
}
