import { cloneDeep } from "lodash";
import {
  Dispatch,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import toast from "react-hot-toast";
import {
  Alert,
  Badge,
  Button,
  CheckBox,
  Drawer,
  Icon,
  Loading,
  Text,
} from "../../../components";
import { useAxios, useConverters } from "../../../hooks";
import { SellOffProduct, togglePropsType } from "../../../types";
import useUpdateSellOffProduct from "./useUpdateSellOffProduct";

type Props = togglePropsType & {
  hasTemplate: boolean;
  start?: boolean;
  sellOffProductId: string | null | undefined;
  onSubmitted?: Dispatch<string>;
};
type Data = {
  start: SellOffProduct.StartPhaseRequirement | null;
  change: SellOffProduct.ChangePhaseRequirement | null;
};
type ChecklistItem = SellOffProduct.CheckListItem;

export default function ChangePhaseDrawer({
  isOpen,
  toggle,
  hasTemplate = false,
  start = false,
  sellOffProductId = null,
  onSubmitted,
}: Props) {
  const [updateParent, parentLoading] = useUpdateSellOffProduct();
  const { convertAmount } = useConverters();
  const { axios, loading } = useAxios();
  const [data, setData] = useState<Data | null>(null);
  const showTemplateWarning = start && !hasTemplate;
  const hasData = !!data?.start || data?.change;
  const pimProduct = data?.start?.pimProduct || data?.change?.pimProduct;
  const [checkList, setCheckList] = useState<ChecklistItem[]>([]);
  const hasCheckListItems = !!checkList.length;
  const currentPhase = {
    name: start
      ? "sellOffProducts.changePhaseDrawer.preparation"
      : data?.change?.currentPhaseName,
    discount: start ? null : data?.change?.currentDiscount,
    price: start
      ? data?.start?.pimProduct?.vk1Price?.amount
      : data?.change?.currentPrice,
  };
  const nextPhase = {
    name: start ? data?.start?.firstPhaseTitle : data?.change?.nextPhaseName,
    days: start
      ? data?.start?.firstPhaseTotalDays
      : data?.change?.nextPhaseTotalDays,
    productPrice: start
      ? data?.start?.pimProduct?.vk1Price?.amount
      : data?.change?.pimProduct?.vk1Price?.amount,
    discount: start
      ? data?.start?.firstPhaseDiscount
      : data?.change?.nextDiscount,
    price: start ? data?.start?.firstPhasePrice : data?.change?.nextPrice,
  };
  const allCheckListChecked = hasCheckListItems
    ? checkList.every((e) => e.isChecked)
    : true;
  const disabledButton = useMemo(() => {
    if (start) return !allCheckListChecked || loading.update || !hasTemplate;
    return !allCheckListChecked || loading.update;
  }, [allCheckListChecked, loading.update, hasTemplate, start]);
  const checkListUrl = useMemo(() => {
    if (start)
      return `/salesservice/api/selloffproducts/${sellOffProductId}/preparationphase-checklistitem`;
    return `/salesservice/api/selloffproducts/${sellOffProductId}/sellphase-checklistitem`;
  }, [start, sellOffProductId]);
  const checkListBody = useCallback(
    (id: string) => {
      if (start) return { preparationPhaseCheckListItemId: id };
      return { sellPhaseCheckListItemId: id };
    },
    [start]
  );
  const handleChecked = (id: string) => {
    return () => {
      axios.patch(checkListUrl, checkListBody(id)).then(() => {
        setCheckList((p) => {
          const data = cloneDeep(p);
          const index = data.findIndex((e) => e.checkListItemId === id);
          data[index].isChecked = !data[index].isChecked;
          return data;
        });
      });
    };
  };
  const getStartData = () => {
    if (!start) return;
    if (!isOpen || !sellOffProductId) return;
    const url = `/salesservice/api/selloffproducts/${sellOffProductId}/start-phase-requirement`;
    axios.get(url).then(({ data }) => {
      setData({ change: null, start: data });
      setCheckList(data?.preparationPhase?.checkListItems ?? []);
    });
  };
  const getChangeData = () => {
    if (start) return;
    if (!isOpen || !sellOffProductId) return;
    const url = `/salesservice/api/selloffproducts/${sellOffProductId}/change-phase-requirement`;
    axios.get(url).then(({ data }) => {
      setData({ change: data, start: null });
      setCheckList(data?.sellPhaseCheckListItems ?? []);
    });
  };
  const startPhase = () => {
    if (!sellOffProductId) return;
    const url = `/salesservice/api/selloffproducts/${sellOffProductId}/start-phase`;
    axios.post(url).then(() => {
      updateParent().then(() => {
        const message = "sellOffProducts.changePhaseDrawer.startSuccessMessage";
        toast.success(message);
        toggle();
        onSubmitted?.(sellOffProductId);
      });
    });
  };
  const changePhase = () => {
    if (!sellOffProductId) return;
    const url = `/salesservice/api/selloffproducts/${sellOffProductId}/change-phase`;
    axios.post(url).then(() => {
      updateParent().then(() => {
        const message =
          "sellOffProducts.changePhaseDrawer.changeSuccessMessage";
        toast.success(message);
        toggle();
        onSubmitted?.(sellOffProductId);
      });
    });
  };
  const submit = start ? startPhase : changePhase;
  useEffect(getStartData, [isOpen, sellOffProductId, start]);
  useEffect(getChangeData, [isOpen, sellOffProductId, start]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base text-dark font-medium">
            <Text>
              {start
                ? "sellOffProducts.changePhaseDrawer.startPhaseTitle"
                : "sellOffProducts.changePhaseDrawer.changePhaseTitle"}
            </Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          {loading.get ? (
            <Loading.ChangePhaseForm />
          ) : hasData ? (
            <Fragment>
              <div className="flex items-center text-start gap-4">
                <Icon.Wrapper>
                  <Icon name="Box" variant="Bold" size={28} />
                </Icon.Wrapper>
                <div className="flex-1 space-y-1">
                  <span className="block">{pimProduct?.originalName}</span>
                  <span className="block text-secondary">
                    #{pimProduct?.articleNumber}
                  </span>
                </div>
              </div>
              <div className="w-full border rounded p-2.5 space-y-0.5">
                <Badge className="text-2xs block w-fit px-2 py-1">
                  <Text>sellOffProducts.changePhaseDrawer.currentPhase</Text>
                </Badge>
                <h6 className="text-sm text-dark font-semibold">
                  <Text>{currentPhase.name ?? ""}</Text>
                </h6>
                {!!currentPhase.discount && (
                  <p className="text-sm text-danger font-medium">
                    {currentPhase.discount}%
                  </p>
                )}
                <p className="text-sm text-[#A1A4AC] font-medium">
                  {convertAmount(currentPhase.price)}
                </p>
              </div>
              <Icon
                name="ArrowCircleDown"
                variant="Bold"
                className="text-primary size-5 block mx-auto"
              />
              <div className="w-full border rounded p-2.5 space-y-0.5">
                <Badge className="text-2xs block w-fit px-2 py-1">
                  <Text>sellOffProducts.changePhaseDrawer.nextPhase</Text>
                </Badge>
                <h6 className="text-sm text-dark font-semibold">
                  {nextPhase.name}
                </h6>
                <p className="text-sm text-[#5E6278] font-medium">
                  <Text days={nextPhase.days ?? ""}>
                    sellOffProducts.changePhaseDrawer.days
                  </Text>
                </p>
                <p className="text-sm text-primary font-medium">
                  {convertAmount(nextPhase.productPrice)}
                </p>
                <p className="text-sm text-danger font-medium">
                  {nextPhase.discount}%
                </p>
                <p className="text-sm text-[#A1A4AC] font-medium">
                  {convertAmount(nextPhase.price)}
                </p>
              </div>
              {hasCheckListItems && (
                <div className="space-y-2.5 !mt-8">
                  <h6 className="text-base text-dark font-semibold">
                    <Text>
                      sellOffProducts.changePhaseDrawer.checklistTitle
                    </Text>
                  </h6>
                  <p className="text-sm text-secondary font-medium">
                    <Text>
                      sellOffProducts.changePhaseDrawer.checklistDescription
                    </Text>
                  </p>
                  <ul className="divide-y divide-dashed">
                    {checkList?.map((e) => (
                      <li
                        key={e.checkListItemId}
                        className="py-4 first:pt-0 last:pb-0"
                      >
                        <CheckBox
                          label={e.label}
                          value={e.isChecked}
                          setValue={handleChecked(e.checkListItemId)}
                          disabled={loading.update}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Fragment>
          ) : null}
        </Drawer.Body>
        {showTemplateWarning && (
          <Drawer.Footer>
            <Alert variant="warning" light>
              <Alert.Text>
                <Text>sellOffProducts.changePhaseDrawer.templateWarning</Text>
              </Alert.Text>
            </Alert>
          </Drawer.Footer>
        )}
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button type="button" variant="danger">
            <Text>sellOffProducts.changePhaseDrawer.cancelButton</Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            disabled={disabledButton}
            onClick={submit}
            loading={parentLoading || loading.post}
          >
            <Text>
              {start
                ? "sellOffProducts.changePhaseDrawer.startSubmitButton"
                : "sellOffProducts.changePhaseDrawer.changeSubmitButton"}
            </Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
