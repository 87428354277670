import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { SellOffProductDetailsContext } from "..";
import { Button, Form, Select, Text, Wrapper } from "../../../../components";
import { rules } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import useUpdateSellOffProduct from "../useUpdateSellOffProduct";

type DataItem = {
  code: string;
  description: string;
  sellPhaseTemplateId: string;
  title: string;
};

export default function SelectTemplate() {
  const { basicInfo } = useContext(SellOffProductDetailsContext);
  const [updateSellOffProduct, updateLoading] = useUpdateSellOffProduct();
  const { axios, loading } = useAxios();
  const [data, setData] = useState<DataItem[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState(
    basicInfo?.sellPhaseTemplateId ?? ""
  );
  const items = data.map((e) => ({ name: e.title, id: e.sellPhaseTemplateId }));
  const getTemplates = () => {
    const url = "/salesservice/api/sellphasetemplate";
    const config = { params: { pageNumber: 1, pageSize: 100 } };
    axios.get(url, config).then(({ data }) => {
      setData(data?.items ?? []);
    });
  };
  const submit = () => {
    const url = `/salesservice/api/selloffproducts/${basicInfo?.sellOffProductId}/sell-phase-template`;
    const body = { sellPhaseTemplateId: selectedTemplate };
    axios.patch(url, body).then(() => {
      updateSellOffProduct().then(() => {
        const message = "sellOffProducts.details.templateSuccessMessage";
        toast.success(message);
      });
    });
  };
  useEffect(getTemplates, []);
  return (
    <Wrapper as={Form} onSubmit={submit} className="lg:col-span-2">
      <Wrapper.Header>
        <h6 className="text-sm text-dark font-medium">
          <Text>sellOffProducts.details.salesTemplate</Text>
        </h6>
      </Wrapper.Header>
      <Wrapper.Body className="py-10">
        <Select
          value={selectedTemplate}
          setValue={setSelectedTemplate}
          rules={rules.required}
          items={items}
          loading={loading.get}
        />
      </Wrapper.Body>
      <Wrapper.Footer className="flex items-center justify-end">
        <Button type="submit" loading={loading.update || updateLoading}>
          <Text>sellOffProducts.details.submitTemplateButton</Text>
        </Button>
      </Wrapper.Footer>
    </Wrapper>
  );
}
