import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Icon, Status, Text } from "../../../components";
import { taskDurationItems } from "../../../constants";
import { useConverters, useSelector } from "../../../hooks";
import { Task } from "../../../types";

type TaskRowProps = {
  task: Task.Item;
};

export default function TaskRow({ task }: TaskRowProps) {
  const profile = useSelector((s) => s.profile);
  const dueDate = task.dueDate;
  const hasDueDate = !!dueDate;
  const duration = taskDurationItems.find((e) => e.id === task.duration)?.name;
  const hasDuration = !!duration;
  const isAssignedToMe = task.assignedId === profile.employee?.id;
  const { convertDate } = useConverters();
  return (
    <Fragment>
      <tr key={task.taskId}>
        <td>
          <Link to={task.taskId} className="flex items-center gap-3 w-fit">
            <Icon.Wrapper>
              <Icon name="TaskSquare" variant="Bold" />
            </Icon.Wrapper>
            <div>
              <h6 className="text-base font-medium text-dark">
                {task.subject}{" "}
                <Badge className="bg-gray-50 text-gray-900 text-2xs font-medium py-px px-1 rounded-md bg-opacity-100">
                  <Icon name="Link1" variant="Bulk" size={16} />{" "}
                  <Text>
                    {isAssignedToMe
                      ? "task.list.assignedToMe"
                      : "task.list.assignedToDepartment"}
                  </Text>
                </Badge>
              </h6>
              <p className="text-xs text-secondary font-medium mb-[0.125rem]">
                <Icon name="CalendarTick" variant="Bulk" />{" "}
                <Text>task.list.createdAt</Text>{" "}
                <span className="text-gray-900">
                  {convertDate(task.createdAt)}
                </span>
              </p>
              <p className="text-xs text-secondary font-medium">
                {hasDueDate && (
                  <Fragment>
                    <Icon name="CalendarCircle" variant="Bulk" />{" "}
                    <Text>task.list.dueDate</Text>{" "}
                    <span className="text-gray-900">
                      {convertDate(dueDate)}
                    </span>{" "}
                  </Fragment>
                )}
                {hasDuration && (
                  <Badge className="bg-light text-gray-900 text-[10px] bg-opacity-100 px-1 py-[0.1875rem]">
                    <Icon name="Clock" className="size-3 me-[0.125rem]" />{" "}
                    <Text>{duration}</Text>
                  </Badge>
                )}
              </p>
            </div>
          </Link>
        </td>
        <td>
          <p className="w-fit text-xs text-secondary font-medium whitespace-normal max-w-72 line-clamp-2">
            {task.description}
          </p>
        </td>
        <td>
          <div className="flex-center flex-col gap-1">
            <Status.Task status={task.status} />
            <Status.TaskReason id={task.statusReason} />
          </div>
        </td>
        <td data-sticky>
          <Button as={Link} to={task.taskId} light>
            <Text>task.list.detailsButton</Text>
          </Button>
        </td>
      </tr>
    </Fragment>
  );
}
