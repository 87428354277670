import { useCallback } from "react";
import { dateFormats } from "../constants";
import { isEmptyValue } from "../methods";
import useLanguage from "./useLanguage";

export default function useConverters() {
  const [locale] = useLanguage();
  const convertAmount = useCallback(
    (amount: number | null | undefined) => {
      if (isEmptyValue(amount)) return null;
      return (
        amount?.toLocaleString(locale, {
          style: "currency",
          currency: "EUR",
        }) ?? null
      );
    },
    [locale]
  );
  const convertNumber = useCallback(
    (amount: number | null | undefined) => {
      if (isEmptyValue(amount)) return null;
      return (
        amount?.toLocaleString(locale, {
          style: "decimal",
          useGrouping: false,
        }) ?? null
      );
    },
    [locale]
  );
  const convertDate = useCallback(
    (
      date: string | number | Date | null | undefined,
      format: Intl.DateTimeFormatOptions = dateFormats.dateOnly
    ) => {
      if (!date) return null;
      if (String(date).includes("0001")) return null;
      const value = new Date(date);
      return value.toLocaleString(locale, format);
    },
    [locale]
  );
  return { convertAmount, convertDate, convertNumber };
}
