import { useContext } from "react";
import { twMerge } from "tailwind-merge";
import { Alert, Drawer, Icon, Text, Translate } from "../../../../components";
import { useConverters, useFindAttribute } from "../../../../hooks";
import { togglePropsType } from "../../../../types";
import {
  BundleItemWithSalesPrice,
  ProductPriceContext,
} from "../ProductPriceProvider";

type Props = {
  lineItem: BundleItemWithSalesPrice;
} & togglePropsType;

export default function LineItemPriceDetails({
  isOpen,
  toggle,
  lineItem,
}: Props) {
  const findAttribute = useFindAttribute();
  const { convertAmount } = useConverters();
  const { salesPriceLoading } = useContext(ProductPriceContext);
  const hasIln = !!lineItem.supplier?.iln;
  const salesPrice = lineItem?.salesPrice;
  const isOverwritten = salesPrice?.isOverwritten;
  const isWarning = salesPrice?.isWarning;
  const isError = salesPrice?.isError;
  const classes = salesPrice?.color;
  const loading = salesPriceLoading;

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base text-dark font-medium">
            <Text>products.details.linePriceInfo.drawerTitle</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <section className="border rounded p-2 flex items-start gap-2">
            <Icon name="Box1" size={25} className="text-primary" />
            <div className="flex-1 space-y-4">
              <div className="space-y-1">
                <h6 className="text-base text-dark">
                  <Translate
                    value={findAttribute(lineItem.attributeGroups, "title")}
                    defaultValue={lineItem.originalName}
                  />
                </h6>
                <p className="text-sm text-secondary">
                  #{lineItem.articleNumber}
                </p>
                {hasIln && (
                  <p className="text-sm text-secondary">
                    <Icon name="Building" variant="Bold" size={18} />{" "}
                    <Text>products.details.linePriceInfo.supplierIln</Text>:{" "}
                    {lineItem.supplier?.iln}
                  </p>
                )}
              </div>
              <hr className="w-full bg-[#e5e7eb]" />
              <div className="space-y-1">
                <p className="text-xs text-secondary font-medium">
                  <Text>products.details.lineVk2</Text>:{" "}
                  {convertAmount(lineItem.vk2Price?.amount)}
                </p>
                <p className="text-xs text-dark font-medium">
                  <Text>products.details.lineVk1</Text>:{" "}
                  {convertAmount(lineItem.originalVk1Price?.amount)}
                </p>
                {isOverwritten && (
                  <p className="text-xs text-dark font-medium">
                    <Text>products.details.lineOverwritten</Text>:{" "}
                    {convertAmount(lineItem.vk1Price?.amount)}
                  </p>
                )}
              </div>
            </div>
          </section>
          {loading ? (
            <div className="w-full h-[10.25rem] loading" />
          ) : !!salesPrice ? (
            <section
              className={twMerge("p-2.5 rounded-md space-y-2", classes?.bg)}
            >
              <div className="flex-1 space-y-1">
                <h6 className="text-xs text-gray-900 font-medium">
                  <Text>products.details.linePriceInfo.priceNow</Text>:
                </h6>
                <ul className="w-full text-xs *:py-1 [&>li>*]:flex [&>li>*]:items-center [&>li>*]:gap-1">
                  <li>
                    <p className="text-secondary">
                      <Icon name="DollarSquare" variant="Bold" size={22} />
                      <span>
                        <Text>products.details.linePriceInfo.vk2</Text>:
                      </span>
                      <span className="line-through ms-4">
                        {convertAmount(salesPrice.vk2) ?? "--"}
                      </span>
                    </p>
                  </li>
                  <li>
                    <p className="w-full text-start text-dark">
                      <Icon name="DollarSquare" variant="Bold" size={22} />
                      <span>
                        <Text>products.details.linePriceInfo.vk1</Text>:
                      </span>
                      <span className={`${classes?.text} ms-4`}>
                        {convertAmount(salesPrice.vk1) ?? "--"}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              {isWarning && (
                <Alert
                  variant="warning"
                  className="rounded-xl"
                  IconProps={{ variant: "Bulk" }}
                >
                  <Alert.Text className="text-white text-xs font-medium">
                    <Text>products.details.linePriceInfo.warningMessage</Text>
                  </Alert.Text>
                </Alert>
              )}
              {isError && (
                <Alert
                  variant="danger"
                  className="rounded-xl"
                  IconProps={{ variant: "Bulk" }}
                >
                  <Alert.Text className="text-white text-xs font-medium">
                    <Text>products.details.linePriceInfo.errorMessage</Text>
                  </Alert.Text>
                </Alert>
              )}
            </section>
          ) : null}
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
