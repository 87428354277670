import { SalePriceTemplateItemStep } from "../enums";

const salePriceTemplateItemSteps = [
  {
    name: "types.salePriceTemplateItemSteps.system1",
    id: SalePriceTemplateItemStep.System1,
  },
  {
    name: "types.salePriceTemplateItemSteps.system2",
    id: SalePriceTemplateItemStep.System2,
  },
  {
    name: "types.salePriceTemplateItemSteps.automaticDiscounts",
    id: SalePriceTemplateItemStep.AutomaticDiscounts,
  },
  {
    name: "types.salePriceTemplateItemSteps.catalogCalculation",
    id: SalePriceTemplateItemStep.CatalogCalculation,
  },
  {
    name: "types.salePriceTemplateItemSteps.optionalDiscounts",
    id: SalePriceTemplateItemStep.OptionalDiscounts,
  },
  {
    name: "types.salePriceTemplateItemSteps.individualDiscounts",
    id: SalePriceTemplateItemStep.IndividualDiscounts,
  },
];
export default salePriceTemplateItemSteps;
