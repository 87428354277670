import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { ProductDetailsContext } from "..";
import {
  Button,
  Form,
  InputGroup,
  Select,
  Text,
  Wrapper,
} from "../../../../components";
import { rules, types } from "../../../../constants";
import { AmountCalculationType } from "../../../../enums";
import { useAxios } from "../../../../hooks";

export default function SalesPersonCommission() {
  const { axios, loading } = useAxios();
  const { product, setProduct } = useContext(ProductDetailsContext);
  const initAmount = String(product.salesPersonCommission?.amount ?? "");
  const initCalculationType =
    product.salesPersonCommission?.calculationType ??
    AmountCalculationType.Amount;
  const [amount, setAmount] = useState(initAmount);
  const [calculationType, setCalculationType] = useState(initCalculationType);
  const hasAmount = !!Number(amount);
  const isPercent = calculationType === AmountCalculationType.Percent;
  const submit = () => {
    const url = `/salesservice/api/salestoolspimproduct/${product.id}/update-sales-person-commission`;
    const body = { amount: hasAmount ? Number(amount) : null, calculationType };
    axios.post(url, body).then(() => {
      const message = "products.salesPersonCommission.successMessage";
      toast.success(message);
      const salesPersonCommission = hasAmount
        ? {
            amount: body.amount ?? 0,
            calculationType: body.calculationType,
          }
        : null;
      setProduct((p) => ({ ...p!, salesPersonCommission }));
    });
  };
  return (
    <Form onSubmit={submit} className="w-full">
      <Wrapper>
        <Wrapper.Header>
          <h6 className="text-base text-dark font-medium">
            <Text>products.salesPersonCommission.title</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <InputGroup
            label="products.salesPersonCommission.amount"
            value={amount}
            setValue={setAmount}
            rules={isPercent ? rules.optionalPercent : []}
            className="md:col-span-2"
          />
          <Select
            label="products.salesPersonCommission.type"
            value={calculationType}
            setValue={setCalculationType}
            items={types.amountCalculation}
          />
        </Wrapper.Body>
        <Wrapper.Footer className="flex items-center justify-end">
          <Button type="submit" variant="primary" loading={loading.post}>
            <Text>products.salesPersonCommission.submitButton</Text>
          </Button>
        </Wrapper.Footer>
      </Wrapper>
    </Form>
  );
}
