import * as IconSax from "iconsax-react";
import { ComponentProps, ReactNode, useContext, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { IconVariantType, iconNameType, variantType } from "../types";
import { IconButtonContext } from "./_IconButton";
type iconProps = {
  name: iconNameType;
  variant?: IconVariantType;
  size?: number | string;
  className?: string;
};
type IconBoxRoundedType =
  | {
      circle?: boolean;
      rounded?: never;
    }
  | {
      circle?: never;
      rounded?: boolean;
    };
type iconBoxProps = {
  children: ReactNode;
  variant?: variantType;
  className?: string;
} & IconBoxRoundedType;
function Icon({
  name,
  variant,
  size = "1.25em",
  className = "",
  ...props
}: iconProps & Omit<ComponentProps<"svg">, "ref">) {
  const iconButtonContext = useContext(IconButtonContext);
  const Component = useMemo(() => IconSax[name], [name]);
  return (
    <Component
      variant={variant}
      size={size}
      className={twMerge(
        "inline-block align-middle",
        iconButtonContext?.iconClassName,
        className
      )}
      {...props}
    />
  );
}
function IconWrapper({
  children,
  variant = "primary",
  className = "",
  circle = false,
  rounded = false,
  ...props
}: iconBoxProps & Omit<ComponentProps<"div">, keyof iconBoxProps>) {
  const handleVariant = useMemo(() => {
    if (!variant) return "";
    type variantsType = {
      [key in Exclude<variantType, null>]: string;
    };
    const variants: variantsType = {
      dark: "bg-dark text-dark",
      light: "bg-placeholder text-placeholder",
      danger: "bg-danger text-danger",
      primary: "bg-primary text-primary",
      secondary: "bg-secondary text-secondary",
      info: "bg-info text-info",
      success: "bg-success text-success",
      warning: "bg-warning text-warning",
      white: "bg-white text-white",
    };
    return `${variants[variant]} bg-opacity-10`;
  }, [variant]);
  const handleRounded = useMemo(() => {
    if (circle) return "rounded-full";
    if (rounded) return "rounded";
    return "rounded-full";
  }, [circle, rounded]);
  return (
    <div
      className={twMerge(
        "flex-center size-fit p-2",
        handleRounded,
        handleVariant,
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
Icon.Wrapper = IconWrapper;
export default Icon;
