import { Dispatch, Fragment, useRef } from "react";
import toast from "react-hot-toast";
import { Button, Icon, Modal, Text } from "../../../components";
import { CRMTaskStatusReason } from "../../../enums";
import { useAxios, useToggle } from "../../../hooks";
import { Task } from "../../../types";

type Props = {
  task: Task.Details | null;
  onSubmitted: Dispatch<string>;
};

export default function ChangeStatusSection({ task, onSubmitted }: Props) {
  const statusRef = useRef<CRMTaskStatusReason | null>(null);
  const { axios, loading } = useAxios();
  const [showConfirm, toggleConfirm] = useToggle(false);
  const isCanceled = statusRef.current === CRMTaskStatusReason.Cancelled;
  const isCompleted = statusRef.current === CRMTaskStatusReason.Completed;

  const onCancel = () => {
    statusRef.current = CRMTaskStatusReason.Cancelled;
    toggleConfirm();
  };
  const onComplete = () => {
    statusRef.current = CRMTaskStatusReason.Completed;
    toggleConfirm();
  };

  const submit = () => {
    if (!task?.taskId) return;
    const status = statusRef.current;
    const url = `/channelmanagerservice/api/activities/task/${task.taskId}/status/${status}`;
    axios.patch(url).then(() => {
      toast.success("task.changeStatus.successMessage");
      onSubmitted(task.taskId);
      toggleConfirm();
    });
  };
  return (
    <Fragment>
      <Button type="button" variant="danger" onClick={onCancel}>
        <Text>task.details.cancelButton</Text>
      </Button>
      <Button type="button" variant="success" onClick={onComplete}>
        <Text>task.details.completeButton</Text>
      </Button>
      <Modal isOpen={showConfirm} toggle={toggleConfirm}>
        <Modal.Header>
          <h6 className="text-base font-semibold text-dark">
            <Text>task.changeStatus.title</Text>
          </h6>
        </Modal.Header>
        <Modal.Body>
          <p className="flex items-center gap-2 text-sm text-gray-900 font-medium py-5">
            <Icon name="TaskSquare" className="!size-6 text-primary" />
            <span>{task?.subject}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end gap-4">
          {isCompleted ? (
            <Fragment>
              <Button
                type="button"
                variant="danger"
                disabled={loading.update}
                onClick={toggleConfirm}
              >
                <Text>task.changeStatus.cancelButton</Text>
              </Button>
              <Button
                type="button"
                variant="success"
                loading={loading.update}
                onClick={submit}
              >
                <Text>task.changeStatus.completedButton</Text>
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button
                type="button"
                variant="primary"
                light
                disabled={loading.update}
                onClick={toggleConfirm}
              >
                <Text>task.changeStatus.discardButton</Text>
              </Button>
              <Button
                type="button"
                variant="danger"
                loading={loading.update}
                onClick={submit}
              >
                <Text>task.changeStatus.canceledButton</Text>
              </Button>
            </Fragment>
          )}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
