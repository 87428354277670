import { Fragment, useContext, useEffect, useState } from "react";
import { SupplierDetailsContext } from "..";
import { Loading, NoItems, Text, Wrapper } from "../../../../components";
import { types } from "../../../../constants";
import { useAxios, useConverters, useTranslate } from "../../../../hooks";
import { Supplier } from "../../../../types";

type Data = Supplier.HolidayItem;

export default function Settings() {
  const translate = useTranslate();
  const { supplier } = useContext(SupplierDetailsContext);
  const { convertDate } = useConverters();
  const { axios, loading } = useAxios();
  const [holidays, setHolidays] = useState<Data[]>([]);
  const hasHolidays = !!holidays.length;
  const durationUnitName =
    types.supplierShippingDurationUnit.find(
      (e) => e.id === supplier?.shipping?.durationUnit
    )?.name ?? "";

  const holidayItems = (e: Data) => [
    { label: "suppliers.settings.startDate", value: convertDate(e.startDate) },
    { label: "suppliers.settings.endDate", value: convertDate(e.endDate) },
    {
      label: "suppliers.settings.lastOrderDate",
      value: convertDate(e.lastOrderDate),
    },
    {
      label: "suppliers.settings.earliestShipDate",
      value: convertDate(e.earliestShipDate),
    },
  ];
  const shippingItems = [
    {
      label: "suppliers.settings.defaultMinimumShippingTime",
      value: (
        <Text
          value={supplier?.shipping?.minimumShippingDuration ?? 0}
          unit={translate(durationUnitName)}
        >
          suppliers.settings.defaultMinimumShippingTimeValue
        </Text>
      ),
    },
    {
      label: "suppliers.settings.minimumResponseTime",
      value: (
        <Text value={supplier?.shipping?.minimumResponseTime ?? 0}>
          suppliers.settings.minimumResponseTimeValue
        </Text>
      ),
    },
  ];

  const getHolidays = () => {
    const supplierId = supplier?.supplierId;
    if (!supplierId) return;
    const url = "/salesservice/api/supplierholidays";
    const config = { params: { supplierId } };
    axios.get(url, config).then(({ data }) => {
      setHolidays(data);
    });
  };
  useEffect(getHolidays, [supplier?.supplierId]);
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Header className="border-b-0">
          <h2 className="text-dark text-sm font-medium">
            <Text>suppliers.settings.holydaysTitle</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          {loading.get ? (
            <Loading.SupplierHolidays />
          ) : !hasHolidays ? (
            <NoItems className="col-span-full" />
          ) : (
            holidays.map((holiday) => (
              <section
                key={holiday.id}
                className="w-full border rounded p-2.5 space-y-3"
              >
                <h3 className="text-sm text-dark font-medium">
                  {holiday.title}
                </h3>
                <ul className="w-full divide-y">
                  {holidayItems(holiday).map((e) => (
                    <li
                      key={e.label}
                      className="flex items-start gap-5 py-3 first:pt-0 last:pb-0"
                    >
                      <h6 className="flex-1 text-secondary text-sm font-normal">
                        <Text>{e.label}</Text>
                      </h6>
                      <p className="flex-[2] text-gray-900 text-sm font-normal">
                        {e.value}
                      </p>
                    </li>
                  ))}
                </ul>
              </section>
            ))
          )}
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header className="border-b-0">
          <h2 className="text-dark text-sm font-medium">
            <Text>suppliers.settings.shippingTitle</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body>
          <ul className="w-full divide-y">
            {shippingItems.map((e) => (
              <li
                key={e.label}
                className="flex items-start gap-5 py-3 first:pt-0 last:pb-0"
              >
                <h6 className="flex-1 text-secondary text-sm font-normal">
                  <Text>{e.label}</Text>
                </h6>
                <p className="flex-[2] text-gray-900 text-sm font-normal">
                  {e.value}
                </p>
              </li>
            ))}
          </ul>
        </Wrapper.Body>
      </Wrapper>
    </Fragment>
  );
}
