import { convertAddress } from "../methods";
import { Address, Location } from "../types";
import useAxios from "./useAxios";

type validateResult = {
  suggested: Address | null;
  isValid: boolean;
};

export default function useValidateAddress(): [
  (address: Address | null) => Promise<validateResult>,
  boolean
] {
  const { axios, loading } = useAxios();
  const validate = async (address: Address | null): Promise<validateResult> => {
    const hasCountryAndPostalCode = [
      address?.postalCode,
      address?.country,
    ].every(Boolean);
    const url = "/addressservice/api/address/search";
    const config = { params: { query: convertAddress(address) }, cache: true };
    if (!address) return Promise.resolve({ isValid: false, suggested: null });
    if (!hasCountryAndPostalCode)
      return Promise.resolve({ isValid: false, suggested: null });
    return await axios
      .get(url, config)
      .then(({ data }) => {
        const result = data.results?.[0]?.address ?? null;
        const hasResult = !!result;
        const suggestedAddress = hasResult
          ? {
              name: address.name ?? null,
              street: (result?.streetName as string) ?? null,
              number:
                (result?.streetNumber as string) ?? address.number ?? null,
              postalCode: (result?.postalCode as string) ?? null,
              city: (result?.municipality as string) ?? null,
              state: (result?.countrySubdivision as string) ?? null,
              country: (result?.country as string) ?? null,
              countryCode: (result.countryCode as string) ?? null,
              countryCodeISO3: (result.countryCodeISO3 as string) ?? null,
              countrySubdivision: (result.countrySubdivision as string) ?? null,
              countrySubdivisionName:
                (result.countrySubdivisionName as string) ?? null,
              location: (data.results?.[0]?.position as Location) ?? null,
            }
          : null;
        const addressKeys: (keyof Address)[] = [
          "postalCode",
          "city",
          "street",
          "state",
          "countryCode",
        ];
        const addressIsValid =
          hasResult &&
          addressKeys.every((key) => address[key] === suggestedAddress?.[key]);
        return { suggested: suggestedAddress, isValid: addressIsValid };
      })
      .catch(() => ({ suggested: null, isValid: false }));
  };
  return [validate, loading.get];
}
