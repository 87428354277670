import { Fragment, useContext, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { CreateIncidentContext } from ".";
import {
  AddressViewer,
  Button,
  Icon,
  InputGroup,
  Text,
  Wrapper,
} from "../../../components";
import { rules } from "../../../constants";
import { ChooseCustomer } from "../../../containers";
import { SelectedCustomer } from "../../../containers/_ChooseCustomer";
import { CustomerType } from "../../../enums";
import { useAxios, useToggle } from "../../../hooks";
import { showCallNumber } from "../../../methods";
import { Subject } from "../../../types";

export default function BasicInfo() {
  const {
    data,
    setData,
    customer,
    setCustomer,
    canEditCustomer,
    setOrder,
    setLineItem,
    branch,
  } = useContext(CreateIncidentContext);
  const { axios, loading } = useAxios();
  const [subjects, setSubjects] = useState<Subject.Item[]>([]);
  // const [showSelectStore, toggleSelectStore] = useToggle(false);
  const [showCustomerList, toggleCustomerList] = useToggle(false);

  const hasCustomer = !!customer;
  const isCompany = data.customerType === CustomerType.Company;
  // const handleSelectStore = (e: { name: string; id: string }) => {
  //   const isNewStore = e.id !== data.branchId;
  //   if (!isNewStore) return;
  //   setData((p) => ({ ...p, branchId: e.id }));
  //   setOrder(null);
  //   setLineItem(null);
  // };
  const handleSetCustomer = (e: SelectedCustomer) => {
    const isNewCustomer = e.id !== customer?.id;
    if (!isNewCustomer) return;
    setCustomer(e);
    setOrder(null);
    setLineItem(null);
  };
  const getSubjects = () => {
    const url = "/channelmanagerservice/api/incidents/subject";
    axios.get(url).then(({ data }) => {
      setSubjects(data);
    });
  };
  useEffect(getSubjects, []);
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Body className="space-y-8 max-w-screen-md mx-auto">
          <section className="grid grid-cols-2 md:grid-cols-3 gap-4">
            <h6 className="col-span-full text-dark text-base font-medium">
              <Text>incident.selectType.selectIncidentTypeTitle</Text>
            </h6>
            {loading.get
              ? [...Array(6).keys()].map((key) => (
                  <div key={key} className="w-full h-[5.25rem] loading"></div>
                ))
              : subjects.map((e) => {
                  const isActive = e.subjectId === data.subjectId;
                  return (
                    <Button
                      key={e.subjectId}
                      type="button"
                      variant="primary"
                      outline
                      className={twMerge(
                        "text-start border border-current bg-primary/5 px-4 py-8 font-medium capitalize whitespace-normal break-words",
                        !isActive && "bg-[#FBFCFD] border-[#DEE8F1] text-dark"
                      )}
                      onClick={() =>
                        setData((p) => ({ ...p, subjectId: e.subjectId }))
                      }
                    >
                      {e.title}
                    </Button>
                  );
                })}
          </section>
          {/* <section className="border rounded p-2 space-y-4">
            <h6 className="text-base text-dark">
              <Text>incident.form.store</Text>
            </h6>
            <div className="flex items-start gap-2">
              <Icon name="Building" size={25} className="text-primary" />
              <div className="flex-1">
                <h6 className="text-base text-dark">{branch?.title}</h6>
                <AddressViewer
                  withIcon
                  className="whitespace-normal text-sm text-secondary truncate"
                  address={branch?.address}
                />
              </div>
              <button
                type="button"
                className="text-warning text-sm self-center"
                onClick={toggleSelectStore}
              >
                <Icon
                  name="ArrowForwardSquare"
                  variant="Bold"
                  size={25}
                  className="text-warning"
                />
                <Text>incident.form.changeStoreBtn</Text>
              </button>
            </div>
          </section> */}
          {!!hasCustomer ? (
            <section className="border rounded p-2 space-y-4">
              <h6 className="text-base text-dark">
                <Text>incident.form.customer</Text>
              </h6>
              <div className="flex items-start gap-2">
                <Icon
                  name={isCompany ? "Home2" : "ProfileCircle"}
                  size={25}
                  className="text-primary"
                />
                <div className="flex-1">
                  <h6 className="text-base text-dark">{customer.name}</h6>
                  {customer.cimNumber && (
                    <p className="text-sm text-secondary">
                      #{customer.cimNumber}
                    </p>
                  )}
                  {
                    <p className="text-sm text-secondary">
                      {[
                        customer.emailAddress,
                        showCallNumber(customer.phoneNumber),
                      ]
                        .filter(Boolean)
                        .join(" | ")}
                    </p>
                  }
                  <AddressViewer
                    withIcon
                    className="whitespace-normal text-sm text-secondary truncate"
                    address={customer.address}
                  />
                </div>
                {canEditCustomer && (
                  <button
                    type="button"
                    className="text-warning text-sm self-center"
                    onClick={toggleCustomerList}
                  >
                    <Icon
                      name="ArrowForwardSquare"
                      variant="Bold"
                      size={25}
                      className="text-warning"
                    />
                    <Text>incident.form.changeCustomerBtn</Text>
                  </button>
                )}
              </div>
            </section>
          ) : (
            <section className="border rounded p-2">
              <InputGroup
                label="incident.form.customer"
                value=""
                placeholder="incident.form.customerPlaceholder"
                rules={rules.required}
                onClick={toggleCustomerList}
                readOnly
                append={
                  <span className="input-group-text text-primary">
                    <Icon name="User" variant="Bold" size={22} />
                  </span>
                }
              />
            </section>
          )}
        </Wrapper.Body>
      </Wrapper>
      <ChooseCustomer
        isOpen={showCustomerList}
        toggle={toggleCustomerList}
        onSelect={handleSetCustomer}
      />
    </Fragment>
  );
}
