const customers = {
  addresses: {
    shippingAddresses:"Shipping addresses",
    billingAddresses:"Billing addresses",
    mainAddress:"Main Address",
    shippingAddress:"Shipping Address",
    billingAddress:"Billing Address",
    noItem:{
      shipping:"No shipping address added",
      billing:"No billing address added"
    }
  },
};
export default customers;
