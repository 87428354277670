import { cloneDeep } from "lodash";
import { useContext, useEffect } from "react";
import { AddCustomerDataContext } from ".";
import { InputGroup, Select, Text, Wrapper } from "../../../components";
import { rules, types } from "../../../constants";
import { Customer, rulesType } from "../../../types";

type sectionItem = {
  label: string;
  items: formItem[];
};
type formItem = {
  state: keyof Customer.Create;
  placeholder: string;
  rules?: rulesType;
  [key: string]: any;
};
export default function BasicInfo() {
  const { data, setData } = useContext(AddCustomerDataContext);
  const formControls: sectionItem[] = [
    {
      label: "formControls.firstName",
      items: [
        {
          state: "firstName",
          placeholder: "placeholders.firstName",
          rules: rules.required,
          autoCapitalize: true,
        },
      ],
    },
    {
      label: "formControls.lastName",
      items: [
        {
          state: "lastName",
          placeholder: "placeholders.lastName",
          rules: rules.required,
          autoCapitalize: true,
        },
      ],
    },
    {
      label: "formControls.gender",
      items: [
        {
          Element: Select,
          state: "gender",
          placeholder: "placeholders.gender",
          items: types.gender,
        },
      ],
    },
    {
      label: "formControls.salutation",
      items: [
        {
          Element: Select,
          state: "salutation",
          placeholder: "placeholders.salutation",
          items: types.salutation,
        },
      ],
    },
    {
      label: "formControls.salutationTitle",
      items: [
        {
          Element: Select,
          state: "salutationTitle",
          placeholder: "placeholders.salutationTitle",
          items: types.salutationTitle,
        },
      ],
    },
    {
      label: "formControls.birthDate",
      items: [
        {
          state: "birthdate",
          placeholder: "placeholders.birthDate",
          type: "date",
        },
      ],
    },
  ];
  const handleSetData = (key: keyof Customer.Create) => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        // @ts-ignore: Unreachable code error
        data[key] = value;
        return data;
      });
  };
  const handleSetDefaultName = () => {
    return () => {
      setData((p) => {
        const data = cloneDeep(p);
        data.address.name ||= [data.firstName, data.lastName].join(" ");
        return data;
      });
    };
  };
  useEffect(handleSetDefaultName, []);
  return (
    <Wrapper>
      <Wrapper.Body className="space-y-6 max-w-screen-md mx-auto">
        {/* <p className="text-secondary">
          <Text>If you need more info, please check out</Text>{" "}
          <Link to="/" className="text-primary">
            <Text>FAQ Page</Text>
          </Link>
        </p> */}
        {formControls.map((e) => (
          <div key={e.label} className="flex w-full gap-2">
            <span className="flex-[2] leading-11 truncate">
              <Text>{e.label}</Text>
            </span>
            <div className="flex-[5] flex">
              {e.items.map(
                ({ Element = InputGroup, state, flex, ...e }: any) => (
                  <div key={state} style={{ flex: flex || 1 }}>
                    <Element
                      // @ts-ignore: Unreachable code error
                      value={data[state]}
                      setValue={handleSetData(state)}
                      {...e}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </Wrapper.Body>
    </Wrapper>
  );
}
