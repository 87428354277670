import { useContext } from "react";
import {
  Badge,
  Drawer,
  Icon,
  Text,
  Types,
  Wrapper,
} from "../../../../components";
import { togglePropsType } from "../../../../types";
import { LineItemContext } from "./LineItemRow";

export default function LineItemNoteDrawer({
  isOpen,
  toggle,
}: togglePropsType) {
  const { lineItem, hasArticleNumber, hasShippingMethod } =
    useContext(LineItemContext);
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="bg-[#F5F7F9]">
        <Drawer.Header>
          <h6 className="text-start text-dark text-base font-medium">
            <Text>pickup.lineItemNote.title</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Wrapper>
            <Wrapper.Body>
              <section className="flex-1 flex items-center text-start gap-4">
                <div className="flex-center size-11">
                  <Icon.Wrapper variant="primary">
                    <Icon name="Box1" variant="Bold" />
                  </Icon.Wrapper>
                </div>
                <div className="flex-1 space-y-1">
                  <h6 className="w-full text-base font-normal text-balance max-w-72 whitespace-normal line-clamp-2">
                    {lineItem.title}
                  </h6>
                  {hasArticleNumber && (
                    <p className="block text-secondary">
                      #{lineItem.product?.articleNumber}
                    </p>
                  )}
                  <p className="space-x-1 !mt-2">
                    <Types.LineItem id={lineItem.lineItemType} />
                    <Badge
                      variant={hasShippingMethod ? "dark" : "warning"}
                      className="p-1 text-xs leading-none"
                    >
                      <Icon name="TruckFast" variant="Bulk" />
                      {hasShippingMethod ? (
                        lineItem.shippingMethodCode
                      ) : (
                        <Icon name="Danger" variant="Bold" />
                      )}
                    </Badge>
                  </p>
                </div>
              </section>
            </Wrapper.Body>
          </Wrapper>
          <Wrapper>
            <Wrapper.Header className="border-b-0 flex items-center gap-4">
              <h6 className="flex-1 text-sm text-dark font-medium">
                <Text>pickup.lineItemNote.lineItemNote</Text>
              </h6>
            </Wrapper.Header>
            <Wrapper.Body>
              <p className="text-sm text-gray-900 font-medium">
                {lineItem.lineItemNote || "--"}
              </p>
            </Wrapper.Body>
          </Wrapper>
          <Wrapper>
            <Wrapper.Header className="border-b-0">
              <h6 className="text-sm text-dark font-medium">
                <Text>pickup.lineItemNote.customerNote</Text>
              </h6>
            </Wrapper.Header>
            <Wrapper.Body>
              <p className="text-sm text-gray-900 font-medium">
                {lineItem.customerNote || "--"}
              </p>
            </Wrapper.Body>
          </Wrapper>
          <Wrapper>
            <Wrapper.Header className="border-b-0">
              <h6 className="text-sm text-dark font-medium">
                <Text>pickup.lineItemNote.supplierNote</Text>
              </h6>
            </Wrapper.Header>
            <Wrapper.Body>
              <p className="text-sm text-gray-900 font-medium">
                {lineItem.supplierNote || "--"}
              </p>
            </Wrapper.Body>
          </Wrapper>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
