import { cloneDeep } from "lodash";
import { useContext, useEffect } from "react";
import { AddCompanyContext } from ".";
import { InputGroup, Text, Toggler, Wrapper } from "../../../components";
import { rules } from "../../../constants";
import { Company } from "../../../types";

export default function BasicInfo() {
  const { data, setData } = useContext(AddCompanyContext);
  const taxIdIsRequired = !!data.eligibleForTaxDeduction;
  const handleSetData = (key: keyof Company.Create) => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        // @ts-ignore: Unreachable code error
        data[key] = value;
        return data;
      });
  };
  const handleSetDefaultName = () => {
    return () => {
      setData((p) => {
        const data = cloneDeep(p);
        data.address.name ||= data.companyName;
        return data;
      });
    };
  };
  useEffect(handleSetDefaultName, []);
  return (
    <Wrapper>
      <Wrapper.Body className="space-y-6">
        <div className="flex gap-2">
          <span className="flex-[2] leading-11 truncate">
            <Text>company.create.basicInfo.companyName</Text>
          </span>
          <InputGroup
            value={data.companyName}
            setValue={handleSetData("companyName")}
            rules={rules.required}
            className="flex-[5]"
          />
        </div>
        <div className="flex gap-2">
          <span className="flex-[2] leading-11 truncate">
            <Text>company.create.basicInfo.companyRegistrationNumber</Text>
          </span>
          <InputGroup
            value={data.companyRegistrationNumber}
            setValue={handleSetData("companyRegistrationNumber")}
            // rules={rules.required}
            className="flex-[5]"
          />
        </div>
        <hr className="w-full bg-gray" />
        <div className="flex gap-2">
          <span className="flex-[2] leading-11 truncate">
            <Text>company.create.basicInfo.eligibleForTaxDeduction</Text>
          </span>
          <Toggler
            value={!!data.eligibleForTaxDeduction}
            setValue={(eligibleForTaxDeduction) =>
              setData((p) => ({ ...p, eligibleForTaxDeduction }))
            }
            className="w-auto flex-[5]"
          />
        </div>
        <div className="flex gap-2">
          <span className="flex-[2] leading-11 truncate">
            <Text>company.create.basicInfo.taxId</Text>
          </span>
          <InputGroup
            value={data.taxId}
            setValue={handleSetData("taxId")}
            className="flex-[5]"
            rules={taxIdIsRequired ? rules.required : []}
          />
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
