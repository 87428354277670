import { flushSync } from "react-dom";

export default function useStartViewTransition() {
  const startViewTransition = (callback: () => void) => {
    // @ts-ignore
    const supported = !!document.startViewTransition;
    if (!supported) return callback();
    // @ts-ignore
    document.startViewTransition(() => {
      flushSync(() => {
        callback();
      });
    });
  };
  return startViewTransition;
}
