import { useCallback, useMemo } from "react";
import { Text } from "../../../../components";
import { types } from "../../../../constants";
import { useConverters } from "../../../../hooks";
import { cn } from "../../../../methods";
import { SellOffProduct } from "../../../../types";

type Props = {
  timeline: SellOffProduct.TimelineItem;
};

export default function TimelineItem({ timeline }: Props) {
  const { convertDate, convertAmount } = useConverters();
  // const createdItemAt = convertDate(timeline.createdItemAt);
  // const isCurrent = !!createdItemAt
  // last bool createdAt is current
  const actionType = timeline.actionType;
  const action =
    types.sellOffProductTimelineAction.find((e) => e.id === actionType) ?? null;
  const details = useMemo(() => {
    const performedBy = timeline.performedBy;
    const performedAt = timeline.performedAt;
    if (!performedBy && !performedAt) return null;
    if (!performedBy) return convertDate(timeline.performedAt);
    return `sellOffProducts.details.timelinePerformed`;
  }, [timeline, convertDate]);
  const hasDynamicFields = !!timeline.dynamicFields?.length;
  const formattedField = useCallback(
    (e: { key: string; value: string }) => {
      if (e.key === "Duration") return `sellOffProducts.details.timelineDays`;
      if (e.key === "Discount") return `${e.value}%`;
      if (e.key === "SellerCommission") return `${e.value}%`;
      if (e.key === "OriginalPrice") return convertAmount(Number(e.value));
      if (e.key === "PriceWithDiscount") return convertAmount(Number(e.value));
      if (e.key === "SoldPrice") return convertAmount(Number(e.value));
      if (e.key === "OriginalPrice") return convertAmount(Number(e.value));
      if (e.key === "RealSoldPrice") return convertAmount(Number(e.value));
      if (e.key === "ExpectedStartDate") return convertDate(e.value);
      return e.value;
    },
    [convertAmount, convertDate]
  );
  return (
    <li className="relative flex items-start gap-2.5 pb-20 last:pb-0 [&:last-child>.line]:hidden">
      <div className="line absolute left-0 top-0 flex-center w-6 h-full">
        <div className="w-0.5 h-full bg-gray-100"></div>
      </div>
      <span
        className={cn(
          "relative size-6 border-[3px] border-white rounded-full bg-current",
          action?.textClasses
        )}
      ></span>
      <div className={cn("flex-1 rounded p-4 space-y-3", action?.bgClasses)}>
        <h6 className="text-dark text-sm font-semibold">
          <Text>{action?.name ?? ""}</Text>
        </h6>
        <p className="text-gray-900 text-xs font-medium">{timeline.title}</p>
        <p className="text-[#7E8299] text-xs font-normal">
          <Text
            performedBy={timeline.performedBy}
            performedAt={convertDate(timeline.performedAt) ?? ""}
          >
            {details ?? ""}
          </Text>
        </p>
        {hasDynamicFields && (
          <ul>
            {timeline.dynamicFields?.map((e) => (
              <p key={e.key} className="text-[#7E8299] text-xs font-normal">
                <span>{e.key}:</span>{" "}
                <span>
                  <Text days={e.value}>{formattedField(e) ?? ""}</Text>
                </span>
              </p>
            ))}
          </ul>
        )}
      </div>
    </li>
  );
}
