import { CreateAxiosDefaults } from "axios";
import { createContext } from "react";
import { childrenProps } from "../types";

type Props = { config?: CreateAxiosDefaults } & childrenProps;
type Context = { config?: CreateAxiosDefaults };

export const AxiosContext = createContext<Context>({});

export default function AxiosProvider({ config, children }: Props) {
  return (
    <AxiosContext.Provider value={{ config }}>{children}</AxiosContext.Provider>
  );
}
