import { useContext } from "react";
import { OrderDetailsContext } from "..";
import { Wrapper } from "../../../../components";
import { SalesPersonCard, SecondSalesPersonCard } from "../../../../containers";

export default function SalesInfo() {
  const orderContext = useContext(OrderDetailsContext);
  const salesPerson = orderContext?.order.salesPerson;
  const secondSalesPerson = orderContext?.order.secondSalesPerson;
  const hasSalesPerson = !!salesPerson;
  const hasSecondSalesPerson = !!secondSalesPerson;
  return (
    <Wrapper>
      <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {hasSalesPerson && <SalesPersonCard salesPerson={salesPerson} />}
        {hasSecondSalesPerson && (
          <SecondSalesPersonCard salesPerson={secondSalesPerson} />
        )}
      </Wrapper.Body>
    </Wrapper>
  );
}
