import {
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import { OfferDetailsContext } from "..";
import noProductIcon from "../../../../assets/icons/no product.svg";
import {
  Button,
  Icon,
  SearchBox,
  Table,
  Text,
  Wrapper,
} from "../../../../components";
import { PimProductType } from "../../../../enums";
import { useConverters, useToggle } from "../../../../hooks";
import { LineItem } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";
import AddBundle from "./AddBundle";
import AddBundleCatalogue from "./AddBundleCatalogue";
import ConfigProduct from "./ConfigProduct";
import CreateBundleSubLineItem from "./CreateBundleSubLineItem";
import CreateFromCatalogue from "./CreateFromCatalogue";
import ImportBasket from "./ImportBasket";
import LineItemRow from "./LineItemRow";
import SearchProduct from "./SearchProduct";
import SearchSellOffProduct from "./SearchSellOffProduct";
import SelectProductModal from "./SelectProductModal";

type LineItemsContextType = {
  lineItems: LineItem.Item[];
  setLineItems: Dispatch<SetStateAction<LineItem.Item[]>>;
  isCanceled: boolean | null;
  updateLineItems: () => Promise<LineItem.Item[]>;
  updateLineItemsLoading: boolean;
};

export const LineItemsContext = createContext<LineItemsContextType>({
  lineItems: [],
  setLineItems: () => {},
  isCanceled: null,
  updateLineItems: () => Promise.resolve([]),
  updateLineItemsLoading: false,
});

export default function LineItems() {
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const isOffer = !!offer;
  const isOrder = !!order;

  const lineItems = useMemo(() => {
    return offerContext?.lineItems || orderContext?.lineItems || [];
  }, [offerContext?.lineItems, orderContext?.lineItems]);
  const setLineItems =
    offerContext?.setLineItems || orderContext?.setLineItems || (() => {});
  const getLineItemsLoading =
    offerContext?.getLineItemsLoading || orderContext?.getLineItemsLoading;
  const updateLineItems =
    offerContext?.updateLineItems ||
    orderContext?.updateLineItems ||
    (() => Promise.resolve([]));
  const updateLineItemsLoading =
    offerContext?.updateLineItemsLoading ||
    orderContext?.updateLineItemsLoading ||
    false;

  const { convertAmount } = useConverters();
  const [keyword, setKeyword] = useState<string | null>(null);
  const [isCanceled, setIsCanceled] = useState<boolean | null>(null);
  const [parentId, setParentId] = useState<string | null>(null);
  const [pimProductType, setPimProductType] = useState<PimProductType>(
    PimProductType.Standard
  );
  const [showSelectProduct, toggleSelectProduct] = useToggle(false);
  const [showConfigProduct, toggleConfigProduct] = useToggle(false);
  const [showSearchProduct, toggleSearchProduct] = useToggle(false);
  const [showSearchSellOffProduct, toggleSearchSellOffProduct] =
    useToggle(false);
  const [showImportBasket, toggleImportBasket] = useToggle(false);
  const [showAddBundle, toggleAddBundle] = useToggle(false);
  const [showAddBundleCatalogue, toggleAddBundleCatalogue] = useToggle(false);
  const [showCreateFromCatalogue, toggleCreateFromCatalogue] = useToggle(false);
  const parent = lineItems.find((e) => e.id === parentId) ?? null;
  const sortedLineItems = useMemo(() => {
    const parents = lineItems.filter((e) => !e.parentId);
    const subs = lineItems.filter((e) => !!e.parentId);
    const items = parents.map((parent) => [
      parent,
      ...subs.filter((e) => e.parentId === parent.id),
    ]);
    return items.flat();
  }, [lineItems]);
  const filteredLineItems = useMemo(() => {
    return sortedLineItems
      .filter((e) => isCanceled === null || e.isCanceled === isCanceled)
      .filter((e) =>
        [
          e.title,
          e.product?.articleNumber,
          e.originalPrice,
          e.unitPriceWithDiscount,
          e.totalSalePrice,
        ]
          .map((e) => String(e ?? "").toUpperCase())
          .some((e) => e.includes(keyword?.toUpperCase() ?? ""))
      );
  }, [sortedLineItems, keyword, isCanceled]);

  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;
  const totalAmount = offer?.totalAmount || order?.totalAmount;
  const hasLineItems = !!filteredLineItems.length;

  const isCanceledItem = [
    { label: "button.allOrderLineItems", value: null },
    { label: "button.activeOrderLineItems", value: false },
    { label: "button.canceledOrderLineItems", value: true },
  ];

  const clearParentId = () => {
    setParentId(null);
  };
  const handleToggleSelectProduct = () => {
    setParentId(null);
    toggleSelectProduct();
  };
  const handleSetParentId = (id: string) => {
    setParentId(id);
    toggleSelectProduct();
  };
  const handleToggleConfigProduct = () => {
    toggleConfigProduct();
    clearParentId();
  };
  const handleToggleSearchProduct = () => {
    toggleSearchProduct();
    clearParentId();
  };
  const handleToggleSearchSellOffProduct = () => {
    toggleSearchSellOffProduct();
    clearParentId();
  };
  const handleToggleImportBasket = () => {
    toggleImportBasket();
    clearParentId();
  };
  const handleSearchProduct = (productType: PimProductType) => {
    setPimProductType(productType);
    toggleSearchProduct();
  };
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        {isOrder &&
          isCanceledItem.map((e) => {
            const active = e.value === isCanceled;
            const lineItemsCount = lineItems.filter(
              ({ isCanceled }) => e.value === null || isCanceled === e.value
            ).length;
            return (
              <Button
                key={e.label}
                type="button"
                className={twMerge(
                  "text-sm",
                  !active && "text-secondary border-white"
                )}
                variant={active ? "primary" : "white"}
                onClick={() => setIsCanceled(e.value)}
              >
                <Text>{e.label}</Text> ({lineItemsCount})
              </Button>
            );
          })}
        <SearchBox
          onSubmit={setKeyword}
          className={twMerge(
            "w-auto basis-1/3",
            isOffer && "me-auto",
            isOrder && "ms-auto"
          )}
        />
        {isEnabled && (
          <Button onClick={handleToggleSelectProduct}>
            <Icon name="Add" size={20} className="mr-1" />
            <Text>button.addLineItem</Text>
          </Button>
        )}
      </section>
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              {!getLineItemsLoading && (
                <thead>
                  <tr>
                    <td className="!ps-[3.5rem]">
                      <Text>tableTitles.lineItemName</Text>
                    </td>
                    <td>
                      <Text>tableTitles.lineItemSupplier</Text>
                    </td>
                    <td>
                      <Text>tableTitles.lineItemUnitPrice</Text>
                    </td>
                    <td>
                      <Text>tableTitles.lineItemQuantity</Text>
                    </td>
                    <td>
                      <Text>tableTitles.lineItemPrice</Text>
                    </td>
                    {isEnabled && (
                      <td data-sticky>
                        <Text>tableTitles.lineItemAction</Text>
                      </td>
                    )}
                  </tr>
                </thead>
              )}
              <Table.Body loading={getLineItemsLoading}>
                {!hasLineItems ? (
                  <tr>
                    <td colSpan={6}>
                      <div className="w-2/5 mx-auto">
                        <img
                          className="block w-full mb-8"
                          src={noProductIcon}
                          alt="no product"
                        />
                        {isEnabled && (
                          <Button
                            className="block mx-auto"
                            onClick={handleToggleSelectProduct}
                          >
                            <Icon name="Add" size={20} className="mr-1" />
                            <Text>button.addLineItem</Text>
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : (
                  <LineItemsContext.Provider
                    value={{
                      lineItems,
                      setLineItems,
                      updateLineItems,
                      updateLineItemsLoading,
                      isCanceled,
                    }}
                  >
                    {filteredLineItems.map((lineItem) => {
                      const hasSubLineItem = !!lineItems.find(
                        (e) => e.parentId === lineItem.id
                      );
                      return (
                        <LineItemRow
                          key={lineItem.id}
                          lineItem={lineItem}
                          onSetParentId={handleSetParentId}
                          hasSubLineItem={hasSubLineItem}
                        />
                      );
                    })}
                  </LineItemsContext.Provider>
                )}
              </Table.Body>
            </Table>
          </Table.Wrapper>
          {hasLineItems && (
            <div className="flex items-center bg-gray-50 px-4 py-2 gap-4 rounded-md">
              <Icon
                name="DollarSquare"
                variant="Bold"
                size={24}
                className="text-dark"
              />
              <span className="mr-auto">
                <Text>global.totalPrice</Text>
              </span>
              <span>{convertAmount(totalAmount)}</span>
            </div>
          )}
        </Wrapper.Body>
      </Wrapper>
      {isEnabled && (
        <LineItemsContext.Provider
          value={{
            lineItems,
            setLineItems,
            updateLineItems,
            updateLineItemsLoading,
            isCanceled,
          }}
        >
          <SelectProductModal
            parent={parent ?? null}
            isOpen={showSelectProduct}
            toggle={toggleSelectProduct}
            onConfigProduct={toggleConfigProduct}
            onSearchProduct={handleSearchProduct}
            onSearchSellOffProduct={toggleSearchSellOffProduct}
            onImportBasket={toggleImportBasket}
            onAddBundle={toggleAddBundle}
            onAddBundleCatalogue={toggleAddBundleCatalogue}
            onCreateFromCatalogue={toggleCreateFromCatalogue}
          />
          <ConfigProduct
            parentId={parentId}
            isOpen={showConfigProduct}
            toggle={handleToggleConfigProduct}
          />
          <SearchProduct
            parentId={parentId}
            isOpen={showSearchProduct}
            toggle={handleToggleSearchProduct}
            pimProductType={pimProductType}
          />
          <SearchSellOffProduct
            parentId={parentId}
            isOpen={showSearchSellOffProduct}
            toggle={handleToggleSearchSellOffProduct}
          />
          <ImportBasket
            parentId={parentId}
            isOpen={showImportBasket}
            toggle={handleToggleImportBasket}
          />
          <AddBundle
            parentId={parentId}
            isOpen={showAddBundle}
            toggle={toggleAddBundle}
          />
          <AddBundleCatalogue
            parentId={parentId}
            isOpen={showAddBundleCatalogue}
            toggle={toggleAddBundleCatalogue}
          />
          <CreateFromCatalogue
            parentId={parentId}
            isOpen={!parent && showCreateFromCatalogue}
            toggle={toggleCreateFromCatalogue}
          />
          <CreateBundleSubLineItem
            parentId={parentId}
            parent={parent}
            isOpen={!!parent && showCreateFromCatalogue}
            toggle={toggleCreateFromCatalogue}
          />
        </LineItemsContext.Provider>
      )}
    </Fragment>
  );
}
