import { Fragment } from "react";
import { Icon } from "../../../../components";
import { dateFormats } from "../../../../constants";
import { useConverters } from "../../../../hooks";
import { Task } from "../../../../types";

type NoteItemProps = {
  note: Task.NoteItem;
  isFirst?: boolean;
};

export default function NoteItem({ note, isFirst = false }: NoteItemProps) {
  const { convertDate } = useConverters();
  const subject = note.subject;
  const hasSubject = !!subject;
  const splitSubject = subject?.split(" - ");
  const employeeName = splitSubject?.[0];
  const hasEmployeeName = !!employeeName;
  const employeeNumber = splitSubject?.[1];
  const hasEmployeeNumber = !!employeeNumber;
  return (
    <section className="flex items-start gap-3">
      <div>
        <span
          data-active={isFirst}
          className="relative flex-center border border-placeholder data-active:border-primary bg-white rounded-full size-[1.125rem] z-[1]"
        >
          <span
            data-active={isFirst}
            className="size-3 bg-placeholder data-active:bg-primary rounded-full"
          ></span>
        </span>
      </div>
      <div className="flex-1 flex flex-col lg:flex-row lg:items-stretch border border-[#DEEBF4] rounded gap-3 py-3">
        <div className="lg:flex-1 px-3 space-y-3">
          <p className="text-gray-900 text-xs font-medium">
            {convertDate(note.createdOn, dateFormats.fullDate)}
          </p>
          <p
            className="min-h-20 text-gray-900 text-sm font-normal text-justify whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: note.body }}
          ></p>
        </div>
        <span className="w-px h-auto bg-[#DEEBF4] hidden lg:block"></span>
        <div className="px-3 w-52 min-w-52">
          {hasSubject && (
            <Fragment>
              {hasEmployeeName && (
                <p>
                  <Icon name="UserTag" className="text-primary" size={20} />{" "}
                  <span className="text-dark text-sm font-medium">
                    {employeeName}
                  </span>
                </p>
              )}
              {hasEmployeeNumber && (
                <p className="text-secondary text-xs font-medium ps-5">
                  {" "}
                  #{employeeNumber}
                </p>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </section>
  );
}
