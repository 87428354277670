import { Fragment, useContext, useMemo } from "react";
import { OrderDetailsContext } from "..";
import { Icon, NoItems, Table, Text, Wrapper } from "../../../../components";
import { useConverters } from "../../../../hooks";
import LineItemRow from "./LineItemRow";

export default function LineItems() {
  const { order, lineItems } = useContext(OrderDetailsContext);
  const { convertAmount } = useConverters();
  const sortedLineItems = useMemo(() => {
    const parents = lineItems.filter((e) => !e.parentId);
    const subs = lineItems.filter((e) => !!e.parentId);
    const items = parents.map((parent) => [
      parent,
      ...subs.filter((e) => e.parentId === parent.id),
    ]);
    return items.flat();
  }, [lineItems]);

  const totalAmount = order?.totalAmount;
  const hasLineItems = !!sortedLineItems.length;

  return (
    <Fragment>
      <section className="flex items-center gap-4"></section>
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              <thead>
                <tr>
                  <td className="!ps-[3.5rem]">
                    <Text>pickup.details.lineItems.lineItems</Text>
                  </td>
                  <td>
                    <Text>pickup.details.lineItems.quantity</Text>
                  </td>
                  <td>
                    <Text>pickup.details.lineItems.unitPrice</Text>
                  </td>
                  <td>
                    <Text>pickup.details.lineItems.lineItemPrice</Text>
                  </td>
                  <td data-sticky className="!pe-[4rem]">
                    <Text>pickup.details.lineItems.action</Text>
                  </td>
                </tr>
              </thead>
              <Table.Body>
                {!hasLineItems ? (
                  <tr>
                    <td colSpan={5}>
                      <NoItems />
                    </td>
                  </tr>
                ) : (
                  <Fragment>
                    {sortedLineItems.map((lineItem) => {
                      const hasSubLineItem = lineItems.some(
                        (e) => e.parentId === lineItem.id
                      );
                      return (
                        <LineItemRow
                          key={lineItem.id}
                          lineItem={lineItem}
                          hasSubLineItem={hasSubLineItem}
                        />
                      );
                    })}
                  </Fragment>
                )}
              </Table.Body>
            </Table>
          </Table.Wrapper>
          {hasLineItems && (
            <div className="flex items-center bg-gray-50 px-4 py-2 gap-4 rounded-md">
              <Icon
                name="DollarSquare"
                variant="Bold"
                size={24}
                className="text-dark"
              />
              <span className="mr-auto">
                <Text>pickup.details.lineItems.totalPrice</Text>
              </span>
              <span>{convertAmount(totalAmount)}</span>
            </div>
          )}
        </Wrapper.Body>
      </Wrapper>
    </Fragment>
  );
}
