import { useContext } from "react";
import toast from "react-hot-toast";
import { OfferDetailsContext } from "..";
import {
  Button,
  Form,
  InputGroup,
  Text,
  Wrapper,
} from "../../../../components";
import { useAxios, useDataState } from "../../../../hooks";
import { OrderDetailsContext } from "../../../Orders/Details";
import { noteSuggestion } from "./notes";

export default function Notes() {
  const { axios, loading } = useAxios();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const updateOffer = offerContext?.updateOffer;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const order = orderContext?.order;
  const updateOrder = orderContext?.updateOrder;
  const updateOrderLoading = orderContext?.updateOrderLoading;

  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;

  const isOrder = !!order;

  const initData = {
    title: offer?.title || order?.title,
    offerNote: offer?.offerNote,
    orderNote: order?.orderNote,
    customerNote: offer?.customerNote || order?.customerNote,
    supplierNote: offer?.supplierNote || order?.supplierNote,
    currencyCode: "EUR",
  };
  const hasLoading = [
    loading.update,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);
  const [data, setData, setBaseData, isChanged] = useDataState(initData);
  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => {
      setData((p) => ({ ...p, [key]: value || null }));
    };
  };
  const submitOffer = () => {
    const url = `/salesservice/api/offer/${offer?.id}`;
    const body = { ...data };
    body.offerNote ||= null;
    body.customerNote ||= null;
    body.supplierNote ||= null;
    axios.put(url, body).then(() => {
      updateOffer?.().then(() => {
        toast.success("toast.success.editOffer");
        setBaseData(body);
      });
    });
  };
  const submitOrder = () => {
    const url = `/salesservice/api/orders/${order?.id}`;
    const body = { ...data };
    body.orderNote ||= null;
    body.customerNote ||= null;
    body.supplierNote ||= null;
    axios.put(url, body).then(() => {
      updateOrder?.().then(() => {
        toast.success("toast.success.editOrder");
        setBaseData(body);
      });
    });
  };
  return (
    <Form
      onSubmit={isOrder ? submitOrder : submitOffer}
      className="grid grid-cols-1 md:grid-cols-2 gap-4"
    >
      <div className="flex items-center col-span-full">
        <h6 className="text-base text-dark flex-1 truncate">
          <Text>global.submit</Text>
        </h6>
        <Button
          type="submit"
          disabled={!isChanged || !isEnabled}
          loading={hasLoading}
        >
          <Text>button.submitChanges</Text>
        </Button>
      </div>
      <Wrapper>
        <Wrapper.Body>
          {isOrder ? (
            <InputGroup
              as="textarea"
              snippet
              suggestionData={noteSuggestion}
              label="formControls.orderNote"
              value={data.orderNote || ""}
              setValue={handleSetValue("orderNote")}
              readOnly={!isEnabled}
            />
          ) : (
            <InputGroup
              as="textarea"
              snippet
              suggestionData={noteSuggestion}
              label="formControls.offerNote"
              value={data.offerNote || ""}
              setValue={handleSetValue("offerNote")}
              readOnly={!isEnabled}
            />
          )}
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Body>
          <InputGroup
            snippet
            suggestionData={noteSuggestion}
            as="textarea"
            label="formControls.offerCustomerNote"
            value={data.customerNote || ""}
            setValue={handleSetValue("customerNote")}
            readOnly={!isEnabled}
          />
        </Wrapper.Body>
      </Wrapper>
      {/* <Wrapper>
        <Wrapper.Body>
          <InputGroup
            snippet
            suggestionData={noteSuggestion}
            as="textarea"
            label="formControls.offerSupplierNote"
            value={data.supplierNote || ""}
            setValue={handleSetValue("supplierNote")}
            readOnly={!isEnabled}
          />
        </Wrapper.Body>
      </Wrapper> */}
    </Form>
  );
}
