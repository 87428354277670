import { Image } from "../../../../components";
import { Incident } from "../../../../types";

type Props = {
  document: Incident.Document;
};

export default function DocumentItem({ document }: Props) {
  const openImage = () => {
    window.open(document.fileURL);
  };
  return (
    <button
      type="button"
      className="w-full aspect-image rounded overflow-hidden bg-white shadow transition-shadow hover:shadow-lg group"
      onClick={openImage}
    >
      <Image
        src={document.fileURL}
        alt={document.fileName}
        className="size-full object-cover transition-transform group-hover:scale-110"
        isDynamic
      />
    </button>
  );
}
