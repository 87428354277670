import { Fragment, useContext, useMemo } from "react";
import { OrderDetailsContext } from ".";
import { Alert, Button, Drawer, Icon, Text } from "../../../components";
import { useAxios } from "../../../hooks";
import { togglePropsType } from "../../../types";
type PublishOrderProps = togglePropsType & {
  signedDocuments: boolean;
  lineItemsHasShippingMethod: boolean;
  hasDownPayment: boolean;
  isDownPaymentMoreThanTotalAmount: boolean;
};
export default function PublishOrder({
  isOpen,
  toggle,
  signedDocuments,
  lineItemsHasShippingMethod,
  hasDownPayment,
  isDownPaymentMoreThanTotalAmount,
}: PublishOrderProps) {
  const { axios, loading } = useAxios();
  const orderContext = useContext(OrderDetailsContext);
  const order = orderContext?.order;
  const updateOrder = orderContext?.updateOrder;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const hasLoading = [updateOrderLoading, loading.post].some(Boolean);
  const isPublished = !!orderContext?.isPublished;
  const alert = useMemo(() => {
    const hasAddress = !!order?.billingAddress && !!order?.shippingAddress;
    const hasDocument = !!signedDocuments;
    if (!hasAddress)
      return {
        title: "alertTitles.addressRequired",
        desc: "alertDescriptions.addressRequired",
      };
    if (!hasDocument)
      return {
        title: "alertTitles.documentRequired",
        desc: "alertDescriptions.documentRequired",
      };
    if (!hasDownPayment)
      return {
        title: "alertTitles.paymentConditionRequired",
        desc: "alertDescriptions.paymentConditionRequired",
      };
    if (isDownPaymentMoreThanTotalAmount)
      return {
        title: "alertTitles.downPaymentMoreThanTotalAmount",
        desc: "alertDescriptions.downPaymentMoreThanTotalAmount",
      };
    if (!lineItemsHasShippingMethod)
      return {
        title: "alertTitles.allOrderLineItemMustHaveShippingMethod",
        desc: "alertDescriptions.allOrderLineItemMustHaveShippingMethod",
      };
    return null;
  }, [order, signedDocuments, lineItemsHasShippingMethod]);
  const publish = () => {
    const url = `/salesservice/api/orders/${order?.id}/${
      isPublished ? "republish" : "publish"
    }`;
    axios.post(url).then(() => {
      updateOrder?.().then(() => {
        toggle();
      });
    });
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Icon
              name="TickCircle"
              variant="Bold"
              size={16}
              className="text-success"
            />{" "}
            <Text>
              {isPublished
                ? "drawerTitles.republishOrder"
                : "drawerTitles.publishOrder"}
            </Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header className="space-y-1">
          <Icon.Wrapper className="mx-auto">
            <Icon name="ReceiptItem" variant="Bold" />
          </Icon.Wrapper>
          <h6>{order?.title}</h6>
          <p className="text-secondary">#{order?.number}</p>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Fragment></Fragment>
        </Drawer.Body>
        <Drawer.Footer className="space-y-4">
          {!!alert && (
            <Alert variant="warning" light>
              <Alert.Title>
                <Text>{alert.title}</Text>
              </Alert.Title>
              <Alert.Text className="leading-5">
                <Text>{alert.desc}</Text>
              </Alert.Text>
            </Alert>
          )}
          <div className="flex items-center justify-end gap-4">
            <Button
              type="button"
              variant="danger"
              onClick={toggle}
              disabled={hasLoading}
            >
              <Text>button.cancelForNow</Text>
            </Button>
            <Button
              variant="success"
              type="button"
              onClick={publish}
              loading={hasLoading}
              disabled={!!alert}
            >
              <Text>
                {isPublished ? "button.republishOrder" : "button.publishOrder"}
              </Text>
            </Button>
          </div>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
