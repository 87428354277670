import { Link, To } from "react-router-dom";
import Icon from "./_Icon";
import Text from "./_Text";
type backButtonProps = {
  className?: string;
  to?: To;
};
export default function BackButton({
  to = -1 as To,
  className,
}: backButtonProps) {
  return (
    <Link to={to} className={className}>
      <Icon name="ArrowLeft2" size={24} />
      <Text>button.back</Text>
    </Link>
  );
}
