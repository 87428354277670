import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { Icon, Text } from "../components";
import { iconNameType } from "../types";

type actionType =
  | { onClick: () => void; to?: never }
  | { to: string; onClick?: never };
type Props = {
  label: string;
  icon: iconNameType;
  isOpen: boolean;
  isOpenXl: boolean;
} & actionType;
export default function MenuItem({
  icon,
  label,
  onClick,
  to = "",
  isOpen,
  isOpenXl,
}: Props) {
  const Component = useMemo(() => (!!to ? NavLink : "button"), [to]);
  return (
    <Component
      key={label}
      to={to}
      onClick={onClick}
      className="menu-item group flex items-center w-full rounded text-start [&.active]:bg-primary/10 [&.active]:text-primary transition-colors gap-1"
    >
      <div className="size-11 relative">
        <Icon
          name={icon}
          variant="Outline"
          className="absolute text-[#A1A4AC] inset-[0.625rem] scale-100 group-[.menu-item.active]:scale-0 transition-transform m-auto"
          size="1.5rem"
        />
        <Icon
          name={icon}
          variant="Bold"
          className="absolute text-primary inset-[0.625rem] scale-0 group-[.menu-item.active]:scale-100 transition-transform m-auto"
          size="1.5rem"
        />
      </div>
      <span
        className={twMerge(
          "flex-1 truncate",
          isOpen ? "block" : "hidden",
          isOpenXl ? "xl:block" : "xl:hidden"
        )}
      >
        <Text>{label}</Text>
      </span>
    </Component>
  );
}
