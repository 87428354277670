import { Wrapper } from "../../../../components";

export default function InventoryLoading() {
  return (
    <Wrapper>
      <Wrapper.Header className="flex items-center justify-between gap-4">
        <div className="w-24 h-6 loading"></div>
        <div className="w-60 h-6 loading"></div>
      </Wrapper.Header>
      <Wrapper.Body>
        <ul className="w-full divide-y">
          {["9rem", "7.5rem", "6rem", "10rem"].map((width) => (
            <li key={width} className="flex items-center w-full py-3">
              <div className="w-1/3">
                <div className="w-28 h-6 loading"></div>
              </div>
              <div className="flex-1">
                <div style={{ width }} className="h-6 loading"></div>
              </div>
            </li>
          ))}
        </ul>
      </Wrapper.Body>
    </Wrapper>
  );
}
