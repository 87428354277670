import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Button,
  Drawer,
  Icon,
  List,
  Pagination,
  SearchBox,
  Text,
} from "../../../../components";
import { config } from "../../../../constants";
import { useAxios, useConverters } from "../../../../hooks";
import {
  CommissionGroup,
  WithPaging,
  togglePropsType,
} from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";

type ChooseCommissionGroupProps = togglePropsType;

export default function ChooseCommissionGroup({
  isOpen,
  toggle,
}: ChooseCommissionGroupProps) {
  const { convertDate } = useConverters();
  const { axios, loading } = useAxios();
  const orderContext = useContext(OrderDetailsContext);
  const updateOrder = orderContext?.updateOrder;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const order = orderContext?.order;
  const [commissionGroups, setCommissionGroups] = useState<
    WithPaging<CommissionGroup.Item>
  >({});
  const [params, setParams] = useState<CommissionGroup.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    customerId: order?.customer?.customerId,
  });
  const chooseItemLoading = [loading.post, updateOrderLoading].some(Boolean);
  const handleChoose = (commissionGroup: CommissionGroup.Item) => {
    return () => {
      if (chooseItemLoading) return;
      const url = `/salesservice/api/commissiongroups/${commissionGroup.id}/order`;
      const body = { orderIds: [order?.id] };
      axios.post(url, body).then(() => {
        updateOrder?.().then(() => {
          const message = "commissions.updateCommissionSuccessfuly";
          toast.success(message);
          toggle();
        });
      });
    };
  };
  const getCommissionGroups = () => {
    if (!isOpen) return;
    const url = "/salesservice/api/commissionGroups";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setCommissionGroups(data);
    });
  };
  useEffect(getCommissionGroups, [params, isOpen]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="border-b-0">
          <h6 className="text-dark text-base font-semibold">
            <Text>drawerTitles.chooseCommissionGroup</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox
            variant="gray"
            onSubmit={(keyword) =>
              setParams((p) => ({ ...p, pageNumber: 1, keyword }))
            }
          />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading.get}>
            {commissionGroups.items?.map((e) => {
              return (
                <List.Item
                  key={e.id}
                  onClick={handleChoose(e)}
                  className="cursor-pointer"
                >
                  <Icon.Wrapper variant="primary">
                    <Icon name="CalendarTick" variant="Bold" />
                  </Icon.Wrapper>
                  <div className="flex-1">
                    <h6 className="text-base text-dark font-medium truncate">
                      {e.title}
                    </h6>
                    <p className="text-xs text-secondary font-medium">
                      #{e.code}
                    </p>
                    <p className="text-xs text-secondary font-medium">
                      <Icon name="CalendarTick" variant="Bold" size={14} />{" "}
                      {convertDate(e.deliveryDate)}
                    </p>
                  </div>
                  <Button as="span" light loading={chooseItemLoading}>
                    <Text>button.choose</Text>
                  </Button>
                </List.Item>
              );
            })}
          </List>
        </Drawer.Body>
        <Drawer.Footer>
          <Pagination
            pageNumber={+params.pageNumber}
            pageSize={params.pageSize}
            setActivePage={(pageNumber) =>
              setParams((p) => ({ ...p, pageNumber }))
            }
            totalItems={commissionGroups.totalItems}
            totalPages={commissionGroups.totalPages}
          />
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
