import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { ImportBasketContext } from ".";
import {
  Badge,
  Button,
  CheckBox,
  Drawer,
  Icon,
  List,
  RadioButton,
  Text,
} from "../../../../../components";
import { dateFormats } from "../../../../../constants";
import { useAxios, useConverters } from "../../../../../hooks";
import { Basket, iconNameType } from "../../../../../types";
type headerDataItemType = {
  icon: iconNameType;
  label: string;
  value: any;
};
export default function BasketDetails() {
  const { axios, loading } = useAxios();
  const { convertAmount, convertDate, convertNumber } = useConverters();
  const {
    activeBasket,
    back,
    importBasket,
    removeAfterImport,
    toggleRemoveAfterImport,
    isBundle,
    setIsBundle,
    hasInitParentId,
  } = useContext(ImportBasketContext);
  const [data, setData] = useState<Basket.LineItem[]>([]);
  const [asBundle, setAsBundle] = useState(isBundle);
  const disabledSubmit = useMemo(() => {
    if (hasInitParentId) return loading.post;
    return asBundle === null || loading.post;
  }, [asBundle, loading.post, hasInitParentId]);
  const headerData: headerDataItemType[] = [
    {
      icon: "SidebarRight",
      label: "tableTitles.basketInternalTag",
      value: activeBasket?.internalTag,
    },
    {
      icon: "Document",
      label: "tableTitles.basketTag",
      value: activeBasket?.tag,
    },
    {
      icon: "Calendar2",
      label: "tableTitles.basketCreatedAt",
      value: convertDate(activeBasket?.stamp, dateFormats.fullDate),
    },
    {
      icon: "LocationTick",
      label: "tableTitles.basketSenderName",
      value: `${activeBasket?.senderName} (ILN: ${activeBasket?.iln})`,
    },
    {
      icon: "DollarSquare",
      label: "tableTitles.basketPrice",
      value: (
        <Badge variant="primary">{convertAmount(activeBasket?.price)}</Badge>
      ),
    },
  ];
  const radioItems = [
    { name: "radioButton.basketImportAsSingleItem", id: false },
    { name: "radioButton.basketImportAsBundle", id: true },
  ];
  const getData = () => {
    const url = `/salesservice/api/iwofurnconfigurations/iwofurn-basket-lines/${activeBasket?.internalTag}`;
    axios.post(url).then(({ data }) => {
      setData(data.lineItems);
    });
  };
  const handleSubmit = () => {
    if (asBundle) return setIsBundle(true);
    importBasket();
  };
  useEffect(getData, []);
  return (
    <Fragment>
      <Drawer.Header className="space-y-3 w-full text-end">
        {headerData.map((e) => (
          <div
            key={e.label}
            className="flex items-center gap-4 whitespace-nowrap"
          >
            <p className="text-start text-secondary truncate flex-1">
              <Icon name={e.icon} variant="Bold" size={16} />{" "}
              <Text>{e.label}</Text>:
            </p>
            <p className="text-end text-gray-900">{e.value}</p>
          </div>
        ))}
      </Drawer.Header>
      {/* <Drawer.Header className="border-b-0">
        <SearchBox variant="gray" onSubmit={handleSetParams("keyword")} />
      </Drawer.Header> */}
      <Drawer.Body>
        <List loading={loading.post}>
          {data.map((e, i) => {
            const key = [e.lineName, e.program, e.supplier, i].join("-");
            return (
              <List.Item key={key} className="py-4">
                <Icon.Wrapper className="self-start">
                  <Icon name="Box" variant="Bold" />
                </Icon.Wrapper>
                <div className="flex-1 space-y-[2px]">
                  <h6 className="block text-gray-900">{e.lineName}</h6>
                  <p className="text-sm truncate text-secondary">
                    #{e.articleNumber}
                  </p>
                  <p className="text-sm text-secondary">
                    <Icon name="Document" variant="Bold" size={14} />{" "}
                    <Text>tableTitles.lineItemProgramCode</Text>:{" "}
                    <span className="text-gray-900">{e.program}</span>
                  </p>
                  <p className="text-sm text-secondary">
                    <Icon name="Building" variant="Bold" size={14} />{" "}
                    <Text>tableTitles.lineItemSupplier</Text>:{" "}
                    <span className="text-gray-900">{e.supplier}</span>
                  </p>
                  <p className="text-sm text-secondary">
                    <Icon name="Building" variant="Bold" size={14} />{" "}
                    <Text>tableTitles.lineItemSupplierILN</Text>:{" "}
                    <span className="text-gray-900">{e.supplierIln}</span>
                  </p>
                </div>
                <span className="px-4">X{convertNumber(e.quantity)}</span>
                <span>{convertAmount(e.price.amount)}</span>
              </List.Item>
            );
          })}
        </List>
      </Drawer.Body>
      {!hasInitParentId && (
        <Drawer.Footer>
          {radioItems.map((e) => (
            <RadioButton
              key={e.name}
              label={e.name}
              isActive={e.id === asBundle}
              onClick={() => setAsBundle(e.id)}
            />
          ))}
        </Drawer.Footer>
      )}
      <Drawer.Footer className="flex items-center justify-end gap-4">
        <CheckBox
          label="formControls.removeBasketAfterImport"
          value={removeAfterImport}
          setValue={toggleRemoveAfterImport}
          className="w-auto flex-1"
        />
        <Button type="button" variant="danger" onClick={back}>
          <Text>button.cancel</Text>
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={handleSubmit}
          disabled={disabledSubmit}
        >
          <Text>button.importBasket</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
