
export const noteSuggestion = [
    {
        id: 'Lieferverzug Cor./Uk',
        code: '200',
        display: 'Aufgrund der globalen Herausforderungen der letzten Jahre kommt es leider immer wieder zu Störungen in den weltweiten Lieferketten. Wir bitten um Verständnis, dass wir den in der Bestellung angegebenen Liefertermin derzeit nicht verlässlich zusichern können. Bei der Einschätzung der Lieferzeiten stützen wir uns auf die jeweils verfügbaren Informationen unserer Vorlieferanten. Wir gehen nicht davon aus, dass es bei Ihrer Bestellung zu Lieferverzögerungen kommen wird, möchten Sie aber grundsätzlich vorab auf diese Möglichkeit hinweisen. Wir stehen in ständigem Kontakt mit unseren Lieferanten, sollte es trotz aller Bemühungen zu Verzögerungen bei Ihrer Bestellung kommen, werden wir Sie selbstverständlich frühzeitig informieren. Sollte es wider Erwarten zu einer verspäteten Lieferung Ihrer Bestellung kommen, entschuldigen wir uns bereits im Voraus für die Unannehmlichkeiten.'
    },
    {
        id: 'Abholer Küchen',
        code: '201',
        display: 'Arbeitsplatten, Sockelblenden und sonstige Langteile werden aus technischen Gründen übermäßig geliefert und müssen bauseits an die Räumlichkeiten angepasst werden. Ausschnitte für Kochfelder, Spülen oder sonstige Ausklinkungen müssen ebenfalls bauseits vorgenommen werden.'
    },
    {
        id: 'Anzahlung/NATO',
        code: '202',
        display: 'Die Bestellung erfolgt erst nach Zahlungseingang des kompletten Betrages. Die Lieferzeit errechnet sich ab Eingang der Zahlung. The order will be processed after receipt of the complete payment. The time of delivery is computed after receipt of payment.'
    },
    {
        id: 'Widerruf',
        code: '203',
        display: 'WIDERRUFSRECHT. Diese Bestellung kann bis zum ___.___.______ schriftlich widerrufen werden.'
    },
    {
        id: 'Empfangsbestätigung',
        code: '204',
        display: 'Der vollständige und einwandfreie Empfang der aufgeführten Waren wird hiermit bestätigt. Unterschrift: ______________'
    },
    {
        id: 'Auswahlauftrag',
        code: '205',
        display: 'Zur Auswahl bis:     _________ Nr. Personalausweis: _____________________'
    },
    {
        id: 'Finanzierung',
        code: '206',
        display: 'Aufgrund der bestehenden Widerrufsfrist bei Finanzierungen steht Ihre Ware erst 14 Tage nach der Genehmigung des Finanzierungsbetrages zur Abholung/Lieferung für Sie bereit.'
    },
    {
        id: '0%-Finanzierung',
        code: '207',
        display: '0,00%-Finanzierung ohne Gebühren. Aufgrund der bestehenden Widerrufsfrist bei Finanzierungen steht Ihre Ware erst 14 Tage nach der Genehmigung des Finanzierungsbetrages zur Abholung/Lieferung für Sie bereit.'
    },
    {
        id: 'Jeder 10. Eink. VK',
        code: '208',
        display: 'Ich habe die Teilnahmebedingungen für die Aktion "Jeder 10. Einkauf geschenkt" erhalten.'
    },
    {
        id: 'Altgeräte',
        code: '209',
        display: 'Für den Kauf von Elektro-Großgeräten gelten folgende Vorgaben des aktuell gültigen Elektrogesetzes (ElektroG3): Bei Lieferung eines neuen Großgeräts kann ein entsprechendes Altgerät gleicher Art kostenfrei zur Entsorgung an unser Auslieferpersonal zurückgegeben werden. Bei Selbstabholung kann das Altgerät an unserer Warenausgabe kostenfrei abgegeben werden. Elektro-Kleingeräte (max. 3 Stück) können jederzeit und ohne Kauf eines neuen Gerätes an unserer Warenausgabe kostenfrei entsorgt werden. Sie wünschen eine solche Rücknahme? Bitte informieren Sie Ihre/n Berater/in darüber. Wir setzen diesen Wunsch gerne für Sie um!'
    },
    {
        id: 'versch. Hersteller',
        code: '210',
        display: 'Es wurden verschiedene Herstellerprogramme zusammengestellt. Daraus resultierende Farbunterschiede stellen keinen Reklamationsgrund dar.'
    },
    {
        id: 'Maßverantwortung Kd',
        code: '211',
        display: 'Maß- und Sonderanfertigung nach Kundenwunsch. Maßverantwortung durch Kunden. Ware ist vom Umtausch und von Rückgabe ausgeschlossen!'
    },
    {
        id: 'Garantie 5 Jahre EMV',
        code: '212',
        display: '5-Jahres Möbel-Garantie inklusive. Diese Garantie gilt für Artikel: !BITTE ARTIKELBEZEICHNUNG EINTRAGEN! Die Garantiebedingungen wurden übergeben.'
    },
    {
        id: 'Matratzenumtausch',
        code: '213',
        display: 'Unsere Matratzenumtauschgarantie gilt für die Erstauswahl. Sie kann einmalig innerhalb von 30 Tagen nach Erhalt der Matratze in Anspruch genommen werden.'
    },
    {
        id: 'Wettbewerbspreis',
        code: '214',
        display: 'Nach Überprüfung des Wettbewerbspreises bei Möbelhaus ________________ berechnen wir Ihnen ________ Euro.'
    },
]