import { cloneDeep } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import { ProgramDetailsContext } from "..";
import {
  Button,
  Icon,
  Loading,
  Text,
  Wrapper,
} from "../../../../../components";
import { useAxios, useToggle } from "../../../../../hooks";
import { SupplierProgram } from "../../../../../types";
import AvailabilityStatusForm from "./AvailabilityStatusForm";
import AvailabilityStatusRow from "./AvailabilityStatusRow";

type Data = SupplierProgram.AvailabilityStatusItem[];
type Context = { availabilityStatuses: Data };
export const AvailabilityStatusContext = createContext({} as Context);

export default function AvailabilityStatus() {
  const { program } = useContext(ProgramDetailsContext);
  const { axios, loading } = useAxios();
  const [data, setData] = useState<Data>([]);
  const [showAddMenu, toggleAddMenu, hideAddMenu] = useToggle(false);
  const handleRemovedItem = (id: string) => {
    setData((p) => {
      const data = cloneDeep(p);
      const index = data.findIndex((e) => e.id === id);
      const has = index !== -1;
      if (has) data.splice(index, 1);
      return data;
    });
  };
  const getData = () => {
    const programId = program?.id;
    if (!programId) return;
    const url = `/salesservice/api/supplierprograms/${programId}/availability-status`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [program]);
  return (
    <AvailabilityStatusContext.Provider value={{ availabilityStatuses: data }}>
      <Wrapper>
        <Wrapper.Header className="border-b-0">
          <h6 className="text-sm text-dark font-medium">
            <Text>suppliers.programAvailabilityStatus.title</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-2.5">
          {loading.get ? (
            <Loading.AvailabilityStatus />
          ) : (
            data.map((e) => (
              <AvailabilityStatusRow
                key={e.id}
                availabilityStatus={e}
                onSubmitted={getData}
                onRemoved={handleRemovedItem}
              />
            ))
          )}
        </Wrapper.Body>
        <Wrapper.Footer className="border-t-0">
          <Button type="button" variant="primary" light onClick={toggleAddMenu}>
            <Icon name="Add" className="size-5" />{" "}
            <Text>suppliers.programAvailabilityStatus.addButton</Text>
          </Button>
        </Wrapper.Footer>
      </Wrapper>
      <AvailabilityStatusForm
        isOpen={showAddMenu}
        toggle={hideAddMenu}
        onSubmitted={getData}
      />
    </AvailabilityStatusContext.Provider>
  );
}
