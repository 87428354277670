import { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { Button, Embed, Icon, Image, Text } from "../../../components";
import { fileType } from "../../../constants";
import { useGetFile } from "../../../hooks";
import { downloadFile } from "../../../methods";
import { Document } from "../../../types";

type DocumentFileProps = {
  document: Exclude<Document.Details["documentFileInfo"], null>;
};

export default function DocumentFile({ document }: DocumentFileProps) {
  const [getFile, fileLoading] = useGetFile();
  const mimeType = document.mimeType;
  const isWord = mimeType === fileType.word;
  const isOtherFile = !Object.values(fileType).includes(mimeType);
  const downloadable = [isWord, isOtherFile].some(Boolean);
  const isImage = document.mimeType.includes(fileType.image);
  const hasAspectRatio = !downloadable && !isImage;
  const download = () => {
    getFile(document.fileUrl).then((data) => {
      downloadFile(data);
    });
  };
  return (
    <div
      className={twMerge(
        "relative size-full",
        hasAspectRatio && "aspect-[4/5]"
      )}
    >
      {downloadable ? (
        <Button
          type="button"
          variant="primary"
          className="absolute inset-0 size-fit m-auto"
          loading={fileLoading}
          onClick={download}
        >
          <Icon name="DocumentDownload" variant="Bold" />{" "}
          <Text>button.documentDownload</Text>
        </Button>
      ) : isImage ? (
        <Image
          src={document.fileUrl}
          alt={document.mimeType}
          className="size-full rounded object-contain bg-gray-100"
          isDynamic
        />
      ) : (
        <Fragment>
          {fileLoading ? (
            <span className="block size-full bg-gray-100 rounded animate-pulse" />
          ) : (
            <Embed fileInfo={document} />
          )}
        </Fragment>
      )}
    </div>
  );
}
