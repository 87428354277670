const products = {
  list: {
    searchPlaceholder: "Found products...",
    allProductText: "All product families ({{ items }} Products)",
    selectProductFamily: "Select product family",
    allButton: "All",
    productGroup: "Product group",
  },
  details: {
    printButton: "Print",
    productAvailabilityStatus: "Availability status: {{ status }}",
    productGroup: "Product group",
    vk1: "VK 1",
    vk2: "VK 2",
    priceNowSection: {
      title: "Price (now)",
      vk1: "VK1",
      vk2: "VK2",
      warningMessage: "Warning message.",
      errorMessage: "Error message.",
    },
    linePriceInfo: {
      drawerTitle: "Price info",
      supplierIln: "Supplier ILN",
      priceNow: "Price (now)",
      vk2: "VK2",
      vk1: "VK1",
      warningMessage: "Warning message.",
      errorMessage: "Error message.",
    },
    overwrittenPrice: "Overwritten Price",
    priceInOtherChannel: "Price in other channels",
    generalTab: "General info",
    itemsTab: "Items",
    attributesTab: "Attributes",
    mediaTab: "Media",
    inventoryTab: "Inventory",
    availabilityStatusTab: "Availability status",
    printHistoryTab: "Print history",
    salesPersonCommissionTab: "Sales person commission",
    // General tab
    aboutProductTitle: "About Product",
    aboutProductText: "Some description About this Product",
    moreInfo: "More info",
    articleNumber: "Article number",
    supplier: "Supplier",
    supplierArticleNumber: "Supplier article number",
    productFamily: "Product family",
    program: "Program",
    brand: "Brand",
    // Attributes tab
    yesItem: "Yes",
    noItem: "No",
    // Items tab
    itemsTitle: "Items",
    itemsLength: "{{ length }} items",
    headerProducts: "Products",
    headerQuantity: "Quantity",
    headerItemPrice: "Item Price",
    headerPrice: "line price",
    headerAction: "Action",
    lineSupplier: "Supplier",
    lineProgram: "Program",
    lineProductFamily: "Product Family",
    lineVk2: "VK2",
    lineVk1: "VK1",
    lineOverwritten: "Overwritten price",
    lineDetailsButton: "Details",
    // Media tab
    viewMediaBtn: "View",
    // Inventory tab
    inventoryNotAvailable: "Not Available",
    // Availability status Tab
    availabilityStatus: {
      // List
      title: "Availability status",
      isDefault: "(Default)",
      statusDate: "From {{ fromDate }} to {{ toDate }}",
      addButton: "Add",
      // Form
      addTitle: "Add availability status",
      editTitle: "Edit availability status",
      status: "Status",
      validationPeriod: "Add validation period",
      fromDate: "From Date",
      toDate: "To Date",
      conflictErrorTitle: "Validation Error",
      conflictErrorText: "Date range has conflict",
      cancelButton: "Cancel",
      addStatusButton: "Add",
      updateButton: "Update",
      addMessage: "Status has been created successfully.",
      updateMessage: "Status has been updated successfully.",
      // Remove Confirm
      removeStatusTitle: "Remove status",
      removeStatusText:
        "This operation cannot be reversed. Are you sure about your request?",
      cancelRemoveButton: "Cancel",
      removeStatusButton: "Remove status",
      removeSuccessMessage:
        "Availability status has been Removed successfully.",
    },
    pricePerChannel: {
      title: "Price in other channels",
      myChannel: "My Channel",
      otherChannels: "Other Channels",
      ilnNumber: "ILN #{{ number }}",
      priceInChannel: "Price in channel",
    },
    printHistory: {
      items: "Items",
      status: "Status",
      price: "Price",
      action: "Action",
      downloadButton: "Download",
      cancelButton: "Cancel Process",
      detailsButton: "Details",
    },
    printHistoryDetails: {
      drawerTitle: "Print request details",
      info: "Info",
      title: "Title",
      requestDate: "Request date",
      vk2: "VK2",
      vk1: "VK1",
      language: "Language",
      status: "Status",
      errorNote: "Error note",
      closeButton: "Close",
    },
    removeConfirm: {
      title: "Remove {{ title }}",
      text: "Are you sure about removing this item?",
      cancelButton: "Cancel",
      removeButton: "Remove item",
      removeMessage: "Print history item has been removed successfully.",
    },
  },
  editPrice: {
    title: "Edit Price",
    articleNumber: "Article number",
    supplierName: "Supplier",
    programName: "Program",
    vk1: "VK1",
    vk2: "VK2",
    cancelButton: "Cancel",
    submitButton: "Submit",
    successMessage: "Price has been updated successfully.",
  },
  printTemplate: {
    title: "Print templates",
    cancelButton: "Cancel",
    submitButton: "Print",
    submitMessage: "Print request submitted successfully.",
  },
  salesPersonCommission: {
    title: "Sales Person Commission",
    amount: "Amount",
    type: "Type",
    cancelButton: "Cancel",
    submitButton: "Save changes",
    successMessage: "Product has been updated successfully.",
  },
  filter: {
    filterTitle: "Filter",
    resetFilterBtn: "Reset filter",
    productFamily: "Product family",
    brand: "Brand",
    supplier: "Supplier",
    program: "Program",
    priceRange: "Price range",
    priceFrom: "From",
    priceTo: "To",
    cancelBtn: "Cancel",
    submit: "Submit filter",

    selectProductFamily: "Select product family",
    allProductFamilies: "All products",
    productCount: "{{ count }} products",
    chooseProductFamily: "Choose",
  },
};
export default products;
