type formatType = Intl.DateTimeFormatOptions;
const monthOnly: formatType = {
  year: "numeric",
  month: "2-digit",
};
const dateOnly: formatType = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};
const dateWithMonthName: formatType = {
  year: "numeric",
  month: "short",
  day: "2-digit",
};
const timeOnly: formatType = {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};
const fullDate: formatType = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};
const dateFormats = {
  monthOnly,
  dateOnly,
  fullDate,
  timeOnly,
  dateWithMonthName,
};
export default dateFormats;
