import { useContext } from "react";
import { SellOffProductDetailsContext } from ".";
import {
  Badge,
  Image,
  Status,
  Text,
  Translate,
  Wrapper,
} from "../../../components";
import { useFindAttribute } from "../../../hooks";

export default function ProductInfo() {
  const { basicInfo, canChangePhase, canClosePhase } = useContext(
    SellOffProductDetailsContext
  );
  const findAttribute = useFindAttribute();
  const articleNumber = basicInfo?.pimProduct?.articleNumber;
  // const supplier = basicInfo?.pimProduct?.supplier?.name;
  // const program = basicInfo?.pimProduct?.supplier?.program?.name;
  // const productFamily = basicInfo?.pimProduct?.productFamily?.translates;
  const hasArticleNumber = !!articleNumber;
  // const hasSupplier = !!supplier;
  // const hasProgram = !!program;
  // const hasProductFamily = !!productFamily;
  return (
    <Wrapper className="lg:col-span-2">
      <Wrapper.Body className="flex flex-col lg:flex-row lg:items-start gap-4">
        <Image
          src={basicInfo?.avatar?.url}
          alt={basicInfo?.pimProduct?.originalName ?? ""}
          className="w-full max-w-sm lg:w-48 aspect-image object-contain bg-gray-100 rounded"
          isDynamic
        />
        <div className="flex-1 flex flex-col items-start gap-1">
          <h6 className="flex flex-wrap items-center gap-1 w-full text-dark text-base font-medium break-words">
            <Translate
              value={findAttribute(
                basicInfo?.pimProduct?.attributeGroups,
                "title"
              )}
              defaultValue={basicInfo?.pimProduct?.originalName}
            />
            <Status.SellOffProduct id={basicInfo?.status} />
            {canChangePhase && (
              <Badge variant="warning" className="text-xs">
                <Text>sellOffProducts.details.needPhaseChange</Text>
              </Badge>
            )}
            {canClosePhase && (
              <Badge variant="danger" className="text-xs">
                <Text>sellOffProducts.details.needClose</Text>
              </Badge>
            )}
          </h6>
          {hasArticleNumber && (
            <p className="text-sm text-secondary font-medium w-full">
              #{articleNumber}
            </p>
          )}
          {/* {hasSupplier && (
            <p className="text-sm text-secondary font-medium w-full">
              <Text>sellOffProducts.details.supplier</Text>: {supplier}
            </p>
          )}
          {hasProgram && (
            <p className="text-sm text-secondary font-medium w-full">
              <Text>sellOffProducts.details.program</Text>: {program}
            </p>
          )}
          {hasProductFamily && (
            <p className="text-sm text-secondary font-medium w-full">
              <Text>sellOffProducts.details.productFamily</Text>:{" "}
              <Translate value={productFamily} />
            </p>
          )} */}
        </div>
      </Wrapper.Body>
    </Wrapper>
  );
}
