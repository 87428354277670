import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Drawer, Icon, List, SearchBox, Text } from "../components";
import { useGetProfile, useSelector } from "../hooks";
import { setProfile } from "../redux/profile";
import { toggleSelectSaleChannelMenu } from "../redux/selectSaleChannelActions";

export default function SelectSaleChannelMenu() {
  const navigate = useNavigate();
  const [getProfile, loading] = useGetProfile();
  const dispatch = useDispatch();
  const profile = useSelector((s) => s.profile);
  const [keyword, setKeyword] = useState("");
  const isOpen = useSelector(
    (s) => s.selectSaleChannelActions.showSelectSaleChannelMenu
  );
  const toggle = () => {
    dispatch(toggleSelectSaleChannelMenu());
  };
  const availableSaleChannels = useMemo(
    () => profile.availableSaleChannels ?? [],
    [profile.availableSaleChannels]
  );
  const saleChannels = useMemo(() => {
    if (!keyword) return availableSaleChannels;
    const search = keyword.toLowerCase();
    return availableSaleChannels.filter((e) =>
      [e.title, e.code]
        .map((e) => e.toLowerCase())
        .some((e) => e.includes(search))
    );
  }, [availableSaleChannels, keyword]);
  const handleSelectChannel = (id: string) => {
    return () => {
      const data = cloneDeep(profile);
      const isNewSaleChannel = data.defaultSaleChannel !== id;
      if (isNewSaleChannel) {
        sessionStorage.setItem("default-sale-channel-id", id);
        data.defaultSaleChannel = id;
        dispatch(setProfile(data));
        navigate("/customers");
      }
      toggle();
    };
  };
  useEffect(() => {
    isOpen && getProfile();
  }, [isOpen]);
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-50">
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-center mb-3">
            <Text>global.selectSaleChannel</Text>
          </h6>
          <SearchBox variant="gray" onSubmit={setKeyword} />
        </Drawer.Header>
        <Drawer.Body>
          <List loading={loading}>
            {saleChannels.map((e) => {
              const hasCode = !!e.code;
              const hasIlnNumber = !!e.ilnNumber;
              return (
                <List.Item
                  key={e.saleChannelId}
                  onClick={handleSelectChannel(e.saleChannelId)}
                >
                  <Icon.Wrapper>
                    <Icon name="DirectboxDefault" variant="Bold" />
                  </Icon.Wrapper>
                  <div className="space-y-px flex-1">
                    <h6 className="text-dark text-base font-medium truncate">
                      {e.title}
                    </h6>
                    {hasCode && (
                      <p className="text-secondary text-xs font-medium truncate">
                        #{e.code}
                      </p>
                    )}
                    {hasIlnNumber && (
                      <p className="text-secondary text-xs font-medium truncate">
                        ILN #{e.ilnNumber}
                      </p>
                    )}
                  </div>
                  <Button as="span" light>
                    <Text>button.choose</Text>
                  </Button>
                </List.Item>
              );
            })}
          </List>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
