const sellOffProducts = {
  list: {
    allItems: "All sell off products {{ count }}",
    all: "All",
    single: "Single",
    bundle: "Bundle",
    productGroup: "Product group",
    needPhaseChange: "Need phase change",
    needClose: "Need close",
    currentPhaseDays:
      "{{ currentPhaseName }} ({{ currentPhaseDay }}/{{ currentPhaseTotalDays }} Days)",
    soldBy: "Sold by {{ soldByName }}",
  },
  details: {
    startsProcessButton: "Starts process",
    changePhaseButton: "Change phase",
    needCloseButton: "Close phase",
    needPhaseChange: "Need phase change",
    needClose: "Need close",
    supplier: "Supplier",
    supplierArticleNumber: "Supplier article number",
    program: "Program",
    productFamily: "Product family",
    productGroup: "Product group",
    brand: "Brand",
    basicInfoTab: "Basic info",
    lineItemsTab: "Line items",
    mediaTab: "Media",
    salesProcessTab: "Sales process",
    attributesTab: "Attributes",
    // basic info
    aboutProduct: "About product",
    generalInfo: "General info",
    articleNumber: "Article number",
    subProducts: "Sub products",
    product: "Sub products",
    quantity: "Quantity",
    price: "Price",
    action: "Action",
    subProductDetailsButton: "Details",
    // media
    media: "Media",
    addMediaButton: "Add media",
    // sales process
    salesTemplate: "Sales template",
    submitTemplateButton: "Submit",
    templateSuccessMessage: "Sell off product has been updated successfully.",
    currentPhase: "Current phase",
    currentPhaseDays:
      "({{ currentPhaseDay }}/{{ currentPhaseTotalDays }} Days)",
    salesTimeline: "Sales timeline",
    timelinePerformed: "{{ performedBy }} at {{ performedAt }}",
    timelineDays: "{{ days }} Days",
    priceInfo: "Price",
    regularPrice: "Regular Price",
    discount: "Discount",
    phasePrice: "Phase Price",
    salesStatus: "Sales status",
    sold: "Sold",
    notSold: "Not sold",
    soldPrice: "Sold Price",
    soldDate: "Sold date",
    seller: "Seller",
    sellerCommission: "Seller Commission",
    fastSaleCommission: "Fast Sale Commission",
  },
  subProductDrawer: {
    title: "Line items",
    info: "Info",
    quantity: "Quantity",
    price: "Price",
    description: "Description",
    subProductTitle: "Sub Product title",
    articleNumber: "Article number",
    channel: "Channel",
    vk2: "VK2",
    vk1: "VK1",
  },
  changePhaseDrawer: {
    startPhaseTitle: "Start Sell-Off process",
    changePhaseTitle: "Change phase process",
    currentPhase: "Current phase",
    preparation: "Preparation",
    nextPhase: "Next phase",
    days: "0/{{ days }} Days",
    checklistTitle: "Check list",
    checklistDescription:
      "Alle Artikel in dieser Phase müssen für den Abverkauf vorbereitet werden",
    templateWarning: "We can not calculate and start this process",
    cancelButton: "Cancel",
    startSubmitButton: "Start",
    changeSubmitButton: "Change phase",
    startSuccessMessage: "Phase has been started successfully.",
    changeSuccessMessage: "Phase has been changed successfully.",
  },
  filterDrawer: {
    title: "Filter",
    resetButton: "Reset",
    productFamily: "Product family",
    brand: "Brand",
    supplier: "Supplier",
    status: "Status",
    location: "Location",
    startedDate: "Started",
    phaseChangedDate: "Phase changed",
    soldDate: "Sold",
    from: "From",
    to: "To",
    cancelButton: "Cancel",
    submitButton: "Submit filter",
  },
  closePhaseModal: {
    title: "Close Phase",
    text1: "Are You Sure You Want To Close This Phase?",
    text2:
      "After closing the phase you will not be able to change it to active.",
    cancelButton: "Cancel",
    submitButton: "Close phase",
    successMessage: "Phase has been closed successfully.",
  },
};
export default sellOffProducts;
