import { cloneDeep } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router";
import { Button, Drawer, Form, InputGroup, Text } from "../../../components";
import { rules } from "../../../constants";
import { useAxios } from "../../../hooks";
import { Document, Optional, togglePropsType } from "../../../types";

type editMenuProps = togglePropsType & {
  data: Optional<Document.Details>;
  setData: Dispatch<SetStateAction<Optional<Document.Details>>>;
  isOrderDocument: boolean;
};
export default function EditMenu({
  isOpen,
  toggle,
  data: documentData,
  setData: setDocumentData,
  isOrderDocument,
}: editMenuProps) {
  const { axios, loading } = useAxios();
  const { offerId, orderId, documentId } = useParams();
  const [data, setData] = useState(documentData);
  const isTemplate = !!data.documentTemplateId;
  const handleSetDefaultData = () => {
    setData(documentData);
  };
  const submit = () => {
    const url = [
      "/salesservice",
      "api",
      isOrderDocument ? "orderdocuments" : "offerdocuments",
      documentId,
    ].join("/");
    const body = { ...data, offerId: offerId, orderId: orderId };
    axios.put(url, body).then(() => {
      toast.success("toast.success.editDocument");
      setDocumentData((p) => {
        const prevData = cloneDeep(p);
        prevData.title = data.title;
        return prevData;
      });
      toggle();
    });
  };
  useEffect(handleSetDefaultData, [documentData]);
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <Text>
            {isTemplate ? "types.document.template" : "types.document.personal"}
          </Text>
        </Drawer.Header>
        <Drawer.Body>
          <InputGroup
            label="formControls.documentTitle"
            value={data.title}
            setValue={(title) => setData((p) => ({ ...p, title }))}
            rules={rules.required}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button type="submit" loading={loading.update}>
            <Text>button.submitChanges</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
