import { Fragment } from "react";
import { Button, Icon, Modal, Text } from "../../../../components";
import { OfferStatus } from "../../../../enums";
import { Offer, iconNameType, togglePropsType } from "../../../../types";
type createOfferProps = togglePropsType & {
  onEdit: (id: string) => void;
  data: Offer.Item;
  onReject: (id: string) => void;
};
type actionItem = {
  isActive: boolean;
  icon: iconNameType;
  label: string;
  className: string;
  action: () => void;
};
export default function OfferActions({
  isOpen,
  toggle,
  onEdit,
  onReject,
  data,
}: createOfferProps) {
  // const { axios, loading } = useAxios();
  const isAccepted = data?.status === OfferStatus.Accepted;
  const isRejected = data?.status === OfferStatus.Rejected;
  const isEnabled = data && !isAccepted && !isRejected;
  const handleOnEdit = () => {
    onEdit(data?.id);
    toggle();
  };
  const handleOnReject = () => {
    onReject(data?.id);
    toggle();
  };
  const actions: actionItem[] = [
    {
      isActive: isEnabled,
      icon: "Edit2",
      className: "text-primary",
      label: "actionTitles.editOffer",
      action: handleOnEdit,
    },
    // {
    //   isActive: true,
    //   icon: "Send2",
    //   className: "text-primary",
    //   label: "actionTitles.sendEmailOffer",
    //   action: sendEmail,
    // },
    {
      isActive: isEnabled,
      icon: "Trash",
      className: "text-danger",
      label: "actionTitles.rejectOffer",
      action: handleOnReject,
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6 className="mb-1">{data?.title}</h6>
        <span className="block text-secondary">#{data?.number}</span>
      </Modal.Header>
      <Modal.Body>
        {actions
          .filter((e) => e.isActive)
          .map((e, i) => (
            <Fragment key={e.label}>
              {i !== 0 && <hr className="bg-gray-100 w-full my-4" />}
              <button
                type="button"
                className="w-full flex-center gap-4"
                onClick={e.action}
                // disabled={loading.post}
              >
                <Icon.Wrapper variant="secondary">
                  <Icon name={e.icon} variant="Bold" className={e.className} />
                </Icon.Wrapper>
                <span className="flex-1 text-start">
                  <Text>{e.label}</Text>
                </span>
              </button>
            </Fragment>
          ))}
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button
          light
          onClick={toggle}
          // loading={loading.post}
        >
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
