import { Fragment, useMemo } from "react";
import { Button, Icon, List, Modal, Text } from "../../../components";
import { useToggle } from "../../../hooks";
import { Offer, iconNameType } from "../../../types";

type CompletedInfoProps = {
  offer: Offer.Details;
  signedDocuments: boolean;
  lineItemsHasShippingMethod: boolean;
  lineItemsHasShippingDate: boolean;
  hasDownPayment: boolean;
  isDownPaymentMoreThanTotalAmount: boolean;
};
type ListItem = {
  icon: iconNameType;
  label: string;
  isActive: boolean;
};

export default function CompletedInfo({
  offer,
  signedDocuments,
  lineItemsHasShippingMethod,
  lineItemsHasShippingDate,
  hasDownPayment,
  isDownPaymentMoreThanTotalAmount,
}: CompletedInfoProps) {
  const [showModal, toggleModal] = useToggle(false);
  const hasShipping = !!offer.shippingAddress;
  const hasBilling = !!offer.billingAddress;

  const listItems: ListItem[] = [
    {
      icon: "DocumentText",
      label: "alertTitles.offerRequiredDocument",
      isActive: signedDocuments,
    },
    {
      icon: "Calendar",
      label: "alertTitles.offerRequiredDesiredDeliveryDate",
      isActive: [
        offer.desiredDeliveryCalendarWeek,
        offer.desiredDeliveryDate,
      ].some(Boolean),
    },
    {
      icon: "Routing",
      label: "alertTitles.offerRequiredAddress",
      isActive: hasShipping && hasBilling,
    },
    {
      icon: "CardPos",
      label: isDownPaymentMoreThanTotalAmount
        ? "alertTitles.downPaymentMoreThanTotalAmount"
        : "alertTitles.offerRequiredPaymentConditions",
      isActive: hasDownPayment && !isDownPaymentMoreThanTotalAmount,
    },
    {
      icon: "Truck",
      label: "alertTitles.allOfferLineItemMustHaveShippingMethod",
      isActive: lineItemsHasShippingMethod,
    },
    {
      icon: "Calendar",
      label: "alertTitles.allOfferLineItemMustHaveShippingDate",
      isActive: lineItemsHasShippingDate,
    },
  ];
  const length = listItems.length;
  const validItems = listItems.filter((e) => e.isActive);
  const validLength = validItems.length;
  const progress = Math.min(Math.round(100 * (validLength / length)), 100);
  const background = `conic-gradient(currentColor var(--progress),#E9EBF3 var(--progress))`;
  const style = { background, "--progress": `${progress}%` };
  const color = useMemo(() => {
    if (progress < 20) return "text-danger";
    if (progress === 100) return "text-success";
    return "text-warning";
  }, [progress]);
  return (
    <Fragment>
      <div className="flex-col flex-center text-center gap-1">
        <div
          style={style}
          className={`flex-center w-20 h-20 rounded-full bg-gradient-to-t ${color}`}
        >
          <div className="flex-center w-16 h-16 rounded-full bg-white">
            {progress}%
          </div>
        </div>
        <p className="text-sm text-dark">
          <Text>global.offerCompletedInfo</Text>
        </p>
        <Button
          type="button"
          outline
          className="btn-sm border-0"
          onClick={toggleModal}
        >
          <Text>button.checkOfferCompleted</Text>
        </Button>
      </div>
      <Modal isOpen={showModal} toggle={toggleModal}>
        <Modal.Header>
          <h6 className="text-base text-dark">
            <Text>modalTitles.offerCompleteInfo</Text>
          </h6>
        </Modal.Header>
        <Modal.Body>
          <List>
            {listItems.map((e) => (
              <List.Item key={e.label}>
                <Icon.Wrapper
                  rounded
                  variant={e.isActive ? "light" : "warning"}
                >
                  <Icon name={e.icon} variant="Bold" />
                </Icon.Wrapper>
                <h6 className="flex-1 text-dark text-base">
                  <Text>{e.label}</Text>
                </h6>
                <span className="w-6 h-6 rounded-full bg-gray-50">
                  {e.isActive && (
                    <Icon
                      name="TickCircle"
                      variant="Bold"
                      className="w-full h-full text-primary"
                    />
                  )}
                </span>
              </List.Item>
            ))}
          </List>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button type="button" onClick={toggleModal} light>
            <Text>button.close</Text>
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
