import { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CustomerContext } from "..";
import {
  Button,
  Icon,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
  WithPermission,
  Wrapper,
} from "../../../../components";
import { config } from "../../../../constants";
import {
  OfferFilter,
  OfferForm,
  RejectOfferMenu,
} from "../../../../containers";
import { OfferStatus } from "../../../../enums";
import { withPermission } from "../../../../hoc";
import { useAxios, useConverters, useToggle } from "../../../../hooks";
import { Offer, WithPaging } from "../../../../types";
import OfferActions from "./OfferActions";
type Data = WithPaging<Offer.Item>;
type Params = Offer.Params;
function Offers() {
  const { customerData } = useContext(CustomerContext);
  const { convertAmount, convertDate } = useConverters();
  const { axios, loading } = useAxios();
  const [data, setData] = useState<Data>({});
  const [showFilterMenu, toggleFilterMenu] = useToggle(false);
  const [activeActionId, setActiveActionId] = useState<string | null>(null);
  const [activeRejectId, setActiveRejectId] = useState<string | null>(null);
  const [activeEditId, setActiveEditId] = useState<string | null>(null);
  const [showAddOfferMenu, toggleAddOfferMenu] = useToggle(false);
  const [params, setParams] = useState<Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    customerId: customerData.id,
  });
  const someOffersIsActive = data.items?.some(
    (e) => e.status === OfferStatus.Active
  );
  const clearActiveActionId = () => {
    setActiveActionId(null);
  };
  const clearActiveRejectId = () => {
    setActiveRejectId(null);
  };
  const clearActiveEditId = () => {
    setActiveEditId(null);
  };
  const handleSetParams = (key: keyof Params) => {
    return (value: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: value, pageNumber: 1 }));
      }
      return setParams((p) => ({ ...p, [key]: value }));
    };
  };
  const getData = () => {
    const url = "/salesservice/api/offer";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <div className="basis-1/3">
          <SearchBox onSubmit={handleSetParams("keyword")} />
        </div>
        <Button light onClick={toggleFilterMenu}>
          <Icon name="Filter" variant="Bold" size={20} />
        </Button>
        <WithPermission
          permissions={[
            "SC_SaleServiceOfferFullAccess",
            "SC_CreateSaleServiceOffer",
          ]}
        >
          <Button className="ml-auto" onClick={toggleAddOfferMenu}>
            <Icon name="Add" size={20} className="mr-1" />
            <Text>button.addOffer</Text>
          </Button>
        </WithPermission>
      </section>
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              <Table.Body loading={loading.get}>
                {data.items?.map((e) => {
                  const isActive = e.status === OfferStatus.Active;
                  return (
                    <tr key={e.id}>
                      <td>
                        <Link
                          to={`/offers/${e.id}`}
                          className="flex-center w-fit gap-4"
                        >
                          <Icon.Wrapper>
                            <Icon name="DiscountShape" variant="Bold" />
                          </Icon.Wrapper>
                          <div className="space-y-1">
                            <span>{e.title}</span>
                            <span className="block text-secondary">
                              #{e.number}
                            </span>
                          </div>
                        </Link>
                      </td>
                      <td>{convertAmount(e.totalAmount)}</td>
                      <td className="space-y-1">
                        <span className="block">
                          <Text>global.version</Text> {e.version}
                        </span>
                        <span className="block text-secondary">
                          <Text>global.createdAt</Text> (
                          {convertDate(e.createdAt)})
                        </span>
                        {!!e.lastUpdatedAt && (
                          <span className="block text-secondary">
                            <Text>global.updatedAt</Text> (
                            {convertDate(e.lastUpdatedAt)})
                          </span>
                        )}
                      </td>
                      <td>
                        <Status.Offer id={e.status} />
                      </td>
                      {someOffersIsActive && (
                        <WithPermission
                          permissions={[
                            "SC_SaleServiceOfferFullAccess",
                            "SC_UpdateSaleServiceOffer",
                          ]}
                        >
                          <td data-sticky>
                            {isActive && (
                              <Fragment>
                                <Button
                                  light
                                  onClick={() => setActiveActionId(e.id)}
                                >
                                  <Text>button.action</Text>
                                  <Icon
                                    name="ArrowDown2"
                                    size={18}
                                    className="ml-1"
                                  />
                                </Button>
                                <OfferActions
                                  data={e}
                                  isOpen={activeActionId === e.id}
                                  toggle={clearActiveActionId}
                                  onEdit={setActiveEditId}
                                  onReject={setActiveRejectId}
                                />
                                <OfferForm
                                  offer={e}
                                  customer={customerData}
                                  isOpen={activeEditId === e.id}
                                  toggle={clearActiveEditId}
                                  onSubmitted={getData}
                                />
                                <RejectOfferMenu
                                  isOpen={activeRejectId === e.id}
                                  toggle={clearActiveRejectId}
                                  offerData={e}
                                  onSubmitted={getData}
                                />
                              </Fragment>
                            )}
                          </td>
                        </WithPermission>
                      )}
                    </tr>
                  );
                })}
              </Table.Body>
            </Table>
          </Table.Wrapper>
        </Wrapper.Body>
      </Wrapper>
      <Pagination
        pageNumber={params.pageNumber}
        setActivePage={handleSetParams("pageNumber")}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
      />
      <OfferFilter
        isOpen={showFilterMenu}
        toggle={toggleFilterMenu}
        onSubmit={(params) => setParams((p) => ({ ...p, ...params }))}
      />
      <WithPermission
        permissions={[
          "SC_SaleServiceOfferFullAccess",
          "SC_CreateSaleServiceOffer",
        ]}
      >
        <OfferForm
          offer={null}
          customer={customerData}
          isOpen={showAddOfferMenu}
          toggle={toggleAddOfferMenu}
          onSubmitted={getData}
        />
      </WithPermission>
    </Fragment>
  );
}
export default withPermission(Offers, [
  "SC_SaleServiceOfferFullAccess",
  "SC_GetSaleServiceOffer",
]);
