import {
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import checkIcon from "../../../assets/icons/check.svg";
import { Button, Form, Icon, Layout, Text, Wrapper } from "../../../components";
import { SelectedCustomer } from "../../../containers/_ChooseCustomer";
import { SelectedLineItem } from "../../../containers/_ChooseIncidentLineItem";
import { SelectedOrder } from "../../../containers/_ChooseOrder";
import { SelectedEmployee } from "../../../containers/_IncidentAssignedEmployee";
import { AssignedType, CustomerType, IncidentPriority } from "../../../enums";
import { withPermission } from "../../../hoc";
import { useAxios, useDefaultSaleChannel } from "../../../hooks";
import { isEmptyValue } from "../../../methods";
import { Company, Customer, Incident, Order } from "../../../types";
import BasicInfo from "./BasicInfo";
import Completed from "./Completed";
import IncidentInfo from "./IncidentInfo";
import OtherInfo from "./OtherInfo";

type Data = Incident.Create;

type ContextType = {
  data: Data;
  setData: Dispatch<SetStateAction<Data>>;
  customer: SelectedCustomer | null;
  setCustomer: Dispatch<SetStateAction<SelectedCustomer | null>>;
  order: SelectedOrder | null;
  setOrder: Dispatch<SetStateAction<SelectedOrder | null>>;
  lineItem: SelectedLineItem | null;
  setLineItem: Dispatch<SetStateAction<SelectedLineItem | null>>;
  employee: SelectedEmployee | null;
  setEmployee: Dispatch<SetStateAction<SelectedEmployee | null>>;
  branch: null;
  canEditCustomer: boolean;
  canEditOrder: boolean;
};

export const CreateIncidentContext = createContext({} as ContextType);

function CreateIncident() {
  const location = useLocation();
  const saleChannel = useDefaultSaleChannel();
  const steps = [
    {
      title: "incident.tabTitles.basicInfo",
      description: "incident.tabDescriptions.basicInfo",
      component: BasicInfo,
    },
    {
      title: "incident.tabTitles.incidentInfo",
      description: "incident.tabDescriptions.incidentInfo",
      component: IncidentInfo,
    },
    {
      title: "incident.tabTitles.otherInfo",
      description: "incident.tabDescriptions.otherInfo",
      component: OtherInfo,
    },
    {
      title: "incident.tabTitles.completed",
      description: "incident.tabDescriptions.completed",
      component: Completed,
    },
  ];
  const stateCustomer: Customer.Details | Company.Details | null =
    location.state?.customer ?? null;
  const stateOrder: Order.Details | null = location.state?.order ?? null;

  const hasStateCustomer = !!stateCustomer;
  const hasStateOrder = !!stateOrder;

  const initCustomer: SelectedCustomer | null = useMemo(() => {
    if (!hasStateCustomer) return null;
    const isCompany = stateCustomer.customerType === CustomerType.Company;
    return {
      id: stateCustomer.id,
      address: stateCustomer.address,
      cimNumber: stateCustomer.cimNumber,
      emailAddress: stateCustomer.emailAddress,
      name: isCompany
        ? // @ts-ignore
          stateCustomer.companyName
        : [stateCustomer.firstName, stateCustomer.lastName].join(" "),
      phoneNumber: stateCustomer.phoneNumber,
      type: stateCustomer.customerType,
    };
  }, [stateCustomer, hasStateCustomer]);
  const initOrder: SelectedOrder | null = useMemo(() => {
    if (!hasStateOrder) return null;
    return {
      id: stateOrder.id,
      name: stateOrder.title,
      number: stateOrder.number,
      totalAmount: stateOrder.totalAmount,
    };
  }, [stateOrder, hasStateOrder]);

  const canEditCustomer = !stateCustomer;
  const canEditOrder = !stateOrder;

  const { axios, loading } = useAxios();
  const navigate = useNavigate();
  const initData: Data = {
    assignedType: AssignedType.Employee,
    assignedId: null,
    branchId: saleChannel?.saleChannelId ?? null,
    // branchId: null,
    priority: IncidentPriority.Normal,
    documents: null,
  };
  const [data, setData] = useState(initData);
  const [customer, setCustomer] = useState(initCustomer);
  const [order, setOrder] = useState(initOrder);
  const [lineItem, setLineItem] = useState<SelectedLineItem | null>(null);
  const [employee, setEmployee] = useState<SelectedEmployee | null>(null);
  const [stepIndex, setStepIndex] = useState(0);
  const activeStep = steps[stepIndex];
  const ActiveStepComponent = activeStep?.component || Fragment;
  const branch = null;
  const isFirstStep = stepIndex === 0;
  const isThirdStep = stepIndex === 2;
  const isLastStep = stepIndex === steps.length - 1;

  const goToNextStep = () => {
    setStepIndex((p) => p + 1);
  };
  const goToPrevStep = () => {
    if (isFirstStep) return;
    setStepIndex((p) => p - 1);
  };
  const submit = () => {
    const url = "/channelmanagerservice/api/incidents";
    const body = { ...data };
    body.priority = Number(body.priority);
    body.origin = Number(body.origin);
    body.branchId = saleChannel?.saleChannelId ?? null;
    body.customerId = customer?.id || null;
    body.customerType = customer?.type ?? null;
    body.orderId = order?.id || null;
    body.assignedId = employee?.id ?? null;
    body.assignedType = employee?.type ?? 0;
    body.orderLineId = lineItem?.id ?? null;
    axios.post(url, body).then(({ data: incidentId }) => {
      toast.success("incident.form.createSuccessMessage");
      navigate(`/incidents/${incidentId}`);
    });
  };
  const handleSubmit = () => {
    const hasSubject = !isEmptyValue(data.subjectId);
    const hasCustomerMood = !isEmptyValue(data.customerMood);
    if (isFirstStep && !hasSubject)
      return toast.error("incident.form.incidentTypeMessage");
    if (isThirdStep && !hasCustomerMood)
      return toast.error("incident.form.customerMoodMessage");
    if (isLastStep) return submit();
    goToNextStep();
  };

  return (
    <Layout as={Form} onSubmit={handleSubmit}>
      <Layout.Header>
        <h1 className="flex-1 truncate text-base text-dark">
          <Text>incident.form.createNewIncidentTitle</Text>
        </h1>
        {isFirstStep ? (
          <Button as={Link} to="/incidents" variant="danger">
            <Text>incident.form.cancelBtn</Text>
          </Button>
        ) : (
          <Button
            type="button"
            variant="primary"
            light
            disabled={loading.post}
            onClick={goToPrevStep}
          >
            <Text>incident.form.backBtn</Text>
          </Button>
        )}
        {isLastStep ? (
          <Button type="submit" variant="success" loading={loading.post}>
            <Text>incident.form.submitBtn</Text>
          </Button>
        ) : (
          <Button type="submit" variant="primary">
            <Text>incident.form.nextBtnBtn</Text>
          </Button>
        )}
      </Layout.Header>
      <Layout.Body className="space-y-4">
        <Wrapper>
          <Wrapper.Body className="flex-center gap-4 py-10">
            {steps.map((e, i) => (
              <Fragment key={e.title}>
                <div
                  data-active={i === stepIndex}
                  data-prev={i < stepIndex || undefined}
                  className="group flex-center gap-4"
                  onClick={() => i < stepIndex && setStepIndex(i)}
                >
                  <div className="flex-center text-xl bg-primary/10 text-primary w-10 h-10 rounded transition-colors group-data-active:bg-primary group-data-active:text-white">
                    <img
                      src={checkIcon}
                      alt={e.title}
                      className="hidden group-data-[prev]:inline-block w-4 h-4"
                    />
                    <span className="inline-flex group-data-[prev]:hidden">
                      {i + 1}
                    </span>
                  </div>
                  <div className="flex-1">
                    <h6 className="truncate">
                      <Text>{e.title}</Text>
                    </h6>
                    <span className="truncate block text-secondary text-sm">
                      <Text>{e.description}</Text>
                    </span>
                  </div>
                </div>
                <div
                  data-active={i <= stepIndex}
                  className="group w-7 h-7 [&>*]:w-full [&>*]:h-full [&>*]:object-contain last:hidden"
                >
                  <Icon
                    name="ArrowRight"
                    variant="TwoTone"
                    className="text-placeholder group-data-active:text-primary"
                    size={28}
                  />
                </div>
              </Fragment>
            ))}
          </Wrapper.Body>
        </Wrapper>
        <CreateIncidentContext.Provider
          value={{
            data,
            setData,
            customer,
            setCustomer,
            order,
            setOrder,
            lineItem,
            setLineItem,
            employee,
            setEmployee,
            canEditCustomer,
            canEditOrder,
            branch,
          }}
        >
          <ActiveStepComponent />
        </CreateIncidentContext.Provider>
        {/* <SelectIncidentType
          isOpen={showSelectIncidentType}
          toggle={toggleSelectIncidentType}
          onSelect={setIncidentType}
        /> */}
      </Layout.Body>
    </Layout>
  );
}
export default withPermission(CreateIncident, [
  "CMS_IncidentFullAccess",
  "CMS_CreateIncident",
]);
