import { Fragment, useContext } from "react";
import { SellOffProductDetailsContext } from "..";
import {
  Accordion,
  Drawer,
  Icon,
  Text,
  Translate,
  Wrapper,
} from "../../../../components";
import { useConverters, useFindAttribute } from "../../../../hooks";
import { cn } from "../../../../methods";
import { iconNameType, togglePropsType } from "../../../../types";
import { LineItemContext } from "./LineItemRow";

type Props = togglePropsType;

export default function LineItemDetailsDrawer({ isOpen, toggle }: Props) {
  const { convertAmount } = useConverters();
  const { basicInfo } = useContext(SellOffProductDetailsContext);
  const { lineItem } = useContext(LineItemContext);
  const findAttribute = useFindAttribute();
  // const [showRemoveModal, toggleRemoveModal, hideRemoveModal] =
  //   useToggle(false);
  // const [showEditDrawer, toggleEditDrawer, hideEditDrawer] = useToggle(false);
  const infoItems = [
    {
      icon: "Box" as iconNameType,
      label: "sellOffProducts.subProductDrawer.subProductTitle",
      value: lineItem.originalName,
    },
    {
      icon: "Hashtag" as iconNameType,
      label: "sellOffProducts.subProductDrawer.articleNumber",
      value: `#${lineItem.articleNumber}`,
    },
    {
      icon: "LocationTick" as iconNameType,
      label: "sellOffProducts.subProductDrawer.channel",
      value: basicInfo?.channelTitle,
    },
  ];

  const priceItems = [
    {
      label: "sellOffProducts.subProductDrawer.vk2",
      value: convertAmount(lineItem.vk2Price?.amount),
      classes: "text-secondary line-through",
    },
    {
      label: "sellOffProducts.subProductDrawer.vk1",
      value: convertAmount(lineItem.vk1Price?.amount),
      classes: "text-dark",
    },
  ];

  return (
    <Fragment>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu className="bg-[#F5F7F9]">
          <Drawer.Header className="flex items-center gap-4">
            <h6 className="flex-1 text-start text-base text-dark font-medium">
              <Text>sellOffProducts.subProductDrawer.title</Text>
            </h6>
            {/* <IconButton
              type="button"
              variant="danger"
              light
              onClick={toggleRemoveModal}
            >
              <Icon name="Trash" variant="Bold" />
            </IconButton> */}
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            <Accordion defaultActiveKey={["info", "description", "price"]}>
              <Wrapper>
                <Accordion.Item eventKey="info">
                  <div className="flex items-center">
                    <Accordion.Toggle className="flex-1 flex-row-reverse">
                      <h6 className="flex-1 text-sm text-dark text-start">
                        <Text>sellOffProducts.subProductDrawer.info</Text>
                      </h6>
                    </Accordion.Toggle>
                    {/* <button
                      type="button"
                      className="text-primary text-sm"
                      onClick={toggleEditDrawer}
                    >
                      <Icon name="Edit2" />
                    </button> */}
                  </div>
                  <Accordion.Body className="text-start">
                    <table className="w-full text-sm font-medium">
                      <tbody className="divide-y divide-dashed">
                        {infoItems.map((e) => (
                          <tr
                            key={e.label}
                            className="*:py-3 [&:first-child>*]:pt-0 [&:last-child>*]:pb-0"
                          >
                            <td className="text-secondary text-start whitespace-nowrap">
                              <Icon name={e.icon} variant="Bold" />{" "}
                              <Text>{e.label}</Text>
                            </td>
                            <td className="text-gray-900 text-end">
                              {e.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              <Wrapper>
                <Wrapper.Body className="flex items-center gap-4">
                  <h6 className="flex-1 text-sm text-dark">
                    <Text>sellOffProducts.subProductDrawer.quantity</Text>
                  </h6>
                  <p className="text-base text-secondary">
                    x{lineItem.quantity}
                  </p>
                  {/* <button
                  type="button"
                  className="text-primary text-sm"
                  // onClick={() => handleOnEdit("quantity")}
                >
                  <Icon name="Edit2" />
                </button> */}
                </Wrapper.Body>
              </Wrapper>
              <Wrapper>
                <Accordion.Item eventKey="price">
                  <div className="flex items-center">
                    <Accordion.Toggle className="flex-1 flex-row-reverse">
                      <h6 className="flex-1 text-sm text-dark text-start">
                        <Text>sellOffProducts.subProductDrawer.price</Text>
                      </h6>
                    </Accordion.Toggle>
                    {/* <button
                      type="button"
                      className="text-primary text-sm"
                      // onClick={() => handleOnEdit("originalPrice")}
                    >
                      <Icon name="Edit2" />
                    </button> */}
                  </div>
                  <Accordion.Body>
                    <table className="w-full text-sm font-medium">
                      <tbody className="divide-y divide-dashed">
                        {priceItems.map((e) => (
                          <tr
                            key={e.label}
                            className="*:py-3 [&:first-child>*]:pt-0 [&:last-child>*]:pb-0"
                          >
                            <td className="text-secondary text-start whitespace-nowrap">
                              <Text>{e.label}</Text>
                            </td>
                            <td
                              className={cn(
                                "text-gray-900 text-end",
                                e.classes
                              )}
                            >
                              {e.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              <Wrapper>
                <Accordion.Item eventKey="description">
                  <div className="flex items-center">
                    <Accordion.Toggle className="flex-1 flex-row-reverse">
                      <h6 className="flex-1 text-sm text-dark text-start">
                        <Text>
                          sellOffProducts.subProductDrawer.description
                        </Text>
                      </h6>
                    </Accordion.Toggle>
                    {/* <button
                      type="button"
                      className="text-primary text-sm"
                      // onClick={() => handleOnEdit("lineItemNote")}
                    >
                      <Icon name="Edit2" />
                    </button> */}
                  </div>
                  <Accordion.Body>
                    <p className="text-sm text-gray-900">
                      <Translate
                        value={findAttribute(
                          lineItem.attributeGroups,
                          "descriptions"
                        )}
                        defaultValue="--"
                      />
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
            </Accordion>
          </Drawer.Body>
        </Drawer.Menu>
      </Drawer>
      {/* <RemoveLineItemModal isOpen={showRemoveModal} toggle={hideRemoveModal} />
      <EditLineItemDrawer isOpen={showEditDrawer} toggle={hideEditDrawer} /> */}
    </Fragment>
  );
}
