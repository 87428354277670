import { Fragment, useMemo } from "react";
import { Badge, Button, Drawer, Icon, Status, Text } from "../components";
import { dateFormats } from "../constants";
import { useConverters } from "../hooks";
import { LineItem, Order, togglePropsType } from "../types";

type Props = togglePropsType & {
  variant:
    | "salesStatuses"
    | "salesDocumentStatuses"
    | "purchaseStatuses"
    | "purchaseDocumentStatuses";
  order?: Order.Details;
  lineItem?: LineItem.Item;
};

export default function ErpStatusHistory({
  isOpen,
  toggle,
  variant,
  order,
  lineItem,
}: Props) {
  const { convertDate } = useConverters();
  const title = useMemo(() => {
    if (variant === "salesStatuses") return "drawerTitles.erpSalesStatuses";
    if (variant === "salesDocumentStatuses")
      return "drawerTitles.erpSalesDocumentStatuses";
    if (variant === "purchaseStatuses")
      return "drawerTitles.erpPurchaseStatuses";
    if (variant === "purchaseDocumentStatuses")
      return "drawerTitles.erpPurchaseDocumentStatuses";
    return null;
  }, [variant]);
  const items = useMemo(() => {
    const object = order || lineItem || null;
    if (variant === "salesStatuses") return object?.salesStatuses;
    if (variant === "salesDocumentStatuses")
      return order?.salesDocumentStatuses;
    if (variant === "purchaseStatuses") return object?.purchaseStatuses;
    if (variant === "purchaseDocumentStatuses")
      return order?.purchaseDocumentStatuses;
    return null;
  }, [order, lineItem, variant]);
  const Component = useMemo(() => {
    if (variant === "salesStatuses") return Status.Sales;
    if (variant === "salesDocumentStatuses") return Status.Document;
    if (variant === "purchaseStatuses") return Status.Purchase;
    if (variant === "purchaseDocumentStatuses") return Status.Document;
    return null;
  }, [variant]);
  const sortItems = useMemo(() => {
    return items?.sort(
      (a, b) =>
        new Date(b.eventTime).getTime() - new Date(a.eventTime).getTime()
    );
  }, [items]);
  if (!Component || !sortItems) return null;
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base font-medium text-dark">
            <Text>{title ?? ""}</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="divide-y">
          {sortItems.map((e, i, items) => {
            const hasMoreItems = items.length > 1;
            const isCurrentStatus = i === 0;
            const current = e.status as any;
            const prev = items[i + 1]?.status as any;
            const hasPrev = prev !== undefined;
            const key = [variant, prev, current, e.eventTime].join("-");
            if (!hasPrev && hasMoreItems) return null;
            return (
              <div key={key} className="flex items-center gap-4 py-3">
                <div className="flex-1 space-y-3.5">
                  <p className="flex items-center gap-2.5">
                    {hasPrev && (
                      <Fragment>
                        <Component id={prev} isOrderPublished />
                        <Icon
                          name="ArrowRight2"
                          size={20}
                          className="text-[#A1A4AC]"
                        />
                      </Fragment>
                    )}
                    <Component id={current} isOrderPublished />
                  </p>
                  <p className="text-secondary text-xs font-medium">
                    <Icon name="Calendar2" variant="Bulk" size={16} />{" "}
                    {convertDate(e.eventTime, dateFormats.fullDate)}
                  </p>
                </div>
                {isCurrentStatus && (
                  <Badge className="bg-opacity-100 bg-gray-50 text-gray-900 text-xs py-1 px-3">
                    <Text>global.currentErpStatus</Text>
                  </Badge>
                )}
              </div>
            );
          })}
        </Drawer.Body>
        <Drawer.Footer className="flex-center">
          <Button type="button" variant="primary" light onClick={toggle}>
            <Text>button.close</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
