import { createContext, ReactNode, useRef } from "react";
import { Transition, TransitionStatus } from "react-transition-group";
import { twMerge } from "tailwind-merge";
import { sizeType, togglePropsType } from "../types";
import Portal from "./_Portal";
type ModalProps = togglePropsType & {
  children: ReactNode;
  size?: sizeType;
  className?: string;
  modalClassName?: string;
};
type ModalElementProps = {
  className?: string;
  children: ReactNode;
};
type ModalContextType = {
  transitionState: TransitionStatus;
};
type TransitionClasses = { [key in TransitionStatus]: string };
const ModalContext = createContext({} as ModalContextType);
function Modal({
  isOpen,
  toggle = () => {},
  size = "md",
  className = "",
  modalClassName = "",
  children,
}: ModalProps) {
  const divRef = useRef<HTMLDivElement | null>(null);
  const transitionClasses: TransitionClasses = {
    entering: "opacity-100 pointer-events-auto active",
    entered: "opacity-100 pointer-events-auto active",
    exiting: "opacity-0 pointer-events-none",
    exited: "opacity-0 pointer-events-none",
    unmounted: "",
  };
  const transitionDialogClasses: TransitionClasses = {
    entering: "translate-y-0",
    entered: "translate-y-0",
    exiting: "translate-y-[25vh]",
    exited: "translate-y-[25vh]",
    unmounted: "",
  };
  return (
    <Transition nodeRef={divRef} in={isOpen} timeout={300} unmountOnExit>
      {(state) => (
        <Portal>
          <div
            ref={divRef}
            className={twMerge(
              "modal group fixed inset-0 flex flex-col z-30 bg-black/20 transition-opacity",
              transitionClasses[state],
              modalClassName
            )}
          >
            <button
              type="button"
              onClick={() => toggle()}
              className="absolute inset-0 cursor-default opacity-0"
            />
            <div
              className={twMerge(
                `modal-dialog modal-${size} relative flex flex-col bg-white m-auto w-full rounded shadow px-6 transition-transform max-w-[97.5%] max-h-[97.5%] min-[576px]:max-w-[500px] min-[576px]:[&.modal-sm]:max-w-[300px] min-[992px]:[&.modal-lg]:max-w-[800px] min-[992px]:[&.modal-xl]:max-w-[800px] min-[1200px]:[&.modal-xl]:max-w-[1140px]`,
                transitionDialogClasses[state],
                className
              )}
            >
              <ModalContext.Provider value={{ transitionState: state }}>
                {children}
              </ModalContext.Provider>
            </div>
          </div>
        </Portal>
      )}
    </Transition>
  );
}
function ModalHeader({ className = "", children = null }: ModalElementProps) {
  return (
    <div
      className={`w-full text-center py-4 border-b border-gray ${className}`}
    >
      {children}
    </div>
  );
}
function ModalBody({ className = "", children = null }: ModalElementProps) {
  return (
    <div className={`flex-1 py-4 overflow-auto ${className}`}>{children}</div>
  );
}
function ModalFooter({ className = "", children = null }: ModalElementProps) {
  return (
    <div className={`w-full py-4 border-t border-gray ${className}`}>
      {children}
    </div>
  );
}
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
export default Modal;
