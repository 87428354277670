import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Permission } from "../types";

const permissions = createSlice({
  name: "permissions",
  initialState: [] as Permission[],
  reducers: {
    setPermissions: (state, action: PayloadAction<Permission[]>) => {
      return action.payload;
    },
  },
});

export const { setPermissions } = permissions.actions;
export default permissions.reducer;
