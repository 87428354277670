import { ErpStatusDto } from "../types";

type Arg<T> = {
  statuses?: null | ErpStatusDto<T>[];
};

export default function useErpStatus<T>({ statuses }: Arg<T>) {
  const toNumber = (str: string) => new Date(str).getTime();
  const sorted = statuses?.sort(
    (a, b) => toNumber(b.eventTime) - toNumber(a.eventTime)
  );
  const active = sorted?.[0]?.status;
  return active ?? null;
}
