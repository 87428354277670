import { useContext } from "react";
import { SupplierDetailsContext } from "..";
import { Text, Wrapper } from "../../../../components";

export default function BankInfo() {
  const { supplier } = useContext(SupplierDetailsContext);
  const bankInfoItems = [
    { label: "suppliers.bankInfo.name", value: supplier?.bankInfo?.bankName },
    { label: "suppliers.bankInfo.iban", value: supplier?.bankInfo?.iban },
    {
      label: "suppliers.bankInfo.ownerName",
      value: supplier?.bankInfo?.accountOwnerName,
    },
    { label: "suppliers.bankInfo.bic", value: supplier?.bankInfo?.bic },
  ];
  return (
    <Wrapper>
      <Wrapper.Header className="border-b-0">
        <h2 className="text-dark text-sm font-medium">
          <Text>suppliers.bankInfo.title</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body>
        <ul className="w-full divide-y">
          {bankInfoItems.map((e) => (
            <li
              key={e.label}
              className="flex items-start gap-5 py-3 first:pt-0 last:pb-0"
            >
              <h6 className="flex-1 text-secondary text-sm font-normal">
                <Text>{e.label}</Text>
              </h6>
              <p className="flex-[2] text-gray-900 text-sm font-normal">
                {e.value}
              </p>
            </li>
          ))}
        </ul>
      </Wrapper.Body>
    </Wrapper>
  );
}
