import { useEffect, useState } from "react";
import {
  Layout,
  NoItems,
  Pagination,
  SearchBox,
  Text,
} from "../../../components";
import { config } from "../../../constants";
import { useAxios } from "../../../hooks";
import { Supplier, WithPaging } from "../../../types";
import SupplierItem from "./SupplierItem";
import SupplierLoading from "./SupplierLoading";

type Data = Supplier.SalesItem;
type Params = Omit<Supplier.SalesParams, "channelCode">;

export default function SupplierList() {
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Data>>({});
  const [params, setParams] = useState<Params>({
    keyword: null,
    pageNumber: 1,
    pageSize: config.pageSize,
    supplierType: null,
  });
  const hasData = !!data.items?.length;
  const handleSetParams = (key: keyof Params) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p) => ({ ...p, [key]: val }));
    };
  };
  const getData = () => {
    const url = "/salesservice/api/suppliers";
    const config = { params: { ...params } };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Layout>
      <Layout.Header>
        <SearchBox
          onSubmit={handleSetParams("keyword")}
          placeholder="suppliers.list.searchBox"
          className="w-auto flex-1"
        />
        {/* <Button light>
          <Icon name="Element3" variant="Bold" size={20} />
        </Button>
        <Button light>
          <Icon name="Scan" variant="Bold" size={20} />
        </Button>
        <Button light>
          <Icon name="Sort" size={20} />
        </Button>
        <Button light>
          <Icon name="Filter" variant="Bold" size={20} />
        </Button> */}
      </Layout.Header>
      <Layout.Body className="space-y-5 py-8">
        <h1 className="text-sm text-dark font-medium">
          <Text>suppliers.list.title</Text>
        </h1>
        <section className="w-full space-y-4 overflow-auto">
          {loading.get ? (
            <SupplierLoading />
          ) : hasData ? (
            data.items?.map((e) => (
              <SupplierItem key={e.supplierId} supplier={e} />
            ))
          ) : (
            <NoItems />
          )}
        </section>
        <Pagination
          pageNumber={params?.pageNumber ?? 0}
          totalItems={data?.totalItems ?? 0}
          totalPages={data?.totalPages ?? 0}
          setActivePage={handleSetParams("pageNumber")}
        />
      </Layout.Body>
    </Layout>
  );
}
