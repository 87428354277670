import isEmpty from "lodash/isEmpty";
import { Fragment, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon, Modal, Text } from "../components";
import { useDefaultSaleChannel, useLogout, useSelector } from "../hooks";
import {
  toggleSelectSaleChannelMenu,
  toggleSelectSaleChannelModal,
} from "../redux/selectSaleChannelActions";
export default function SelectSaleChannelModal() {
  const dispatch = useDispatch();
  const logout = useLogout();
  const profile = useSelector((s) => s.profile);
  const defaultSaleChannel = useDefaultSaleChannel();
  const hasDefaultSaleChannel = !!defaultSaleChannel;
  const showSelectSaleChannelMenu = useSelector(
    (s) => s.selectSaleChannelActions.showSelectSaleChannelMenu
  );
  const showSelectSaleChannelModal = useSelector(
    (s) => s.selectSaleChannelActions.showSelectSaleChannelModal
  );
  const isOpen = useMemo(() => {
    if (isEmpty(profile)) return false;
    if (!profile.defaultSaleChannel) return !showSelectSaleChannelMenu;
    return showSelectSaleChannelModal;
  }, [profile, showSelectSaleChannelModal, showSelectSaleChannelMenu]);
  const toggle = () => {
    dispatch(toggleSelectSaleChannelModal());
  };
  const toggleSaleChannelMenu = () => {
    dispatch(toggleSelectSaleChannelMenu());
  };
  return (
    <Fragment>
      {hasDefaultSaleChannel ? (
        <Modal isOpen={isOpen} toggle={toggle}>
          <Modal.Body>
            <div className="space-y-3 text-center border-b border-gray-100 pb-5 mb-10">
              <div className="w-20 h-20 bg-[#F3F6F9] rounded flex flex-center mx-auto">
                <Icon
                  name="DirectboxDefault"
                  variant="Bold"
                  className="text-placeholder"
                  size={48}
                />
              </div>
              <h6>{defaultSaleChannel?.title}</h6>
              <p className="text-secondary">#{defaultSaleChannel.code}</p>
            </div>
            <button
              className="flex items-center gap-4"
              onClick={() => {
                toggle();
                toggleSaleChannelMenu();
              }}
            >
              <Icon.Wrapper variant="secondary">
                <Icon
                  name="RefreshSquare"
                  variant="Bold"
                  className="text-primary"
                />
              </Icon.Wrapper>
              <Text>button.changeSaleChannel</Text>
            </button>
          </Modal.Body>
          <Modal.Footer className="flex flex-center">
            <Button light onClick={toggle}>
              <Text>button.close</Text>
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal isOpen={isOpen} toggle={() => {}}>
          <Modal.Body className="space-y-3 text-center">
            <div className="w-20 h-20 bg-[#F3F6F9] rounded flex flex-center mx-auto">
              <Icon
                name="DirectboxDefault"
                variant="Bold"
                className="text-placeholder"
                size={48}
              />
            </div>
            <h6>
              <Text>global.selectSaleChannel</Text>
            </h6>
            <p>
              <Text>alertDescriptions.selectSaleChannel</Text>
            </p>
          </Modal.Body>
          <Modal.Footer className="flex-col flex-center gap-2">
            <Button type="button" light onClick={toggleSaleChannelMenu}>
              <Text>button.selectSaleChannel</Text>
            </Button>
            <Button type="button" variant="danger" light onClick={logout}>
              <Text>global.logout</Text>
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
}
