import { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { config, languages } from "../constants";
import { languageType } from "../types";

export default function useLanguage(): [languageType, Dispatch<languageType>] {
  const { i18n } = useTranslation();
  const lng = i18n.language as languageType;
  const changeLanguage: Dispatch<languageType> = (id) => {
    const language = languages.find((e) => e.id === id);
    const languageId = language?.id ?? config.lang;
    i18n.changeLanguage(languageId, () => {
      document.documentElement.lang = languageId;
    });
    localStorage.setItem("language", languageId);
  };
  return [lng, changeLanguage];
}
