const company = {
  selectAccountType: {
    title: "Select account type",
    companyLabel: "Individual Customer",
    companyDescription: "Private account for customers",
    personLabel: "Company Customer",
    personDescription: "Create company account",
  },
  create: {
    title: "New Company customer",
    tab: {
      basicInfo: "Company info",
      basicInfoDescription: "Name & ...",
      contactInfo: "Contact info",
      contactInfoDescription: "Email & number",
      location: "Location",
      locationDescription: "Address & ...",
      completed: "Completed",
      completedDescription: "Woah, we are here",
    },
    cancelBtn: "cancel",
    backBtn: "Back",
    submitBtn: "Submit Company",
    nextBtn: "Next",
    successMessage: "Company added successfully.",
    basicInfo: {
      companyName: "Company name",
      companyRegistrationNumber: "Registration Number",
      eligibleForTaxDeduction: "Eligible for Tax",
      taxId: "Tax ID",
    },
    contactInfo: {
      firstName: "First name",
      firstNamePlaceholder: "First name",
      lastName: "Last name",
      lastNamePlaceholder: "Last name",
      gender: "Gender",
      genderPlaceholder: "Gender",
      salutation: "Salutation",
      salutationPlaceholder: "Select ...",
      salutationTitle: "Salutation title",
      salutationTitlePlaceholder: "Select ...",
      emailAddress: "Email address",
      emailAddressPlaceholder: "sample@test.com",
      position: "Position",
      positionPlaceholder: "Position",
      mobileNumber: "Contact mobile Number",
      mobileNumberPlaceholder: "111 22 33 444",
      phoneNumber: "Contact Phone Number",
      phoneNumberPlaceholder: "111 22 33 444",
    },
    completed: {
      taxId: "Tax ID",
      companyRegistrationNumber: "Registration Number",
    },
    termsConditions: {
      title: "Terms & Conditions",
      gdprAccepted: "DSGVO Akzeptiert Terms & conditions",
      companyRegistrationNumberApproved: "Company Registration number Approved",
      personPowerOfAttorneyApproved: "Person power of attorney Approved",
      cancelBtn: "Cancel",
      submitBtn: "Submit company",
    },
  },
  details: {
    cimNumber: "CIM",
    taxId: "Tax ID",
    companyRegistrationNumber: "Registration Number",
    contactPersonTitle: "Contact person",
    tabs: {
      overview: "Overview",
      contactPerson: "Contact Person",
      customerCommunicationChannels: "Communication channels",
      offers: "Offers",
      orders: "Orders",
      incidents: "Incidents",
    },
    changeStatus: {
      changeBtn: "Change",
      title: "Terms & Conditions",
      gdprAccepted: "DSGVO Akzeptiert Terms & conditions",
      companyRegistrationNumberApproved: "Company Registration number Approved",
      personPowerOfAttorneyApproved: "Person power of attorney Approved",
      cancelBtn: "Cancel",
      submitBtn: "Submit company",
      successMessage: "Company status updated successfully.",
    },
    overview: {
      submitText: "Please submit your changes",
      submitBtn: "Submit changes",
      companyName: "Company name",
      eligibleForTaxDeduction: "Eligible for Tax",
      taxId: "Tax ID",
      companyRegistrationNumber: "Registration Number",
      emailAddress: "Email address",
      countryCode: "Country code",
      mobileNumber: "Contact mobile Number",
      phoneNumber: "Contact Phone Number",
      editMessage: "Company edited successfully.",
    },
    contactPerson: {
      defaultPersonTitle: "Default Contact Person",
      editBtn: "Edit",
      contactPersonsTitle: "Contact persons",
      addPersonBtn: "Add contact person",
      viewDetailsBtn: "View details",
      position: "Position",
      form: {
        formTitle: "Add Contact Person",
        firstName: "First name",
        firstNamePlaceholder: "First name",
        lastName: "Last name",
        lastNamePlaceholder: "Last name",
        gender: "Gender",
        genderPlaceholder: "Gender",
        salutation: "Salutation",
        salutationPlaceholder: "Select ...",
        salutationTitle: "Salutation title",
        salutationTitlePlaceholder: "Select ...",
        emailAddress: "Email address",
        emailAddressPlaceholder: "sample@test.com",
        position: "Position",
        positionPlaceholder: "Position",
        countryCode: "Country code",
        mobileNumber: "Contact mobile Number",
        mobileNumberPlaceholder: "111 22 33 444",
        phoneNumber: "Contact Phone Number",
        phoneNumberPlaceholder: "111 22 33 444",
        editDefaultPersonMessage: "Default person updated successfully.",
        editPersonMessage: "Person updated successfully.",
        addPersonMessage: "Person added successfully.",
        cancelBtn: "Cancel",
        submitPerson: "Submit person",
        submitChanges: "Submit changes",
      },
      remove: {
        title: "Remove contact person",
        text: "are you sure you want to remove this contact person?",
        cancelBtn: "Cancel",
        removeBtn: "Remove",
        removeMessage: "Contact person deleted successfully.",
      },
    },
    communicationChannels: {
      phone: "Phone",
      application: "In App-Benachrichtigung",
      socialMedia: "Whatsapp",
      email: "Email",
      sms: "SMS",
      post: "Post",
      newsletter: "News letter",
      bulkEmail: "Bulk email",
    },
    offer: {
      form: {
        contactPerson: "Contact person",
        contactPersonPlaceholder: "Select ....",
        changeBtn: "Change",
        selectContactPersonTitle: "Select Company contact person",
        chooseContactPersonBtn: "Choose",
      },
    },
  },
};
export default company;
